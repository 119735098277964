import React, { useEffect, useState } from "react"
import { useAuth } from "../../../AUTH_CONFIGS/Auth"
import { useNavigate } from "react-router-dom"
import { useLocation } from "react-router-dom";
import { styled } from '@mui/system';
import ReactLoading from "react-loading";
import Loading from "../../components/loading/Loading";


import logo from '../../../media/logos/logo_branca.png'
import logo_small from '../../../media/logos/logo_branca_small.png'
import arrow_left_light from '../../../media/icons/arrow-left-light.svg'
import arrow_right_light from '../../../media/icons/arrow-right-light.svg'
import home_dark from '../../../media/icons/home-dark.svg'
import userconfig_light from '../../../media/icons/user-config-light.svg'
import userconfig_dark from '../../../media/icons/user-config-dark.svg'
import home_light from '../../../media/icons/home-light.svg'
import test_light from '../../../media/icons/test-light.svg'
import test_dark from '../../../media/icons/test-dark.svg'
import people_dark from '../../../media/icons/people-dark.svg'
import people_light from '../../../media/icons/people-light.svg'
import target_light from '../../../media/icons/target-light.svg'
import target_dark from '../../../media/icons/target-dark.svg'
import calendar_dark from '../../../media/icons/calendar-dark.svg'
import calendar_light from '../../../media/icons/calendar-light.svg'
import marketing_light from '../../../media/icons/marketing-light.svg'
import marketing_dark from '../../../media/icons/marketing-dark.svg'
import course_dark from '../../../media/icons/course-dark.svg'
import course_light from '../../../media/icons/course-light.svg'
import idea_light from '../../../media/icons/idea-light.svg'
import idea_dark from '../../../media/icons/idea-dark.svg'
import flag_dark from '../../../media/icons/flag-dark.svg'
import flag_light from '../../../media/icons/flag-light.svg'
import staff_light from '../../../media/icons/staff-light.svg'
import staff_dark from '../../../media/icons/staff-dark.svg'
import avatar_dark from '../../../media/icons/avatar-dark.svg'
import avatar_light from '../../../media/icons/avatar-light.svg'
import promotores_light from '../../../media/icons/recommended-svgrepo-com.svg'
import promotores_dark from '../../../media/icons/recommended-svgrepo-com (1).svg'

import Courses_adm from "../../components/courses";
import Dashboard_adm from "../../components/dashboard";
import Inscription_adm from "../../components/inscription";
import Candidate_adm from "../../components/candidates";
import Campaign_adm from "../../components/campaign";
import Ways_to_join_adm from "../../components/ways_to_join";
import Essay_adm from "../../components/essay";
import Leads from "../../components/leads/Index";
import API from "../../../API_CONFIGS/redux/API";
import EssayThemes from "../../components/EssayThemes/EssayThemes";
import Users from "../../components/Users/Users";
import Promotores_adm from "../../components/promotores";

const RootStyle = styled('div')(({ theme }) => ({
    width: '100%',
    display:'flex'
}));

const LeftNavBar = styled('div')(({theme}) => ({
    width:'300px',
    minHeight:`100vh`,
    maxHeight:window.screen.height,
    display:'flex',
    flexDirection:'column',
    backgroundColor:'#1e1e2d',
    alignItems:'center',
    gap:'7px',


}))
const PageContent = styled('div')(({theme}) => ({
    width:'calc(100% - 300px)',
    // minHeight:`100vh`,
    // maxHeight:window.screen.height,
    display:'flex',
    // flexDirection:'column',
    // backgroundColor:'#1e1e2d',
    // alignItems:'center',
    // gap:'7px',


}))


const SingleOption = styled('div')(({theme}) => ({
    display:'flex',
    gap:'10px',

    width:'90%',
    padding:'5px',
    paddingLeft:'10px',
    fontSize:'12pt',
    borderRadius:'5px',
    display:'flex',
    alignItems:'center',
    color:'white',
    fontWeight:'600px',
    cursor:'pointer',

    '&:hover': {
        backgroundColor: '#fff',
        color: '#1e1e2d', 
      },
    

}))

const UserOption = styled('div')(({theme}) => ({
    display:'flex',
    gap:'10px',

    width:'100%',
    padding:'5px',
    paddingLeft:'10px',
    fontSize:'12pt',
    borderRadius:'5px',
    display:'flex',
    alignItems:'center',
    color:'#1e1e2d',
    fontWeight:'600px',
    cursor:'pointer',
    textAlign:'center',
    justifyContent:'center',

    '&:hover': {
        backgroundColor: '#fff',
        fontWeight:'bold',
      },
    

}))







const options = [
    {name:'Dashboard',image_dark:home_dark,image_light:home_light, path:'dashboard'},
    {name:'Gerenciar Inscrições',image_dark:userconfig_dark,image_light:userconfig_light, path:'inscricoes'},
    {name:'Redações',image_dark:test_dark,image_light:test_light, path:'redacao'},
    {name:'Gerenciar Candidatos',image_dark:people_dark,image_light:people_light, path:'candidatos'},
    {name:'Formas de Ingresso',image_dark:target_dark,image_light:target_light, path:'ingresso'},
    {name:'Campanhas',image_dark:calendar_dark,image_light:calendar_light, path:'campanhas'},
    {name:'Leads (Marketing)',image_dark:marketing_dark,image_light:marketing_light, path:'leads'},
    {name:'Cursos',image_dark:course_dark,image_light:course_light, path:'cursos'},
    {name:'Temas das Redações',image_dark:idea_dark,image_light:idea_light, path:'temas'},
    {name:'Banners',image_dark:flag_dark,image_light:flag_light, path:'banners'},
    {name:'Usuários',image_dark:staff_dark,image_light:staff_light, path:'usuarios'},
    {name:'Promotores',image_dark:marketing_dark,image_light:marketing_light, path:'promotores'},
]








 
export default function Root_navbar_body(props){
    const navigate = useNavigate()
    const {currentUser} = useAuth()

    const {state} = useLocation()

    const {logout} = useAuth() 

    const [isHover,setIsHover]=useState(state?.isSelected || -1)
    const [isSelected,setIsSelected]=useState(state?.isSelected ||0)
    const [isClosed,setIsClosed]=useState(false)
    const [loading,setLoading]=useState(false)
    const [openUserModal,setOpenUserModal]=useState(false)
    const [leadCount,setLeadCount]=useState(0)


    // useEffect(()=>{
    //     if(!currentUser){
    //         navigate('/login_administrativo')
    //     }
    //     if(currentUser && currentUser.id && !currentUser.tipo_usuario_id ){
    //         navigate('/vestibular')
    //     }
    //     else{
    //         navigate('/login')
    //     }
    // },[currentUser])


    useEffect(()=>{

        API.get('administrativo/getallleads_unviewed')
        .then((res)=>{
            setLeadCount(res?.data?.leads)
        })
        .catch((error)=>{

        })


    },[])

    
    useEffect(()=>{

        const path = window.location.pathname
        const subject = path.split('/')[2]

        switch (subject) {
            case 'redacao':
                setIsSelected(2)
                break;
        
            case 'candidatos':
                setIsSelected(3)
                break;
        
            case 'inscricoes':
                setIsSelected(1)
                break;
        
            case 'dashboard':
                setIsSelected(0)
                break;
        
            case 'campanhas':
                setIsSelected(5)
                break;
        
            case 'ingresso':
                setIsSelected(4)
                break;
        
            case 'leads':
                setIsSelected(6)
                break;
        
            case 'cursos':
                setIsSelected(7)
                break;
        
            case 'temas':
                setIsSelected(8)
                break;
        
            case 'banners':
                setIsSelected(9)
                break;
        
            case 'usuarios':
                setIsSelected(10)
                break;
        
            case 'promotores':
                setIsSelected(11)
                break;
        
            default:
                setIsSelected(0)
                break;
        }

    },[])



    return (
    <RootStyle >

        <Loading active={loading} />

        <LeftNavBar style={{width:isClosed&&'70px'}} id="panel_adm_navbar">
            {isClosed?
                <img src={arrow_right_light}  onClick={()=>{setIsClosed(false)}} style={{cursor:'pointer', display:'flex', padding:'5px', margin:'0px auto 0px auto', width:'35px',  }}/>
                :
                <img src={arrow_left_light}  onClick={()=>{setIsClosed(true)}} style={{cursor:'pointer', display:'flex', padding:'5px', margin:'0px 0px 0px auto', width:'35px',  }}/>
            }

            {isClosed?
                <>
                    <img src={logo_small} width='80%' onClick={()=>{navigate('/painel_administrativo')}} style={{cursor:'pointer', marginBottom:'30px',  }}/>

                </>
                :
                <>
                    <img src={logo} width='80%' onClick={()=>{navigate('/painel_administrativo')}} style={{cursor:'pointer', }}/>

                </>
            }
            {options && options.map((single,index)=>{
                return(
                    <SingleOption style={{backgroundColor:isSelected===index&&'#fff',color:isSelected===index&&'#1e1e2d',}} onMouseOver={()=>{setIsHover(index)}} onMouseLeave={()=>{setIsHover(-1)}} onClick={()=>{window.location.pathname = `painel_administrativo/${single.path}`}}  key={index}>
                        {isHover===index || isSelected===index ?
                            <img src={single.image_dark} width='25px' style={{margin:isClosed&&'auto'}}/>
                            :
                            <img src={single.image_light} width='25px'style={{margin:isClosed&&'auto'}}/>
                        }
                        {
                            single?.path === 'leads' && leadCount>0 &&
                            <div style={{
                                    backgroundColor:'red',
                                    borderRadius:'99px',
                                    width:'20px',
                                    height:'20px',
                                    fontSize:'8pt',
                                    color:'white',
                                    fontWeight:'bold',
                                    display:'flex',
                                    alignItems:'center',
                                    justifyContent:'center',
                                    padding:'3px',

                                    position:'relative',
                                    left: isClosed ? '-30px' : '-20px',
                                    top:'-8px',

                                    marginRight:'-30px',




                                
                                }}>

                                {leadCount<=99 ? leadCount : '99+'}

                            </div>
                        }
                        {!isClosed&&single.name}
                    </SingleOption>
                )
            })}


                <div style={{width:'90%', height:'0.1px', backgroundColor:'white', margin:'auto auto 0px auto'}}/>

                    {openUserModal &&

                    <>

                        <div style={{width:'100vw', height:'100vh', backgroundColor:'rgba(0,0,0,0.3)',position:'fixed', zIndex:'997', right:'0',top:'0'}}    onClick={()=>{setOpenUserModal(!openUserModal)}}/>
                    
                    
                        <div style={{minWidth:'200px', minHeight:'50px', borderRadius:'10px', backgroundColor:'rgba(0, 220, 255)', zIndex:'999',
                            position:'relative',
                            right:isClosed?'-80px':'0px',
                            // bottom:'100px',
                            display:'flex', flexDirection:'column',
                            gap:'10px'
                        }}>
                            <UserOption style={{margin:'5px 0px 0px 0px', display:'flex', flexDirection:'column', gap:'0px', padding:'1px', cursor:'default'}}>{currentUser?.nome || 'Usuário'}<br/><p style={{fontSize:'10px', fontWeight:'normal', padding:'0px', margin:'0px'}}>Administrador</p></UserOption>
                            <UserOption style={{color:'red', margin:'auto 0px 5px 0px'}}
                            onClick={()=>{
                                    localStorage.removeItem("token");
                                    localStorage.removeItem("currentUser");
                                    return window.location.href = '/login_administrativo'
                            }}
                            >LogOut</UserOption>
                        </div>
                        <div style={{
                                width: "0px",
                                height: "0px",
                                borderStyle: "solid",
                                borderWidth: "20px 20px 0 20px",
                                borderColor: "rgba(0, 220, 255,1) transparent transparent transparent",
                                transform: "rotate(0deg)",
                                position:'relative',
                                bottom:'10px',
                                right:isClosed?'-10px':'40px',
                                zIndex:'998',
                            
                        }}/>
                        </>
                    }
                <SingleOption style={{bottom:'0px', margin:'5px auto 10px auto', backgroundColor:isSelected===999&&'#fff',color:isSelected===999&&'#1e1e2d', zIndex:'999'}} onMouseOver={()=>{setIsHover(999)}} onMouseLeave={()=>{setIsHover(-1)}} onClick={()=>{setOpenUserModal(!openUserModal)}} >
                        {isHover===999 || isSelected===999 ?
                            <img src={avatar_dark} width='35px' style={{margin:isClosed&&'auto'}}/>
                            :
                            <img src={avatar_light} width='35px'style={{margin:isClosed&&'auto'}}/>
                        }

                        {!isClosed&&
                            <div style={{fontSize:'12pt', fontWeight:'bold'}}>
                                Minha Conta
                                {currentUser&&currentUser.nome&&
                                    <div style={{fontSize:'8pt', fontWeight:'normal'}}>
                                        {currentUser.nome}
                                    </div>
                                }
                            </div>
                        }
                </SingleOption>


        </LeftNavBar>


        

        <PageContent style={{width:isClosed&&'calc(100% - 70px)', overflow:'auto'}}>
            {isSelected===0 && <Dashboard_adm setLoading={setLoading} />}
            {isSelected===1 && <Inscription_adm setLoading={setLoading}/>}
            {isSelected===2 && <Essay_adm setLoading={setLoading} />}
            {isSelected===3 && <Candidate_adm setLoading={setLoading} />}
            {isSelected===4 && <Ways_to_join_adm setLoading={setLoading} />}
            {isSelected===5 && <Campaign_adm setLoading={setLoading} />}
            {isSelected===6 && <Leads setLoading={setLoading} setLeadCount={setLeadCount} />}
            {isSelected===7 && <Courses_adm setLoading={setLoading} />}
            {isSelected===8 && <EssayThemes setLoading={setLoading} />}
            {isSelected===10 && <Users setLoading={setLoading} />}
            {isSelected===11 && <Promotores_adm setLoading={setLoading} />}
        </PageContent>
        

    </RootStyle>    
    )
}