import React, {FC, useEffect, useState} from 'react'
import HTMLRenderer from '../renderstringhtml/Index';
import API from '../../../../API_CONFIGS/redux/API';
import { useAuth } from '../../../../AUTH_CONFIGS/Auth';
import { API_BASE, IMAGE_BASE } from '../../../../API_CONFIGS/constants';
import { toast } from 'react-toastify';
import DragAndDropExample from "../DragAndDrop";

import './step4.css'

interface step4vest {
    OKchosenTheme:boolean,
    setOKchosenTheme:(OKchosenTheme:boolean)=>void,
    chosenTheme:number,

    titleTest:string,
    settitleTest:(titleTest:string)=>void,
    textTest:string,
    settextTest:(textTest:string)=>void,

    wordCount:number,
    setwordCount:(wordCount:number)=>void,

    redacao:{tipo:string,id:number},
    tipo_redacao:string ,

    OKTestFiles:boolean,
    setOKTestFiles:(OKTestFiles:boolean)=>void,

    startCount:number,
    setstartCount:(startCount:number)=>void,

    setCronometro:()=>void,
    autoUpdateTest:()=>void,
    

  }
  

const Step4Vestibular: FC<step4vest> = ({
  tipo_redacao,OKchosenTheme,setOKchosenTheme, chosenTheme,titleTest,settitleTest,textTest,settextTest,wordCount,setwordCount,redacao,setOKTestFiles,startCount,setstartCount,setCronometro,autoUpdateTest
}) => {

    const {currentUser}=useAuth()

    const formatTime = (totalSeconds:number) => {
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(seconds).padStart(2, '0');
        return `${formattedMinutes}min e ${formattedSeconds}seg`;
      }
      
    const [startTest,setstartTest]=useState(false)
    
    
    const [themeInfo,setthemeInfo]=useState({nome:'',descricao:'',imagem:''})
    const [handleThemeInfo,sethandleThemeInfo]=useState(false)

    const [isPhotoType,setisPhotoType]=useState(false)
    const [isAnyDocChecked, setisAnyDocChecked]=useState(false)
    const [docSelectedId, setdocSelectedId]=useState('')


    const [allowSendFile, setAllowSendFile] = useState(true); 

    useEffect(()=>{
      // alert(docSelectedId)
      if(Number(docSelectedId)  && Number(docSelectedId) >= 0){
        setisAnyDocChecked(true)
      }
      
    },[docSelectedId])
  
    const handleCount = ()=>{

      if(startTest){
        setTimeout(() => {
          setstartCount(startCount-1)
          autoUpdateTest()

        }, 1000);
      }
    }

    useEffect(()=>{
      const interval = setInterval(() => {
        // autoUpdateTest()
      }, 10000); // 10 seconds in milliseconds
      // return () => clearInterval(interval);
    },[])

    useEffect(()=>{
        handleCount()
    },[startTest,startCount])


    useEffect(()=>{

      if(chosenTheme){
        const data = {
          chosenTheme:chosenTheme
        }
        API.post('/course/specificthemeinfo_redacao', data)
        .then((result)=>{
          if(result.data.status==='success'){
            // toast.success('Dados do tema obtidos')
            setthemeInfo(result.data.themeInfo)
          }
        })
        .catch((err)=>{
          toast.error('Erro ao obter dados do tema. Entre em contato com o suporte.')
          console.log(err);
          
        })
      }
      else{
        toast.error('Sem chosenTheme')
      }
    },[chosenTheme])

    const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        // Get the text content of the textarea
        const text: string = event.target.value;
      
        // Count the number of words
        const words: string[] = text.trim().split(/\s+/);
        const count: number = words.length;
      
        // Update the state with the word count
        setwordCount(count);
        settextTest(event.target.value)
      }
          
useEffect(()=>{
  if(redacao.tipo==='D'){
    setisPhotoType(false)
  }
  else if(redacao.tipo==='F'){
    setisPhotoType(true)
  }
  else if(tipo_redacao==='F'){
    setisPhotoType(true)
  }
  else if(tipo_redacao==='D'){
    setisPhotoType(false)
  }
},[redacao])
    
const handleOKTestFiles=()=>{
  if(redacao){
    API.post('/course/checkfiles_redacao/'+redacao.id)
    .then((res)=>{
      if(res.data.status==='success'){
        toast.success(res.data.message)
        setOKTestFiles(true)
        setAllowSendFile(false)
      }
    })
    .catch((err)=>{
      toast.error(err)
    })
  }
}

const handleFileRoute = 'upload_test'

  return (
      <div >
    
    {!startTest &&
        <div className="modal-overlay-step4">
            <div className="modal-content">
                <div className='adjust-content'>
                    <h1>Está pronto para começar a prova?</h1> 
                    <p>Você não poderá sair desta página até concluir a prova. Caso contrário, sua prova será perdida.</p>
                    <button className="close-btn" onClick={()=>{setstartTest(true);}}>Fechar</button>
                </div>
            </div>
        </div>
  }
    {handleThemeInfo &&
      <div className="modal-overlay-step4"> 
        <div className="modal-content" >
          <div className="modal-left">
            <img src={`${IMAGE_BASE}redacao_imagem/${themeInfo.imagem}`} />
          </div>
          <div className="modal-right">
            <h2>{themeInfo.nome}</h2>
            <HTMLRenderer htmlString={themeInfo.descricao}/>

            {/* <div>{themeInfo.descricao}</div> */}
            <button className="close-btn" onClick={()=>{sethandleThemeInfo(false)}}>Fechar</button>
          </div>
        </div>
      </div>
    } 

  <div className='card' style={{display:'flex'}}>
    <h1 className='titles'> Tema escolhido : </h1> 
    <h2 className='titles' style={{fontSize:'25px'}}>{themeInfo.nome}</h2>

    <div className=' card form-check-label text-gray-700 fw-bold text-nowrap ' style={{textAlign:'center',padding:'5px',margin:'10px',cursor:'pointer'}}
        onClick={()=>{sethandleThemeInfo(!handleThemeInfo)}}
    >
          Saber mais sobre o tema
    </div> 

  </div>



          <div className='card'>

          {isPhotoType?
          <>
                    {
                startCount && allowSendFile &&
                <div className='' style={{display:'flex'}}> 
                  <div style={{ minWidth:'50%',}}>
                  </div>
                  <div className=' timeleftcard'   
                  >
                      Tempo para concluir a prova: 
                        <br/>
                      <p style={{fontSize:'20pt'}}>
                        {formatTime(startCount)}
                      </p>
                  </div>
                </div>
              } 


              {allowSendFile?
              
              
              <DragAndDropExample 
              messageToShow={`Clique para fazer Upload da redação\n(Apenas imagens)`}
              supportedFiles={"image/*"}
              style={''}
              routeToSend={API_BASE+'course/sendfiles_redacao/'+(redacao?.id)} 
              //  canDrop={true} saveRoute={handleFileRoute} objectAuxID={redacao.id}
              setAllowSendFile={setAllowSendFile}
              allowSendFile={allowSendFile}
              multiple={false}
              handleAfterSend={handleOKTestFiles}
              />
            
              
              :


              <div style={{width:'100%', textAlign:'center', fontSize:'12pt', fontWeight:'bold', }}>
                Arquivos enviados com sucesso. Finalize a prova e avance para próxima etapa.
              </div>





            }


              <button className="close-btn" onClick={()=>{handleOKTestFiles()}}>Finalizar prova</button>


          </>
          :
          <>
                    {
                startCount && 
                <div className='' style={{display:'flex'}}> 
                  <div style={{ minWidth:'50%',}}>
                  </div>
                  <div className=' timeleftcard'   
                  >
                      Tempo para concluir a prova: 
                        <br/>
                      <p style={{fontSize:'20pt'}}>
                        {formatTime(startCount)}
                      </p>
                  </div>
                </div>
              } 



        <label className='titles'>Título da redação</label>
        <textarea rows={1} style={{resize:'none', width:'100%'}}
          value={titleTest}
          onChange={(e)=>{settitleTest(e.target.value)}}
        />
    
        <label className='titles'>Seu texto</label>
        <p style={{fontSize:'10pt'}}> seu texto tem {wordCount} palavras</p>
        <textarea rows={20} style={{resize:'none', width:'100%'}} onChange={handleTextChange}
          value={textTest}
        />

          </>

          }
    </div>




    </div>
  )
}

export {Step4Vestibular}

