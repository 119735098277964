import React, {FC, ReactChild, useEffect, useState, ReactElement} from 'react'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

import HTMLRenderer from '../renderstringhtml/Index'
import useWindowSize from '../../../../API_CONFIGS/useWindowSize'
import './step3.css'

import API from '../../../../API_CONFIGS/redux/API'
import {toast} from "react-toastify"

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IMAGE_BASE } from '../../../../API_CONFIGS/constants'

library.add(faCheck)






interface step3vest{
  tipo_redacao:string ,
  set_tipo_redacao:(tipo_redacao:string)=>void,
  OKchosenTheme:boolean,
  setOKchosenTheme:(OKchosenTheme:boolean)=>void,
  chosenTheme:number,
  setchosenTheme:(chosenTheme:number)=>void,
}

const Step3Vestibular: FC<step3vest> = ({tipo_redacao,set_tipo_redacao,OKchosenTheme,setOKchosenTheme,chosenTheme,setchosenTheme,}) => {

  const { width } = useWindowSize()
  const isMobile = width < 800



    const [themeInfo1,setthemeInfo1] = useState([{nome: '',descricao:'',imagem:'',id:-1}])

    const [handleThemeInfo,sethandleThemeInfo]=useState(false)
    const [confirmTheme,setconfirmTheme]=useState(false)
    const [handleImage,sethandleImage]=useState(false)
    const [viewTheme,setViewTheme]=useState(-1)

    useEffect(()=>{
      API.post('course/gettesttheme')
      .then((result)=>{
        setthemeInfo1(result.data.testTheme)
      })
      .catch((err)=>{ 
        toast.error('ERRO AO ENVIAR')
      })
  
    },[])


    // const change


    const convertTextToJSX = (text:string) => {
      const regex = /<([^>]+)>([^<]+)<\/\1>/g;
      const matches = text.matchAll(regex);
      let result = text;
    
      for (const match of matches) {
        const [fullMatch, tag, innerText] = match;
        const jsxTag = tag.toLowerCase();
    
        switch (jsxTag) {
          case "a":
            result = result.replace(fullMatch, `<${jsxTag} href="${innerText}">${convertTextToJSX(innerText)}</${jsxTag}>`);
            break;
          case "em":
          case "strong":
            result = result.replace(fullMatch, `<${jsxTag}>${convertTextToJSX(innerText)}</${jsxTag}>`);
            break;
          case "br":
            result = result.replace(fullMatch, `<${jsxTag} />`);
            break;
          default:
            result = result.replace(fullMatch, `<${jsxTag}>${convertTextToJSX(innerText)}</${jsxTag}>`);
            break;
        }
      }
    
      return result;
    };
    
    useEffect(()=>{
    },[chosenTheme])

    const handleConfirmTheme = ()=>{
      // alert(chosenTheme)
      const data={
        tema_id:chosenTheme,
        userID:'7483482345902390542',
        tipo:tipo_redacao,
      }

      // alert(JSON.stringify(data)) 
      setOKchosenTheme(true)

      setTimeout(() => {
        window.location.reload()
      }, 2000);


    }
    
  return (
      <div >
    
    {handleThemeInfo &&
      <div className="modal-overlay-step3"> 
        <div className="modal-content" >
          <div className="modal-left">
            <img src={`${IMAGE_BASE}redacao_imagem/${themeInfo1[viewTheme].imagem}`} />
          </div>
          <div className="modal-right">
            <h2>{themeInfo1[viewTheme].nome}</h2>
            <HTMLRenderer htmlString={themeInfo1[viewTheme].descricao}/>
            {/* <div>{convertTextToJSX(themeInfo1[viewTheme].descricao)}</div> */}
            <button className="close-btn" onClick={()=>{sethandleThemeInfo(false)}}>Fechar</button>
          </div>
        </div>
      </div>
    } 
    {confirmTheme &&
      <div className="modal-overlay-step3"> 
        <div className="modal-content-confirm" >  
            <div style={{display:'flex', flexDirection:isMobile?'column':'row', width:isMobile?'100%':'70%', margin:'auto'}}>
              <div className='alert-img'>
                <svg fill="#000000" width={800} height={800} viewBox="0 0 24 24" id="information-chat-right" data-name="Flat Line" xmlns="http://www.w3.org/2000/svg" className="icon flat-line">
                  <path id="secondary" d="M3.12,13.47a9,9,0,0,0,13,6.52h0l3.39.7a1,1,0,0,0,1.18-1.18L20,16.11A9,9,0,1,0,3.12,13.47Z" style={{fill: "rgb(44, 169, 188)", strokeWidth: 2}} />
                  <line id="primary-upstroke" x1={12.05} y1={8} x2={11.95} y2={8} style={{fill: "none", stroke: "rgb(0, 0, 0)", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 2.5}} />
                  <path id="primary" d="M12,16V13m-8.88.47a9,9,0,0,0,13,6.52h0l3.39.7a1,1,0,0,0,1.18-1.18L20,16.11A9,9,0,1,0,3.12,13.47Z" style={{fill: "none", stroke: "rgb(0, 0, 0)", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 2}} />
                </svg>
              </div>

            <div className='alert-txt'>
              <h1>Confirmo que escolhi o tema <div className='themeName'>"{themeInfo1[viewTheme].nome}"</div> para fazer minha prova</h1>
              <p>Uma vez confirmado, não poderei mudar o tema.</p>
              <button className="close-btn" onClick={()=>{setconfirmTheme(false)}} style={{marginRight:'10px'}}>Mudar tema</button>
              <button className="close-btn" onClick={()=>{handleConfirmTheme();setconfirmTheme(false)}}>Confirmar</button>
            </div>
          </div>
        </div> 
      </div>
    } 
    {handleImage &&
      <div className="modal-overlay-step3">
              <div className="modal-content-confirm" >
 
  <div style={{position: 'relative'}}>
    <img src={`${IMAGE_BASE}redacao_imagem/${themeInfo1[viewTheme].imagem}`} style={{width:'80%'}}/>
    <button 
      className="close-btn" 
      onClick={() => {sethandleImage(false)}}
      style={{position: 'absolute', top: 0, right: 10}}
    >
      Fechar
    </button>
  </div>
  </div>
</div>
    } 


    {!OKchosenTheme && <>


    
{themeInfo1?.map((theme,index)=>{

    return(
    <div className={` `} style={{padding:'5px', margin:'5px',width:'100%', border:'0.5px solid #CCC', borderRadius:'10px'}}> 
      {/* begin::Body */}
      <div className=''
        style={{display:'flex',padding:'10px', flexDirection:isMobile?'column':'row', width:'100%'}}   
      >
        <img className="imgTheme"
          style={isMobile?{
            maxWidth:'100%',
            maxHeight:'40%',     
          }:{}}
          src={`${IMAGE_BASE}redacao_imagem/${theme.imagem}`} onClick={()=>{setViewTheme(index);sethandleImage(!handleImage)}}/>
        <div >

        
        <div >
          <div className='d-flex  '  style={{ padding:'10px', }} >

              <a className='nome'  >
                {theme.nome}
                {!OKchosenTheme &&
                    <div className='chooseTheme'  onClick={() => {setchosenTheme(theme.id);setconfirmTheme(true);setViewTheme(index)}}>
                            <input
                            style={{backgroundColor:'rgba(0, 0, 0, 1)', marginRight:'5px',}}
                              type='radio'
                              checked={theme.id === chosenTheme} 
                              disabled={OKchosenTheme}
                              />
                            <div  >
                              Escolher este tema 
                            </div>  
                      </div>
                }
              </a>
          </div>
        </div>
        <div className=''>


          <div>
            {/* {convertTextToJSX(theme.descricao)} */}
            <HTMLRenderer htmlString={theme.descricao}/>
        </div>


            <div className=' card form-check-label text-gray-700 fw-bold text-nowrap ' style={{textAlign:'center',padding:'5px',margin:'10px',cursor:'pointer'}}
                onClick={()=>{setViewTheme(index); sethandleThemeInfo(!handleThemeInfo)}}
            >Saber mais sobre o tema</div> 
      </div>
      </div>
      </div>
    </div> 
)
})}

</>}
{OKchosenTheme && <>

  {themeInfo1.map((theme,index)=>{
  
  if(theme.id === chosenTheme){

    return(
      <div className={`card `} style={{padding:'5px', margin:'5px',width:'100%'}}> 
      {/* begin::Body */}
      <div className=''
        style={{display:'flex',padding:'10px'}}   
      >
        <img className="imgTheme" src={`${IMAGE_BASE}redacao_imagem/${theme.imagem}`} onClick={()=>{setViewTheme(index);sethandleImage(!handleImage)}}/>

        <div >

        
        <div >
          <div className='d-flex  '  style={{ padding:'10px', }} >

              <a className='nome'  >
                <div style={{color:'black',fontSize:'20pt'}}> Tema selecionado: </div> 
                  "{theme.nome}"
                {!OKchosenTheme &&
                    <div className='chooseTheme'  onClick={() => {setchosenTheme(theme.id);setconfirmTheme(true);setViewTheme(index)}}>
                            <input
                            style={{backgroundColor:'rgba(0, 0, 0, 1)', marginRight:'5px',}}
                              type='radio'
                              checked={theme.id === chosenTheme} 
                              disabled={OKchosenTheme}
                              />
                            <div  >
                              Escolher este tema 
                            </div>  
                      </div>
                }
              </a>
          </div>
        </div>
        <div className=''>


          <div>
            {/* {convertTextToJSX(theme.descricao)} */}
            <HTMLRenderer htmlString={theme.descricao}/>
        </div>


            <div className=' card form-check-label text-gray-700 fw-bold text-nowrap ' style={{textAlign:'center',padding:'5px',margin:'10px',cursor:'pointer'}}
                onClick={()=>{setViewTheme(index); sethandleThemeInfo(!handleThemeInfo)}}
            >Saber mais sobre o tema</div> 
      </div>
      </div>
      </div>
    </div> 
    )
  }

  })}

</>}
    </div>
  )
}

export {Step3Vestibular}

