import React from "react";

import {useState, useEffect} from 'react'
import DragAndDropExample from "../DragAndDrop";

import useWindowSize from "../../../../API_CONFIGS/useWindowSize";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import DoneIcon from '@mui/icons-material/Done';
import Avatar from '@mui/material/Avatar';

import { useAuth } from "../../../../AUTH_CONFIGS/Auth";
import { API_BASE } from "../../../../API_CONFIGS/constants";
import { IMAGE_BASE } from "../../../../API_CONFIGS/constants";
import API from "../../../../API_CONFIGS/redux/API";
import { toast } from "react-toastify";

import './step6.css'
import { json } from "node:stream/consumers";


import docImage from '../../../../media/icons/pdf.svg'


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];




interface Step6Props{ 
  redacao:{
    id: number;
    vestibular_id: number;
    tema_id: number;
    status_id: number;
    tipo: string;
    titulo: null;
    texto: null;
    imagem: null;
    cronometro: string;
    nota: null;
    created_at: string;
    updated_at: string;
    set: boolean;
  },

}





const Step6Vestibular: React.FC<Step6Props> = ({redacao}) => {
  const {currentUser}=useAuth()

  const { width } = useWindowSize()
  const isMobile = width < 800

  const [allDocumentsNeeded,setallDocumentsNeeded] = useState([{nome:'',id:-1,status_arquivo:-1,is_doc_pessoal:false,arquivo:'',fileExtensionPDF:0}])
  const [documentacao,setdocumentacao] = useState({observacao:'',id:-1})
  const [inscricao,setInscricao] = useState({id:-1})

  const [isAnyDocChecked, setisAnyDocChecked]=useState(false)
  const [docSelectedId, setdocSelectedId]=useState('-1')


  useEffect(()=>{
    if(Number(docSelectedId)  && Number(docSelectedId) >= 0){
      setisAnyDocChecked(true)
    }
    
  },[docSelectedId])

  useEffect(()=>{
    // alert(JSON.stringify(allDocumentsNeeded[0]))
  },[allDocumentsNeeded])

 const handleFileRoute = 'upload_vest_docs'


 // Rest of your code
 
 useEffect(() => {
   if (currentUser) {
   const fetchData = async () => {
     try {
         const res = await API.post('/course/handledocsvest', { redacao: redacao, userID: currentUser.id });
         if (res.data.status === 'success') {
           setallDocumentsNeeded(res.data.docTypes);
           setdocumentacao(res.data.documentacao);
           setInscricao(res.data.inscricao);
         } else {
           toast.error(res.data.message);
         }
       
     } catch (err) {
      toast.error('Erro durante carregamento. Contate o suporte ')
        console.log(err);
     }
    }
   
   
 
   fetchData(); // Initial fetch
 
   const intervalId = setInterval(fetchData, 1000); // Run fetch every 5 seconds
   return () => {
     clearInterval(intervalId); // Clean up the interval on unmount
   };

  }
  else {
    window.location.href = '/login'
  }
 }, []); // Empty dependency array to run only on mount/unmount
  
  

  // Handle change event for the select component
  const handleSelectChange = (event: any) => { // Use 'any' type for event
    setdocSelectedId(event.target.value as string); // Use type assertion to specify the type of event.target.value as string
  }

  const [handleImage,setHandleImage]=useState(false)
  const [isPDFFile,setIsPDFFile]=useState(false)
  const [imageUrl,setImageUrl]=useState('')
  const handleClickChip =(url:string,nome:string)=>{
    setHandleImage(!handleImage)
    setImageUrl(url)
    if(/.pdf$/i.test(nome)){
      setIsPDFFile(true)
    }
  }


  useEffect(()=>{

  },[])




  const handleDownload = () => {
    // Specify the URL of the PDF file
    const pdfUrl = `${IMAGE_BASE}contract_to_show/termo_de_compromisso.pdf`

    // Trigger download by changing the window location
    window.open(pdfUrl, '_blank');
  };


 
  return (
<div className="">

      {handleImage &&

            <div className="modal-overlay-step6"> 

      <div className="modal-content-confirm" >
        <div style={{position: 'relative', zIndex:'999999999', }}>

          {
            isPDFFile?
            <iframe src={imageUrl} ></iframe>

            :
            <img src={imageUrl} style={{width:'80%'}}/>
          }
          <button 
            className="close-btn" 
            onClick={() => {setHandleImage(false)}}
            style={{position: 'absolute', top: 0, right: 10}}
          >
            Fechar
          </button>
        </div>
      </div>
      </div>
          } 

  <div className="obs-section">
    <h6>Observações:</h6>
    <textarea value={documentacao?.observacao || 'Adicione todos os documentos mais atuais.'} disabled className="obs-area" rows={3} />
  </div>

  
  
  <div style={!isMobile?{display:'flex'}:{display:'flex', flexDirection:'column-reverse'}}>


      <div style={{width:!isMobile? '50%':'100%' }}>
        <label htmlFor="selectDoc" className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
          Situação dos documentos:
        </label>
        <Stack direction="column" spacing={1} >

        {allDocumentsNeeded && allDocumentsNeeded.map((single,index)=>{
            return(
              <Chip
                key={index}
                label={single?.nome || ''}
                onClick={()=>{if(single?.arquivo || '')handleClickChip(`${IMAGE_BASE}user_personal_files/${currentUser?currentUser.id:null}/${single?.id || ''}/${single?.arquivo || ''}`,single?.arquivo || '')}}
                // onDelete={handleDelete}
                deleteIcon={<DoneIcon />}
                color={single?.status_arquivo===0 ? "secondary":single?.status_arquivo===1?'success':single?.status_arquivo===2?'error':'default'}

                avatar={<Avatar  src={
                  !single.arquivo?
                  `${IMAGE_BASE}/avatars/close.svg`
                    :
                    single?.fileExtensionPDF?
                      `${IMAGE_BASE}/avatars/pdf.svg`
                        :
                        single?.is_doc_pessoal?
                          `${IMAGE_BASE}user_personal_files/${currentUser?currentUser.id:null}/${single.id}/${single.arquivo}`
                            :
                          `${IMAGE_BASE}/avatars/file.svg`
                } 
                  />}
              />
            )
          })}
          
          </Stack>

          <label htmlFor="selectDoc" className="d-flex align-items-center  fs-6 fw-bold form-label mb-2" style={{textAlign:'center'}}>
            Entenda as cores:
          </label>

          {/* <div style={{display:'flex', width:'50%'}}> */}
          {/* </div> */}


      </div>

      <div style={{padding:'10px',width:!isMobile? '50%':'100%'  }}>

        <FormControl sx={{width:'100%'}}>
          <InputLabel id="demo-simple-select-autowidth-label">Selecione um documento</InputLabel>
          <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            value={docSelectedId}
            onChange={handleSelectChange}
            autoWidth
            // label="Selecione um documento"
          >
            <MenuItem value="-1">
              ---- Selecione um documento ----
            </MenuItem>
            {allDocumentsNeeded.map((single,index)=>{
              if(!single.arquivo || single.status_arquivo===2){
                return(
                  <MenuItem value={single.id} key={index}>
                    {single.nome}
                  </MenuItem>
                )
              }
            })}
          </Select>
        </FormControl>
        <DragAndDropExample       
            setdocSelectedId={setdocSelectedId}           
            messageToShow={'Clique para fazer Upload do arquivo'}
            supportedFiles={"application/pdf, image/*"}
            // creating different routes for personal data on backend
            routeToSend={
              API_BASE+'course/sendfiles_personaldoc/'+(currentUser?currentUser.id:null) + '/' + inscricao.id + '/' + docSelectedId
            } 
            disabled={docSelectedId!=='-1'?false:true}
            multiple={false}
          />
    </div>




  </div>

            <Stack direction="row" spacing={1} sx={{width:'100%',textAlign:'center'}}>
                <Chip
                  label='Não enviado'
                  deleteIcon={<DoneIcon />}
                  color={"default"}
                  

                />
                <Chip
                  label='Em análise'
                  deleteIcon={<DoneIcon />}
                  color={"secondary"}
                />
                <Chip
                  label='Confirmado'
                  deleteIcon={<DoneIcon />}
                  color={"success"}
                />
                <Chip
                  label='Necessário correção'
                  deleteIcon={<DoneIcon />}
                  color={"error"}
                />

            </Stack>




            <div style={{display:'flex', flexDirection:'column', width:'100%', justifyContent:'center', alignContent:'initial', fontSize:'12pt', fontWeight:'normal', margin:'50px 0px 50px 0px' }}>

              <p style={{}}>Não possui um dos documentos?</p>
              <p style={{fontWeight:'normal', marginTop:'-20px'}}>Você pode utilizar o termo de compromisso abaixo:</p>


              <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', gap:'10px', margin:'30px auto 30px auto', }}>
                <img src={docImage} width={'50px'} alt="Termo de compromisso" style={{cursor:'pointer',  }} onClick={handleDownload}/>
                <p style={{cursor:'pointer',  }} onClick={handleDownload}>Termo de compromisso</p>
              </div>

            </div>



</div>



  )
}


export {Step6Vestibular}