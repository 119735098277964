import React, {useEffect, useRef, useState} from 'react'
import { Link } from 'react-router-dom'

import { useNavigate } from 'react-router-dom'


import { toast } from 'react-toastify';

import API from '../../../API_CONFIGS/redux/API';
 

export default function Registration_adm(){
  const navigate = useNavigate()

  const [name,setName]=useState('')
  const [nameOK,setNameOK]=useState('')
  const [email,setEmail]=useState('')
  const [emailOK,setEmailOK]=useState('')
  const [password,setPassword]=useState('')
  const [password1,setPassword1]=useState('')
  const [authcode,setAuthCode]=useState('')
  const [passwordOK,setPasswordOK]=useState('')
  const [passwordOK1,setPasswordOK1]=useState('')

  const handleSubmitForm = ()=>{
    // alert('dados obtidos: ' + JSON.stringify(allSteps))

    let data = {
      email,
      password,
      authcode,
      name,

    }
    API.post('/administrativo/register_adm', data)
    .then((result)=>{
      toast.success(result.data.message?result.data.message:'Sucesso')
      return navigate('/login_administrativo')
    })
    .catch((error)=>{
      if (error.response) {
        console.error('Server error:', error.response.data); // Log the custom error message
        return toast.error(error.response.data.message?error.response.data.message:'Erro')
      } else {
        console.error('Request error:', error.message);
        return toast.error(error.message?error.message:'Erro')
      }
})
  }



  const handleName=(e)=>{
    const value = e.target.value
    setName(value)
    if (value.length>=5 && value.includes(' ')!==-1) {
        const words = value.split(' ')
        if (words.length>1 && !words.some(single=>single.length<2)) {
            return setNameOK(true)
        }
    }
    return setNameOK(false)
  }

  const handleEmail=(e)=>{
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.(com|com.br);?$/;
    const value = e.target.value
    setEmail(value)
    if (emailRegex.test(value)) {
        return setEmailOK(true)
    }
    return setEmailOK(false)
  }

  const handlePassword=(e)=>{
    const passwordRegex = /^(?=.*[a-z]{3})(?=.*[A-Z]{3})(?=.*\d)(?=.*[\W_]).{8,}$/

    const value = e.target.value
    setPassword(value)
    if (passwordRegex.test(value)) {
        return setPasswordOK(true)
    }
    return setPasswordOK(false)
  }
  const handleAuthCode=(e)=>{
    const value = e.target.value
    setAuthCode(value)
  }
  const handlePassword1=(e)=>{
    const value = e.target.value
    setPassword1(value)
    if (value===password) {
        return setPasswordOK1(true)
    }
    return setPasswordOK1(false)
  }


  


  return (
    <div className='card'>

      <div className='card-body'>
        <div
          // ref={currentStep}
          className='stepper stepper-links d-flex flex-column '
          id='kt_create_account_stepper'
        >
          <div className='stepper-nav mb-5'>
            <div 
            className={"stepper-item" + " current"} 
            data-kt-stepper-element='nav'
            onClick={()=>{}}
            style={{cursor:'pointer'}}
            >
              <h3 className='stepper-title'>Dados pessoais</h3>
            </div>

          </div>

            <>
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6'>Nome Completo</label>
        <input
          value={name}
          onChange={handleName}
          placeholder='Nome'
          type='text'
          autoComplete='off'
          
          className={`form-control bg-transparent ${name && !nameOK&&'is-invalid'}`}
        />
          {name && !nameOK &&
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>O nome inválido.</span>
            </div>
          </div>
        }

      </div>
      {/* <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6'>Sobrenome</label>
        <input
        value={lastName}
        onChange={handleLastNameChange}
          placeholder='Sobrenome'
          type='text'
          autoComplete='off'
          className={'form-control bg-transparent'}
        />
      </div> */}

      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6'>Email</label>
        <input
        value={email}
        onChange={handleEmail}
          placeholder='Email'
          type='email'
          autoComplete='off'
          className={`form-control bg-transparent ${email&&!emailOK&&'is-invalid'}`}
        />
        {email&&!emailOK&&
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>O Email informado é inválido</span>
            </div>
          </div>
        }

      </div>
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6'>Senha</label>
        <input
        value={password}
        onChange={handlePassword}
          placeholder='Senha'
          type='text'
          className={`form-control bg-transparent inputNumber ${password&&!passwordOK&&'is-invalid'}`}

        />
        {password&&!passwordOK&&
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>A senha deve ter:<br/>8 ou mais caracteres;<br/>3 letras minúsculas;<br/>3 letras maiúsculas;<br/>1 número;<br/>1 caracter especial.</span>
            </div>
          </div>
        }

      </div>
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6'>Confirme senha</label>
        <input
        value={password1}
        onChange={handlePassword1}
          placeholder='Senha'
          type='text'
          className={`form-control bg-transparent inputNumber ${password1&&!passwordOK1&&'is-invalid'}`}

        />
        {password1&&!passwordOK1&&
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>As senhas estão diferentes.</span>
            </div>
          </div>
        }
        {password1&&password&&!passwordOK&&
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>A senha deve ter:<br/>8 ou mais caracteres;<br/>3 letras minúsculas;<br/>3 letras maiúsculas;<br/>1 número;<br/>1 caracter especial.</span>
            </div>
          </div>
        }

      </div>
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6'>Código de autorização</label>
        <input
        value={authcode}
        onChange={handleAuthCode}
          placeholder='Código de autorização'
          type='text'
          className={`form-control bg-transparent inputNumber ${password&&email&&name&&!authcode&&'is-invalid'}`}


        />
        {password&&email&&name&&!authcode&&

          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>Número de autorização fornecido pelo administrador.</span>
            </div>
          </div>
        }

      </div>

                <div className='d-flex flex-stack '>
                
                  <div className='mr-2'>
                    <button onClick={()=>{}} type='button' className='btn btn-lg btn-light-primary me-3'  >
                    <span className='indicator-label'>
                     Back
                      </span>
                    </button>
                  </div>

                  {
                  <div>
                    <button
                    disabled={!password||!passwordOK||!password1||!passwordOK1||!name||!nameOK||!email||!emailOK}
                    type='submit' className='btn btn-lg btn-primary me-3' onClick={handleSubmitForm} >
                      <span className='indicator-label'>
                        Continue
                      </span>
                    </button>
                  </div>
                  }
                </div>
              {/* </Form> */}
            {/* )} */}
          {/* </Formik> */}
                  <div className='text-gray-500 text-center fw-semibold fs-6' style={{paddingTop:'30px'}}>
                    Já é cadastrado?{' '}
                    <Link to='/login_administrativo' className='link-primary'>Fazer login</Link>
                  </div>
          </>
        </div>
       </div>
    </div>

  )
}

