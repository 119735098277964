// import React, {useState, useEffect, useRef, } from 'react';

import { IMAGE_BASE } from '../../../../API_CONFIGS/constants';
// import API from '../../../../API_CONFIGS/redux/API';
import { useAuth } from '../../../../AUTH_CONFIGS/Auth';

// import { toast } from "react-toastify";
// import DocViewer, { DocViewerRenderers, PDFRenderer, PNGRenderer } from "@cyntler/react-doc-viewer";

// import { styled } from '@mui/material';

const logo = `${IMAGE_BASE}logo.png`

// const div = styled('div')(({
//   width:'20%', 
//   border:'0.5px solid', 
//   padding:'10px',

// }))
// const div = styled('div')(({
//   width:'80%', 
//   border:'0.5px solid', 
//   padding:'10px',

// }))

// const div = styled('div')(({
//   width:'10%', 
//   border:'0.5px solid', 
//   padding:'10px',

// }))
// const div = styled('div')(({
//   width:'40%', 
//   border:'0.5px solid', 
//   padding:'10px',

// }))
// const DubleRow2 = styled('div')(({
//   width:'10%', 
//   border:'0.5px solid', 
//   padding:'10px',

// }))
// const DubleRow3 = styled('div')(({
//   width:'40%', 
//   border:'0.5px solid', 
//   padding:'10px',

// }))








function convertToValidPath(str) {
  // Use encodeURI to handle special characters
  let encodedStr = encodeURI(str);
  
  // Replace spaces with %20
  encodedStr = encodedStr.replace(/ /g, '%20');


  
  return encodedStr;
}





const Contract1 = ({ user, contract, course, inscription, ContractRef }) => {


  // const {currentUser} = useAuth()


// const [avaiableContracts, setAvaiableContracts] = useState([
//   'sample.pdf',
//   'UYGVTYUV iugIUHi Çâoõi.pdf',
//   'Contrato Graduação 2024.docx',

// ])









// const docs = [
//   // { uri: `${IMAGE_BASE}contract_to_show/${avaiableContracts[0]}`, }, 
//   { uri: `${IMAGE_BASE}contract_to_show/${convertToValidPath(avaiableContracts[1])}`}, 
// ];


// const [htmlContent, setHtmlContent] = useState('');



function formatDate(inputString) {
  if(!inputString){
      return ''
  }
  var inputDate = new Date(inputString)
  // Ensure input is a valid Date object
  if (!(inputDate instanceof Date) || isNaN(inputDate)) {
    throw new Error("Invalid Date");
    return ''
  }

  // Extract date components
  const day = String(inputDate.getDate()).padStart(2, '0');
  const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = String(inputDate.getFullYear()); // Get last two digits of the year

  // Extract 
  const formattedDate = `${day}/${month}/${year}`;

  return formattedDate;
}
const textWithLineBreaks = `

Pelo instrumento particular de Contrato de adesão para prestação de serviços educacionais de um lado o ALUNO e RESPONSÁVEL, acima qualificados, doravante denominado CONTRATANTE/ADERENTE e, do outro lado a FACULDADE REBOUÇAS DE CAMPINA GRANDE, mantida pela INFOGENIUS - ESCOLA TÉCNICA PROFISSIONALIZANTE LTDA - ME, pessoa jurídica de direito privado, CNPJ: 13.445.508/0001-45, com sede na Avenida Ministro José Américo de Almeida, nº 447, Centro, Campina Grande/PB, fone: (83)3343-6280, neste ato representada por seus administradores, Jeannine Nóbrega de Araújo e Fábio Rebouças Figueiredo, doravante denominada CONTRATADA, tem como certo e ajustada a prestação de serviços educacionais, mediante:
CONSIDERANDO que:
a) O CONTRATANTE/ADERENTE requereu e teve deferido pedido de matrícula, necessitando ser regulamentado os termos que regulam a prestação de serviços educacionais ofertados pela CONTRATADA;
b) O CONTRATANTE/ADERENTE tem interesse em contratar a CONTRATADA para prestar serviços objeto deste contrato, com o que concorda a CONTRATADA, desde que observadas as cláusulas adiante estabelecidas.
Resolvem, mutuamente, firmar o presente CONTRATO DE PRESTAÇÃO DE SERVIÇOS EDUCACIONAIS, que será regido pela legislação aplicável e pelas cláusulas e condições adiante estabelecidas:

CLAÚSULA PRIMEIRA: o presente Contrato é de natureza adesiva, celebrado sob a égide dos artigos 1º, inciso IV, 5º, inciso II, 173 parágrafos 1º a 5º, 205, 206, inciso II e III e 209, da Constituição Federal, Lei 10.406/2002 – Código Civil Brasileiro, as determinações da Lei Nº 8.06990 (Estatuto da Criança e do Adolescente), a Lei Nº8.880/94, a lei Nº 9.069/95 e Medida Provisória Nº1477/55 de 19/11/98, a lei Nº 9.870/99, a lei Nº8.078/90, a lei Nº 9.492/97, a lei Nº 9.394/96 e demais disposições legais, do Regimento Geral da Faculdade Rebouças de Campina Grande, regimento disponível ao CONTRATANTE/ADERENTE no ato da matrícula, bem como das Normas Internas, mediante cláusulas e condições a seguir especificadas e a cujo cumprimento se obrigam mutuamente, sendo proibida e nula, a sua alteração de seu texto para caso especifico, conforme previsão contida no Artigo 54. Da Lei 8.078 de 11/09/90 e ainda, sendo certo que os valores divulgados são os resultantes da compatibilização de preços e custos, que é de conhecimento prévio do CONTRATANTE/ADERENTE, nos termos da legislação vigente.
Parágrafo Único: O CONTRATANTE / ADERENTE declara-se ciente de todas as normas internas referidas nesta cláusula.
CLÁUSULA SEGUNDA: O objeto deste contrato é a prestação de serviços educacionais de ensino superior, a saber curso de Pós-Graduação, oferecido e ministrado pela FACULDADE REBOUÇAS DE CAMPINA GRANDE – FRCG, ao CONTRANATE / ADERENTE (ALUNO), e demais atividades acadêmicas, devendo o plano de estudos, modalidade de ensino, programas, currículo e calendário estarem em conformidade com o disposto na legislação em vigor e de acordo com o projeto pedagógico, no Regimento Interno da CONTRATADA e, ainda, o Manual Interno do Docente e do Discente.
Parágrafo Primeiro: todos os cursos poderão ter disciplinas completas ou parciais à Distância (EAD – Programa de Educação a Distância) com aulas presenciais ou semipresenciais, no modelo escolhido pela CONTRATADA, na forma da legislação em vigor e do que dispõe a Portaria MEC nº 2.117, de 6 de dezembro de 2019 e de acordo com o planejamento pedagógico/acadêmico da CONTRATADA.
Parágrafo Segundo: O(A) CONTRATANTE receberá da CONTRATADA uma senha e login padrão, para acessar o ambiente virtual, no qual são encontradas as vídeo aulas, os materiais de leitura, os exercícios de fixação, os fóruns de discussões e as atividades relacionadas à disciplina ministrada.
Parágrafo Terceiro: O computador deverá possuir a seguinte configuração mínima para visualização das aulas via internet: processador: 1.5 GHz (equivalente a Pentium III); memória RAM: 1GB MB; espaço livre no disco rígido (HD): 300 MB; conexão de internet banda larga 10 MB; sistema operacional: Windows XP SP2 ou linux; navegador (Browser): resolução da tela: 1024 x 768;
Parágrafo Quarto: A CONTRATADA não se responsabiliza pela qualidade e eficiência dos serviços e equipamentos fornecidos pelas prestadoras de serviços de telecomunicações e provedores de acesso à Internet, por quaisquer limitações ou problemas com conexão ou banda de internet, configurações do computador e perda de acesso, estando totalmente isentos de quaisquer responsabilidades. Desta forma, torna-se imprescindível a verificação dos requisitos mínimos descritos nesta cláusula pelo(a) CONTRATANTE.
Parágrafo Quinto: O Regimento Interno e o Manual Interno do Discente encontram-se disponíveis na Página da CONTRATADA na Rede Mundial de Computadores (Internet), na biblioteca e na secretaria acadêmica da CONTRATADA de Ensino Superior (CONTRATADA), e deles o CONTRATANTE declara ter conhecimento.
Parágrafo Sexto: A CONTRATADA poderá aplicar procedimentos disciplinares ao Aluno, nos termos de seu Regimento, do Manual do Aluno, do Código de Ética, bem como da Legislação pertinente à espécie.   
Parágrafo Sétimo: O (A) CONTRATANTE compromete-se a adotar uma postura ética, respeitosa e de boa-fé nas relações com outros discentes, docentes, colaboradores, pacientes e toda a comunidade acadêmica, prezando sempre pela honestidade, solidariedade, alteridade, veracidade e transparência de suas ações a todo momento, independente se em ambientes físicos ou digitais.

Parágrafo Oitavo: Ao firmar o presente, o(a) CONTRATANTE/ADERENTE declara conhecer e submeter-se as normas contidas no Regimento da CONTRATADA QUE DECLARA SER UMA CONTRATADA CONFESSIONAL CRISTÃ PROTESTANTE conforme Lei nº 9.394 de 20 de Dezembro de 1986, lei de Diretrizes Bases da Educação nos seus Arts. 19 e 20, ao Regimento Geral, Editais, Portarias, Resoluções dos Órgãos Colegiados Superiores, Atos Executivos e normas gerais administrativas da CONTRATADA. A CONTRATADA tem em sua visão o comprometimento com a formação de valores éticos e morais essenciais para uma vida profissional. O Regimento Interno é apresentado ao aluno com as obrigações constantes neste contrato e na legislação aplicável à área de ensino, e deles o CONTRATANTE declara expressamente ter conhecimento, além das disposições constantes na legislação educacional vigente e demais normas emanadas de outras fontes legais, desde que regulem supletivamente a matéria, que ficam fazendo partes integrantes deste contrato, para todos os efeitos. 
CLÁUSULA TERCEIRA: A configuração formal do ato da matrícula/rematrícula se dá mediante: A) Por meio impresso ou via web no Portal Acadêmico, cujo acesso se dá por meio de login e senha pessoal confidencial e intransferível cadastrado pelo CONTRATANTE no ato da matrícula, e respectiva homologação pela CONTRATADA. Caso o contrato seja formalizado via web, o preenchimento dos dados solicitados e o respectivo aceite, implicam em adesão expressa do CONTRATANTE ao Contrato, independentemente de assinatura das partes. B) Pelo pagamento da Matrícula no ato do ingresso do CONTRATANTE junto a CONTRATADA, exceto para os casos de rematrícula, homologada e deferida; C) Apresentar a esta CONTRATADA de Ensino Superior (CONTRATADA), os seguintes documentos: Diploma de conclusão de ensino superior, registrado e reconhecido pela autoridade competente, nos termos da legislação vigente; Documento de identidade (RG, Órgão de Classe, Passaporte) e CPF; Título de Eleitor; Comprovante de Residência; Foto 3x4; Certidão de Casamento (se casado for); A aceitação do presente instrumento/contrato pelo CONTRATANTE/ADERENTE e a sua respectiva homologação; O respectivo deferimento da matrícula/rematrícula.
CLÁUSULA QUARTA - Declara o(a) CONTRATANTE, ter ciência do objeto contratual do presente instrumento, e das condições de oferta do curso, pagamento, duração e das exigências necessárias para a devida validação de sua matrícula, podendo a CONTRATADA em vista do número de alunos, fundir turmas, organizar módulos, e aula extras, ou qualquer evento necessário para reestruturação, até mesmo mudar o dia das aulas, substituir professores a qualquer tempo sem a ingerência do(a) CONTRATANTE/ADERENTE.
Parágrafo Primeiro: Para adesão a este Contrato, bem como a renovação, o CONTRATANTE/ADERENTE deverá estar adimplente com a CONTRATADA com quaisquer de seus serviços prestados através de contratação direta ou indiretamente.
Parágrafo Segundo: Os procedimentos acima elencados configuram-se como condições suspensivas para o início da vigência deste pacto, nos termos dos Artigos 125 e 332 do Código Civil, vigendo este instrumento, depois de solvidas tais condições suspensivas, exclusivamente para o semestre então contratado. 
Parágrafo Terceiro: Para os casos de renovação de matrícula, o aluno que deixar de fazer a rematrícula no período indicado ou fixado por meio de comunicado conforme prazos estabelecidos em calendário acadêmico, o CONTRATANTE perderá o direito à vaga.
Parágrafo Quarto: Para a efetiva conclusão do curso, as renovações semestrais de matrículas são obrigatórias. O Vínculo de matrícula do CONTRATANTE com a CONTRATADA deixará de existir caso não haja sua renovação até o último dia fixado para tanto, nos termos do Regimento Interno e dos atos normativos.

Parágrafo Quinto: É vedada, ao acadêmico, a realização da renovação de matrícula, ainda que haja vagas remanescentes, acaso não lhe seja mais possível obter a frequência mínima de 75% (setenta e cinco por cento), exigida para aprovação, conforme as normas internas da IES e a legislação aplicável à espécie.
CLÁUSULA QUINTA – No caso de procedimentos (matrícula, renovação de matrícula, protocolos, etc) realizados via web (no acesso do Portal do Aluno através de Login e senha pessoal e intransferível no endereço eletrônico da CONTRATADA www.faculdareboucas.com.br), as partes reconhecem a validade e a segurança jurídica da produção documental eletrônica e de seu processamento em meio eletrônico, assim como reconhecem a validade do contrato, ao qual atribuem eficácia legal equivalente à de um documento originalmente com suporte físico subscrito pelos contratantes.
Parágrafo Primeiro - Taxa Administrativa – As renovações de matrícula efetuadas após o período concedido para tal, conforme CALENDÁRIO ACADÊMICO, poderão ensejar, a critério da CONTRATADA, cobrança de taxa administrativa.
Parágrafo Segundo - A CONTRATADA se reserva no direito de ajustar condições contratuais específicas com o (a) CONTRATANTE, por intermédio de termo aditivo ao presente instrumento, em hipótese de necessidade educacional especial relacionado ao (a) aluno (a) beneficiário (a).
CLÁUSULA SEXTA: O efetivo oferecimento do curso fica condicionado ao preenchimento mínimo de 75% (Setenta e Cinco por cento) das vagas, desobrigando-se a CONTRATADA, à prestação dos serviços, caso o referido número não seja atingido. 
CLÁUSULA SÉTIMA: este instrumento tem como objetivo a contratação da prestação de serviços educacionais de ensino superior, curso de Pós-Graduação, oferecidos e ministrados pela CONTRATADA e demais atividades escolares, devendo o plano de estudos, programas, currículos, horários, dias e calendário estarem em conformidade com o disposto na legislação em vigor e de acordo com o projeto pedagógico aprovado pelo MEC, e estrutura curricular vigente. 
Parágrafo Primeiro: É de inteira responsabilidade da CONTRATADA o planejamento e a prestação dos serviços de ensino, no que se refere à calendário escolar, definição e indicação de locais, horários e dias de aulas, visitas técnicas/aulas de campo, fixação de carga horária, designação de professores , especialistas, aulas e provas, orientação didático pedagógica e educacional, além de outras providências que as atividades docentes exigem, obedecendo seu exclusivo critério, também sem a ingerência do(a) CONTRATANTE/ADERENTE.
Parágrafo Segundo: a CONTRATADA está expressamente autorizada por força deste contrato: Substituir a qualquer tempo e discricionariamente, conforme sua autonomia administrativa, professores e funcionários; b) Selecionar e designar locais onde serão ministradas as aulas teóricas e práticas, que poderão ser na sede da CONTRATADA, onde usualmente realiza suas atividades acadêmicas e administrativas, ou entre outros locais distintos; c) Celebrar convênios com quaisquer outras instituições para a realização de aulas teóricas e práticas; d) Alterar a qualquer tempo, o calendário acadêmico; e) Ministrar aulas em qualquer dias da semana; sábados, ou quaisquer outros dias necessários para atender as exigências do Ministério da Educação (MEC) para fins de cumprimento do calendário acadêmico; f) Normatizar regras de funcionamento dos laboratórios; g) A realizar aulas e estágios diurnos nos cursos noturnos de saúde e de acordo com a disponibilidade, planejamento e oferta.
Parágrafo Terceiro: Declara expressamente o(a) CONTRATANTE/ADERENTE ter tomado conhecimento prévio do Currículo do Curso, do Calendário Acadêmico, Critérios de Avaliação, Registros e limites de Faltas, Notas e demais procedimentos operacionais pertinentes ao curso que também estão disponíveis na Secretaria da CONTRATADA, fazendo parte integrante deste Contrato, e que, em virtude de ter lido todas as Cláusulas constante deste Contrato, está ciente de todas elas, aceitando-as expressamente, obrigando-se, o Aluno a obedecê-los fielmente.
Parágrafo Quarto: Declara o(a) CONTRATANTE/ADERENTE, ainda, ter tomado conhecimento prévio sobre os termos deste Contrato, que encontra-se disponível na Secretaria e na Biblioteca da CONTRATADA, e ter, por ocasião de sua matrícula, recebido cópia deste Contrato.
Parágrafo Quinto: Nos termos da legislação vigente, do Regulamento da CONTRATADA e do Projeto Pedagógico do Curso contratado, o CONTRATANTE deverá ter, no MÍNIMO, 75% (setenta e cinco por cento) de participação nas atividades previstas DE CADA DISCIPLINA DO CURSO e, ainda, alcançar a nota ou conceito necessários em cada disciplina/unidade curricular oferecida, para que obtenha a condição de aprovado.
Parágrafo Quinto: O CONTRATANTE/ADERENTE que não fizer as provas/avaliações nas datas previstas no calendário oficial da CONTRATADA deve seguir o que estabelece o Regimento Interno da CONTRATADA sobre o assunto. 
Parágrafo Sexto: Em caso de reprovação por qualquer motivo, o Aluno poderá refazer a disciplina em uma próxima turma da mesma disciplina, quando esta estiver em oferta, se possível dentro do mesmo curso, mediante o pagamento do valor previsto em que este requerer, em regime de dependência, e, ainda, com o pagamento da contraprestação prevista no Manual do Aluno, Editais e Regimento Interno, fazendo-se necessário requerimento formal na Secretária Acadêmica e a observância  dos prazos, cujo deferimento ficará a critério da CONTRATADA, de acordo com a disponibilidade de vaga. Nesse caso, o CONTRATANTE perderá automaticamente a concessão de descontos concedidos liberalmente pela CONTRATADA. 
Parágrafo Sétimo: No caso de Regime de Dependência, o CONTRATANTE pagará para cada uma das disciplinas/módulos, matrícula e o valor equivalente a esta(s), INTEGRALMENTE E SEM DESCONTO, valor este vigente em que ocorrer a solicitação para a dependência. 

Parágrafo Oitavo: A matrícula em disciplina a ser cursada em regime de dependência, somente será concedida ao aluno que esteja em situação totalmente regular com a Instituição (financeira e acadêmica).
Parágrafo Nono: Em nenhuma hipótese será admitido que o aluno deixe de cursar alguma das disciplinas previstas para a grade curricular, de qualquer dos cursos.
Parágrafo Décimo: O término do Curso, restará configurado mediante a conclusão da carga horária do Curso contratado pelo CONTRATANTE e aprovação deste em todas as disciplinas, inclusive o Trabalho de Conclusão de Curso.
Parágrafo Décimo Primeiro: Constituem condições cumulativas para obtenção do Certificado de Conclusão do curso, deverá o CONTRATANTE (i) estar em dia com as mensalidades; (ii) obter aproveitamento acadêmico e de frequência de no mínimo 75% da carga horário em cada disciplina do curso; (iii) aprovação do Trabalho de Conclusão de Curso conforme normas da CONTRATADA; (iv) inexistir pendência na entrega de documentos; (v) ter integralizado o curso no prazo correspondente à sua duração.
CLÁUSULA OITAVA: o(a) CONTRATANTE/ADERENTE poderá fazer a utilização de microcomputadores, laptop, notebook, tablets, smartphones e quaisquer outros dispositivos moveis, de sua propriedade, ou disponibilizados pela CONTRATADA em seus laboratórios, salas de apoio pedagógico, bibliotecas exclusivamente para fins acadêmicos, sendo vedada a instalação, sob qualquer pretexto, de software não licenciado, impróprios aos bons costumes, como também aqueles não autorizados pela CONTRATADA, sujeitando-se as penas civis, penais e regimentais internas em vigência, assumindo total e plena responsabilidade no caso do não cumprimento do que nesta Cláusula é estabelecido além de orientar o CONTRATANTE/ADERENTE a: a)Não trazer material eletrônico e/ou pessoal, pois não se responsabiliza por nenhum material (de cunho pessoal e/ou eletrônico) trazido pelo(a) Aluno(a); b) os celulares deverão permanecer desligados durante a realização de atividades de avaliação, caso ocorra toque sonoro e identificado o aluno, a este também será atribuída nota ZERO; c) é defeso ao aluno o uso de qualquer aparelho elétrico-eletrônico em sala de aula em dias de prova ou outro tipo de método de avaliação; d) Caso constatado a posse de qualquer aparelho elétrico-eletrônico, nas condições acima, ao aluno será atribuída nota ZERO na avaliação em questão, não cabendo nenhum tipo de recurso ou solicitação de reavaliação; e) É permanentemente proibida a utilização de celulares, máquina fotográficas ou quaisquer outros equipamentos com a finalidade de capturar imagens de pacientes ou de cadáveres dentro das unidades de saúde ou de qualquer laboratório sendo considerada falta gravíssima.
Parágrafo Primeiro: A CONTRATADA não assume qualquer responsabilidade em relação ao CONTRATANTE e/ou aluno por danos que este(s) venha(m) a sofrer ou que possa dar causa em razão:
a)	Da inobservância de normas de segurança e de proteção individual, das recomendações, instruções e avisos da Administração, bem como de professores ou funcionários, quando no exercício de atividades acadêmicas;
b)	Utilização inadequada ou desautorizada dos espaços, instalações ou equipamentos existentes nos prédios da CONTRATADA ou áreas adjacentes.
Parágrafo Segundo: O Contratante se responsabiliza pelo bom atendimento e acompanhamento clínico dos pacientes que lhe forem designados pela coordenação do curso, com auxílio e assistência dos professores, até o final do tratamento e a liberação do paciente pelo professor/coordenador.
CLÁUSULA NONA: ao CONTRATANTE/ADERENTE é permanentemente proibido a entrada de alunos com qualquer tipo de armamento, seja de fogo ou armas brancas, mesmo nos possíveis casos daqueles que trabalham na área de segurança pública e também dos que possuem porte legal de armas.
CLÁUSULA DÉCIMA: Caso o CONTRATANTE seja portador de alergias ou doenças, que o impeçam de manipular certos materiais, independente do curso matriculado, deve informar expressamente por escrito a CONTRATADA. A responsabilidade por omissão do ocorrido será do CONTRATANTE. 
CLÁUSULA DÉCIMA PRIMEIRA:  O(A) CONTRATANTE obriga-se pelo fiel cumprimento dos termos e condições deste Contrato, assim como pela obediência às normas regimentais da CONTRATADA, disponibilizadas ao mesmo, e legislação aplicável, além de: 
a) Obrigar-se a ressarcir e/ou indenizar os danos morais e/ou materiais que, por dolo ou culpa, causar à CONTRATADA DE ENSINO.
b) Obrigar-se pela veracidade das informações que apresentar à CONTRATADA DE ENSINO, devendo comunicar-lhe qualquer alteração em seus dados pessoais, no prazo de 30 (trinta) dias contados da ocorrência. 
c) Efetuar a sua matrícula no prazo previsto no Edital publicado nos meios de comunicação tais como murais e o próprio site da CONTRATADA.
d) Assistir aulas nos limites fixados pela legislação vigente, submetendo-se aos trabalhos escolares e avaliações determinados pela CONTRATADA.
e) Participar de atividades curriculares, em locais indicados pela CONTRATADA, em razão do conteúdo e da proposta didático-pedagógica que se fizerem necessários.
f) Atualizar seus dados cadastrais no ato de cada processo de matrícula.
g) Informar seu endereço de e-mail e telefone celular, além do endereço residencial, para que a CONTRATADA possa divulgar informações que julgar necessárias.
h) Fica proibido fumar nas instalações da CONTRATADA, independentemente da idade do aluno, incluindo, nomeadamente, salas de aula, de estudo, de professores e reuniões, bibliotecas, ginásios, pátios e corredores, cantinas, refeitórios, banheiros e espaços de circulação.
i) No caso de reposição de aulas, estas poderão ocorrer aos sábados ou em contra turno, sendo a presença do aluno obrigatória.
j) No caso de aulas de campo e/ou visitas técnicas, estas poderão ocorrer em horário designado pela Coordenação do Curso, sendo a presença do aluno obrigatória.

CLÁUSULA DÉCIMA SEGUNDA: O CONTRATANTE/ADERENTE do(s) curso(s) de Pós-Graduação de Saúde da CONTRATADA, declara neste ato, que tem conhecimento de que poderá estagiar ou ter aulas práticas nos horários de funcionamento das empresas ou instituições conveniadas com a CONTRATADA, bem como da clínica escola, e deverão obedecer fielmente às Leis e aos regulamentos internos que regulam as atividades de estágio, vivências e atividades práticas e, ainda, as suas custas, adquirirem todo o material de uso próprio, tais quais estetoscópio, tenciômetro, etc, bem como trajar jalecos, pijamas e sapatos apropriados para desenvolvimento das atividades práticas, necessários à prevenção e segurança contra acidentes, inclusive portar a carteira de vacinação devidamente atualizada. 
CLÁUSULA DÉCIMA TERCEIRA – No que diz respeito a aquisição e do uso obrigatório de equipamentos de Proteção individuais, quando necessários às atividades acadêmicas, estes deverão ser adquiridos pelo CONTRATANTE/ADERENTE, sem nenhum ônus para a CONTRATADA.
Parágrafo Primeiro: Os alunos cujos cursos dependam de aulas de laboratório, não poderão ter acesso aos laboratórios sem os equipamentos adequados de proteção, tais como: roupas e sapatos fechados, bata, jaleco, luvas, equipamentos de proteção individual e outros que sejam necessários.
Parágrafo Segundo: o jaleco e/ou bata necessários para as aulas de laboratório, visitas técnicas e/ou aulas de campo, deverão obedecer o modelo padrão adotado pela CONTRATADA, não sendo permitido ao aluno o acesso aos laboratórios e locais designados caso esteja portando modelo diferente ao indicado.
CLÁUSULA DÉCIMA QUARTA: O CONTRATANTE está ciente e concorda expressamente que todos os materiais para aulas práticas, a exemplo EPI\`s, Kit de Odontologia, insumos, materiais, instrumentais, modelos e kit acadêmicos, moldes e/ou quaisquer materiais solicitados pelos docentes para estudos curriculares, aulas práticas e atendimentos ao público clinico e cirúrgico, para os alunos dos cursos de saúde, não estão incluídos neste contrato e são de inteira responsabilidade do CONTRATANTE, e por ele deve ser adquirido e obrigatoriamente utilizados nas aulas práticas. 
Parágrafo Primeiro: Não estão incluídos neste contrato os serviços especiais como Apólice de Seguro de Vida contra Acidentes,  de locação, de transporte para viagens curriculares ou extracurriculares, cursos de extensão, atividades de pesquisa e extensão, atividades nas empresas juniores, eventos, workshops, custos  com estacionamentos, garagem, impressão de apostilas, fardamentos, ou quaisquer outros não especificados no corpo deste instrumento jurídico ou mesmo que se refiram direta ou indiretamente às atividades não incluídas neste CONTRATO. 
Parágrafo Segundo: No caso de contratação de serviço específico, o CONTRATANTE será responsável pelo pagamento das taxas decorrentes destes se, expressamente, requerer, física ou eletronicamente, seu fornecimento e quando no ato do requerimento lhe for informado o valor cobrado pelo referido serviço. 
Parágrafo Terceiro: Observado o previsto no caput, o Contratante/Aluno autoriza desde já, de forma expressa, a cobrança dos serviços específicos através de boleto, caso não seja possível manter-se-á o procedimento de pagamento direto na Tesouraria.
CLÁUSULA DÉCIMA QUINTA:  Não é autorizado ao aluno o uso da Sigla FRCG, da Faculdade Rebouças de Campina Grande e/ou do logotipo da CONTRATADA sem prévia autorização em qualquer meio de comunicação, camisas, inclusive Internet.
CLÁUSULA DÉCIMA SEXTA: É vedado ao aluno praticar qualquer tipo de atividade ou ato comercial nas dependências da CONTRATADA.
Parágrafo Único: Não é permitida ao CONTRATANTE/ADERENTE a comercialização de qualquer produto (camisas, adereços, jalecos, etc) e/ou serviços com a marca ou a Sigla FRCG, da Faculdade Rebouças de Campina Grande e/ou logotipo da CONTRATADA.
CLÁUSULA DÉCIMA SÉTIMA: Para acesso as instalações da CONTRATADA, o Aluno deverá comprovar a sua condição. Para tanto, deverá apresentar comprovante de vínculo com a CONTRATADA e/ou outro meio de identificação definido pela CONTRATADA.
Parágrafo Único: não é permitida a entrada de alunos, na Faculdade ou em qualquer atividade acadêmica proposta usando bermuda, calção, minissaia ou short (cujo cumprimento seja acima do joelho), bandanas (boné) visando a boa apresentação dos acadêmicos.
CLÁUSULA DÉCIMA OITAVA: A CONTRATADA não oferece ao contratante/aderente estacionamento para guarda de veículos em via pública ou nos pátios internos da CONTRATADA, pois os pátios não tem infraestrutura de guarda e vigilância, não tendo a CONTRATADA, portanto, qualquer responsabilidade sobre possíveis danos que venham ocorrer nos veículos estacionados e no que existir no seu interior nos pátios internos ou no entorno de suas dependências.
CLAÚSULA DÉCIMA NONA: Os(as) alunos(as) dos cursos de graduação e/ou pós-graduação da área de saúde, a partir do segundo período e a cada renovação de matrícula, deverão entregar através de protocolo junto a Secretaria Acadêmica, cópia da carteira de vacinação atualizada, sob a pena de indeferimento da mesma.
CLAÚSULA VIGÉSIMA: o(a) CONTRATANTE/ADERENTE, por si ou através de seu representante legal, autoriza a CONTRATADA, a título gratuito e por tempo indeterminado, a utilizar a sua imagem física e ou intelectual para que este divulgue toda e qualquer atividade do CONTRATANTE na condição de aluno da CONTRATADA, sem que de tal utilização de imagem possa advir qualquer espécie de contraprestação pecuniária ou de qualquer outra espécie. A presente autorização é feita em caráter irrevogável e irretratável e de forma gratuita, ficando a CONTRATADA isenta de pagamento de quaisquer ônus ao CONTRATANTE/ADERENTE, a qualquer tempo e pretexto pela utilização da imagem.
Parágrafo Primeiro: o(a) CONTRATANTE/ADERENTE se declara ciente da existência de circuito interno de vigilância com filmagem, como medida de segurança em todas as dependências da CONTRATADA, podendo as imagens gravadas servirem de provas de quaisquer atos praticados no âmbito interno da CONTRATADA e também quando solicitadas por autoridades judiciais e/ou policiais, desde que oficialmente requisitadas.
Parágrafo Segundo: O CONTRATANTE/ADERENTE que não quiser expor a sua imagem nos termos acima deverá por escrito desautorizar a CONTRATADA logo no ato da assinatura deste contrato.
CLAÚSULA VIGÉSIMA SEGUNDA: a CONTRATADA só cobrará TAXA para emissão de documentos, para aqueles que não forem a primeira via.
Parágrafo Único: Os documentos extraídos através do site ou Portal Acadêmico da CONTRATADA, para terem validade, obrigatoriamente deverão ser chancelados pela Secretaria Acadêmica.
CLAÚSULA VIGÉSIMA TERCEIRA: A configuração formal do ato de matrícula se procede quando as partes firmarem o presente CONTRATO, o CONTRATANTE pagar a MATRICULA NO VALOR estabelecido na tabela constante no Anexo I – Parte integrante do presente contrato, adicionada ao ato de entrega dos documentos solicitados neste instrumento, ficando convencionado, desde já, que os mesmos são parte integrante do presente contrato.
Parágrafo Único: O não comparecimento do Aluno as aulas ou não apresentação de documentos, não o exime do pagamento das parcelas, tendo em vista a oferta do serviço colocado à sua disposição pela CONTRATADA.
CLAÚSULA VIGÉSIMA QUARTA:  O pagamento do valor dos serviços educacionais ora contratados, correspondente ao calendário escolar de um semestre, compreende o valor a ser da Matrícula e ainda o valor da semestralidade, tendo que ser paga a CONTRATADA de uma única vez ou em até 6 parcelas mensais e sucessivas, conforme a data do seu pagamento, nas formas estabelecidas na tabela constante do Anexo I – PARTE INTEGRANTE DO PRESENTE CONTRATO.
Parágrafo Primeiro: Fica a parte CONTRATANTE desde já ciente e esclarecida que os descontos eventualmente concedidos pela CONTRATADA ao CONTRATANTE correspondente à semestralidade será o previsto no aditivo da matrícula quando do ingresso à instituição.
Parágrafo Segundo: Embora assinado o presente contrato e atendidos os aspectos formais de sua constituição, o início da sua vigência fica subordinado ao pagamento do valor da matricula, de modo que, se o CONTRATANTE NÃO efetue o pagamento da matrícula, a apresente avença NÃO cria qualquer obrigação para a CONTRATADA , nem gera, sequer, expectativa de direito para o CONTRATANTE, NÃO produzindo pois, qualquer efeito, sendo tido como inexistente, de sorte que, tais atividades do CONTRATANTE NÃO gerarão quaisquer efeito.
Parágrafo Terceiro: A partir da matrícula, ainda que antes do início das aulas, a CONTRATADA já presta os seguintes serviços ao CONTRATANTE / ADERENTE: registros de boletos financeiros, preparação da acolhida dos alunos, organização das salas de aula e do diário de classe, registro de grupos de trabalho, definição do quadro docente, dentre outros.
Parágrafo Sétimo: O não comparecimento do ALUNO às aulas não dá direito à recusa de pagamento das mensalidades correspondentes ao período de vigência do presente contrato.
Parágrafo Oitavo: O pagamento das parcelas começará quando do início das aulas.

CLAÚSULA VIGÉSIMA QUINTA: Fica estabelecido que a CONTRATADA dará início ao curso quando atingir o preenchimento mínimo de 80% (Oitenta por cento) das vagas.

Parágrafo Primeiro: No caso em que não houver a formação de turma, se o aluno não optar por outro curso ou turma, o valor da matricula será integralmente devolvido, desde que assim o requeira por escrito.
Parágrafo Segundo: Fica ciente o CONTRATANTE de que, num prazo de 6 (seis) meses, não havendo quórum mínimo estipulado nesta CLAUSULA para que seja possível dar início ao serviço, a CONTRATADA fará restituição do valor da matricula. 

CLAÚSULA VIGÉSIMA SEXTA: O CONTRATANTE declara que teve conhecimento prévio das condições financeiras deste contrato, que foi exposto pela CONTRATADA disponíveis na Secretaria Acadêmica e no Departamento de Matrícula, conhecendo e aceitando-as todas de forma livremente e espontânea.
Parágrafo Único: A CONTRATADA poderá ceder no todo ou em parte o crédito advindo deste contrato a CONTRATADA ou Agente Financeiro, com o que o CONTRATANTE desde já manifesta o seu pleno consentimento.
CLAÚSULA VIGÉSIMA SÉTIMA: O pagamento dos valores constantes do Anexo I, parte integrante deste contrato, somente poderá ser efetuado em AGENCIA BANCARIA OFICIAL, através de boleto bancário emitido para este fim, sendo vedado o pagamento por quaisquer outros meios ou de qualquer outra forma, tais como depósito efetuado pela Internet, depósito efetuado através de DOC – transferência eletrônica - , efetuando através de caixa automático e similar, sob pena de não ser dada quitação ao CONTRATANTE, que será tratado como inadimplente, sendo obrigado, portanto, a pagar novamente, isso a luz do contido no art. 308, do Código Civil Brasileiro.
CLAÚSULA VIGÉSIMA OITAVA: A falta de fornecimento de boleto ou aviso de cobrança pela CONTRATADA não justifica a ausência de pagamento da parcela no seu vencimento, ficando acordado que constitui obrigação do CONTRATANTE diligenciar para coletar e receber o boleto para pagamento nas centrais de informação e centrais de atendimento financeiro da CONTRATADA e que este procedimento deve ser realizado pelo CONTRATANTE independente de aviso da CONTRATADA.  
CLAÚSULA TRIGÉSIMA: Ficam desta forma, expressamente excluído do valor ora contratado, aqueles valores referente a serviços realizados pela CONTRATADA e usufruído pelo discente, tais como: segunda via de diplomas ou certificados; segunda via de históricos escolares; segunda via de ementas; segunda via de declaração de conclusão de curso ou de disciplinas; segunda via de carteira do aluno; reabertura de matriculas; reingresso; cartões de identificação de acesso dos discentes ao recintos da CONTRATADA, dentre outros, os quais serão fixados para todo o prazo de vigência deste contrato sendo divulgados neste ato para o CONTRATANTE que fica ciente de seus valores.
CLAÚSULA TRIGÉSIMA PRIMEIRA: Reserva-se a CONTRATADA o direito de cobrar pelo fornecimento de quaisquer serviços e ou documentos, de acordo com a tabela constante do ANEXO I deste Contrato, e em caso de omissão na presente tabela, de acordo com as tabelas afixadas nos quadros de avisos da CONTRATADA, distribuídos nos corredores da FACULDADE.
Parágrafo Único: a CONTRATADA só cobrará TAXA para emissão de documentos, para aqueles que não forem a primeira via.
CLAÚSULA TRIGÉSIMA SEGUNDA: Os valores dos serviços educacionais fixados neste contrato poderão ser objeto de reajuste pela aplicação do IGP-M ou por qualquer outro índice oficial a ser escolhido pela CONTRATADA, e ao seu critério, quando houver alteração nas políticas econômicas e/ou salarial, acordo, convenção ou dissídio coletivo ou Lei referente a salários do pessoal docente e auxiliar bem como pela incidência de tributos e/ou contribuição previdenciária advindo de normas legais.
Parágrafo Único: O valor da semestralidade será reajustado sempre no início do ano civil e, publicado em edital pela DIRETORIA da CONTRATADA.
CLAÚSULA TRIGÉSIMA TERCEIRA: Em caso de falta de pagamento na data do vencimento, o valor da parcela será de multa de 2% (dois por cento) clausula penal moratória, além de juros de 1% (um por cento) ao mês, atualização monetária, com a aplicação da variação do IGP-M ou, na sua ausência, índice similar e legalmente previsto, desde a data do vencimento até sua liquidação, bem como honorário advocatícios correspondentes a 20% (vinte por cento) sobre o valor da dívida, nos termos do novo Código Civil, do Código de Defesa do Consumidor, do CPC, da Lei 8.906/94 e demais normas legais em vigor.
CLAÚSULA TRIGÉSIMA QUARTA: Reserva-se a CONTRATADA ao direito a cancelar o andamento e funcionamento de qualquer turma no caso em que esta venha a ficar com menos de 50% (Cinquenta por Cento) das vagas previstas, podendo a Instituição disponibilizar ao aluno o direito de ocupar uma vaga em outra turma da mesma natureza, no mesmo ou em outro turno e/ou curso, desde de que exista a turma e a vaga, visto a CONTRATADA não estar obrigada a ficar com turmas com menos de 50% (Cinquenta por cento) do  úmero de alunos. 
Parágrafo Único: Fica estabelecido que a CONTRATADA dará início ao curso quando atingir o preenchimento mínimo de 80% (oitenta por cento) das vagas. 
CLAÚSULA TRIGÉSIMA QUINTA: Não procedendo o CONTRATANTE a aquisição de seus encargos educacionais nos respectivos vencimentos, fica a CONTRATADA autorizada a emitir duplicatas de prestação de serviços, de acordo com os valores devidos, no valor total das parcelas em atraso, com os acréscimos legais e ora pactuados, valendo a assinatura do presente contrato como concordância com aquelas, e para todos os efeitos legais, encaminhando após 30 (trinta dias) do vencimento ao Departamento Jurídico para efetivação da cobrança.
CLAÚSULA TRIGÉSIMA SEXTA: O CONTRATANTE, neste ato, fica ciente e concorda EXPRESSAMENTE, que perderá todo e qualquer desconto nas parcelas vencidas e avançadas, concedidos liberalmente pela CONTRATADA, de que seja eventualmente beneficiário nas seguintes hipóteses: Em caso de inadimplência igual ou superior a 30 (Trinta) dias, independente da origem e da espécie do débito; no caso de reprovação no em 1 (UMA) ou mais DISCIPLINAS/MÓDULOS; qualquer forma de interrupção de estudos (Trancamento, Cancelamento, Abandono, dentre outros); e/ou por descumprimento as determinações do Regimento Geral da CONTRATADA. Em caso de inadimplência igual ou superior a 30 (Trinta) dias, independente da origem e da espécie do débito.
Parágrafo Único: Nesses casos, o CONTRATANTE perderá automaticamente a concessão de descontos concedidos liberalmente pela CONTRATADA devendo o CONTRATANTE pagar demais parcelas previstas INTEGRALMENTE E SEM DESCONTO, valor este vigente em que ocorrer a solicitação para matrícula. 
CLAÚSULA TRIGÉSIMA SÉTIMA: Em caso de inadimplência, a CONTRATADA poderá ainda A) NEGATIVAR o devedor em cadastro ou serviço legalmente constituído (SPC/SERASA) e destinados a proteção de tal cobrança; B) Promover a cobrança através de advogados ou de empresa especializada, sendo o CONTRATANTE responsável pelo pagamento de todas as despesas decorrentes de tal cobrança, inclusive honorários advocatícios na base de todas as despesas decorrentes de tal cobrança de 20% (vinte por cento) sobre o valor da dívida; C) Promover a cobrança judicial, arcando o CONTRATANTE com honorário advocatícios correspondentes a 20% (vinte por cento) sobre o valor da dívida, valendo o presente contrato como título executivo extrajudicial, nos termos do art. 784, III, do CPC ( Lei n° 13.105/2015), recolhendo, as partes, desde já, este título, como líquido, certo e exigível, ou, ainda, qualquer tipo de cobrança previsto na legislação brasileira, independentemente de prévia notificação, podendo tais providências serem tomadas isoladas, gradativa ou cumulativamente.
CLAÚSULA TRIGÉSIMA OITAVA: A CONTRATADA terá direito a recusar a rematrícula, ou a matrícula em qualquer outro curso por ela mantido, ou a inscrição em atividade desenvolvida pela CONTRATADA, mantida pela CONTRATADA, quando o requerente: 1. Estiver inadimplente, inclusive em relação a vinculação anterior, independentemente da origem e da espécie do débito e da manutenção do direito de cobrança das dívidas contraídas; 2. Não cumprir as determinações e prazos contidos no calendário acadêmico e no Regimento Geral da CONTRATADA; 3. Estiver em débito para com obrigação junto ao Sistema de Bibliotecas da CONTRATADA. 4. Em caso de indisciplina ou conduta incompatível com o ensino superior e com as normas regimentais da CONTRATADA.
CLAÚSULA TRIGÉSIMA NONA: O TCC – Trabalho de Conclusão de Curso constitui-se em um trabalho individual obrigatório do curso de pós-graduação. 
Parágrafo Primeiro: O formato do TCC deverá seguir o Regulamento Interno de Trabalho de Conclusão de Curso da CONTRATADA.
Parágrafo Segundo: O ALUNO que ficar pendente na entrega e defesa de Trabalho de Conclusão de Curso, somente poderá requerer a sua defesa após o prazo de 60 (sessenta) dias do início do semestre subsequente, devendo o ALUNO efetivar sua matrícula regularmente no citado período e pagar as parcelas da semestralidade relativas ao período que permanecer até a entrega e respectiva defesa.
CLAÚSULA QUINQUAGÉSIMA: A prestação de serviços educacionais objeto deste contrato, poderá ser rescindida: a) em virtude de cancelamento da matrícula, transferência de CONTRATADA ou trancamento do curso, conforme estipulado neste contrato, no Regimento da CONTRATADA e no Manual Interno do Aluno, os quais compõem este instrumento e são do conhecimento do CONTRATANTE; b) por acordo entre as partes; c) por infração disciplinar prevista no Código de Ética e Regimento Interno, que justifica, nos termos deste contrato, e da legislação pertinente, seu desligamento do estabelecimento de ensino ao final do devido procedimento disciplinar. 
CLAÚSULA QUINQUAGÉSIMA PRIMEIRA: O presente Contrato de Prestação de Serviços Educacionais poderá ser rescindido pelo CONTRATANTE / ADERENTE nos seguintes casos: 
a) Por arrependimento até 07 (sete) dias após a matrícula: Se no prazo impostergável de até 07 (sete) dias, contados a partir da data de pagamento da matrícula, mediante requerimento protocolado, faz-se jus, neste caso, o cancelamento SEM cobrança da multa contratual e SEM restituição dos valores pagos. Se decorrido o prazo acima aludido, sem que o contratante manifeste seu arrependimento, cabe ao mesmo a obrigação de pagar as parcelas vincendas do semestre até que requeira por escrito o cancelamento oficial e expresso da matrícula. Em todos os casos, o cancelamento ou desistência em sendo realizado a partir do 8º (oitavo) dia, após a data da realização da matrícula, já é devida a cobrança da multa contratual.
a.I – Se o requerimento de desistência for formulado após o início das aulas será devido o pagamento de todas as parcelas devidas com vencimento até o mês da data do requerimento além de multa rescisória prevista neste instrumento.
b) Desistência do Curso – Para o cancelamento de matrícula, desistência do Curso, o CONTRATANTE, deverá pagar multa contratual de 20% (Vinte por Cento) do valor total sem desconto deste Contrato, estar quite com todas as parcelas vencidas do presente contrato, bem como pagar outros débitos e/ou taxas eventualmente existentes para com a CONTRATADA.
b.I - Em todos os casos de rescisão contratual, fica o CONTRATANTE obrigado a pagar o valor da parcela do mês em que ocorrer o evento, além de outras parcelas eventualmente existentes, com os acréscimos legais e contratuais, quando for o caso.
b.II - Por se tratar de ato solene, a exemplo da matricula do Aluno, o Requerimento de Desistência do curso APENAS PODERÁ SER FEITO DE FORMA PRESENCIAL NA SEDE DA CONTRADADA, JUNTO AO ATENDIMENTO PESSOAL, CASO EM QUE O CONTRATANTE (ALUNO OU RESPONSÁVEL FINANCEIRO), PREENCHERÁ REQUERIMENTO PADRÃO, COM ASSINATURA CUJA AUTENCIDADE DEVERÁ SER ATESTADA NO ATO. Isto é, não é admitido e nem será conhecido qualquer requerimento feito e forma eletrônica, através do Portal do Aluno.
b.III - A exceção do requerimento de que trata a clausula anterior, que obrigatoriamente deverá ser feito de forma presencial, todos os demais requerimentos do CONTRATANTE deverão ser formalizados por meio de formulário próprio disponíveis na secretaria da FACULDADE ou, quando for o caso, on-line, no Portal Acadêmico.   NÃO SERÃO ACEITAS DE FORMA ALGUMA SOLICITAÇÕES TÁCITAS, VERBAIS OU POR FORMULÁRIOS DISTINTOS DAQUELES EXIGIDOS FORMALMENTE PELA CONTRATADA.
b.IV - A multa contratual será devida independente do início das aulas ou do aluno(a) ter usufruído do serviço tendo em vista que este ocupou a vaga.  Em todos os casos de rescisão contratual, fica o CONTRATANTE obrigado a pagar o valor da parcela do mês em que ocorrer o evento, além de outras parcelas eventualmente existentes, com os acréscimos legais e contratuais, quando for o caso.
c) Quando requerida na vigência deste contrato, obriga o CONTRATANTE/ADERENTE ao pagamento das parcelas vencidas até a data de comunicação formal da CONTRATADA além da Multa Contratual de 20% (Vinte por Cento) sobre o valor total deste INSTRUMENTO e de outros débitos e/ou taxas administrativas porventura existentes.
c.I - O CONTRATANTE / ADERENTE deverá efetuar o pagamento integral da parcela da semestralidade correspondente ao mês do pedido ou comunicação PROTOCOLADO.
d) Nas hipóteses das letras “a”, “b” e “c” desta cláusula, o CONTRATANTE / ADERENTE perde expressamente o vínculo institucional e as concessões de descontos que eventualmente desfrute. 
CLAÚSULA QUINQUAGÉSIMA SEGUNDA – DA LEI 13.709, DE 14 DE AGOSTO DE 2018 – LGPD. O CONTRATANTE declara conhecer a LGPD – Lei Geral de Proteção de Dados, a íntegra do seu conteúdo, que dispõe sobre o tratamento de dados pessoais, inclusive nos meios digitais, por pessoa natural ou por pessoa jurídica de direito público ou privado, com o objetivo de proteger os direitos fundamentais de liberdade e de privacidade e o livre desenvolvimento da personalidade da pessoa natural. 
Parágrafo Primeiro - O CONTRATANTE dá o CONSENTIMENTO e/ou AUTORIZAÇÃO à CONTRATADA para coleta de seus dados pessoais (sensíveis ou não), para uso e tratamento, podendo a Faculdade Rebouças de Campina Grande, através de seus departamentos, colaboradores, parceiros, conveniados e associados, utilizá-los por tempo indeterminado e/ou pelo período determinado pelos Ministérios da Educação e Saúde, ou por período determinado pelo respectivo Órgão de Classe, em decorrência da atividade contratada e para uso do seus objetivos sociais e de propaganda e marketing. 
Parágrafo Segundo - Entendem-se por dados pessoais, segundo o inciso I, do artigo 5º da LGPD, toda informação relacionada à pessoa natural (CONTRATANTE) identificada ou identificável, e que permita individualizá-lo. 
Parágrafo Terceiro - O consentimento e/ou autorização abarca os dados pessoais objetivos, os dados identificáveis, sensíveis ou não. 
Parágrafo Quarto - O consentimento e/ou autorização dada pelo CONTRATANTE abrange: acesso, armazenamento, arquivamento, avaliação, classificação, coleta, comunicação, controle, difusão, distribuição, eliminação, extração, modificação, processamento, produção, recepção, transferência, transmissão e utilização.
Parágrafo Quinto - Fica consentido e /ou autorizado o envio de dados pessoais (sensíveis ou não sensíveis) ao MEC, INEP, CAPES, FNDE, Secretarias que compõem os Ministérios da Educação Federal, Estadual, Municipal e da Economia, Poder Judiciário, Ministério Público Federal, Ministério Público Estadual, Órgãos de Classe, Instituições Financeiras, Bancos, Caixa Econômica Federal, Sindicatos das Categorias, Delegacia de Polícia Civil, Delegacia de Polícia Federal, Receita Federal do Brasil, INSS, PROCON, Prefeitura Municipal, Posto Fiscal Municipal, Posto Fiscal Estadual, Secretaria de Saúde Municipal, Secretaria de Saúde Estadual e, Departamento Nacional de Transporte, SERASA, SCPC, Cartórios de Notas e Protestos, Cartório de Imóveis, Cartório Eleitoral, Seguradora, Entidade Hospitalar, Entidades concedentes de estágio, Agentes Integradores de Estágio, e demais órgãos. 
Parágrafo Sexto - O CONTRATANTE declara saber que a LGPD permite que o tratamento de dados se opere via consentimento do titular, para o cumprimento de obrigações legais ou regulatórias, para execução de políticas públicas, para a realização de estudos de órgão de pesquisa, para execução de contrato/diligências pré-contratuais, exercício regular de direito, proteção da vida, tutela de saúde, interesse legítimo da Faculdade Rebouças de Campina Grande ou de terceiros e proteção ao crédito. A não autorização poderá acarretar a não celeridade no recebimento das informações, bem como a limitação de acesso em sistemas, ambientes virtuais e de atendimentos da Faculdade Rebouças de Campina Grande que demandam o consentimento pleno do CONTRATANTE. 
Parágrafo Sétimo - O CONTRATANTE AUTORIZA o uso de seus dados para fins de propaganda e marketing (mídia escrita, televisionada, periódicos, redes sociais, bem como a cessão dos mesmos à empresas conveniadas, e o envio de informações, via celular, e-mail, ligação telefônica ou por outros meios. 
Parágrafo Oitavo - Cabe ao CONTRATANTE, a qualquer tempo e mediante requisição, solicitar à CONTRATADA a confirmação da existência do tratamento de dados, se há anonimização de dados, efetivar pedido de bloqueio ou eliminação de dados desnecessários, excessivos ou tratados ilicitamente, solicitar a portabilidade dos dados a outro fornecedor de serviço ou produto, informar sobre a revogação do consentimento de dados, solicitar informações sobre o tratamento dos seus dados e as entidades com as quais a CONTRATADA realizou uso compartilhado desses dados, solicitar informações da possibilidade de não compartilhamento de dados. 
Parágrafo Nono - A CONTRATADA terá prazo de 15 dias úteis, contados do próximo dia útil posterior ao protocolo do pedido, e prorrogável por mais 15 dias, mediante justificativa fundamentada, para emissão da resposta de que trata o parágrafo anterior. Situações excepcionais, força maior, pandemias, número excessivo de pedidos, justificam a prorrogação do prazo de resposta, por mais 30 dias. 
Parágrafo Décimo - A CONTRATADA declara possuir mecanismos de segurança para o tratamento de dados, com o objetivo de reduzir a probabilidade e o impacto de incidentes de segurança e garantir a continuidade do negócio, como: Datacenter com conformidades e atualizações, sistema de segurança física e lógica, backups redundantes interna e externamente, antivírus, softwares com mecanismos de controle de acesso por nível de usuário e relatórios de acessos. 
Parágrafo Décimo Primeiro – O CONTRATANTE, em caso de correção de dados incompletos, inexatos ou desatualizados, poderá solicitar a correção, o bloqueio ou eliminação de dados desnecessários, excessivos, mediante requerimento fundamentado e dirigido à CONTRATADA. A oposição do CONTRATANTE não surtirá efeitos se conflitar com as disposições da Lei 13.709, de 14 de agosto de 2018 e suas alterações. 
Parágrafo Décimo Segundo - A CONTRATADA declara que empregará políticas e capacitação, continuadamente, com a finalidade de coibir o desrespeito a LGPD, bem como divulgará Plano de Resposta a Incidentes de Segurança. 
Parágrafo Décimo Terceiro- O CONTRATANTE deverá cumprir as regras de identificação aprovadas pela CONTRATADA para obter atendimento presencial, via sistema ou via telefone.
CLAÚSULA QUINQUAGÉSIMA TERCEIRA: Qualquer requerimento formulado pelo CONTRATANTE à CONTRATADA somente será válido se realizado pelo CONTRATADO e/ou responsável financeiro, em formulário próprio e protocolado no competente setor de atendimento aos alunos do respectivo campus e/ou on-line.
CLAÚSULA QUINQUAGÉSIMA QUARTA – O CONTRATANTE é proibido de fotografar, gravar, registrar, compartilhar ou divulgar, por qualquer outro meio, a imagem, a voz ou conteúdo autoral (slides, apostilas, etc.) do professor, para utilização por ele não autorizada ou uso indevido, sob pena das responsabilidades civis, criminais e daquelas previstas nas normas internas da CONTRATADA.
CLAÚSULA QUINQUAGÉSIMA QUINTA – O CONTRATANTE declara-se ciente de que a CONTRATADA tem a possibilidade de ofertar disciplinas do semestre ora contratado ou da semestralidade imediatamente anterior, por meio de atividades letivas que utilizem recursos educacionais digitais, tecnologias de informação e comunicação ou outros meios convencionais, nos estritos termos previstos na Portaria do Ministério da Educação nº 544/2020 e nos termos do art. 2º da Lei nº 9.131, de 24 de novembro de 1995, homologo o Parecer CNE/CP nº 19/2020, do Conselho Pleno do Conselho Nacional de Educação - CP/CNE, que definiu as Diretrizes Nacionais para a implementação dos dispositivos da Lei nº 14.040, de 18 de agosto de 2020, que estabelece normas educacionais excepcionais a serem adotadas durante o estado de calamidade pública reconhecido pelo Decreto Legislativo nº 6, de 20 de março de 2020, consoante o Projeto de Resolução a ele anexo, conforme consta do Processo nº 23001.000334/2020-21.
CLAÚSULA QUINQUAGÉSIMA SEXTA - PACTUAÇÃO DE COMPROMISSO DE CONCILIAÇÃO E MEDIAÇÃO NOS TERMOS DA LEI N.º 13.140/2015: fica desde já estabelecido que antes da propositura de qualquer litígio originário ou relacionado com o presente contrato será necessário se buscar a Câmara de Conciliação e Arbitragem de Campina Grande – Paraíba, visando a composição da Demanda. Somente em caso de não resolução por parte daquele Órgão é que se poderá buscar a solução do conflito junto ao Poder Judiciário.
Parágrafo Primeiro: O presente Contrato é de natureza adesiva, adotado sem distinção para todos os acadêmicos, sendo proibida e nula, a alteração de seu texto para caso específico, conforme previsão contida no art. 54, da Lei 8.078, de 11/09/90.
Parágrafo Segundo: O descumprimento ou o inadimplemento deste contrato implica na perda do direito de contratar a Mantenedora através das suas mantida FACULDADE REBOUÇAS DE CAMPINA GRANDE, enquanto perdurar o inadimplemento. A eventual tolerância no descumprimento de qualquer cláusula deste contrato não implica em alteração ou novação contratual, caracterizando simples liberalidade da CONTRATADA.
Parágrafo Terceiro: O presente contrato tem sua vigência semestral, devendo ser renovado no semestre subsequente desde que observados os prazos estabelecidos para este fim e demais   Regulamentos.
CLAÚSULA QUINQUAGÉSIMA SÉTIMA – As partes elegem o foro da Comarca de Campina Grande como competente para as questões eventualmente advindas deste contrato, com exclusão de qualquer outro, por mais privilegiado que seja. E, em decorrência da concordância plena do CONTRATANTE, o mesmo registra sua aquiescência ao Contrato de Prestação de Serviços Educacionais por aceite “on-line”, mediante uso de senha pessoal e intransferível, conforme registrado.
E por estarem justos e CONTRATADAS, assinam o presente instrumento, sem rasuras ou entrelinhas, na presença de duas testemunhas, que igualmente o subscrevem, para que produza seus efeitos em qualquer tempo e lugar.




`;




// const formattedText = contractBody.replace(/\n/g, '<br>');
// const formattedText =  contract









	return (

    <div
    ref={ContractRef}
    style={{
      // width: '1050px',
    }}
    >
    
    {contract?
    
  
    

    <div

    style={{
      width: '1000px',
      // height: '1500px', 
      display: "flex",
      margin:'auto',
      // padding: '50px',
      // borderColor: "#000",
      flexWrap: "wrap",
      fontFamily: "Arial, sans-serif",
      fontSize: "12pt",
      // justifyContent:"center",
      alignItems:'center',
      textAlign:'justify',

    }}
  > 
  
    <div style={{ textAlign: "center", margin:'0pxauto 0px 0px', fontSize: "14pt", width:'40%' }}>
      <img
        src={logo}
        style={{ margin: "auto", width: "100%" }}
      />
    </div>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 0,
        margin: "0px 0px 0px auto",
        flexWrap: "wrap",
        width: "40%"
      }}
    >
      <p
        style={{ fontWeight: "bold", margin: 0, width: "100%", textAlign: "end" }}
      >
        Av. José Américo de Almeida, 447, Centro, Campina Grande, PB, CEP:
        58.406-040
        <br />
        tel: (83) 3343-6280
        <br />
        www.faculdadereboucas.com.br
      </p>
    </div>
  
  
  
  <div style={{margin:'50px auto 0px auto', fontSize:'20pt', fontWeight:'bold',  }}>
    CONTRATO DE PRESTAÇÃO DE SERVIÇOS EDUCACIONAIS
  </div>
  
  <div style={{margin:'50px 0px 10px 0px', fontSize:'16pt', fontWeight:'bold',  }}>
    Pelo instrumento particular de Contrato de adesão para prestação de serviços educacionais de um lado o ALUNO:
  </div> 
  
  <table style={{width:'900px', margin:'0px auto 0px auto', border:'0.5px solid', borderCollapse: 'collapse'}}>
  <tr>
    <td style={{width:'200px', border:'0.5px solid', padding:'10px'}}>Curso:</td>
    <td colSpan="3" style={{width:'800px', border:'0.5px solid', padding:'10px'}}>{course?.nome || " - "}</td>
  </tr>
  <tr>
    <td style={{width:'100px', border:'0.5px solid', padding:'10px'}}>Tema:</td>
    <td colSpan="1" style={{width:'400px', border:'0.5px solid', padding:'10px'}}>{inscription?.theme?.nome || ' - '}</td>
    <td style={{width:'100px', border:'0.5px solid', padding:'10px'}}>Matrícula:</td>
    <td colSpan="1" style={{width:'400px', border:'0.5px solid', padding:'10px'}}>{inscription?.inscription?.id || " - "}</td>
  </tr>
  <tr>
    <td style={{width:'100px', border:'0.5px solid', padding:'10px'}}>Aluno:</td>
    <td colSpan="1" style={{width:'400px', border:'0.5px solid', padding:'10px'}}>{user?.nome || " - "}</td>
    <td style={{width:'100px', border:'0.5px solid', padding:'10px'}}>CPF:</td>
    <td colSpan="1" style={{width:'400px', border:'0.5px solid', padding:'10px'}}>{user?.cpf || " - "}</td>
  </tr>
  <tr>
    <td style={{width:'100px', border:'0.5px solid', padding:'10px'}}>Nascimento:</td>
    <td colSpan="1" style={{width:'400px', border:'0.5px solid', padding:'10px'}}>{formatDate(user?.nascimento) || " - "}</td>
    <td style={{width:'100px', border:'0.5px solid', padding:'10px'}}>Estado Civil:</td>
    <td colSpan="1" style={{width:'400px', border:'0.5px solid', padding:'10px'}}>{user?.estado_civil || " - "}</td>
  </tr>
  <tr>
    <td style={{width:'100px', border:'0.5px solid', padding:'10px'}}>RG:</td>
    <td colSpan="1" style={{width:'400px', border:'0.5px solid', padding:'10px'}}>{user?.rg || " - "}</td>
    <td style={{width:'100px', border:'0.5px solid', padding:'10px'}}>Emissão:</td>
    <td colSpan="1" style={{width:'400px', border:'0.5px solid', padding:'10px'}}>{user?.rg_emissao || " - "}</td>
  </tr>
  <tr>
    <td style={{width:'200px', border:'0.5px solid', padding:'10px'}}>Logradouro:</td>
    <td colSpan="3" style={{width:'800px', border:'0.5px solid', padding:'10px'}}>{user?.endereco || " - "}</td>
  </tr>
  <tr>
    <td style={{width:'100px', border:'0.5px solid', padding:'10px'}}>CEP:</td>
    <td colSpan="1" style={{width:'400px', border:'0.5px solid', padding:'10px'}}>{user?.cep || " - "}</td>
    <td style={{width:'100px', border:'0.5px solid', padding:'10px'}}>Bairro:</td>
    <td colSpan="1" style={{width:'400px', border:'0.5px solid', padding:'10px'}}>{user?.bairro || " - "}</td>
  </tr>
  <tr>
    <td style={{width:'100px', border:'0.5px solid', padding:'10px'}}>Cidade:</td>
    <td colSpan="1" style={{width:'400px', border:'0.5px solid', padding:'10px'}}>{user?.cidade || " - "}</td>
    <td style={{width:'100px', border:'0.5px solid', padding:'10px'}}>Celular:</td>
    <td colSpan="1" style={{width:'400px', border:'0.5px solid', padding:'10px'}}>{user?.celular || " - "}</td>
  </tr>
  <tr>
    <td style={{width:'100px', border:'0.5px solid', padding:'10px'}}>Residencial:</td>
    <td colSpan="1" style={{width:'400px', border:'0.5px solid', padding:'10px'}}>{user?.residencial || " - "}</td>
    <td style={{width:'100px', border:'0.5px solid', padding:'10px'}}>Comercial:</td>
    <td colSpan="1" style={{width:'400px', border:'0.5px solid', padding:'10px'}}>{user?.comercial || " - "}</td>
  </tr>
  <tr>
    <td style={{width:'200px', border:'0.5px solid', padding:'10px'}}>Email:</td>
    <td colSpan="3" style={{width:'800px', border:'0.5px solid', padding:'10px'}}>{user?.email || " - "}</td>
  </tr>
</table>
  {/* <div style={{margin:'50px 0px 10px 0px', fontSize:'16pt', fontWeight:'bold',  }}>
    RESPONSÁVEL LEGAL E/OU FINANCEIRO:
  </div>
  
    <div style={{width:'100%', display:'flex', flexWrap:'wrap', border:'0.5px solid', }}>
  
      <div>Nome:</div>                 <div>AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA </div>
      <div>Nascimento:</div>           <div>AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA </div>            <div>CPF:</div>                  <div>AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA </div>
      <div>RG:</div>                   <div>AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA </div>            <div>Emissão:</div>              <div>AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA </div>
      <div>Parentesco:</div>           <div>AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA </div>
      <div>Esatdo civil:</div>         <div>AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA </div>            <div>Profissão:</div>            <div>AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA </div>
      <div>Logradouro:</div>           <div>AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA </div>
      <div>CEP:</div>                  <div>AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA </div>            <div>Bairro:</div>               <div>AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA </div>
      <div>Cidade:</div>               <div>AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA </div>            <div>Celular:</div>              <div>AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA </div>
      <div>Residencial:</div>          <div>AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA </div>            <div>Comercial:</div>            <div>AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA </div>
      <div>Email:</div>                <div>AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA </div>
      <div>Local de trabalho:</div>    <div>AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA </div>            <div>Telefone trabalho:</div>    <div>AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA </div>
      
      
    </div> */}
  
  
      {/* <div style={{  
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'justify',
        fontWeight: 'normal',
        fontSize: '12pt',
        whiteSpace: 'pre-wrap', 
        textIndent:'100px'
      }}
  
  
        dangerouslySetInnerHTML={{ 
          __html: contract?.replace(/\n/g, '<br>') || ""
          }} /> */}
  
  {

contract?.split(/\n/g)?.map((single, index)=>{
  return(
      <div id='single_text_paragraph' key={index} style={{
        width:'100%', 
        margin:'20px 0px 0px 0px', 
        fontSize: '12pt',
        whiteSpace: 'pre-wrap', 
        textIndent:'100px'
      }}>

        {single}

      </div>
  )
})

  }



  
  
  
  <div style={{margin:'100px 0px 10px 0px', fontSize:'16pt', fontWeight:'bold', width:'100%' }}>
    Campina Grande/PB, {formatDate(new Date())}
  </div>
  <div style={{margin:'100px 0px 10px 0px', fontSize:'16pt', fontWeight:'bold', width:'100%' , textAlign:'center', borderTop:'2px solid'}}>
    CONTRATANTE / ADERENTE
  </div>
  <div style={{margin:'100px 0px 10px 0px', fontSize:'16pt',  width:'100%' , textAlign:'center', borderTop:'2px solid', gap:'0px'}}>
    <div style={{fontWeight:'bold',}}>RESPONSÁVEL LEGAL/FINANCEIRO</div>
     <br/>
    <div style={{fontWeight:'normal !important', margin:'-20px'}}>(No caso de Menor e/ou Responsável Financeiro)</div>
  </div>
  <div style={{margin:'100px 0px -80px 0px', fontSize:'16pt', fontWeight:'NORMAL', width:'100%' , textAlign:'center'}}>
    FACULDADE REBOUÇAS DE CAMPINA GRANDE
  </div>
  <div style={{margin:'100px 0px 10px 0px', fontSize:'16pt', fontWeight:'bold', width:'100%' , textAlign:'center', borderTop:'2px solid'}}>
    CONTRATADA
  </div>
  <div style={{margin:'100px 0px 10px 0px', fontSize:'16pt', fontWeight:'bold', width:'100%' , textAlign:'center', borderTop:'2px solid'}}>
    TESTEMUNHA 1
  </div>
  <div style={{margin:'100px 0px 10px 0px', fontSize:'16pt', fontWeight:'bold', width:'100%' , textAlign:'center', borderTop:'2px solid'}}>
    TESTEMUNHA 2
  </div>
  
  
    
  
  
      
  
      {/* <DocViewer 
        documents={docs}       
        // pluginRenderers={DocViewerRenderers}
      /> */}
  
  
  
  
  
  
  </div>
  
  :

  <div style={{display:'flex', fontSize:'26pt', fontWeight:'bold', textAlign:'center', justifyContent:'center', }}>Carregando contrato ...</div>
  
    }
    

    </div>

	);
}

export default Contract1;