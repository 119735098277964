import React, { useEffect, useState } from "react";
import { styled } from '@mui/system';
import { PieChart } from '@mui/x-charts/PieChart';
import API from "../../../API_CONFIGS/redux/API";


const RootStyle = styled('div')(({theme})=>({
    width: '100%',
    padding:'10px',

    
})) 
const SubHeader = styled('div')(({theme})=>({
    width: '100%',
    padding:'10px',
    display:'flex',

    borderBottom:'0.5px solid black'
    


}))
const DashBody = styled('div')(({theme})=>({
    width: '100%',
    maxHeight:'90vh',
    overflow:'auto',

    padding:'10px',
    display:'flex',
    flexWrap:'wrap',
    gap:'10px',
    justifyContent:'center',



    


}))
const SingleInfoDiv = styled('div')(({theme})=>({
    width: '40%',
    minHeight:'400px',
    maxHeight:'400px',

    padding:'10px',
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
    gap:'10px',

    border:'0.5px solid #ccc',
    borderRadius:'10px',

    // backgroundColor:'red',

    


}))
const SingleInfoH3 = styled('div')(({theme})=>({
    fontSize:'12pt',
    fontWeight:'bold',
    width:'100%',
    textAlign:'center'

    


}))







export default function Promotores_adm({setLoading, }){
    
    const [inscricaoData,setInscricaoData] = useState([])
    const [inscricaoData_total,setInscricaoData_total] = useState(0)

    const [month,setMonth] = useState(new Date().getMonth())

    const [allPromotores,setAllPromotores] =useState([])
    
    
    
    useEffect(()=>{
    
        API.get(`administrativo/getAllPromotoresData/${month}`)
        .then((res)=>{
            setInscricaoData(res?.data?.promotores_dashboard || [])
            var auxCount = 0
            for (let index = 0; index < res?.data?.promotores_dashboard.length; index++) {
                auxCount+=res?.data?.promotores_dashboard[index]?.value
            }
            setInscricaoData_total(auxCount)            
        })
        .catch((error)=>{
            console.error(error);
        })
    
    
    },[month])

    return(
        <RootStyle>


            <SubHeader>
                <h1 style={{margin:'auto auto auto 10px', }}>
                    Dashboard
                </h1>
            </SubHeader>


            <DashBody>

                <SingleInfoDiv  style={{width:'95%'}}>
                    <SingleInfoH3>Inscrições ({inscricaoData_total})</SingleInfoH3>
                    <PieChart
                        series={[
                            {
                            data: inscricaoData ,
                            innerRadius: 20,
                            cornerRadius: 5,
                            startAngle: 0,
                            endAngle: 360,
                            cx: 200,
                            highlightScope: { faded: 'global', highlighted: 'item' },
                            faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                            },
                        ]}
                    />

                </SingleInfoDiv>


                <SingleInfoDiv  style={{width:window.screen.width>730?'30%':"100%"}}>
                    <SingleInfoH3>Filtrar por mês</SingleInfoH3>
                    <select 
                        onChange={(e)=>{
                            // alert(e.target.value)
                            setMonth(e.target.value) 
                        }
                        }

                        name="periodo" id="periodo" style={{width:'100%', padding:'5px', borderRadius:'5px'}}>
                        {
                            // all months
                            Array.from({ length: 12 }).map((_, index) => {
                                // Creates a new Date object and subtracts 'index' months from the current month
                                const monthDate = new Date();
                                monthDate.setMonth(new Date().getMonth() - index);
                                monthDate.setDate(1); // To avoid the end of month edge case
                            
                                return (
                                  <option key={index} value={monthDate.getMonth()}>
                                    {monthDate.toLocaleString('default', { month: 'long', year: 'numeric' })}
                                  </option>
                                );
                              })
                        }
                    </select>
                </SingleInfoDiv>

            </DashBody>




        </RootStyle>
    )
}