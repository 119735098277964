import {createRoot} from 'react-dom/client'
// Axios
import axios from 'axios'
import {Chart, registerables} from 'chart.js'
import {QueryClient, QueryClientProvider} from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'
// Apps
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 * fdsfvcsdvfsdgvfsdgvewdfwefcewdfewfcwefcesdfgvsd
 **/
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'
import {AppRoutes} from './app/routing/AppRoutes'
import { AuthProvider } from './AUTH_CONFIGS/Auth'



const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
        <AuthProvider>
          <AppRoutes />
        </AuthProvider>
  )
}
