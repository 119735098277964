import React from "react";
import { useState } from "react";

import API from "../API_CONFIGS/redux/API";
import { toast } from "react-toastify";

interface UserModel {
  id: number
  password: string | undefined
  email: string
  nome: string,
  avatar:string,
  tipo_usuario_id:Number,
  cep:string,
  endereco:string,
  numero:string,
  complemento:string,
  bairro:string,
  cidade:string,
  estado:string,
  cpf:string,
}

interface AuthContextType {
  isLoggedIn: boolean;
  login: (email: string, password: string) => void;
  logout: () => void;
  checkIsLoggedIn: () => boolean;
  currentUser: UserModel | undefined;
}

export const AuthContext = React.createContext<AuthContextType>({
  isLoggedIn: false,
  login: () => {},
  logout: () => {},
  checkIsLoggedIn: () => false,
  currentUser: undefined,
});

export const useAuth = () => React.useContext(AuthContext);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(
    localStorage.getItem("token") ? true : false
  );
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()


  async function login(email: string, password: string) {
    try {
      const { data } = await API.post("/aluno/login_candidato", {
        email: email,
        senha: password,
      });
  
      if (data.status === "success") {
        toast.success(data.message);
        const token = data.token;
        localStorage.setItem("token", token);
        const userData = data.candidato;
        localStorage.setItem("currentUser", JSON.stringify(userData));
        setIsLoggedIn(true);
        setUser(localStorage.getItem("currentUser") || "");
        window.location.href = '/vestibular'
      }
      else{
        toast.error(data.message)
      }
    } catch (err) {
      toast.error('Não foi possível logar. Verifique seus dados.')
      console.log(err);
      return false;
    }
  }
  
  function setUser(str: string) {
    const userData = JSON.parse(str);
    setCurrentUser(userData);
  }

  function logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("currentUser");
    setIsLoggedIn(false);
    return window.location.href = '/'
  }

  function checkIsLoggedIn() {
    const token = localStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true);
      setUser(localStorage.getItem("currentUser") || "");
      return true;
    } else {
      setIsLoggedIn(false);
      return false;
    }
  }

  return (
    <AuthContext.Provider
      value={{ isLoggedIn, login, logout, checkIsLoggedIn, currentUser}}
    >
      {children}
    </AuthContext.Provider>
  );
};

