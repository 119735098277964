import React from 'react';

/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const desktopStyle = {
  backgroundImage: "url('https://vestibular.faculdadereboucas.com/assets/media/bg/bg-5.jpg')",
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'bottom left',
  width: '350px',
};

const mobileStyle = {
  backgroundImage: "url('https://vestibular.faculdadereboucas.com/assets/media/bg/bg-5.jpg')",
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'top right',
  height: '150px'
};


const AuthLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 992);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid '>
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          <div className='w-lg-500px ' >
            {children}
            {/* <div className='d-flex flex-center '>
              <div className='d-flex fw-semibold text-primary fs-base'>
                <a href='#' className='px-5' target='_blank'>
                  Terms
                </a>
                <a href='#' className='px-5' target='_blank'>
                  Plans
                </a>
                <a href='#' className='px-5' target='_blank'>
                  Contact Us
                </a>
              </div>
            </div> */}
          </div>
        </div>


      </div>

      {/* begin::Aside */}
          <div className='d-flex flex-lg-row  order-1 order-lg-2'
            style={
              isMobile ? mobileStyle : desktopStyle 
            }
              >
              <Link to='/' 
                style={
                  isMobile?{
                    // width:'50%'
                    padding:'10px'
                  }:{
                    // width:'50%',
                    textAlign:'center',
                    paddingTop:'40px'
                
                  }}
              
              >
                <img alt='Logo' 
                src='https://vestibular.faculdadereboucas.com/assets/media/logos/logo_branca.png' 

                style={
                  isMobile?{
                    width:'50%'
                  }:{
                    width:'50%',
                
                  }}
                  />
              </Link>


              
      </div>
      
    </div>
  )
}

export {AuthLayout}
