import React, { useState, useEffect } from 'react';

import ReactLoading from "react-loading";




function Loading({active,text}) {
    return ( 
        <>
        {active &&
            <div style={{display:'flex', flexDirection:'column', gap:'20px', width:'100vw', height:'100vh', position:'fixed', justifyContent:'center', alignItems:'center',zIndex:'9999999999999', backgroundColor:'rgba(0, 12, 124, 0.6)' }}>
            
            <ReactLoading type="spinningBubbles" color='#fff' height='200px' width='200px' />
            <h1 style={{color:'#fff'}}>{text?text:'Carregando'}</h1>

            
            </div>
        }
        </>
     );
}

export default Loading;