import React from "react";

import {useState, useEffect} from 'react'

import API from "../../../../API_CONFIGS/redux/API";
import {toast} from "react-toastify";


interface Step1Props{



}



const Step1Vestibular: React.FC<Step1Props> = ({}) => {

    const [vestoptions,setvestoptions] = useState([{nome:'Vestibular Online',id:-1}])

    const [entrance,setEntrance]=useState('')

    // useEffect(()=>{
    //   API.post('course/entranceoption', )
    //   .then((result)=>{
    //     setvestoptions(result.data.allEntranceOptions)
    //   })
    //   .catch((err)=>{
    //     toast.error(err)
    //     console.log(err);
    //   })

    // },[])

    const handleEntranceOption = (val:string)=>{
    }

  return (
    <div className=""  >
            <div className='row mb-6'>
              <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2 required' >Escolha uma opção de vestibular</label>

              <div className='col-lg-8 fv-row' >
                <select
                  className='form-select form-select-solid form-select-lg'
                  style={{width:'100%'}}
                  onChange={(e)=>{
                    setEntrance(e.target.value);
                    handleEntranceOption(e.target.value);
                  }}

                >
                        
                        {vestoptions.map((item,index)=>{
                            return(
                                <option value={item.id} key={index}
                                >{item.nome}</option> 
                            )
                        })}
                </select>
              </div>
            </div>

    </div>
  )
}


export {Step1Vestibular}