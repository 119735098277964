import React, { useEffect, useState } from "react";
import { styled } from '@mui/system';
import CustomButtom from "../customButtom";
import API from "../../../API_CONFIGS/redux/API";
import { toast } from "react-toastify";
import exportFromJSON from 'export-from-json'  
import jsPDF from 'jspdf';
import { useRef } from 'react';

import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';

import Modal_singlecourse from "./Modal_singleinscription";

import excel from '../../../media/icons/excel.svg'
import print from '../../../media/icons/print.svg'
import plus_light from '../../../media/icons/plus-light.svg'
import list_dark from '../../../media/icons/list-dark.svg'
import edit_dark from '../../../media/icons/edit-dark.svg'
import info_dark from '../../../media/icons/magnifying-dark.svg'
import remove_dark from '../../../media/icons/trash-dark.svg'
import arrow_up_dark from '../../../media/icons/arrow-up-dark.svg'
import arrow_down_dark from '../../../media/icons/arrow-down-dark.svg'








const RootStyle = styled('div')(({theme})=>({
    width: '100%',
    height:'100vh',
    padding:'10px',
    display:'flex',
    flexDirection:'column',

}))
const SubHeader = styled('div')(({theme})=>({
    width: '100%',
    padding:'10px',
    display:'flex',

    borderBottom:'0.5px solid black'
    


}))

const Table = styled('div')(({theme})=>({
    width: '100%',
    maxHeight:'100%',
    padding:'10px',
    display:'flex',
    flexDirection:'column',

    border:'0.5px solid black',
    // gap:'5px',

    overflow:'auto',    


}))
const Line = styled('div')(({theme})=>({
    width: '100%',
    // padding:'5px',
    display:'flex',
    alignItems:'center',
    justifyContent:'space-between',
    padding:'0px 10px 0px 10px',
    fontSize:'12pt',
    // gap:'15px', 

    // backgroundColor:'blue'

    '&:hover':{
        backgroundColor:'rgba(178, 178, 178, 0.2)',
        cursor:'default',
        borderRadius:'10px',
    }

}))
const LineInfo = styled('div')(({ theme }) => ({
    width: '10%',
    minWidth:'250px',
    height: '50px',
    display: 'flex',
    position: 'relative', // Add relative positioning to LineInfo
    overflow: 'scroll', // Show horizontal scrollbar
    whiteSpace: 'normal', // Allow text to wrap and break words when they exceed the width.
  
  }));


  const Options = styled('div')(({theme})=>({
    width: '10%',
    minWidth:'250px',
    display:'flex',
    // justifyContent:'center',
    fontSize:'12pt',
    fontWeight:'bold',
    // gap:'5px',

    // margin:'0px 10px 0px 10px',

    



}))

const SingleElement_info_select = styled(Select)(({theme})=>({
    display:'flex',
    // width:'70px',
    maxHeight:'30px',
    margin:'auto',

    // padding:'10px',
    // border:'1px solid black',
    // borderRadius:'5px',


}))


const ButtonEditLine = styled('div')(({theme})=>({
    width: '30px',
    height:'30px',
    display:'flex',
    cursor:'pointer',
    backgroundColor:'rgba(255, 222, 119, 0.8)',
    borderRadius:'5px',
    padding:'3px',
}))
const ButtonRemoveLine = styled('div')(({theme})=>({
    width: '30px',
    height:'30px',
    display:'flex',
    cursor:'pointer',
    backgroundColor:'rgba(255, 109, 109, 0.8)',
    borderRadius:'5px',
    padding:'3px',
}))
const ButtonDetailsLine = styled('div')(({theme})=>({
    width: '30px',
    height:'30px',
    display:'flex',
    cursor:'pointer',
    backgroundColor:'rgba(186, 109, 255, 0.8)',
    borderRadius:'5px',
    padding:'5px',
}))
const CustomButton = styled('div')(({theme})=>({
    display:'flex', 
    gap:'10px', 
    alignItems:'center', 
    justifyContent:'center', 
    cursor:'pointer', 
    border:'1px solid #ccc', 
    borderRadius:'5px', 
    padding:'5px', 
    '&: hover':{
        backgroundColor:'rgba(100,100,200,0.4)'
    }
}))



function getFullDate_toShow(date,hideHours){
    const thisDate = new Date(date)
        if(!date){return ''}
    var thisDay = thisDate.getDate()
    var thisMonth = thisDate.getMonth() + 1
    var thisYear = thisDate.getFullYear()
    var thisHour = thisDate.getHours()
    var thisMinute = thisDate.getMinutes()
    if(thisDay<10){thisDay = '0'+thisDay}
    if(thisMonth<10){thisMonth = '0'+thisMonth}
    if(thisHour<10){thisHour = '0'+thisHour}
    if(thisMinute<10){thisMinute = '0'+thisMinute}
        if(hideHours){return `${thisDay}/${thisMonth}/${thisYear}`}
        return `${thisDay}/${thisMonth}/${thisYear}, ${thisHour}:${thisMinute}`
}


export default function Inscription_adm({setLoading, }){


    const [list,setList]=useState([])
    const [tipo_cursos,settipo_cursos]=useState([])
    const [selectedCourse,setselectedCourse]=useState({})
    const [isChanging,setisChanging]=useState(false)
    const [isRemoving,setisRemoving]=useState(false)
    const [isCreating,setisCreating]=useState(false)

    const [qtdToShow,setQtdToShow]=useState(10)
    const [showFrom,setshowFrom]=useState(1)
    const [showTo,setshowTo]=useState(10)
    const [isFiltering,setisFiltering]=useState(true)
    const [filterType,setfilterType]=useState('')
    const [isFilterUp,setisFilterUp]=useState(true)
    const [totalPages,setTotalPages]=useState(1)
    const [page,setpage]=useState(1)
    
    const [openModal,setOpenModal]=useState(false)
    const [reload,setReload]=useState(false)
    const [options_qtd,setoptions_qtd]=useState(6)

    // const data = [{ foo: 'foo' }, { bar: 'bar' }]  

    const [data,setdata]=useState({})
    const [fileName,setfileName]=useState('download')
    const [exportType,setexportType]=useState('xls')
    

    const [edited_selectedCourse1,setedited_selectedCourse1]=useState({})


    
  const exportToExcel = () => {  
    exportFromJSON({ data, fileName, exportType })  
  }  


    useEffect(()=>{
        setLoading(true)
        API.post('administrativo/listinscription')
        .then((res)=>{
            var aux = [...res.data.inscricoes_edited]
            setList(aux)
            setTotalPages(Math.ceil(aux.length/qtdToShow))
            setisFiltering(true);
            setfilterType('updated_at'); 
            setisFilterUp(true);


            setfileName(`Inscrições do dia ${getFullDate_toShow(new Date)}`)
            setdata(aux)
            setLoading(false)
        })
        .catch((error)=>{
            console.log(error);
            toast.error(error.response.data.message?error.response.data.message:'Erro ao buscar cursos')
            setLoading(false)
        })
    },[reload])

    const formatValue = (value)=>{
        value = Number(value)
        value = value.toFixed(2).replace(".",',')
        return `R$ ${value}`
    }
    const handleChangePage =(e,newPage)=>{
        const value = Number(newPage)
        return setpage(value)
    }

    useEffect(()=>{
        const to_number = page*qtdToShow
        const from_number = to_number-(qtdToShow-1)
        setshowFrom(from_number)
        return setshowTo(to_number)
    },[page,qtdToShow])



    const getNumbers = (str) => {
        let result = "";
        for (let i = 0; i < str.length; i++) {
          let char = str[i];
          if (char.match(/[0-9]/)) {
            result += char;
          }
        }
        return result;
      }
      

    const handleFilterList=()=>{
        var aux = [...list]
        if(filterType==='candidato'){
            aux = aux.sort((a,b)=>{
                if(isFilterUp){
                    if ((a?.this_candidato?.nome || '') > (b?.this_candidato?.nome || '')) {
                        return -1;
                    }
                    if ((b?.this_candidato?.nome || '') > (a?.this_candidato?.nome || '')) {
                        return 1;
                    }
                    return 0;
                }
                else if(!isFilterUp){
                    if ((a?.this_candidato?.nome || '') < (b?.this_candidato?.nome || '')) {
                        return -1;
                    }
                    if ((b?.this_candidato?.nome || '') < (a?.this_candidato?.nome || '')) {
                        return 1;
                    }
                    return 0;
                }
            })
        }
        if(filterType==='curso'){
            aux = aux.sort((a,b)=>{
                if(isFilterUp){
                    if ((a?.this_curso?.nome || '') > (b?.this_curso?.nome || '')) {
                        return -1;
                    }
                    if ((b?.this_curso?.nome || '') > (a?.this_curso?.nome || '')) {
                        return 1;
                    }
                }
                else if(!isFilterUp){
                    if ((a?.this_curso?.nome || '') < (b?.this_curso?.nome || '')) {
                        return -1;
                    }
                    if ((b?.this_curso?.nome || '') < (a?.this_curso?.nome || '')) {
                        return 1;
                    }
                }
                return 0;
            })
        }
        if(filterType==='status'){
            aux = aux.sort((a,b)=>{
                if(isFilterUp){
                    if ((a?.this_status?.nome || '') > (b?.this_status?.nome || '')) {
                        return -1;
                    }
                    if ((b?.this_status?.nome || '') > (a?.this_status?.nome || '')) {
                        return 1;
                    }
                    return 0;
                }
                else if(!isFilterUp){
                    if ((a?.this_status?.nome || '') < (b?.this_status?.nome || '')) {
                        return -1;
                    }
                    if ((b?.this_status?.nome || '') < (a?.this_status?.nome || '')) {
                        return 1;
                    }
                    return 0;
                }
            })
        }
        if(filterType==='modalidade'){
            aux = aux.sort((a,b)=>{
                if(isFilterUp){
                    if ((a?.this_ingresso?.nome || '') > (b?.this_ingresso?.nome || '')) {
                        return -1;
                    }
                    if ((b?.this_ingresso?.nome || '') > (a?.this_ingresso?.nome || '')) {
                        return 1;
                    }
                    return 0;
                }
                else if(!isFilterUp){
                    if ((a?.this_ingresso?.nome || '') < (b?.this_ingresso?.nome || '')) {
                        return -1;
                    }
                    if ((b?.this_ingresso?.nome || '') < (a?.this_ingresso?.nome || '')) {
                        return 1;
                    }
                    return 0;
                }            })
        }
        if(filterType==='set'){
            aux = aux.sort((a,b)=>{
                if(isFilterUp){
                    if (a.set && !b.set) {
                        return -1;
                    }
                    if (!a.set && b.set) {
                        return 1;
                    }
                    return 0;
                }
                else if(!isFilterUp){
                    if (!a.set && b.set) {
                        return -1;
                    }
                    if (a.set && !b.set) {
                        return 1;
                    }
                    return 0;
                }
            })
        }
        if(filterType==='updated_at'){
            aux = aux.sort((a,b)=>{
                if(isFilterUp){
                    if((new Date(a?.updated_at) || null) && (new Date(b?.updated_at) || null)){
                        if ((new Date(a?.updated_at) || null) > (new Date(b?.updated_at) || null)) {
                            return -1;
                        }
                        if ((new Date(b?.updated_at) || null) > (new Date(a?.updated_at) || null)) {
                            return 1;
                        }
                    }
                    return 0;
                }
                else if(!isFilterUp){
                    if((new Date(a?.updated_at) || null) && (new Date(b?.updated_at) || null)){
                        if ((new Date(a?.updated_at) || null) < (new Date(b?.updated_at) || null)) {
                            return -1;
                        }
                        if ((new Date(b?.updated_at) || null) < (new Date(a?.updated_at) || null)) {
                            return 1;
                        }
                    }
                    return 0;
                }
            })
        }
        setpage(1)
        return setList(aux) 
    }

    const editCourse = (courseData) =>{
        setLoading(true)
        API.post('administrativo/editsinglecourse', courseData)
        .then((res)=>{
            toast.success('sucesso')
            setLoading(false)
        })
        .catch((error)=>{
            console.log(error);
            toast.error(error.response.data.message?error.response.data.message:'Erro ao buscar cursos')
            setLoading(false)
        })
    }


    useEffect(()=>{
        if (isFiltering && filterType) {
            handleFilterList();
        }
    },[isFiltering,filterType,isFilterUp])


    const hideAllToPrint = ()=>{

        // if(window?.location?.pathname?.includes('printmode') || false){
        //     if(list){
        //         setLoading(true)
        //         console.log(list.length);
        //         setQtdToShow(list?.length || 0)
        //         const elementToEdit = document.getElementById('showPrintMode')
        //         const elementToHide1 = document.getElementById('panel_adm_navbar')
        //         const elements_ToHide2 = document.getElementsByClassName('buttonToHide_printmode')
        //         const linesInfo = document.getElementsByClassName('lineInfo')
        //         const elementToExtend = document.getElementById('inscription_table');
        //         // setLoading(true)
        //         elementToHide1.style.display = 'none'
        //         // elementToEdit.style.display = 'none'
        //         elementToEdit.style.position = 'absolute'
        //         elementToEdit.style.width = '100vw'
        //         elementToEdit.style.top = '0px'
        //         elementToEdit.style.left = '0px'
        //         elementToEdit.style.zIndex = '99999999999999999999999999999999999999999999999999999999'
        //         elementToExtend.style.overflow = 'visible'
        //         elementToExtend.style.border = 'none'
        //         // setLoading(true)
        //         for(let i = 0 ; i < elements_ToHide2?.length || 0 ; i++){
        //             console.log(i);
        //             setLoading(true)
        //             var singleToEdit = elements_ToHide2[i]
        //             singleToEdit.style.display = 'none'
        //         }
        //         // setLoading(true)
        //         for(let i = 0 ; i < linesInfo?.length || 0 ; i++){
        //             console.log(i);
        //             setLoading(true)
        //             var singleToEdit = linesInfo[i]
        //             singleToEdit.style.overflow = 'visible'
        //         }
        //         setLoading(false)
                
        //     }
        // }

    }



    useEffect(()=>{
        // hideAllToPrint()


    },[list])

    const addnewcourse =()=>{
        setselectedCourse({id:0, nome:'', tipo_id:'', nota_minima:0, duracao:'', duracao_horas:0, valor:0, set:false})
        setisChanging(false)
        setisRemoving(false)
        setisCreating(true)
        setOpenModal(true)
    }


    const handleSingleCourse = ()=>{
        setLoading(true)
        const data = {
            edited_selectedCourse1:edited_selectedCourse1,
            isChanging:isChanging,
            isRemoving:isRemoving,
            isCreating:isCreating,
        }
        API.post(`/administrativo/handlesinglecandidate`,data)
        .then((res)=>{
            setReload(!reload)
            setOpenModal(false)
            toast.success(res.data.message?res.data.message:'sucesso')
            setLoading(false)
        })
        .catch((error)=>{
            console.log(error);
            toast.error('erro')
            setLoading(false)
        })
    }



    const reportTemplateRef = useRef(null);

	const handleGeneratePdf = async () => {
        setLoading(true)

        API.post('/administrativo/printinscriptions', {list}, { responseType: 'blob' })
        .then((res)=>{
            // const doc = new jsPDF({
            //     format: [2000, 1000],
            //     orientation: 'landscape',
            //     unit: 'px',
            // });
    
            // Adding the fonts.
            // doc.setFont('Inter-Regular', 'normal');
    

            // doc.html(res?.data || '', {
            //     async callback(doc) {
            //         setLoading(true)
            //         await doc.save('inscrições');
            //         setLoading(false)
            //     },
            // });
            
            const blob = new Blob([res.data], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            
            // Open the PDF in a new tab or download it as needed
            // For example, open in a new tab:
            setLoading(false)
            window.open(url, '_blank');
      

    
        })
        .catch((error)=>{
            setLoading(false)
            console.error(error);
        })

        // const html = `
        //     <table style="width:2000px ; padding:20px ; " >
        //     <tr style="margin-bottom: 200px ; " >
        //         <th style="width: 30% ; font-size:20pt ; " >Candidato</th>
        //         <th style="width: 15% ; font-size:20pt ; " >Atualização</th>
        //         <th style="width: 15% ; font-size:20pt ; " >Status</th>
        //         <th style="width: 15% ; font-size:20pt ; " >Curso</th>
        //         <th style="width: 15% ; font-size:20pt ; " >Modalidade</th>
        //     </tr>
        //     ${
        //         list&&list.map((single,index)=>{

                    
        //             if (
        //                 // index < list.length-1000 &&
        //                 index < 500 &&
        //                 single?.this_candidato?.nome && 
        //                 single?.updated_at  &&
        //                 single?.this_status?.nome &&
        //                 single?.this_curso?.nome &&
        //                 single?.this_ingresso?.nome
        //             ) {
        //             return(
        //                 '<tr>' +
        //                     '<td style="width: 30% ; font-size: 12pt ;  " >' + (single?.this_candidato?.nome || '-') + '</td>' +
        //                     '<td style="width: 15% ; font-size: 12pt ;  " >' + (getFullDate_toShow(single?.updated_at)) + '</td>' +
        //                     '<td style="width: 15% ; font-size: 12pt ;  " >' + (single?.this_status?.nome || '-') + '</td>' +
        //                     '<td style="width: 15% ; font-size: 12pt ;  " >' + (single?.this_curso?.nome || '-') + '</td>' +
        //                     '<td style="width: 15% ; font-size: 12pt ;  " >' + (single?.this_ingresso?.nome  || '-') + '</td>' +
        //                 '</tr>'
        //             )
        //             }
        //             return null
        //         }).filter(Boolean)
        //     }
        //     </table>
        //     `
    





        
        
        // setQtdToShow(list?.length || 0)



        


    
	};


    return(
        <RootStyle id="showPrintMode">

        {openModal && 
            <Modal_singlecourse getFullDate_toShow={getFullDate_toShow} setOpenModal={setOpenModal}  selectedCourse={selectedCourse} isChanging={isChanging} isRemoving={isRemoving} isCreating={isCreating} handleSingleCourse={handleSingleCourse} edited_selectedCourse1={edited_selectedCourse1}        setedited_selectedCourse1={setedited_selectedCourse1} tipo_cursos={tipo_cursos}              setLoading={setLoading}            />
        }



            <SubHeader>
                <h1 style={{margin:'auto auto auto 10px', }}>
                    Inscrições {list&&`(${list.length})`}
                </h1>
                <div style={{margin:'auto 0px auto auto', display:'flex',  gap:'10px'}}  className="" >
                        <CustomButton onClick={exportToExcel} >Exportar EXCEL <img src={excel} width='18px' /></CustomButton>
                        {/* <CustomButton onClick={()=>{window?.location?.pathname?.includes('printmode') ? handleGeneratePdf() : window.open(`/painel_administrativo/inscricoes/printmode`, '_blank')}} >Imprimir <img src={print} width='20px' /></CustomButton> */}
                        <CustomButton onClick={handleGeneratePdf} >Imprimir <img src={print} width='20px' /></CustomButton>
                </div>
            </SubHeader>


<div ref={reportTemplateRef}>


                <Table id="inscription_table">
                    <Line style={{backgroundColor:'rgba(0,0,0,0)', width:`${options_qtd*250}px`  }}>
                        <Options style={{width:'20%',justifyContent:'space-between', left:'0px',}}>
                            <div style={{display:'flex', gap:'10px', cursor:'pointer',  }} onClick={()=>{setisFiltering(true); setfilterType('candidato'); setisFilterUp(!isFilterUp);  }} >
                            Candidato
                            {isFiltering && filterType==='candidato' && isFilterUp &&<img src={arrow_up_dark} width='20px' />}
                            {isFiltering && filterType==='candidato' && !isFilterUp &&<img src={arrow_down_dark} width='20px' />}
                            </div>
                        </Options>
                        <Options >
                            <div style={{display:'flex', gap:'10px', cursor:'pointer'}} onClick={()=>{setisFiltering(true); setfilterType('updated_at'); setisFilterUp(!isFilterUp);   }} >
                            Atualização
                            {isFiltering && filterType==='updated_at' && isFilterUp &&<img src={arrow_up_dark} width='20px' />}
                            {isFiltering && filterType==='updated_at' && !isFilterUp &&<img src={arrow_down_dark} width='20px' />}
                            </div>
                        </Options>
                        <Options >
                            <div style={{display:'flex', gap:'10px', cursor:'pointer'}} onClick={()=>{setisFiltering(true); setfilterType('status'); setisFilterUp(!isFilterUp);   }} >
                            Status
                            {isFiltering && filterType==='status' && isFilterUp &&<img src={arrow_up_dark} width='20px' />}
                            {isFiltering && filterType==='status' && !isFilterUp &&<img src={arrow_down_dark} width='20px' />}
                            </div>
                        </Options>
                        <Options >
                            <div style={{display:'flex', gap:'10px', cursor:'pointer'}} onClick={()=>{setisFiltering(true); setfilterType('curso'); setisFilterUp(!isFilterUp);   }} >
                            Curso
                            {isFiltering && filterType==='curso' && isFilterUp &&<img src={arrow_up_dark} width='20px' />}
                            {isFiltering && filterType==='curso' && !isFilterUp &&<img src={arrow_down_dark} width='20px' />}
                            </div>
                        </Options>
                        <Options >
                            <div style={{display:'flex', gap:'10px', cursor:'pointer'}} onClick={()=>{setisFiltering(true); setfilterType('modalidade'); setisFilterUp(!isFilterUp);   }} >
                            Modalidade
                            {isFiltering && filterType==='modalidade' && isFilterUp &&<img src={arrow_up_dark} width='20px' />}
                            {isFiltering && filterType==='modalidade' && !isFilterUp &&<img src={arrow_down_dark} width='20px' />}
                            </div>
                        </Options>
                        <Options  >
                            <div style={{display:'flex', gap:'10px', cursor:'pointer', }} onClick={()=>{setisFiltering(true); setfilterType('set'); setisFilterUp(!isFilterUp);   }} >
                            Ativo
                            {isFiltering && filterType==='set' && isFilterUp &&<img src={arrow_up_dark} width='20px' />}
                            {isFiltering && filterType==='set' && !isFilterUp &&<img src={arrow_down_dark} width='20px' />}
                            </div>
                        </Options>
                        <LineInfo className="buttonToHide_printmode"  style={{display:'flex', gap:'5px', minWidth:'50px', width:'100px', justifyContent:'center',  position:'absolute', right:'10.5px', top:'65px', backgroundColor:'#f9f9f9' , boxShadow: '-2px 0 0 0 rgba(0, 0, 0, 0.2)',  }}/>
                    </Line>

                    <div style={{width:`${options_qtd*250}px`, height:'0.5px', backgroundColor:'rgba(0,0,0,0.5)', margin:'5px auto 5px auto'}}/>


                    {list&&list.map((single,index,array)=>{
                        if(index>=showFrom-1 && index<=showTo-1){
                            return(
                                <div key={index}>
                                {index>0 && 
                                    <div style={{width:`${options_qtd*250}px`,  height:'1px', backgroundColor:'rgba(178, 178, 178, 0.4)'}}/>
                                }
                                <Line key={index} style={{width:`${options_qtd*250}px`  }}>
                                    {/* <LineInfo style={{width:'20%',justifyContent:'space-between', left:'0px', }}>{single.nome}</LineInfo> */}
                                    <LineInfo className="lineInfo">{single?.this_candidato?.nome || '-'}</LineInfo>
                                    <LineInfo className="lineInfo">{getFullDate_toShow(single?.updated_at) || '-'}</LineInfo>
                                    <LineInfo className="lineInfo" style={{minWidth:'300px'}} >
                                        <div style={{padding:'2px 10px 2px 10px', display:'flex', width:'100%', maxHeight:'35px',
                                            backgroundColor:single.this_status.nome === 'Pagamento'?'green':single.this_status.nome === 'Documentação'?'gray':single.this_status.nome === 'Redação'?'blue':single.this_status.nome === 'Resultado do Vestibular'?'darkorchid':single.this_status.nome === 'Resultado da Documentação'?'darkgrey':single.this_status.nome === 'Matriculado'?'darkgoldenrod':'rgba(255, 137, 137, 0.8)', borderRadius:'10px',  fontWeight:'bold', color:'white', alignItems:'center',    }}
                                        >{single?.this_status?.nome || '-'}
                                        </div>
                                    </LineInfo>
                                    <LineInfo className="lineInfo">{single?.this_curso?.nome || '-'}</LineInfo>
                                    <LineInfo className="lineInfo">{single?.this_ingresso?.nome || '-'}</LineInfo>

                                    <LineInfo className="lineInfo" ><div style={{padding:'2px 35px 2px 35px', display:'flex', backgroundColor:single.set?'rgba(137, 255, 143, 0.8)':'rgba(255, 137, 137, 0.8)', borderRadius:'15px', maxHeight:'30px', fontWeight:'bold'  }}>{single.set?'Sim':'Não'}</div></LineInfo>
                                    <LineInfo className="buttonToHide_printmode" style={{display:'flex', gap:'5px', minWidth:'50px', width:'100px',  justifyContent:'center',  position:'absolute', right:'10.5px', backgroundColor:'#f9f9f9' , boxShadow: '-2px 0 0 0 rgba(0, 0, 0, 0.2)',  }}>
                                        {/* <ButtonDetailsLine className="buttonToHide_printmode" ><img src={info_dark}   onClick={()=>{setselectedCourse(single) ; setisChanging(false) ; setisRemoving(false);  setisCreating(false) ; setOpenModal(true)  }}   /></ButtonDetailsLine> */}
                                        <ButtonEditLine className="buttonToHide_printmode" ><img src={edit_dark}   onClick={()=>{setselectedCourse(single) ; setisChanging(true) ; setisRemoving(false);  setisCreating(false) ; setOpenModal(true)  }}      /></ButtonEditLine>
                                    </LineInfo>
                                </Line>
                                </div>
                            )
                        }
                        return null
                    })}



                </Table>

                <Stack sx={{width:'100%', margin:'20px auto 20px auto', display:'flex', flexDirection:'row',  }} className="buttonToHide_printmode" >
                    <Pagination color="primary" count={totalPages} page={page} onChange={handleChangePage} sx={{width:'80%', margin:'auto' }} showLastButton/>
                    <div style={{width:'20%', margin:'auto 0px auto auto', display:'flex', gap:'20px',  alignItems:'center', justifyContent:'center', }}>
                        {/* <div style={{ margin:'auto', display:'flex', gap:'20px',  alignItems:'center', justifyContent:'center', }}>
                            Ítens por página
                        </div>
                        <FormControl sx={{display:'flex', margin:'auto', height:'30px'}}>
                            <SingleElement_info_select
                            value={qtdToShow}
                            onChange={(e)=>{setpage(1);setQtdToShow(e.target.value)}}
                            MenuProps={{
                                sx: {
                                    zIndex: 999999999999, 
                                },
                            }}
                            inputProps={{ 'aria-label': 'Without label', }}
                            >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                            </SingleElement_info_select>
                            <FormHelperText> </FormHelperText>
                        </FormControl> */}

                    </div>
                </Stack>


</div>




        </RootStyle>
    )
}