import React from "react";

import {useState, useEffect} from 'react'
import { styled } from '@mui/system';
import Button from '@mui/material/Button';

import DragAndDropExample from "../../vestibular/components/DragAndDrop";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import saveicon from '../../../media/icons/save-dark.svg'

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import DoneIcon from '@mui/icons-material/Done';
import Avatar from '@mui/material/Avatar';

import { useAuth } from "../../../AUTH_CONFIGS/Auth";
import { API_BASE } from "../../../API_CONFIGS/constants";
import { IMAGE_BASE } from "../../../API_CONFIGS/constants";
import API from "../../../API_CONFIGS/redux/API";
import { toast } from "react-toastify";

import '../../../app/vestibular/components/steps/step6.css'

import approved from '../../../media/icons/approved.svg'
import approvedLight from '../../../media/icons/approved-light.svg'
import rejected from '../../../media/icons/rejected.svg'
import rejectedLight from '../../../media/icons/rejected-light.svg'
import alert from '../../../media/icons/alert.svg'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;


const CustomButton = styled('div')(({theme})=>({
  display:'flex', 
  gap:'10px', 
  alignItems:'center', 
  justifyContent:'center', 
  cursor:'pointer', 
  border:'1px solid #ccc', 
  borderRadius:'5px', 
  padding:'5px', 
  '&: hover':{
      backgroundColor:'rgba(100,100,200,0.4)'
  }
}))





export default function Documents ({redacao, subscriptionID, setLoading, }) {
  const {currentUser}=useAuth()

  const [allDocumentsNeeded,setallDocumentsNeeded] = useState([{nome:'',id:-1,status_arquivo:-1,is_doc_pessoal:false,arquivo:'',fileExtensionPDF:0}])
  const [documentacao,setdocumentacao] = useState({observacao:'Adicione todos os documentos mais atuais.',id:-1})
  const [inscricao,setInscricao] = useState({id:-1})
  const [comment, setcomment]=useState('')

  const [isAnyDocChecked, setisAnyDocChecked]=useState(false)
  const [docSelectedId, setdocSelectedId]=useState('-1')


  useEffect(()=>{
    if(Number(docSelectedId)  && Number(docSelectedId) >= 0){
      setisAnyDocChecked(true)
    }
    
  },[docSelectedId])

  useEffect(()=>{
    // alert(JSON.stringify(allDocumentsNeeded[0]))
    for (let index = 0; index < allDocumentsNeeded.length; index++) {
      const element = allDocumentsNeeded[index];
      if(element.status_arquivo===2){
        setcanObs(true)
      }
    }
  },[allDocumentsNeeded])

 
 const handledocsvest_adm = ()=>{
  setLoading(true)
  API.post('/administrativo/handledocsvest_adm', { subscriptionID: subscriptionID})
  .then((res)=>{
    setallDocumentsNeeded(res.data.docTypes);
    setdocumentacao(res.data.documentacao);
    setInscricao(res.data.subscription);
    setLoading(false)
  }) 
  .catch((error)=>{
    console.error(error);  
    setLoading(false)
  })

 }



 useEffect(()=>{
  if(subscriptionID){
    handledocsvest_adm()
  }
},[subscriptionID])


const aproveSingleDocument = (document)=>{
  setLoading(true)
  API.post('/administrativo/setsinglefile', {file:document, subscriptionID: subscriptionID, status_arquivo:1})
  .then((res)=>{
    handledocsvest_adm()
    setLoading(false)
  }) 
  .catch((error)=>{
    console.error(error);
    setLoading(false)
  })
}
// const correctSingleDocument = (document)=>{
//   API.post('/administrativo/setsinglefile', {file:document, subscriptionID: subscriptionID, status_arquivo:2})
//   .then((res)=>{
//     handledocsvest_adm()
//   }) 
//   .catch((error)=>{
//       console.error(error);
//   })
// }
const rejectSingleDocument = (document)=>{
  setLoading(true)
  API.post('/administrativo/setsinglefile', {file:document, subscriptionID: subscriptionID, status_arquivo:2})
  .then((res)=>{
    handledocsvest_adm()
    setLoading(false)
  }) 
  .catch((error)=>{
    console.error(error);
    setLoading(false)
  })
}



//  useEffect(() => {
//    const fetchData = async () => {
//      try {
//        if (currentUser) {
//          const res = await API.post('/course/handledocsvest', { redacao: redacao, userID: currentUser.id });
//          if (res.data.status === 'success') {
//            setallDocumentsNeeded(res.data.docTypes);
//            setdocumentacao(res.data.documentacao);
//            setInscricao(res.data.inscricao);

//           //  toast.success(res.data.message);
//          } else {
//            toast.error(res.data.message);
//          }
//        }
//      } catch (err) {
//         window.location.href = '/login'
//         console.log(err);
//      }
//    };
 
//    fetchData(); // Initial fetch
 
//    const intervalId = setInterval(fetchData, 1000); // Run fetch every 5 seconds
//    return () => {
//      clearInterval(intervalId); // Clean up the interval on unmount
//    };
//  }, []); // Empty dependency array to run only on mount/unmount
  
  

  // Handle change event for the select component
  const handleSelectChange = (event) => { // Use 'any' type for event
    setdocSelectedId(event.target.value ); // Use type assertion to specify the type of event.target.value as string
  }

  const [handleImage,setHandleImage]=useState(false)
  const [isPDFFile,setIsPDFFile]=useState(false)
  const [canObs,setcanObs]=useState(false)
  const [imageUrl,setImageUrl]=useState('')
  const handleClickChip =(url,nome)=>{
    console.log(url);
    setHandleImage(!handleImage)
    setImageUrl(url)
    if(/.pdf$/i.test(nome)){
      console.log('ispdf file');
      setIsPDFFile(true)
    }
  }



  
  const handleAddObs =()=>{
    setLoading(true)
    const data = {
      subscriptionID:subscriptionID,
      comment:comment
    }
    API.post('/administrativo/addobstodocuments', data)
    .then((res)=>{
      setLoading(false)
      toast.success('Comentário adicionado com sucesso')
    })
    .catch((error)=>{
      setLoading(false)
      toast.error('Erro ao adicionar comentário')
      console.error(error);
    })
  }










 
  return (
<div style={{}}>

      {handleImage &&

            <div className="modal-overlay" style={{
              position: 'fixed',
              top: 'calc(50% - 50vh)',
              left: 'calc(50% - 50vw)',
              width: '100vw',
              height: '100vh',
              backgroundColor: 'rgba(0,0,0,0.5)',
              zIndex: '999',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
                  
            }}> 

      <div className="modal-content-confirm" >
        <div style={{position: 'relative'}}>

          {
            isPDFFile?
            <iframe src={imageUrl} ></iframe>

            :
            <img src={imageUrl} style={{width:'80%'}}/>
          }
          <button 
            className="close-btn" 
            onClick={() => {setHandleImage(false)}}
            style={{position: 'absolute', top: 0, right: 10}}
          >
            Fechar
          </button>
        </div>
      </div>
      </div>
          } 

  <div className="obs-section">
    <h6>Observações (Em caso de negativa de documentos):</h6>
    <textarea value={comment || ''} readOnly={!canObs} className="obs-area" rows={3}
      onChange={(e)=>{setcomment(e.target.value)}}
    />
    {
      comment &&
      <Button onClick={handleAddObs} style={{margin:'auto'}} variant="contained" endIcon={<img src={saveicon} width='20px' />}>
        SALVAR ALTERAÇÕES
      </Button>
}
  </div>

  
  
  <div style={{display:'flex', width:'600px', }}>


      {/* <div style={{width: '50%' }}> */}
      <div >
        <label htmlFor="selectDoc" className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
          Situação dos documentos:
        </label>
        <Stack direction="column" spacing={1} >

        {allDocumentsNeeded.map((single,index)=>{
            return(
              <>
              {index>0 && <div style={{width:'500px', height:'1px', backgroundColor:'#ccc', margin:'auto'}}/>}
              
              <div style={{display:'flex', gap: '10px', width:'80%', margin:'5px auto 5px auto', alignItems:'center'}}>
              <Chip
                key={index}
                label={single?.nome || ''}
                onClick={()=>{if(single?.arquivo || '')handleClickChip(`${IMAGE_BASE}${single?.url || ''}`,single?.arquivo || '')}}
                // onDelete={handleDelete}
                deleteIcon={<DoneIcon />}
                color={single?.status_arquivo===0 ? "secondary":single?.status_arquivo===1?'success':single?.status_arquivo===2?'error':'default'}
                sx={{width:'400px'}}
                avatar={<Avatar  src={
                  !single.arquivo?
                  `${IMAGE_BASE}avatars/close.svg`
                    :
                    single?.fileExtensionPDF?
                      `${IMAGE_BASE}avatars/pdf.svg`
                        :
                        single?.is_doc_pessoal?
                        `${IMAGE_BASE}${single?.url || ''}`
                            :
                          `${IMAGE_BASE}avatars/file.svg`
                } 
                  />}
              />

                <>
                  {single?.status_arquivo===0 ?
                    <div style={{display:'flex',flexDirection:'column', gap:'5px', width:'200px', }} >
                      <div style={{display:'flex', flexDirection:'row', alignItems:'center', color:'green', cursor:'pointer',}} onClick={()=>{if(single?.status_arquivo===0){aproveSingleDocument(single?.arquivo_data || {})}}}>
                        <img  src={approved} width='30px' />
                        Confirmar
                      </div> 
                      <div style={{display:'flex', flexDirection:'row', alignItems:'center',  color:'red', cursor:'pointer',}} onClick={()=>{if(single?.status_arquivo===0){rejectSingleDocument(single?.arquivo_data || {})}}}>
                        <img  src={rejected} width='30px' />
                        Recusar / Reenviar
                      </div> 
                    </div>
                    :
                    <div style={{display:'flex',flexDirection:'column', gap:'5px', width:'200px'}} >
                      <div style={{display:'flex', flexDirection:'row', alignItems:'center', color:'#ccc' , cursor:'not-allowed',}} onClick={()=>{if(single?.status_arquivo===0){aproveSingleDocument(single?.arquivo_data || {})}}}>
                        <img  src={approvedLight} width='30px' />
                        Confirmar
                      </div> 
                      <div style={{display:'flex', flexDirection:'row', alignItems:'center',  color:'#ccc', cursor:'not-allowed',}} onClick={()=>{if(single?.status_arquivo===0){rejectSingleDocument(single?.arquivo_data || {})}}}>
                        <img  src={rejectedLight} width='30px' />
                        Recusar / Reenviar
                      </div> 
                    </div>
                  }
                  </>

              </div>

              </>
            )
          }) || null}
          
          </Stack>

          <label htmlFor="selectDoc" className="d-flex align-items-center  fs-6 fw-bold form-label mb-2" style={{textAlign:'center'}}>
            Entenda as cores:
          </label>

      </div>


  </div>

            <Stack direction="row" spacing={1} sx={{width:'100%',textAlign:'center'}}>
                <Chip
                  label='Aguardando envio'
                  deleteIcon={<DoneIcon />}
                  color={"default"}
                  

                />
                <Chip
                  label='Aguardando análise'
                  deleteIcon={<DoneIcon />}
                  color={"secondary"}
                />
                <Chip
                  label='Confirmado'
                  deleteIcon={<DoneIcon />}
                  color={"success"}
                />
                <Chip
                  label='Rejeitado / Em Correção'
                  deleteIcon={<DoneIcon />}
                  color={"error"}
                />

            </Stack>



</div>



  )
}

