import React, { useState } from "react";
import { styled } from '@mui/system';

const RootStyle = styled('div')(({theme})=>({
    width: '250px',
    padding:'5px 10px 5px 10px',
    display:'flex',
    borderRadius:'5px',
    justifyContent:'center',
    justifyItems:'center',

    alignContent:'center',
    alignItems:'center',

    cursor:'pointer'



}))
const SubHeader = styled('div')(({theme})=>({
    width: '100%',
    padding:'10px',
    backgroundColor:'red',

}))

export default function CustomButtom(props){

    const [color,setColor]=useState(props?.color || -1)
    const [isHover,setIsHover]=useState(false)

    return( 
        <RootStyle style={props.sx?props.sx:{
            backgroundColor:color===0?'green':color===1&&!isHover?'rgba(0, 202, 247 )':color===1&&isHover?'rgba(0, 88, 252)':color===2&&!isHover?'rgba(250, 234, 5, 0.5)':color===2&&isHover?'rgba(250, 148, 5)':color===3&&isHover?'rgba(225, 0, 0, 1)':color===3&&!isHover?'rgba(255, 53, 53, 0.8)':'white',
            color:color===0?'white':color===1?'white':color===2?'black':color===3?'white':'white',
            
            
            


            }}
            onMouseOver={()=>{setIsHover(true)}} onMouseLeave={()=>{setIsHover(false)}} onClick={props.onClick}
        >
            {props?.icon&&
                <img src={props.icon} width='20px'   />
            }
            {props?.text&&
                <p style={{margin:'auto'}}>{props.text}</p>
            }
        </RootStyle>
    )
}