import React, { FC, useEffect, useMemo } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { PrivateRoutes } from "./PrivateRoutes";
import { ErrorsPage } from "../modules/errors/ErrorsPage";
import { Logout, AuthPage } from "../modules/auth";
import { App } from "../App";

import { useAuth } from "../../AUTH_CONFIGS/Auth";
import Login_adm from "../pages/login_adm";
import { AuthLayout } from "../modules/auth/AuthLayout";
import Registration_adm from "../pages/register_adm";
import { Login } from "../modules/auth/login/Login";
import Registration from "../modules/auth/registration/Registration";
import { ForgotPassword } from "../modules/auth/components/ForgotPassword";
import Contract1 from "../vestibular/components/contracts_models/Contract1";

import Vestibular from '../vestibular/Vestibular'
import Panel_adm from '../pages/panel_adm/index'



const { PUBLIC_URL } = process.env;


const AppRoutes: FC = () => {
  const { checkIsLoggedIn } = useAuth();

  useEffect(() => {
    checkIsLoggedIn();
  }, []); // Run only once when the component mounts

  const isAuth = useMemo(() => checkIsLoggedIn(), []); // Memoize the value



  return (
    <BrowserRouter >
          <Routes>
              <Route element={<App />}>
                <Route path="error/*" element={<ErrorsPage />} />
                <Route path="logout" element={<Logout />} />
                    <Route path="/" element={<Navigate to="/login" />} /> 
                    <Route path="/login" element={<AuthLayout><Login/></AuthLayout>} />
                    <Route path="/registro" element={<AuthLayout><Registration/></AuthLayout>} />
                    <Route path="/esqueci-minha-senha" element={<AuthLayout><ForgotPassword/></AuthLayout>} />
                    <Route path="/login_administrativo" element={<AuthLayout><Login_adm/></AuthLayout>} />
                    <Route path="/cadastro_administrativo" element={<AuthLayout><Registration_adm/></AuthLayout>} />
                    <Route path="/esqueci-minha-senha_adm" element={<AuthLayout><ForgotPassword/></AuthLayout>} />

                {/* validate if user is logged */}
                {isAuth ? ( 
                  <>
                    {/* <Route path="/*" element={<PrivateRoutes />} /> */}


                    <Route path='vestibular/*' element={<Vestibular />} />
                    <Route path='/painel_administrativo/*' element={<Panel_adm />} />
                    <Route path='/testtest' element={<Contract1 ContractRef={null} contract={null}  user={null}  course={null}  inscription={null}/>} />


                  </>
                ) : (
                  <>



                    <Route path="/*" element={<Navigate to="/login" />} />

                  </> 
                )}
              </Route>
          </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
