import React, { useEffect, useState } from "react";
import { styled } from '@mui/system';
import CustomButtom from "../customButtom";


import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';

import closeicon from '../../../media/icons/close-dark.svg'
import saveicon from '../../../media/icons/save-dark.svg'
import removeicon from '../../../media/icons/trash-dark.svg'

import { toast } from "react-toastify";
import API from "../../../API_CONFIGS/redux/API";
import { IMAGE_BASE } from "../../../API_CONFIGS/constants";

const RootStyle = styled('div')(({theme})=>({
    position:'fixed',
    width:'100%',
    height:'100%',
    top:'0',
    left:'0',
    backgroundColor:'rgba(0, 0, 0, 0.3)',
    zIndex:'9999',



}))
const Modal = styled('div')(({theme})=>({
    display:'flex',
    flexDirection:'column',
    position:'fixed',
    minWidth:'20%',
    maxWidth:'60%',
    minHeight:'20vh',
    maxHeight:'70vh',
    // overflow:'auto',
    backgroundColor:'rgba(255,255,255)',
    borderRadius:'10px',
    top:'50%',
    left:'50%',
    transform: 'translate(-50%,-50%)',
    zIndex:'99999',
}))

const Header = styled('div')(({theme})=>({
    display:'flex',
    width:'100%',
    display:'flex', 
    top:'0px', 
    left:'0px',
    padding:'10px',
    borderBottom:'1px solid black',
    gap:'10px',
    justifyContent:'center',
    alignItems:'center',
}))
const Body = styled('div')(({theme})=>({
    display:'flex',
    width:'100%',
    display:'flex',
    overflow:'auto',

    padding:'20px',
    gap:'10px',
    justifyContent:'space-between',
    alignItems:'center',


    // backgroundColor:'red',
    margin:'auto auto 55px auto',

    flexWrap: 'wrap',


}))
const SingleElement = styled('div')(({theme})=>({
    width:'calc(33.33% - 10px)',
    display:'flex',
    flexDirection:'column',

    gap:'5px',
    // justifyContent:'space-around',
    // alignItems:'center',


    // backgroundColor:'red'
}))
const SingleElement_title = styled('div')(({theme})=>({
    display:'flex',
    width:'100%',
    fontSize:'10pt',
    fontWeight:'bold',

}))
const SingleElement_info = styled(TextField)(({theme})=>({
    // display:'flex',
    // width:'100%',
    // padding:'10px',
    // border:'1px solid black',
    // borderRadius:'5px',



}))
const SingleElement_info_select = styled(Select)(({theme})=>({
    // display:'flex',
    // width:'100%',
    // padding:'10px',
    // border:'1px solid black',
    // borderRadius:'5px',


}))
const Footer = styled('div')(({theme})=>({
    display:'flex',
    width:'100%',
    display:'flex', 
    position:'inherit',  
    bottom:'0px', 
    left:'0px',
    padding:'10px',
    gap:'10px',
    justifyContent:'space-around',
    alignItems:'center',
    borderTop:'1px solid black',

}))


export default function Modal_singleessay(props){

    const [selectedEssay1,setselectedEssay1]=useState(
        {id:0, nome:'', tipo_id:'', nota_minima:0, duracao:'', valor:0, set:false},
    )
    const [isEdited,setisedited]=useState(false)
    const [isOK,setisOK]=useState(false)
    const [canRemove,setcanRemove]=useState(false)
    const [essayScore,setEssayScore]=useState(0)
    const [canUpdate,setCanUpdate]=useState(false)
    const [canEditEssay,setcanEditEssay]=useState(false)
    const [editedEssay,setEditedEssay]=useState({})

    const SendEssayScore =()=>{
        API.post('administrativo/setessayscore',{score:essayScore,essay:selectedEssay1})
        .then((res)=>{
            window.location.reload()
        })
        .catch((error)=>{
            toast.info(error?.message || 'Erro ao atualizar. Tente novamente.')
        })
    }

    const handleEdit = (field,value)=>{
        var aux = {...props.edited_selectedEssay1}
        aux[field] = value
        props.setedited_selectedEssay1(aux)
    }

    useEffect(() => {
        const areEdited = () => {
            if(props?.edited_selectedEssay1){
                const keys = Object.keys(props.edited_selectedEssay1)
            for (let i = 0; i < Object.keys(props.edited_selectedEssay1).length; i++) {
                const keyName = keys[i]
                if (props.edited_selectedEssay1[keyName] !== selectedEssay1[keyName]) {
                    return true;
                }
            }
            }
          return false;
        };
      
        const isEdited = areEdited();
        setisedited(isEdited);
      }, [props.edited_selectedEssay1, selectedEssay1]);
    
      useEffect(() => {
        const areOK = () => {
            if(props?.edited_selectedEssay1){
            const keys = Object.keys(props.edited_selectedEssay1)
          for (let i = 0; i < Object.keys(props.edited_selectedEssay1).length; i++) {
            const keyName = keys[i]
            if (props.edited_selectedEssay1[keyName] !== selectedEssay1[keyName]) {
                return true;
            }
        }
        }
          return false;
        };
      
        const isOK = areOK();
        setisOK(isOK);
      }, [props.edited_selectedEssay1]);
      
    useEffect(()=>{
        const aux = {...selectedEssay1}
        return props.setedited_selectedEssay1(aux)
    },[selectedEssay1])

    useEffect(()=>{
        if(props.selectedEssay){
            return setselectedEssay1(props.selectedEssay)
        }
        return setselectedEssay1({id:0, nome:'', tipo_id:'', nota_minima:0, duracao:'', valor:0, set:false})
    },[props])


    
    useEffect(()=>{
        const toReturn = () =>{
            if( props?.edited_selectedEssay1?.this_status_data?.nome==='Em Correção' && 
            !props.edited_selectedEssay1.nota ){
                return setcanEditEssay(true)
            }
            // setEditedEssay(props?.edited_selectedCourse1?.this_redacao || {})
            return setcanEditEssay(false)
        }
        toReturn()
    },[props?.edited_selectedEssay1 , ])


    const handleEssayScore = (e)=>{
            const value = Number(e?.target?.value)
            if(value !== NaN && value>=0 && value<=1000){ return setEssayScore(value) }
            return setEssayScore(0)
    }



    return(
        <>
            <RootStyle onClick={()=>{props.setOpenModal(false)}}     />
            <Modal>
                <div style={{ display:'flex', position:'inherit',  top:'0px', left:'calc(100% - 40px)', width:'40px', padding:'7px', zIndex:'99'  }}   ><img width='100%'  src={closeicon} style={{cursor:'pointer'}}  onClick={()=>{props.setOpenModal(false)}}/></div>
            
                <Header>
                    <h3 style={{ marginLeft:'50px'}}>Redação:</h3>
                    <h2 style={{fontWeight:'bold', color:'#000c7c', marginRight:'50px'}}>{selectedEssay1?.id || ''}</h2>
                </Header>

                {selectedEssay1 && 
                <Body>
                        <SingleElement>
                            <SingleElement_title>Candidato:</SingleElement_title>
                            <SingleElement_info value={props?.edited_selectedEssay1?.this_candidate_data?.nome || ''}  helperText={' '}  />
                        </SingleElement>
                        <SingleElement>
                            <SingleElement_title>Tema:</SingleElement_title>
                            <SingleElement_info  value={props?.edited_selectedEssay1?.this_tema_data?.nome || ''} helperText={' '}  />
                        </SingleElement>
                        <SingleElement>
                            <SingleElement_title>Forma:</SingleElement_title>
                            <SingleElement_info  value={props?.edited_selectedEssay1?.tipo=='D'?'Digitada':props?.edited_selectedEssay1?.tipo=='F' ? "Foto" : 'Indefinido'} helperText={' '} />
                        </SingleElement>
                        <SingleElement>
                            <SingleElement_title>Situação:</SingleElement_title>
                            <SingleElement_info  value={props?.edited_selectedEssay1?.this_status_data?.nome || ''}     helperText={' '}   />
                        </SingleElement>
                        <SingleElement>
                            <SingleElement_title>Enviado em:</SingleElement_title>
                            <SingleElement_info  value={props?.edited_selectedEssay1?.updated_at && props.formatDate ? props.formatDate(selectedEssay1.updated_at) : 'Indefinido'}   helperText={' '}  />
                        </SingleElement>
                        <SingleElement>
                            <SingleElement_title>Nota:</SingleElement_title>
                            <SingleElement_info  
                                value={props?.edited_selectedEssay1?.nota || essayScore} 
                                disabled={!canEditEssay}
                                error={(!props?.edited_selectedEssay1?.nota && !essayScore && canEditEssay) || (essayScore>1000 || essayScore<0) } 
                                onChange={handleEssayScore}
                                helperText={props?.edited_selectedEssay1?.status_id >=3 && !props.edited_selectedEssay1.nota ? 'Esperando correção' : ' '}   
                            />
                            </SingleElement>
                        <SingleElement style={{width:'100%', }}>
                            <SingleElement_title>Título:</SingleElement_title>
                            <SingleElement_info  value={props?.edited_selectedEssay1?.this_tema_data?.nome || ''} helperText={' '}  />
                        </SingleElement>
                        {props?.edited_selectedEssay1?.texto && 
                            <SingleElement style={{width:'100%', height:'500px',  }}>
                                <SingleElement_title>Texto:</SingleElement_title>
                                <SingleElement_info
                                    multiline
                                    rows={20}
                                    InputProps={{
                                        style: {
                                        overflow:'auto',
                                        },
                                    }}
                                    value={props?.edited_selectedEssay1?.texto || ''}   />
                            </SingleElement>
                        }
                        {props?.edited_selectedEssay1?.imagem && 
                            <SingleElement style={{width:'100%', }}>
                                <SingleElement_title>Foto:</SingleElement_title>
                                <img src={`${IMAGE_BASE}redacao/${selectedEssay1?.id}/${selectedEssay1?.imagem}`} width='100%' style={{margin:'auto', padding:'20px', border:'1px solid black', borderRadius:'5px'}} />
                            </SingleElement>
                        }
                </Body>
                }

                {props && props.isRemoving &&
                        <div style={{width:'100%', padding:'10px', margin:'-50px auto 50px auto', display:'flex', gap:'10px', alignItems:'center', justifyContent:'center', fontSize:'8pt',  color:'red', fontWeight:'bold', cursor:'pointer'}}  onClick={()=>{setcanRemove(!canRemove)}}>
                                <input type='checkbox' checked={canRemove} style={{width:'20px', height:'20px', cursor:'pointer'}}/>
                                <div style={{display:'flex', flexDirection:'column', gap:'0px', textAlign:'justify' }}>
                                    <div >Confirmo que desejo excluir este dado. Eles não estarão mais disponíveis após a exclusão.</div>
                                    <div >Também salvaremos os seus dados, por questões de controle/identificação de quem realizou a exclusão.</div>
                                </div>
                        </div>
                }


                <Footer>
                    <CustomButtom sx={{backgroundColor:'rgba(200, 200, 200, 0.5)', }}  text='Fechar' onClick={()=>{props.setOpenModal(false)}}     />
                    {props && props.isChanging && canEditEssay && essayScore>0 &&
                        <CustomButtom color={1} text='Salvar' icon={saveicon} onClick={SendEssayScore}/>
                    }
                </Footer>
            </Modal>
        </>
    )
}