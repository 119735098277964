// importa o hook useState e useEffect do React
import {
    useState,
    useEffect
} from 'react'

// cria o hook customizado useWindowSize
export default function useWindowSize() {
    // define o tamanho da janela como um estado com largura e altura indefinidas
    const [windowSize, setWindowSize] = useState({
        width: 0,
        height: 0
    })

    // executa a função ao carregar a página e sempre que houver mudanças no tamanho da janela
    useEffect(() => {
        // cria uma função para atualizar o tamanho da janela
        const handleResize = () => {
            setWindowSize({
                // atualiza a largura com o tamanho interno da janela (innerWidth)
                width: window.innerWidth,
                // atualiza a altura com o tamanho interno da janela (innerHeight)
                height: window.innerHeight
            })
        }

        // adiciona o evento de redimensionamento à janela
        window.addEventListener('resize', handleResize)
        // chama a função handleResize logo após a inicialização
        handleResize()

        // remove o evento de redimensionamento da janela ao sair da página
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    // retorna o tamanho da janela como estado
    return windowSize

}