import React, { useEffect, useState } from "react";
import { styled } from '@mui/system';
import { PieChart } from '@mui/x-charts/PieChart';
import API from "../../../API_CONFIGS/redux/API";


const RootStyle = styled('div')(({theme})=>({
    width: '100%',
    padding:'10px',

    
})) 
const SubHeader = styled('div')(({theme})=>({
    width: '100%',
    padding:'10px',
    display:'flex',

    borderBottom:'0.5px solid black'
    


}))
const DashBody = styled('div')(({theme})=>({
    width: '100%',
    maxHeight:'90vh',
    overflow:'auto',

    padding:'10px',
    display:'flex',
    flexWrap:'wrap',
    gap:'10px',
    justifyContent:'center',



    


}))
const SingleInfoDiv = styled('div')(({theme})=>({
    width: '40%',
    minHeight:'400px',
    maxHeight:'400px',

    padding:'10px',
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
    gap:'10px',

    border:'0.5px solid #ccc',
    borderRadius:'10px',

    // backgroundColor:'red',

    


}))
const SingleInfoH3 = styled('div')(({theme})=>({
    fontSize:'12pt',
    fontWeight:'bold',
    width:'100%',
    textAlign:'center'

    


}))









export default function Dashboard_adm({setLoading, }){
    
    const [inscricaoData,setInscricaoData] = useState([])
    const [inscricaoData_total,setInscricaoData_total] = useState(0)
    const [redacaoData,setRedacaoData] = useState([])
    const [redacaoData_total,setRedacaoData_total] = useState(0)
    const [ingressoData,setIngressoData] = useState([])
    const [ingressoData_total,setIngressoData_total] = useState(0)
    const [cursosData,setCursosData] = useState([])
    const [cursosData_total,setCursosData_total] = useState(0)



    useEffect(()=>{
    
        API.get('administrativo/getAllDashboardData')
        .then((res)=>{
            // inscrições
            setInscricaoData(res?.data?.inscricoes_status_dashboard || [])
            var auxCount = 0
            for (let index = 0; index < res?.data?.inscricoes_status_dashboard.length; index++) {
                auxCount+=res?.data?.inscricoes_status_dashboard[index]?.value
            }
            setInscricaoData_total(auxCount)
            // inscrições
            
            // Redações
            setRedacaoData(res?.data?.redacoes_status_dashboard || [])
            auxCount = 0
            for (let index = 0; index < res?.data?.redacoes_status_dashboard.length; index++) {
                auxCount+=res?.data?.redacoes_status_dashboard[index]?.value
            }
            setRedacaoData_total(auxCount)
            // Redações
            
            // Ingresso
            setIngressoData(res?.data?.ingressos_dashboard || [])
            auxCount = 0
            for (let index = 0; index < res?.data?.ingressos_dashboard.length; index++) {
                auxCount+=res?.data?.ingressos_dashboard[index]?.value
            }
            setIngressoData_total(auxCount)
            // Ingresso
            
            // Cursos
            setCursosData(res?.data?.cursos_dashboard || [])
            auxCount = 0
            for (let index = 0; index < res?.data?.cursos_dashboard.length; index++) {
                auxCount+=res?.data?.cursos_dashboard[index]?.value
            }
            setCursosData_total(auxCount)
            // Cursos
        })
        .catch((error)=>{
            console.error(error);
        })
    
    
    },[])

    return(
        <RootStyle>


            <SubHeader>
                <h1 style={{margin:'auto auto auto 10px', }}>
                    Dashboard
                </h1>
            </SubHeader>


            <DashBody>

                <SingleInfoDiv  style={{width:'70%'}}>
                    <SingleInfoH3>Inscrições ({inscricaoData_total})</SingleInfoH3>
                    <PieChart
                        series={[
                            {
                            data: inscricaoData ,
                            innerRadius: 20,
                            cornerRadius: 5,
                            startAngle: 0,
                            endAngle: 360,
                            cx: 200,
                            highlightScope: { faded: 'global', highlighted: 'item' },
                            faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                            },
                        ]}
                    />

                </SingleInfoDiv>

                <SingleInfoDiv  style={{width:'50%'}}>
                    <SingleInfoH3>Redações ({redacaoData_total})</SingleInfoH3>
                    <PieChart
                        series={[
                            {
                            data: redacaoData,
                            innerRadius: 20,
                            cornerRadius: 5,
                            startAngle: 0,
                            endAngle: 360,
                            cx: 200,
                            highlightScope: { faded: 'global', highlighted: 'item' },
                            faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                            },
                        ]}
                    />

                </SingleInfoDiv>

                <SingleInfoDiv style={{width:'50%'}}>
                    <SingleInfoH3>Formas de ingresso ({ingressoData_total})</SingleInfoH3>
                    <PieChart
                        series={[
                            {
                            data: ingressoData,
                            innerRadius: 20,
                            cornerRadius: 5,
                            startAngle: 0,
                            endAngle: 360,
                            cx: 200,
                            highlightScope: { faded: 'global', highlighted: 'item' },
                            faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                            },
                        ]}
                    />
                </SingleInfoDiv>

                <SingleInfoDiv style={{width:'100%'}}>
                    <SingleInfoH3>Matriculados ({cursosData_total})</SingleInfoH3>
                    <PieChart
                        series={[
                            {
                            data: cursosData,
                            innerRadius: 20,
                            cornerRadius: 5,
                            startAngle: 0,
                            endAngle: 360,
                            cx: 200,
                            highlightScope: { faded: 'global', highlighted: 'item' },
                            faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                            },
                        ]}
                    />
                </SingleInfoDiv>





                
            </DashBody>




        </RootStyle>
    )
}