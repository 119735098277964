import React, { useEffect, useState } from "react";
import { styled } from '@mui/system';
import CustomButtom from "../customButtom";


import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';

import closeicon from '../../../media/icons/close-dark.svg'
import saveicon from '../../../media/icons/save-dark.svg'
import removeicon from '../../../media/icons/trash-dark.svg'

import { toast } from "react-toastify";

const RootStyle = styled('div')(({theme})=>({
    position:'fixed',
    width:'100%',
    height:'100%',
    top:'0',
    left:'0',
    backgroundColor:'rgba(0, 0, 0, 0.3)',
    zIndex:'9999',



}))
const Modal = styled('div')(({theme})=>({
    display:'flex',
    flexDirection:'column',
    position:'fixed',
    minWidth:'20%',
    maxWidth:'60%',
    minHeight:'20vh',
    maxHeight:'70vh',
    backgroundColor:'rgba(255,255,255)',
    borderRadius:'10px',
    top:'50%',
    left:'50%',
    transform: 'translate(-50%,-50%)',
    zIndex:'99999',
}))

const Header = styled('div')(({theme})=>({
    display:'flex',
    width:'100%',
    display:'flex', 
    top:'0px', 
    left:'0px',
    padding:'10px',
    borderBottom:'1px solid black',
    gap:'10px',
    justifyContent:'center',
    alignItems:'center',
}))
const Body = styled('div')(({theme})=>({
    display:'flex',
    width:'100%',
    display:'flex',

    padding:'10px',
    gap:'10px',
    justifyContent:'space-between',
    alignItems:'center',


    // backgroundColor:'red',
    margin:'auto auto 50px auto',

    flexWrap: 'wrap',


}))
const SingleElement = styled('div')(({theme})=>({
    width:'calc(33.33% - 10px)',
    display:'flex',
    flexDirection:'column',

    gap:'5px',
    // justifyContent:'space-around',
    // alignItems:'center',


    // backgroundColor:'red'
}))
const SingleElement_title = styled('div')(({theme})=>({
    display:'flex',
    width:'100%',
    fontSize:'10pt',
    fontWeight:'bold',

}))
const SingleElement_info = styled(TextField)(({theme})=>({
    // display:'flex',
    // width:'100%',
    // padding:'10px',
    // border:'1px solid black',
    // borderRadius:'5px',


}))
const SingleElement_info_select = styled(Select)(({theme})=>({
    // display:'flex',
    // width:'100%',
    // padding:'10px',
    // border:'1px solid black',
    // borderRadius:'5px',


}))
const Footer = styled('div')(({theme})=>({
    display:'flex',
    width:'100%',
    display:'flex', 
    position:'inherit',  
    bottom:'0px', 
    left:'0px',
    padding:'10px',
    gap:'10px',
    justifyContent:'space-around',
    alignItems:'center',
}))


export default function Modal_singlecampaign(props){

    const [selectedCourse1,setselectedCourse1]=useState(
        {id:0, nome:'', tipo_id:'', nota_minima:0, duracao:'', valor:0, set:false},
    )
    const [isEdited,setisedited]=useState(false)
    const [isOK,setisOK]=useState(false)
    const [canRemove,setcanRemove]=useState(false)



    const handleEdit = (field,value)=>{
        var aux = {...props.edited_selectedCourse1}
        aux[field] = value
        props.setedited_selectedCourse1(aux)
    }

    useEffect(() => {
        const areEdited = () => {
            const keys = Object.keys(props.edited_selectedCourse1)
          for (let i = 0; i < Object.keys(props.edited_selectedCourse1).length; i++) {
            const keyName = keys[i]
            if (props.edited_selectedCourse1[keyName] !== selectedCourse1[keyName]) {
                return true;
            }
        }
          return false;
        };
      
        const isEdited = areEdited();
        setisedited(isEdited);
      }, [props.edited_selectedCourse1, selectedCourse1]);
    
      useEffect(() => {
        const areOK = () => {
            const keys = Object.keys(props.edited_selectedCourse1)
          for (let i = 0; i < Object.keys(props.edited_selectedCourse1).length; i++) {
            const keyName = keys[i]
            if (props.edited_selectedCourse1[keyName] !== selectedCourse1[keyName]) {
                return true;
            }
        }
          return false;
        };
      
        const isOK = areOK();
        setisOK(isOK);
      }, [props.edited_selectedCourse1]);
      
    useEffect(()=>{
        const aux = {...selectedCourse1}
        return props.setedited_selectedCourse1(aux)
    },[selectedCourse1])

    useEffect(()=>{
        if(props.selectedCourse){
            return setselectedCourse1(props.selectedCourse)
        }
        return setselectedCourse1({id:0, nome:'', tipo_id:'', nota_minima:0, duracao:'', valor:0, set:false})
    },[props])


    return(
        <>
            <RootStyle onClick={()=>{props.setOpenModal(false)}}     />
            <Modal>
                <div style={{ display:'flex', position:'inherit',  top:'0px', left:'calc(100% - 40px)', width:'40px', padding:'7px', zIndex:'99'  }}   ><img width='100%'  src={closeicon} style={{cursor:'pointer'}}  onClick={()=>{props.setOpenModal(false)}}/></div>
            
                <Header>
                    <h3 style={{ marginLeft:'50px'}}>Campanha:</h3>
                    <h2 style={{fontWeight:'bold', color:'#000c7c', marginRight:'50px'}}>{selectedCourse1.nome}</h2>
                </Header>

                {selectedCourse1 && 
                <Body>
                        <SingleElement>
                            <SingleElement_title>Nome:</SingleElement_title>
                            <SingleElement_info value={props.edited_selectedCourse1.nome?props.edited_selectedCourse1.nome:''} disabled={props.isChanging?false:props.isCreating?false:true} error={!props.edited_selectedCourse1.nome} helperText={!props.edited_selectedCourse1.nome?"Inválido / Incompleto":' '}  onChange={(e)=>{handleEdit('nome',e.target.value)}}       />
                        </SingleElement>
                        {/* <SingleElement>
                            <SingleElement_title>Tipo:</SingleElement_title>

                            <FormControl>
                                <SingleElement_info_select
                                value={props.edited_selectedCourse1.tipo_id}
                                onChange={(e)=>{handleEdit('tipo_id',e.target.value)}}
                                MenuProps={{
                                    sx: {
                                      zIndex: 999999999999, 
                                    },
                                }}
                                inputProps={{ 'aria-label': 'Without label',  }}
                                disabled={props.isChanging?false:props.isCreating?false:true}
                                >
                                    {props.tipo_cursos &&
                                        props.tipo_cursos.map((single)=>{
                                            return <MenuItem value={single.nome} key={single.id}>{single.nome}</MenuItem>
                                        })
                                    
                                    }
                                </SingleElement_info_select>
                                <FormHelperText> </FormHelperText>
                            </FormControl>

                            <SingleElement_info value={props.edited_selectedCourse1.tipo_id?props.edited_selectedCourse1.tipo_id:''} disabled={props.isChanging?false:props.isCreating?false:true} error={!props.edited_selectedCourse1.tipo_id} helperText={!props.edited_selectedCourse1.tipo_id?"Inválido / Incompleto":' '}  onChange={(e)=>{handleEdit('tipo_id',e.target.value)}}       />
                        </SingleElement> */}
                        {/* <SingleElement>
                            <SingleElement_title>Nota de corte:</SingleElement_title>
                            <SingleElement_info type="number" value={props.edited_selectedCourse1.nota_minima?props.edited_selectedCourse1.nota_minima:''} disabled={props.isChanging?false:props.isCreating?false:true} error={!props.edited_selectedCourse1.nota_minima} helperText={!props.edited_selectedCourse1.nota_minima?"Inválido / Incompleto":' '}  onChange={(e)=>{handleEdit('nota_minima',e.target.value)}}       />
                        </SingleElement> */}
                        {/* <SingleElement>
                            <SingleElement_title>Duração - Texto:</SingleElement_title>
                            <SingleElement_info value={props.edited_selectedCourse1.duracao?props.edited_selectedCourse1.duracao:''} disabled={props.isChanging?false:props.isCreating?false:true} error={!props.edited_selectedCourse1.duracao} helperText={!props.edited_selectedCourse1.duracao?"Inválido / Incompleto":' '}  onChange={(e)=>{handleEdit('duracao',e.target.value)}}       />
                        </SingleElement> */}
                        {/* <SingleElement>
                            <SingleElement_title>Valor:</SingleElement_title>
                            <SingleElement_info type="number" value={props.edited_selectedCourse1.valor?props.edited_selectedCourse1.valor:''} disabled={props.isChanging?false:props.isCreating?false:true} error={!props.edited_selectedCourse1.valor} helperText={!props.edited_selectedCourse1.valor?"Inválido / Incompleto":' '}  onChange={(e)=>{handleEdit('valor',e.target.value)}}       />
                        </SingleElement> */}
                        <SingleElement>
                            <SingleElement_title>Ano:</SingleElement_title>
                            <SingleElement_info  value={props.edited_selectedCourse1.ano?props.edited_selectedCourse1.ano:''} disabled={props.isChanging?false:props.isCreating?false:true} error={!props.edited_selectedCourse1.ano} helperText={!props.edited_selectedCourse1.ano?"Inválido / Incompleto":' '}  onChange={(e)=>{handleEdit('ano',e.target.value)}}       />
                        </SingleElement>
                        <SingleElement>
                            <SingleElement_title>Ativo:</SingleElement_title>
                            <FormControl>
                                <SingleElement_info_select
                                value={props.edited_selectedCourse1.set?'Sim':'Não'}
                                onChange={(e)=>{handleEdit('set',e.target.value==='Sim'?true:false)}}
                                MenuProps={{
                                    sx: {
                                      zIndex: 999999999999, 
                                    },
                                }}
                                inputProps={{ 'aria-label': 'Without label',  }}
                                disabled={props.isChanging?false:props.isCreating?false:true}
                                >
                                <MenuItem value={'Sim'}>Sim</MenuItem>
                                <MenuItem value={'Não'}>Não</MenuItem>
                                </SingleElement_info_select>
                                <FormHelperText> </FormHelperText>
                            </FormControl>
                        </SingleElement>
                        {/* <SingleElement style={{margin:'auto'}}>
                            <SingleElement_title>Duração - Horas:</SingleElement_title>
                            <SingleElement_info type="number" value={props.edited_selectedCourse1.duracao_horas?props.edited_selectedCourse1.duracao_horas:''} disabled={props.isChanging?false:props.isCreating?false:true} error={!props.edited_selectedCourse1.duracao_horas} helperText={!props.edited_selectedCourse1.duracao_horas?"Inválido / Incompleto":' '}  onChange={(e)=>{handleEdit('duracao_horas',Number(e.target.value))}}       />
                        </SingleElement> */}


                </Body>
                }

                {props && props.isRemoving &&
                        <div style={{width:'100%', padding:'10px', margin:'-50px auto 50px auto', display:'flex', gap:'10px', alignItems:'center', justifyContent:'center', fontSize:'8pt',  color:'red', fontWeight:'bold', cursor:'pointer'}}  onClick={()=>{setcanRemove(!canRemove)}}>
                                <input type='checkbox' checked={canRemove} style={{width:'20px', height:'20px', cursor:'pointer'}}/>
                                <div style={{display:'flex', flexDirection:'column', gap:'0px', textAlign:'justify' }}>
                                    <div >Confirmo que desejo excluir este dado. Eles não estarão mais disponíveis após a exclusão.</div>
                                    <div >Também salvaremos os seus dados, por questões de controle/identificação de quem realizou a exclusão.</div>
                                </div>
                        </div>
                }


                <Footer>
                    <CustomButtom sx={{backgroundColor:'rgba(200, 200, 200, 0.5)', }}  text='Fechar' onClick={()=>{props.setOpenModal(false)}}     />
                    {props && props.isChanging &&
                        <CustomButtom color={1} text='Salvar' icon={saveicon} onClick={()=>{if(isEdited){return props.handleSingleCourse()}return toast.info('Altere algum campo, se desejar salvar')}}/>
                    }
                    {props && props.isCreating &&
                        <CustomButtom color={1} text='Salvar' icon={saveicon} onClick={()=>{if(isOK){return props.handleSingleCourse()}return toast.info('Altere algum campo, se desejar salvar')}}/>
                    }
                    {props && props.isRemoving &&
                        <CustomButtom color={3} text='Excluir' icon={removeicon} onClick={()=>{if(canRemove){return props.handleSingleCourse()}return toast.info('Confirme antes de excluir')}}/>
                    }
                </Footer>
            </Modal>
        </>
    )
}