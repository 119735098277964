import React, {FC, useEffect, useState} from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import {useIntl} from 'react-intl'

import { SidebarMenuItemWithSub } from '../../../../_metronic/layout/components/sidebar/sidebar-menu/SidebarMenuItemWithSub'
import { SidebarMenuItem } from '../../../../_metronic/layout/components/sidebar/sidebar-menu/SidebarMenuItem'
import { right } from '@popperjs/core'

import API from '../../../../API_CONFIGS/redux/API'
import { toast } from 'react-toast'

interface step2vest{
  tipo_redacao:string ,
  set_tipo_redacao:(tipo_redacao:string)=>void,
  valid_tipo_redacao:boolean,
  setValid_tipo_redacao:(valid_tipo_redacao:boolean)=>void,
  canstep2:boolean,
  setcanstep2:(canstep2:boolean)=>void,
}

const Step2Vestibular: FC<step2vest>=({tipo_redacao,set_tipo_redacao,setValid_tipo_redacao,valid_tipo_redacao}) => {

    const themeOptions = [
      {nome:'Selecione uma opção',id:-1,tipo:''},
      {nome:'Digitada (Online)',id:0,tipo:'D'},
      {nome:'Escrita (Envio de imagem)',id:1,tipo:'F'},
    ]

    const [firstActive,setfirstActive]=useState(false)
    const [secondActive,setsecondActive]=useState(false)
    const [thirdActive,setthirdActive]=useState(false)
    const [fourActive,setfourActive]=useState(false)


    const handleTestOption = (val:string)=>{
      
    }




  return (
    <div className=''>
      {/* <div className='pb-10 pb-lg-15'> */}

        <div className='row mb-6'>
              <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2 required'>Selecione a forma que deseja realizar a redação</label>

              <div className='col-lg-8 fv-row' >
                <select
                  className='form-select form-select-solid form-select-lg'
                    style={{width:'100%', cursor:'pointer'}}
                  onChange={(e)=>{
                    if(e.target.value!==''){
                      setValid_tipo_redacao(true)}
                    else{
                      setValid_tipo_redacao(false)
                    }
                    set_tipo_redacao(e.target.value);
                  }}
                >
                        
                        {themeOptions.map((item,index)=>{
                            return( 
                                <option style={{cursor:'pointer'}}  value={item.tipo}>{item.nome}</option> 
                            )
                        })} 
                </select>
              </div>
            </div>


<div className=' '
          style={{alignItems:right}} 

>

  


            <h3 className='fw-bolder text-dark'>Dúvidas sobre a redação:</h3> 


          <div style={{cursor:'pointer'}} className='form-select form-select-solid form-select-lg ' onClick={()=>{setfirstActive(!firstActive)}} 
          >
                  Como funciona o vestibular online?
          </div>
            {firstActive && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>O vestibular online é um processo seletivo feito à distância, de forma virtual, no qual o candidato será avaliado para o ingresso na Faculdade. O estudante tem um prazo limite para realizar a prova, por isso é recomendável que seja reservado um tempo para o exame. Lembre-se também de conferir o computador, a conexão com a Internet e outros detalhes tecnológicos e digitais que possam comprometer seu desempenho.
                </div>
              </div>
            )}

          <div style={{cursor:'pointer'}} className='form-select form-select-solid form-select-lg' onClick={()=>{setsecondActive(!secondActive)}} >
                  Como fazer a redação?
          </div>
            {secondActive && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>Escreva um texto dissertativo-argumentativo, abordando o tema escolhido. Lembre-se de colocar o título. Depois de iniciada, a redação tem duração de 1 hora e o tempo será controlado por um pequeno cronômetro que encerrará a aplicação quando o limite for atingido. Caso precise interromper a prova, será possível salvar a redação para fazer em outro momento.
                </div>
              </div>
            )}

          <div style={{cursor:'pointer'}} className='form-select form-select-solid form-select-lg' onClick={()=>{setthirdActive(!thirdActive)}} >
                  Como escolher o tipo de redação?
          </div>
            {thirdActive && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>Ao se inscrever, o vestibulando poderá selecionar a opção de Redação digitada (online), a qual deverá ser produzida dentro da plataforma, ou optar pela Redação por imagem, que permite ao candidato produzir a redação de forma manuscrita. Nessa última opção, após o término, o estudante deverá digitalizar o documento e anexar na plataforma.
                </div>
              </div>
            )}
          <div style={{cursor:'pointer'}} className='form-select form-select-solid form-select-lg' onClick={()=>{setfourActive(!fourActive)}} >
                  Como será a escolha do tema?
          </div>
            {fourActive && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>Escolhido o tipo de redação, o sistema sorteará os temas. Escolha aquele que você melhor se identifique.
                </div>
              </div>
            )}

</div>

<h5 style={{textAlign:'center',padding:'15px'}}>Avance para escolher o tema da redação</h5>

   </div>
  )
}

export {Step2Vestibular}
