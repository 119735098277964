import React, { useEffect, useState } from "react";
import { styled } from '@mui/system';
import CustomButtom from "../customButtom";
import Button from '@mui/material/Button';
import { purple } from '@mui/material/colors'



import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';

import closeicon from '../../../media/icons/close-dark.svg'
import saveicon from '../../../media/icons/save-dark.svg'
import removeicon from '../../../media/icons/trash-dark.svg'
import error_svg from '../../../media/icons/error.svg'


import excel from '../../../media/icons/excel.svg'
import print from '../../../media/icons/print.svg'
import plus_light from '../../../media/icons/plus-light.svg'
import list_dark from '../../../media/icons/list-dark.svg'
import edit_dark from '../../../media/icons/edit-dark.svg'
import info_dark from '../../../media/icons/magnifying-dark.svg'
import remove_dark from '../../../media/icons/trash-dark.svg'
import arrow_up_dark from '../../../media/icons/arrow-up-dark.svg'
import arrow_down_dark from '../../../media/icons/arrow-down-dark.svg'



import { toast } from "react-toastify";
import API from "../../../API_CONFIGS/redux/API";

import Documents from "../documents/Documents";
import { IMAGE_BASE } from "../../../API_CONFIGS/constants";



const RootStyle = styled('div')(({theme})=>({
    position:'fixed',
    width:'100%',
    height:'100%',
    top:'0',
    left:'0',
    backgroundColor:'rgba(0, 0, 0, 0.3)',
    zIndex:'9999',



}))
const Modal = styled('div')(({theme})=>({
    display:'flex',
    flexDirection:'column',
    position:'fixed',
    minWidth:'20%',
    maxWidth:'90vw',
    minHeight:'20vh',
    maxHeight:'90vh',
    backgroundColor:'rgba(255,255,255)',
    borderRadius:'10px',
    top:'50%',
    left:'50%',
    transform: 'translate(-50%,-50%)',
    zIndex:'99999',
}))

const Header = styled('div')(({theme})=>({
    display:'flex',
    width:'100%',
    display:'flex', 
    top:'0px', 
    left:'0px',
    padding:'10px',
    borderBottom:'1px solid black',
    gap:'10px',
    justifyContent:'center',
    alignItems:'center',
}))
const Body = styled('div')(({theme})=>({
    display:'flex',
    width:'100%',
    display:'flex',

    padding:'10px',
    gap:'10px',
    justifyContent:'space-between',
    alignItems:'center',


    // backgroundColor:'red',
    margin:'auto auto 50px auto',

    flexWrap: 'wrap',
    overflow:'auto',


}))
const SingleElement = styled('div')(({theme})=>({
    width:'calc(33.33% - 10px)',
    display:'flex',
    flexDirection:'column',

    gap:'5px',
    // justifyContent:'space-around',
    // alignItems:'center',


    // backgroundColor:'red'
}))
const SingleElement_Documents = styled('div')(({theme})=>({
    width:'calc(33.33% - 10px)',
    display:'flex',
    flexDirection:'column',

    gap:'5px',
    width:'100%', 
    border:'solid 1px rgba(32, 145, 237, 0.4)', 
    borderRadius:'5px', 
    cursor:'pointer', 
    padding:'10px', 
    '&:hover':{
        backgroundColor:'rgba(32, 145, 237, 1)', 
        color:'white', 
        fontWeight:'bold'
}
}))

const SingleElement_title = styled('div')(({theme})=>({
    display:'flex',
    width:'100%',
    fontSize:'10pt',
    fontWeight:'bold',

}))
const SingleElement_info = styled(TextField)(({theme})=>({
    
    // display:'flex',
    // width:'100%',
    // padding:'10px',
    // border:'1px solid black',
    // borderRadius:'5px',


}))
const SingleElement_info_select = styled(Select)(({theme})=>({
    // display:'flex',
    // width:'100%',
    // padding:'10px',
    // border:'1px solid black',
    // borderRadius:'5px',


}))
const Footer = styled('div')(({theme})=>({
    display:'flex',
    width:'100%',
    display:'flex', 
    position:'inherit',  
    bottom:'0px', 
    left:'0px',
    padding:'10px',
    gap:'10px',
    justifyContent:'space-around',
    alignItems:'center',
}))


const ColorButton = styled(Button)(({ theme }) => ({
    color: 'black',
    backgroundColor: 'rgba(255, 227, 81, 1)',
    fontWeight:'bold',
    '&:hover': {
      backgroundColor: 'rgba(255, 215, 0, 1)',
    //   color:'white'
    },
  }));


  const CustomButton = styled('div')(({theme})=>({
    display:'flex', 
    gap:'10px', 
    alignItems:'center', 
    justifyContent:'center', 
    cursor:'pointer', 
    border:'1px solid #ccc', 
    borderRadius:'5px', 
    padding:'5px', 
    '&: hover':{
        backgroundColor:'rgba(100,100,200,0.4)'
    }
}))



  const images_to_show = [
    {url:'https://helpx.adobe.com/content/dam/help/en/photoshop/using/convert-color-image-black-white/jcr_content/main-pars/before_and_after/image-before/Landscape-Color.jpg'},
    {url:'https://www.akamai.com/site/im-demo/perceptual-standard.jpg?imbypass=true'},
    {url:'https://mimi-panda.com/wp-content/uploads/2023/02/marguerite-729510_640.jpg'},
    {url:'https://www.freecodecamp.org/news/content/images/2022/09/jonatan-pie-3l3RwQdHRHg-unsplash.jpg'},
    {url:'https://www.adobe.com/acrobat/hub/media_173d13651460eb7e12c0ef4cf8410e0960a20f0ee.jpeg?width=1200&format=pjpg&optimize=medium'},
    {url:'https://statusneo.com/wp-content/uploads/2023/02/MicrosoftTeams-image551ad57e01403f080a9df51975ac40b6efba82553c323a742b42b1c71c1e45f1.jpg'},
    {url:'https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg'},
    {url:'https://www.seiu1000.org/sites/main/files/main-images/camera_lense_0.jpeg'},
    {url:'https://imgv3.fotor.com/images/cover-photo-image/a-beautiful-girl-with-gray-hair-and-lucxy-neckless-generated-by-Fotor-AI.jpg'},
  ]
    


export default function Modal_singleinscription(props){

    const [selectedCourse1,setselectedCourse1]=useState({})
    const [isEdited,setisedited]=useState(false)
    const [isOK,setisOK]=useState(false)
    const [canEditEssay,setcanEditEssay]=useState(false)
    const [canRemove,setcanRemove]=useState(false)
    const [hasDocumentation,setHasDocumentation]=useState(false)
    const [page,setPage]=useState(1)
    const [id_documentSelected,setid_documentSelected]=useState(0)
    
    const [editedEssay,setEditedEssay]=useState({})

      



    const handleEdit = (field,value)=>{
        // console.log(field, ' === ', value);
        var aux = {...props.edited_selectedCourse1}
        // console.log("aux before === ", aux);
        aux[field] = value
        // console.log("aux after === ", aux);
        props.setedited_selectedCourse1(aux)
    }


    const handleEditEssay = (field,value)=>{
        var aux = {...props?.edited_selectedCourse1?.this_redacao || null}
        if(field==='nota'){
            value = Number(value)
            if(value !== NaN && value>=0 && value<=1000){ aux[field] = value ; }
            else{ aux[field] = '' ;  toast.error('Valor inválido') }
        }

        return setEditedEssay(aux)
        
    }

    const checkdocumentation = ()=>{
        API.post('administrativo/checkdocumentation',{subscriptionID:props?.selectedCourse?.id || null})
        .then((res)=>{
            setHasDocumentation(true)
        })
        .catch((error)=>{
            setHasDocumentation(false)
            console.error('NO DOCUMENTS YET', error);
        })
    }

    const handleSaveEssay = (e)=>{
        props.setLoading(true)
        const data = {
            editedEssay:editedEssay,

        }
        API.post('administrativo/handlesaveessay',data)
        .then((res)=>{
            toast.success('Salvo com sucesso')
            props.setLoading(false)
            window.location.reload()
        })
        .catch((error)=>{
            console.error(error)
            toast.error('Erro ao salvar Alterações')
            props.setLoading(false)
        })

    }



    
    const handleConfirmPayment = (userID)=>{
        props.setLoading(true)

        API.post('administrativo/setispaid', {userID:userID})
        .then((res)=>{
            toast.success('Salvo com sucesso')
            props.setLoading(false)
            window.location.reload()
        })
        .catch((error)=>{
            console.error(error)
            toast.error('Erro ao salvar Alterações')
            props.setLoading(false)
        })

    }








    useEffect(()=>{
        const toReturn = () =>{
            if( props?.edited_selectedCourse1?.this_redacao_status?.nome==='Em Correção' && 
            !props?.edited_selectedCourse1?.this_redacao?.nota ){
                return setcanEditEssay(true)
            }
            setEditedEssay(props?.edited_selectedCourse1?.this_redacao || {})
            return setcanEditEssay(false)
        }
        toReturn()
    },[props])

    useEffect(() => {
    
        const areEdited = () => {
            const keys = Object.keys(props.edited_selectedCourse1)
          for (let i = 0; i < Object.keys(props.edited_selectedCourse1).length; i++) {
            const keyName = keys[i]
            // console.log(`keyName = ${keyName}`);
            // console.log(`props.edited_selectedCourse1[keyName] === ${edited_selectedCourse1[keyName]} | selectedCourse1[keyName] === ${selectedCourse1[keyName]}`);
            if (props.edited_selectedCourse1[keyName] !== selectedCourse1[keyName]) {
                // console.log('edited');
                return true;
            }
        }
            // console.log('NOT edited');
          return false;
        };
      
        const isEdited = areEdited();
        setisedited(isEdited);
      }, [props.edited_selectedCourse1, selectedCourse1]);
    
      useEffect(() => {
        const areOK = () => {
            const keys = Object.keys(props.edited_selectedCourse1)
          for (let i = 0; i < Object.keys(props.edited_selectedCourse1).length; i++) {
            const keyName = keys[i]
            if (props.edited_selectedCourse1[keyName] !== selectedCourse1[keyName]) {
                return true;
            }
        }
          return false;
        };
      
        const isOK = areOK();
        setisOK(isOK);
      }, [props.edited_selectedCourse1]);
      
    useEffect(()=>{
        const aux = {...selectedCourse1}
         props.setedited_selectedCourse1(aux)
    },[selectedCourse1])

    useEffect(()=>{
        if(props.selectedCourse){
            return setselectedCourse1(props.selectedCourse)
        }
        return setselectedCourse1({id:0, nome:'-', tipo_id:'-', nota_minima:0, duracao:'-', valor:0, set:false})
    },[props])

    useEffect(()=>{
        checkdocumentation()
    },[])


    return(
        <>
            <RootStyle onClick={()=>{props.setOpenModal(false)}}     />
            <Modal>
                <div style={{ display:'flex', position:'inherit',  top:'0px', left:'calc(100% - 40px)', width:'40px', padding:'7px', zIndex:'99'  }}   ><img width='100%'  src={closeicon} style={{cursor:'pointer'}}  onClick={()=>{props.setOpenModal(false)}}/></div>
            
                <Header>
                    {page===1 && <>
                        <h3 style={{ marginLeft:'50px'}}>Inscrição:</h3>
                        <h2 style={{fontWeight:'bold', color:'#000c7c', marginRight:'50px'}}>{selectedCourse1?.id || '-'}</h2>
                    </>}
                    {page===2 && <>
                        <h3 style={{ marginLeft:'50px'}}>Vestibular:</h3>
                        <h2 style={{fontWeight:'bold', color:'#000c7c', marginRight:'50px'}}>{selectedCourse1?.this_vestibular?.id || '-'}</h2>
                    </>}
                    {page===3 && <>
                        <h3 style={{ marginLeft:'50px'}}>Redação:</h3>
                        <h2 style={{fontWeight:'bold', color:'#000c7c', marginRight:'50px'}}>{selectedCourse1?.this_redacao?.id || '-'}</h2>
                    </>}
                    {page===4 && hasDocumentation &&<>
                        <h3 style={{ marginLeft:'50px'}}>Documentação:</h3>
                        <h2 style={{fontWeight:'bold', color:'#000c7c', marginRight:'50px'}}>{selectedCourse1?.this_documentacao?.id || '-'}</h2>
                    </>}
                    {page===5 && props?.edited_selectedCourse1?.this_payment && <>
                        <h3 style={{ marginLeft:'50px'}}>Pagamento:</h3>
                        <h2 style={{fontWeight:'bold', color:'#000c7c', marginRight:'50px'}}>{selectedCourse1?.id || '-'}</h2>
                    </>}
                </Header>

                {selectedCourse1 && 
                <Body>
                    { page===1 &&<>
                        <SingleElement>
                            <SingleElement_title>Candidato:</SingleElement_title>
                            <SingleElement_info InputProps={{readOnly: true,}}  value={props?.edited_selectedCourse1?.this_candidato?.nome || '-'}
                            // disabled={true} error={false} 
                            
                            helperText={' '}  onChange={(e)=>{handleEdit('nome',e.target.value)}}       />
                        </SingleElement>
                        <SingleElement>
                            <SingleElement_title>Atualização:</SingleElement_title>
                            <SingleElement_info InputProps={{readOnly: true,}}  value={props.getFullDate_toShow(props?.edited_selectedCourse1?.updated_at) || '-'} helperText={' '}  onChange={(e)=>{handleEdit('nome',e.target.value)}}       />
                        </SingleElement>
                        <SingleElement>
                            <SingleElement_title>Curso:</SingleElement_title>
                            <SingleElement_info InputProps={{readOnly: true,}}  value={props?.edited_selectedCourse1?.this_curso?.nome || '-'} helperText={' '}  onChange={(e)=>{handleEdit('nome',e.target.value)}}       />
                        </SingleElement>
                        <SingleElement>
                            <SingleElement_title>Status:</SingleElement_title>
                            <SingleElement_info InputProps={{readOnly: true,}}  ty value={props?.edited_selectedCourse1?.this_status?.nome || '-'} helperText={' '}  onChange={(e)=>{handleEdit('nota_minima',e.target.value)}}       />
                        </SingleElement>
                        <SingleElement style={{margin:'0px 0px 0px auto'}}>
                            <SingleElement_title >Modalidade:</SingleElement_title>
                            <SingleElement_info InputProps={{readOnly: true,}}  value={props?.edited_selectedCourse1?.this_ingresso?.nome || '-'} helperText={' '}  onChange={(e)=>{handleEdit('duracao',e.target.value)}}       />
                        </SingleElement>
                        <SingleElement  style={{margin:'0px auto 0px 0px'}}>
                            <SingleElement_title>Ativo:</SingleElement_title>
                            <FormControl>
                                <SingleElement_info_select
                                value={props?.edited_selectedCourse1?.set?'Sim':'Não'}
                                onChange={(e)=>{handleEdit('set',e.target.value==='Sim'?true:false)}}
                                MenuProps={{
                                    sx: {
                                      zIndex: 999999999999, 
                                    },
                                }}
                                inputProps={{ 'aria-label': 'Without label', readOnly:true }}
                                disabled={true}
                                >
                                <MenuItem value={'Sim'}>Sim</MenuItem>
                                <MenuItem value={'Não'}>Não</MenuItem>
                                </SingleElement_info_select>
                                <FormHelperText> </FormHelperText>
                            </FormControl>
                        </SingleElement>

                        {props?.edited_selectedCourse1?.status_id === 6 && 
                            <SingleElement style={{margin:'20px auto 20px auto',}}>
                                <SingleElement_title >Pagamento:</SingleElement_title>
                                <p style={{margin:'20px 0px 20px 0px', fontWeight:'normal', fontSize:'12pt', textDecoration:'underline blue', color:'blue' , cursor:'pointer'}} onClick={()=>{ window.open(`${IMAGE_BASE}payment_receipt/${props?.selectedCourse?.id}/${props?.selectedCourse?.payment_doc_name}`, '_blank')  }}>Visualizar comprovante de pagamento</p>
                                <ColorButton style={{backgroundColor:'rgba(0, 187, 93, 1)', color:'#fff', fontSize:'12pt'}} variant="contained" onClick={()=>{handleConfirmPayment(props?.edited_selectedCourse1?.candidato_id)}} >Confirmar pagamento</ColorButton>
                            </SingleElement>
                        }

                        {props?.edited_selectedCourse1?.status_id === 11 && 
                            <SingleElement style={{margin:'20px auto 20px auto',}}>
                                <SingleElement_title >Contrato:</SingleElement_title>
                                <p style={{margin:'20px 0px 20px 0px', fontWeight:'normal', fontSize:'12pt', textDecoration:'underline blue', color:'blue' , cursor:'pointer'}} onClick={()=>{ window.open(`${IMAGE_BASE}contracts/${props?.edited_selectedCourse1?.candidato_id}/contract_user_${props?.edited_selectedCourse1?.candidato_id}.pdf`, '_blank')  }}>Visualizar contrato</p>
                            </SingleElement>
                        }


                        <div style={{width:'100%', height:'1px', backgroundColor:'#ccc', margin:'40px 20px 10px 20px'}}/>
                        <h3 style={{width:'100%', textAlign:'center'}}>Mais opções:</h3>
                        {props?.edited_selectedCourse1?.this_vestibular?.id &&
                            <SingleElement >
                                <ColorButton variant="contained" onClick={()=>{setPage(2)}} >Vestibular</ColorButton>
                            </SingleElement>
                            || null
                        }
                        {props?.edited_selectedCourse1?.this_redacao?.id &&
                            <SingleElement >
                                <ColorButton variant="contained" onClick={()=>{setPage(3)}} >Redação</ColorButton>
                            </SingleElement>
                            ||
                            null
                        }
                        {hasDocumentation &&
                            <SingleElement >
                                <ColorButton variant="contained" onClick={()=>{setPage(4)}} >Documentação</ColorButton>
                            </SingleElement>
                        }
                        {props?.edited_selectedCourse1?.this_payment && 
                            <SingleElement >
                                <ColorButton variant="contained" onClick={()=>{setPage(5)}} >Pagamento</ColorButton>
                            </SingleElement>
                        }
                    </>}


                    {page===2 && props?.edited_selectedCourse1?.this_vestibular?.id && <>
                        <SingleElement>
                            <SingleElement_title>Tipo:</SingleElement_title>
                            <SingleElement_info  InputProps={{readOnly: true,}} value={props?.edited_selectedCourse1?.this_vestibular?.tipo === 'O'?'Online': props?.edited_selectedCourse1?.this_vestibular?.tipo === 'P'?'Presencial': '-'  } helperText={' '}  onChange={(e)=>{handleEdit('nome',e.target.value)}}       />
                        </SingleElement>
                        <SingleElement>
                            <SingleElement_title>Unidade:</SingleElement_title>
                            <SingleElement_info  InputProps={{readOnly: true,}} value={props?.edited_selectedCourse1?.this_vestibular?.unidade || '-'} helperText={' '}  onChange={(e)=>{handleEdit('nome',e.target.value)}}       />
                        </SingleElement>
                        <SingleElement>
                            <SingleElement_title>Data e Hora:</SingleElement_title>
                            <SingleElement_info  InputProps={{readOnly: true,}} value={props?.getFullDate_toShow(props?.edited_selectedCourse1?.this_vestibular?.createdAt || null) || '-'} helperText={' '}  onChange={(e)=>{handleEdit('nome',e.target.value)}}       />
                        </SingleElement>


                        <div style={{width:'100%', height:'1px', backgroundColor:'#ccc', margin:'40px 20px 10px 20px'}}/>
                        <h3 style={{width:'100%', textAlign:'center'}}>Mais opções:</h3>
                        {props?.edited_selectedCourse1?.this_redacao?.id &&
                            <SingleElement >
                                <ColorButton variant="contained" onClick={()=>{setPage(3)}} >Redação</ColorButton>
                            </SingleElement>
                            || null
                        }
                        {hasDocumentation &&
                            <SingleElement >
                                <ColorButton variant="contained" onClick={()=>{setPage(4)}} >Documentação</ColorButton>
                            </SingleElement>
                        }
                        {props?.edited_selectedCourse1?.this_payment &&
                            <SingleElement >
                                <ColorButton variant="contained" onClick={()=>{setPage(5)}} >Pagamento</ColorButton>
                            </SingleElement>
                        }
                    </> || null}


                    {page===3 && props?.edited_selectedCourse1?.this_redacao?.id && <>
                        <SingleElement>
                            <SingleElement_title>Candidato:</SingleElement_title>
                            <SingleElement_info InputProps={{readOnly: true,}} value={props?.edited_selectedCourse1?.this_candidato?.nome || '-'}  helperText={' '}  />
                        </SingleElement>
                        <SingleElement>
                            <SingleElement_title>Tema:</SingleElement_title>
                            <SingleElement_info InputProps={{readOnly: true,}}   value={props?.edited_selectedCourse1?.this_tema?.nome || '-'} helperText={' '}  />
                        </SingleElement>
                        <SingleElement>
                            <SingleElement_title>Forma:</SingleElement_title>
                            <SingleElement_info  InputProps={{readOnly: true,}}  value={props?.edited_selectedCourse1?.this_redacao?.tipo=='D'?'Digitada':props?.edited_selectedCourse1?.this_redacao?.tipo=='F' ? "Foto" : 'Indefinido'} helperText={' '} />
                        </SingleElement>
                        <SingleElement>
                            <SingleElement_title>Situação:</SingleElement_title>
                            <SingleElement_info  InputProps={{readOnly: true,}}  value={props?.edited_selectedCourse1?.this_redacao_status?.nome || '-'}     helperText={' '}   />
                        </SingleElement>
                        <SingleElement>
                            <SingleElement_title>Enviado em:</SingleElement_title>
                            <SingleElement_info  InputProps={{readOnly: true,}}  value={props?.getFullDate_toShow(props?.edited_selectedCourse1?.this_redacao?.updated_at || '-') || '-'}   helperText={' '}  />
                        </SingleElement>
                        <SingleElement>
                            <SingleElement_title>Nota:</SingleElement_title>
                            <SingleElement_info  
                                value={editedEssay?.nota || ''} 
                                error={!editedEssay.nota && canEditEssay} 
                                onChange={(e)=>{
                                    if(canEditEssay){
                                        return handleEditEssay('nota',e.target.value)
                                    }
                                    return toast.info('Não é possível editar esta nota, no momento')
                                }}       
                                // disabled={props?.edited_selectedCourse1?.this_tema_data?.nome==='Em Correção'?false:true}   
                                helperText={props?.edited_selectedCourse1?.this_redacao_status?.nome!=='Avaliada' && props?.edited_selectedCourse1?.this_redacao_status?.nome!=='Em Andamento' && !editedEssay?.nota ? 'Necessário' : ' '}   
                            />
                            </SingleElement>

                            {props?.edited_selectedCourse1?.this_redacao?.titulo &&
                                <SingleElement style={{width:'100%', }}>
                                    <SingleElement_title>Título:</SingleElement_title>
                                    <SingleElement_info  InputProps={{readOnly: true,}}  value={props?.edited_selectedCourse1?.this_redacao?.titulo || '-'} helperText={' '}  />
                                </SingleElement>
                            }

                        {props?.edited_selectedCourse1?.this_redacao?.texto && 
                            <SingleElement style={{width:'100%', height:'500px',  }}>
                                <SingleElement_title>Texto:</SingleElement_title>
                                <SingleElement_info
                                    multiline
                                    rows={20}
                                    InputProps={
                                        {
                                        readOnly: true,
                                        style: {
                                        overflow:'auto',
                                        },
                                    }}
                                    value={props?.edited_selectedCourse1?.this_redacao?.texto || '-'}   />
                            </SingleElement>
                        }
                        
                        {props?.edited_selectedCourse1?.this_redacao?.imagem && 
                            <SingleElement style={{width:'100%', }}>
                                <SingleElement_title>Foto:</SingleElement_title>
                                <img src={`${IMAGE_BASE}redacao/${props?.edited_selectedCourse1?.this_redacao?.id}/${props?.edited_selectedCourse1?.this_redacao?.imagem}`} width='100%' style={{margin:'auto', padding:'20px', border:'1px solid black', borderRadius:'5px'}} />
                            </SingleElement>
                        }



                        {!props.edited_selectedCourse1.this_redacao.nota && canEditEssay &&
                            <Button onClick={handleSaveEssay} style={{margin:'auto'}} variant="contained" endIcon={<img src={saveicon} width='20px' />}>
                                SALVAR ALTERAÇÕES
                            </Button>
                        }

                        


                        <div style={{width:'100%', height:'1px', backgroundColor:'#ccc', margin:'40px 20px 10px 20px'}}/>
                        <h3 style={{width:'100%', textAlign:'center'}}>Mais opções:</h3>
                        {props?.edited_selectedCourse1?.this_vestibular?.id &&
                            <SingleElement >
                                <ColorButton variant="contained" onClick={()=>{setPage(2)}} >Vestibular</ColorButton>
                            </SingleElement>
                            || null
                        }
                        {hasDocumentation &&
                            <SingleElement >
                                <ColorButton variant="contained" onClick={()=>{setPage(4)}} >Documentação</ColorButton>
                            </SingleElement>
                        }
                        {props?.edited_selectedCourse1?.this_payment &&
                            <SingleElement >
                                <ColorButton variant="contained" onClick={()=>{setPage(5)}} >Pagamento</ColorButton>
                            </SingleElement>
                        }
                    </> || null}

                    {page===4 && hasDocumentation &&
                        <Documents  subscriptionID={selectedCourse1?.id || null} setLoading={props?.setLoading || false}/>
                    }


                    {page===5 && props?.edited_selectedCourse1?.this_payment && <>
                        <SingleElement>
                            <SingleElement_title>DESCRIÇÃO DO CURSO:</SingleElement_title>
                            <SingleElement_info InputProps={{readOnly: true,}}  value={props?.edited_selectedCourse1?.this_candidato?.nome || '-'}  helperText={' '}  onChange={(e)=>{handleEdit('nome',e.target.value)}}       />
                        </SingleElement>
                        <SingleElement>
                            <SingleElement_title>TIPO:</SingleElement_title>
                            <SingleElement_info  InputProps={{readOnly: true,}} value={props?.edited_selectedCourse1?.this_candidato?.nome || '-'}  helperText={' '}  onChange={(e)=>{handleEdit('nome',e.target.value)}}       />
                        </SingleElement>
                        <SingleElement>
                            <SingleElement_title>TURNO:</SingleElement_title>
                            <SingleElement_info  InputProps={{readOnly: true,}} value={props?.edited_selectedCourse1?.this_candidato?.nome || '-'}  helperText={' '}  onChange={(e)=>{handleEdit('nome',e.target.value)}}       />
                        </SingleElement>
                        <SingleElement>
                            <SingleElement_title>VALOR DA INSCRIÇÃO:</SingleElement_title>
                            <SingleElement_info  InputProps={{readOnly: true,}} value={props?.edited_selectedCourse1?.this_candidato?.nome || '-'}  helperText={' '}  onChange={(e)=>{handleEdit('nome',e.target.value)}}       />
                        </SingleElement>
                        <SingleElement>
                            <SingleElement_title>STATUS:</SingleElement_title>
                            <SingleElement_info InputProps={{readOnly: true,}}  value={props?.edited_selectedCourse1?.this_candidato?.nome || '-'}  helperText={' '}  onChange={(e)=>{handleEdit('nome',e.target.value)}}       />
                        </SingleElement>
                        <SingleElement>
                            <CustomButton 
                             onClick={()=>{}} >BAIXAR CONTRATO <img src={saveicon} width='20px' /></CustomButton>
                        </SingleElement>


                        <div style={{width:'100%', height:'1px', backgroundColor:'#ccc', margin:'40px 20px 10px 20px'}}/>
                        <h3 style={{width:'100%', textAlign:'center'}}>Mais opções:</h3>
                        {props?.edited_selectedCourse1?.this_vestibular?.id &&
                            <SingleElement >
                                <ColorButton variant="contained" onClick={()=>{setPage(2)}} >Vestibular</ColorButton>
                            </SingleElement>
                            || null
                        }
                        {props?.edited_selectedCourse1?.this_redacao?.id &&
                            <SingleElement >
                                <ColorButton variant="contained" onClick={()=>{setPage(3)}} >Redação</ColorButton>
                            </SingleElement>
                            || null
                        }
                        {hasDocumentation &&
                            <SingleElement >
                                <ColorButton variant="contained" onClick={()=>{setPage(4)}} >Documentação</ColorButton>
                            </SingleElement>
                        }

                    </> || null}
                </Body>
                }

                {props && props.isRemoving &&
                        <div style={{width:'100%', padding:'10px', margin:'-50px auto 50px auto', display:'flex', gap:'10px', alignItems:'center', justifyContent:'center', fontSize:'8pt',  color:'red', fontWeight:'bold', cursor:'pointer'}}  onClick={()=>{setcanRemove(!canRemove)}}>
                                <input type='checkbox' checked={canRemove} style={{width:'20px', height:'20px', cursor:'pointer'}}/>
                                <div style={{display:'flex', flexDirection:'column', gap:'0px', textAlign:'justify' }}>
                                    <div >Confirmo que desejo excluir este dado. Eles não estarão mais disponíveis após a exclusão.</div>
                                    <div >Também salvaremos os seus dados, por questões de controle/identificação de quem realizou a exclusão.</div>
                                </div>
                        </div>
                }


                <Footer>
                    {page>1 && 
                        <CustomButtom sx={{backgroundColor:'rgba(0, 0, 0, 0)', }}  text='Voltar' onClick={()=>{setPage(1)}}     />
                    }
                    <CustomButtom sx={{backgroundColor:'rgba(200, 200, 200, 0.5)', }}  text='Fechar' onClick={()=>{props.setOpenModal(false)}}     />
                    {/* {props && props.isChanging &&
                        <CustomButtom color={1} text='Salvar' icon={saveicon} onClick={()=>{if(isEdited){return props.handleSingleCourse()}return toast.info('Altere algum campo, se desejar salvar')}}/>
                    } */}
                    {/* {props && props.isCreating &&
                        <CustomButtom color={1} text='Salvar' icon={saveicon} onClick={()=>{if(isOK){return props.handleSingleCourse()}return toast.info('Altere algum campo, se desejar salvar')}}/>
                    } */}
                    {/* {props && props.isRemoving &&
                        <CustomButtom color={3} text='Excluir' icon={removeicon} onClick={()=>{if(canRemove){return props.handleSingleCourse()}return toast.info('Confirme antes de excluir')}}/>
                    } */}
                </Footer>
            </Modal>
        </>
    )
}