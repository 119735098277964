import React, { useEffect, useState , useRef} from "react";
import { styled } from '@mui/system';
import CustomButtom from "../customButtom";
import API from "../../../API_CONFIGS/redux/API";
import { toast } from "react-toastify";
import { useLocation } from 'react-router-dom';

import { IMAGE_BASE } from "../../../API_CONFIGS/constants";

import html2pdf from 'html2pdf.js';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';
import domtoimage from 'dom-to-image';
import htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';

import Modal_singleessay from "./Modal_singleessay";


import reload_icon from '../../../media/icons/reload.png'
import plus_light from '../../../media/icons/plus-light.svg'
import list_dark from '../../../media/icons/list-dark.svg'
import edit_dark from '../../../media/icons/edit-dark.svg'
import info_dark from '../../../media/icons/magnifying-dark.svg'
import remove_dark from '../../../media/icons/trash-dark.svg'
import arrow_up_dark from '../../../media/icons/arrow-up-dark.svg'
import arrow_down_dark from '../../../media/icons/arrow-down-dark.svg'
import print from '../../../media/icons/print.svg'
import logo from '../../../media/logos/logo.png'


import ReportTemplate from './ReportTemplate';

// import pdfBase from '../../../ttt.html'


const RootStyle = styled('div')(({theme})=>({
    width: '100%',
    height:'100vh',
    padding:'10px',
    display:'flex',
    flexDirection:'column',

}))
const SubHeader = styled('div')(({theme})=>({
    width: '100%',
    padding:'10px',
    display:'flex',

    borderBottom:'0.5px solid black'
    


}))

const Table = styled('div')(({theme})=>({
    width: '100%',
    maxHeight:'100%',
    padding:'10px',
    display:'flex',
    flexDirection:'column',

    border:'0.5px solid black',
    gap:'5px',

    overflow:'auto',    


}))
const Line = styled('div')(({theme})=>({
    width: '100%',
    // padding:'5px',
    display:'flex',
    alignItems:'center',
    justifyContent:'space-between',
    padding:'0px 10px 0px 10px',
    fontSize:'12pt',
    // gap:'10px', 

    // backgroundColor:'blue'

    '&:hover':{
        backgroundColor:'rgba(178, 178, 178, 0.2)',
        cursor:'default',
        borderRadius:'10px',
    }

}))


// const LineInfo = styled('div')(({ theme }) => ({
//     width: '20%',
//     height: '50px',
//     display: 'flex',
//     position: 'relative',
//     overflowX: 'auto', // Show horizontal scrollbar
//     overflowY: 'hidden', // Hide vertical scrollbar
//     whiteSpace: 'nowrap', // Prevent text from wrapping
//     wordBreak: 'break-all', // Break words when they exceed the width.
//   }));
  



const LineInfo = styled('div')(({ theme }) => ({
    width: '20%',
    height: '50px',
    display: 'flex',
    position: 'relative',
    overflow: 'scroll', // Show horizontal scrollbar
    whiteSpace: 'normal', // Allow text to wrap and break words when they exceed the width.
  }));

  



  const Options = styled('div')(({theme})=>({
    width: '20%',
    display:'flex',
    // justifyContent:'center',
    fontSize:'12pt',
    fontWeight:'bold',
    // gap:'5px',

    // margin:'0px 10px 0px 10px',

    



}))

const SingleElement_info_select = styled(Select)(({theme})=>({
    display:'flex',
    // width:'70px',
    maxHeight:'30px',
    margin:'auto',

    // padding:'10px',
    // border:'1px solid black',
    // borderRadius:'5px',


}))


const ButtonEditLine = styled('div')(({theme})=>({
    width: '30px',
    height:'30px',
    display:'flex',
    cursor:'pointer',
    backgroundColor:'rgba(255, 222, 119, 0.8)',
    borderRadius:'5px',
    padding:'3px',
}))
const ButtonRemoveLine = styled('div')(({theme})=>({
    width: '30px',
    height:'30px',
    display:'flex',
    cursor:'pointer',
    backgroundColor:'rgba(255, 109, 109, 0.8)',
    borderRadius:'5px',
    padding:'3px',
}))
const ButtonDetailsLine = styled('div')(({theme})=>({
    width: '30px',
    height:'30px',
    display:'flex',
    cursor:'pointer',
    backgroundColor:'rgba(186, 109, 255, 0.8)',
    borderRadius:'5px',
    padding:'5px',
}))


function formatDate(date_string) {
    try {
        if(!date_string){return ' - '}
        const date = new Date(date_string);
        
        const options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        };
    
        const formattedDate = new Intl.DateTimeFormat('pt-BR', options).format(date);
        const formattedWithLineBreak = formattedDate.replace(/, /g, ',\n');
        return formattedWithLineBreak;
      
    } catch (error) {
        console.error(error);
        return "Invalido"
    }
  }
  
  
export default function Essay_adm({setLoading, }){

    const location = useLocation();

    const [list,setList]=useState([])
    const [tipo_essay,settipo_essay]=useState([])
    const [selectedEssay,setselectedEssay]=useState(null)
    const [isChanging,setisChanging]=useState(false)
    const [isRemoving,setisRemoving]=useState(false)
    const [isCreating,setisCreating]=useState(false)
    const [filter_type,setfilter_type]=useState('- Forma -')
    const [filter_situation,setfilter_situation]=useState('- Situação -')

    const [qtdToShow,setQtdToShow]=useState(10)
    const [showFrom,setshowFrom]=useState(1)
    const [showTo,setshowTo]=useState(10)
    const [isFiltering,setisFiltering]=useState(true)
    const [filterType,setfilterType]=useState('enviado')
    const [isFilterUp,setisFilterUp]=useState(true)
    const [totalPages,setTotalPages]=useState(1)
    const [page,setpage]=useState(1)
    
    const [openModal,setOpenModal]=useState(false)
    const [reload,setReload]=useState(false)


    const [edited_selectedEssay1,setedited_selectedEssay1]=useState(null)

    const getAllEssay_list = async ()=>{
        setLoading(true)
        var return_this = null
        const filters = {}
        await API.post('administrativo/listessay',filters)
        .then((res)=>{
            var aux = [...res.data.redacoes_edited]
            aux = aux.map((single)=>{
                if(single.this_candidate_data){
                    return single
                }
                return null
            }).filter(Boolean)
            setTotalPages(Math.ceil(aux.length/qtdToShow))
            return_this = aux

            setisFilterUp(!isFilterUp); setisFiltering(true); setfilterType('enviado'); 
            
            setLoading(false)
            
        })
        .catch((error)=>{
            console.log(error);
            toast.error(error?.response?.data?.message || 'Erro ao buscar cursos')
            return_this = [] 
            setLoading(false)
        })
        return return_this
    }

    useEffect(() => {
        setLoading(true)
        const fetchData = async () => {
            

            const searchParams = new URLSearchParams(location.search);
            const type = searchParams.get('type');
        
            // Now you can use the 'name' variable in your component
    
            if(type){ setLoading(false) ; return handleFilterBy(type)}
    
          const essayList = await getAllEssay_list();
          setList(essayList);
          setLoading(false) 
        };
        fetchData();


      }, [qtdToShow, reload, ]);
      


    const formatValue = (value)=>{
        value = Number(value)
        value = value.toFixed(2).replace(".",',')
        return `R$ ${value}`
    }
    const handleChangePage =(e,newPage)=>{
        const value = Number(newPage)
        return setpage(value)
    }

    useEffect(()=>{
        const to_number = page*qtdToShow
        const from_number = to_number-(qtdToShow-1)
        setshowFrom(from_number)
        setshowTo(to_number)
    },[page,qtdToShow])

    useEffect(()=>{
        if(list){
            setTotalPages(Math.ceil(list.length/qtdToShow))
            setpage(1)
        }
    },[list])



    const getNumbers = (str) => {
        let result = "";
        for (let i = 0; i < str.length; i++) {
          let char = str[i];
          if (char.match(/[0-9]/)) {
            result += char;
          }
        }
        return result;
      }
      

    const handleFilterList=()=>{
        var aux = [...list]
        if(filterType==='candidato'){
            aux = aux.sort((a,b)=>{
                if(isFilterUp){
                    if (a.this_candidate_data.nome > b.this_candidate_data.nome) {
                        return -1;
                    }
                    if (b.this_candidate_data.nome > a.this_candidate_data.nome) {
                        return 1;
                    }
                    return 0;
                }
                else if(!isFilterUp){
                    if (a.this_candidate_data.nome < b.this_candidate_data.nome) {
                        return -1;
                    }
                    if (b.this_candidate_data.nome < a.this_candidate_data.nome) {
                        return 1;
                    }
                    return 0;
                }
            })
        }
        if(filterType==='tema'){
            aux = aux.sort((a,b)=>{
                if(isFilterUp){
                    if (a.this_tema_data.nome > b.this_tema_data.nome) {
                        return -1;
                    }
                    if (b.this_tema_data.nome > a.this_tema_data.nome) {
                        return 1;
                    }
                }
                else if(!isFilterUp){
                    if (a.this_tema_data.nome < b.this_tema_data.nome) {
                        return -1;
                    }
                    if (b.this_tema_data.nome < a.this_tema_data.nome) {
                        return 1;
                    }
                }
                return 0;
            })
        }
        if(filterType==='situacao'){
            aux = aux.sort((a,b)=>{
                if(isFilterUp){
                    if (a.this_status_data.nome > b.this_status_data.nome) {
                        return -1;
                    }
                    if (b.this_status_data.nome > a.this_status_data.nome) {
                        return 1;
                    }
                    return 0;
                }
                else if(!isFilterUp){
                    if (a.this_status_data.nome < b.this_status_data.nome) {
                        return -1;
                    }
                    if (b.this_status_data.nome < a.this_status_data.nome) {
                        return 1;
                    }
                    return 0;
                }
            })
        }
        if(filterType==='forma'){
            aux = aux.sort((a,b)=>{
                if(isFilterUp){
                    if (a.tipo > b.tipo) {
                        return -1;
                    }
                    if (b.tipo > a.tipo) {
                        return 1;
                    }
                    return 0;
                }
                else if(!isFilterUp){
                    if (a.tipo < b.tipo) {
                        return -1;
                    }
                    if (b.tipo < a.tipo) {
                        return 1;
                    }
                    return 0;
                }
            })
        }
        if(filterType==='enviado'){
            aux = aux.sort((a,b)=>{

                if (a.updated_at === null && b.updated_at !== null) {
                    return 1;
                }
                if (a.updated_at !== null && b.updated_at === null) {
                    return -1;
                }
                if (a.updated_at === null && b.updated_at === null) {
                    return 0;
                }

                if(isFilterUp){
            
                    if (new Date(a?.updated_at) - new Date(b?.updated_at)) {
                        return -1;
                    }
                    if (new Date(b?.updated_at) - new Date(a?.updated_at)) {
                        return 1;
                    }
                    return 0;
                }
                else if(!isFilterUp){
                    
                    if (new Date(a?.updated_at) - new Date(b?.updated_at)) {
                        return -1;
                    }
                    if (new Date(b?.updated_at) - new Date(a?.updated_at)) {
                        return 1;
                    }
                    return 0;
                }
            })
        }
        setpage(1)
        return setList(aux) 
    }

    const editCourse = (courseData) =>{
        setLoading(true)
        API.post('administrativo/editsinglecourse', courseData)
        .then((res)=>{
            toast.success('sucesso')
            setLoading(false)
        })
        .catch((error)=>{
            console.log(error);
            setLoading(false)
            return toast.error(error.response.data.message?error.response.data.message:'Erro ao buscar cursos')
        })
    }


    useEffect(()=>{
        if (isFiltering && filterType) {
            handleFilterList();
        }
    },[isFiltering,filterType,isFilterUp])


    const handleSingleCourse = ()=>{
        setLoading(true)
        const data = {
            edited_selectedEssay1:edited_selectedEssay1,
            isChanging:isChanging,
            isRemoving:isRemoving,
            isCreating:isCreating,
        }
        API.post(`/administrativo/handlesingleessay`,data)
        .then((res)=>{
            setReload(!reload)
            setOpenModal(false)
            setLoading(false)
            return toast.success(res.data.message?res.data.message:'sucesso')
        })
        .catch((error)=>{
            console.log(error);
            toast.error('erro')
            setLoading(false)
        })
    }


    const handleFilterBy =async (type)=>{
        const aux = await getAllEssay_list()
        var result_location = ''
        switch (type) {
            case 'Digitado':
                setfilter_type('Digitado')
                setList(aux.filter(single=>single.tipo==='D'))
                break;
        
            case 'Foto':
                setfilter_type('Foto')
                setList(aux.filter(single=>single.tipo==='F'))
                break;
        
            case 'Avaliada':
                setfilter_situation('Avaliada')
                setList(aux.filter(single=>single.this_status_data.nome==='Avaliada'))
                break;
        
            case 'Em Andamento':
                setfilter_situation('Em Andamento')
                setList(aux.filter(single=>single.this_status_data.nome==='Em Andamento'))
                break;
        
            case 'Em%20Andamento':
                setfilter_situation('Em Andamento')
                setList(aux.filter(single=>single.this_status_data.nome==='Em Andamento'))
                break;
        
            case 'Correção':
                setfilter_situation('Em Correção')
                setList(aux.filter(single=>single.this_status_data.nome==='Em Correção'))
                break;
        
            case 'Em%20Correção':
                setfilter_situation('Em Correção')
                setList(aux.filter(single=>single.this_status_data.nome==='Em Correção'))
                break;
        
            case 'Enviada':
                setfilter_situation('Enviada')
                setList(aux.filter(single=>single.this_status_data.nome==='Enviada'))
                break;
        
            default:
                setList(aux)
                break;
        }
    }












//     const handleGeneratePdf = async (essay) => {
//         setLoading(true)

//         console.log(essay);

//         const html = `
//             <div style="display: flex; flex-direction: column; ">
//                 <div style="padding: 10px; font-size: 8pt;">
//                     Título: ${essay?.titulo || '-'}
//                 </div>
//                 <div style="padding: 10px; font-size: 8pt;">
//                     Texto: ${essay?.texto || '-'}
//                 </div>
//             </div>
//         `


//         const doc = new jsPDF();
//         doc.setFont('Inter-Regular', 'normal');
//         const margin = 10;
//         let yPos = margin;

//         // Title
//         doc.setFontSize(12);
//         const titleText = `TÍTULO:
// ${essay?.titulo || '-'}`;
//         const titleLines = doc.splitTextToSize(titleText, doc.internal.pageSize.width - 2 * margin);
//         doc.text(titleLines, margin, yPos);
//         yPos += titleLines.length * 12 + 5; // Adjust for line height and padding

//         if(essay?.texto || false){
//             // Text
//             doc.setFontSize(12);
//             const textText = `TEXTO:
// ${essay?.texto || '-'}`;
//             const textLines = doc.splitTextToSize(textText, doc.internal.pageSize.width - 2 * margin);
//             doc.text(textLines, margin, yPos);
//         }

//         if(essay?.imagem || false){         
//             // IMAGE
//             doc.setFontSize(12);
//             const imageText = `IMAGEM:`;
//             doc.text(imageText, margin, yPos);
//             const imgWidth = doc.internal.pageSize.width - 2 * margin;
//             const imgHeight = (imgWidth * 9) / 16; // Assuming a 16:9 aspect ratio
//             doc.addImage(`${IMAGE_BASE}/redacoes/${essay?.imagem}`, 'JPEG', margin, yPos, imgWidth, imgHeight);
//         }

//         // Check if there is enough space for more content, otherwise add a new page
//         // if (yPos + textLines.length * 12 + 5 > doc.internal.pageSize.height - margin) {
//         //     doc.addPage();
//         //     yPos = margin;
//         // } else {
//         //     yPos += textLines.length * 12 + 5; // Adjust for line height and padding
//         // }

//         // Save the PDF with a specific name, e.g., 'essay_submission.pdf'
//         doc.save('essay_submission');

//         setLoading(false)

//     };











const reportTemplateRef = useRef(null);

const handleGeneratePdf = () => {
    setLoading(true);

    const pdfWidth = 1050; // in millimeters
    const pdfHeight = 1500; // in millimeters

    const doc = new jsPDF({
        unit: 'px', // Specify the unit for width and height
        format: [pdfWidth, pdfHeight], // Set custom width and height
    });

    // Adding the fonts.
    doc.setFont('Inter-Regular', 'normal');

    const extraSpace = 30; // You can adjust the value to add more or less space

    doc.html(reportTemplateRef.current, {
        margin: [extraSpace, 0, extraSpace, 0],
        async callback(doc) {
            await doc.save('document');
            setLoading(false);
        },
    });
};




// useEffect(() => {
//     const generatePdf = async () => {
//         const response = await fetch('../../../../ttt.html');
//         const htmlContent = await response.text();
  
//         const pdf = new jsPDF('p', 'mm', 'a4');
//         pdf.fromHTML(htmlContent, 15, 15, {}, () => {
//           pdf.save('output.pdf');
//         });
//       };
  
//       generatePdf();

//     }, [selectedEssay]);














    const getImageData = async (url) => {
        const response = await fetch(url);
        const blob = await response.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    };


      return(
        <RootStyle>

        {openModal && 
            <Modal_singleessay formatDate={formatDate} setOpenModal={setOpenModal}  selectedEssay={selectedEssay} isChanging={isChanging} isRemoving={isRemoving} isCreating={isCreating} handleSingleCourse={handleSingleCourse} edited_selectedEssay1={edited_selectedEssay1}        setedited_selectedEssay1={setedited_selectedEssay1} tipo_essay={tipo_essay}  />
        }

            <SubHeader>
                <h1 style={{margin:'auto auto auto 10px', }}>
                    Redações {list&&`(${list.length})`}
                </h1>

                <div style={{width:'100%', display:'flex', gap:'20px', justifyContent:'end', fontSize:'12pt'}}>

                    <h3 style={{margin:'auto 0px auto 0px'}}>Filtrar por:</h3>
                    <div style={{width:'200px', display:'flex', flexDirection:'column', gap:'0px'}}>
                        <h6>Forma</h6>
                        <FormControl sx={{display:'flex', margin:'auto', height:'30px'}} fullWidth>
                            <SingleElement_info_select
                                fullWidth
                                style={{height:'50px', }}
                                value={filter_type || '- Forma -'}
                                onChange={(e)=>{setfilter_situation('- Situação -') ; window.location.href = `/painel_administrativo/redacao/filters?type=${e.target.value}`; handleFilterBy(e.target.value) ; return setfilter_type(e.target.value)}}
                                inputProps={{ 'aria-label': 'Without label', }}
                            >
                            <MenuItem value={'- Forma -'}>- Forma -</MenuItem>
                            <MenuItem value={'Digitado'}>Digitado</MenuItem>
                            <MenuItem value={'Foto'}>Foto</MenuItem>
                            </SingleElement_info_select>
                        </FormControl>
                    </div>
                    <div style={{width:'200px', display:'flex', flexDirection:'column', gap:'0px'}}>
                        <h6>Situação</h6>
                        <FormControl sx={{display:'flex', margin:'auto', height:'30px'}} fullWidth>
                            <SingleElement_info_select
                                fullWidth
                                style={{height:'50px', }}
                                value={filter_situation || '- Situação -'}
                                onChange={(e)=>{setfilter_type('- Forma -') ; window.location.href = `/painel_administrativo/redacao/filters?type=${e.target.value}`;  handleFilterBy(e.target.value) ; return setfilter_situation(e.target.value)}}
                                inputProps={{ 'aria-label': 'Without label', }}
                            >
                            <MenuItem value={'- Situação -'}>- Situação -</MenuItem>
                            <MenuItem value={'Avaliada'}>Avaliada</MenuItem>
                            <MenuItem value={'Em Andamento'}>Em Andamento</MenuItem>
                            <MenuItem value={'Em Correção'}>Em Correção</MenuItem>
                            <MenuItem value={'Enviada'}>Enviada</MenuItem>
                            </SingleElement_info_select>
                        </FormControl>
                    </div>
                    <div style={{ display:'flex',  gap:'10px', justifyContent:'center', alignItems:'center', cursor:'pointer',  margin:'auto 0px 0px 0px' }}
                        onClick={()=>{
                            window.location.href = '/painel_administrativo/redacao'
                            handleFilterBy('')
                        }}
                    >
                        <h6 style={{margin:'auto 0px auto auto', }}>Limpar filtros</h6>
                        <img src={reload_icon} width='15px' height='15px'  />
                    </div>
                </div>
            </SubHeader>

                <Table>
                    <Line style={{backgroundColor:'rgba(0,0,0,0)',  }}>
                        <Options style={{justifyContent:'space-between', left:'0px',}}>
                            <div style={{display:'flex', gap:'10px', cursor:'pointer',  }} onClick={()=>{setisFiltering(true); setfilterType('candidato'); setisFilterUp(!isFilterUp);  }} >
                            Candidato
                            {isFiltering && filterType==='candidato' && isFilterUp &&<img src={arrow_up_dark} width='20px' />}
                            {isFiltering && filterType==='candidato' && !isFilterUp &&<img src={arrow_down_dark} width='20px' />}
                            </div>
                        </Options>
                        <Options >
                            <div style={{display:'flex', gap:'10px', cursor:'pointer'}} onClick={()=>{setisFiltering(true); setfilterType('tema'); setisFilterUp(!isFilterUp);   }} >
                            Tema
                            {isFiltering && filterType==='tema' && isFilterUp &&<img src={arrow_up_dark} width='20px' />}
                            {isFiltering && filterType==='tema' && !isFilterUp &&<img src={arrow_down_dark} width='20px' />}
                            </div>
                        </Options>
                        <Options >
                            <div style={{display:'flex', gap:'10px', cursor:'pointer'}} onClick={()=>{setisFiltering(true); setfilterType('forma'); setisFilterUp(!isFilterUp);   }} >
                            Forma
                            {isFiltering && filterType==='forma' && isFilterUp &&<img src={arrow_up_dark} width='20px' />}
                            {isFiltering && filterType==='forma' && !isFilterUp &&<img src={arrow_down_dark} width='20px' />}
                            </div>
                        </Options>
                        <Options >
                            <div style={{display:'flex', gap:'10px', cursor:'pointer'}} onClick={()=>{setisFiltering(true); setfilterType('situacao'); setisFilterUp(!isFilterUp);   }} >
                            Situação
                            {isFiltering && filterType==='situacao' && isFilterUp &&<img src={arrow_up_dark} width='20px' />}
                            {isFiltering && filterType==='situacao' && !isFilterUp &&<img src={arrow_down_dark} width='20px' />}
                            </div>
                        </Options>
                        <Options >
                            <div style={{display:'flex', gap:'10px', cursor:'pointer'}} onClick={()=>{setisFiltering(true); setfilterType('enviado'); setisFilterUp(!isFilterUp);   }} >
                            Enviado em
                            {isFiltering && filterType==='enviado' && isFilterUp &&<img src={arrow_up_dark} width='20px' />}
                            {isFiltering && filterType==='enviado' && !isFilterUp &&<img src={arrow_down_dark} width='20px' />}
                            </div>
                        </Options>
                        <Options style={{width:'10%',height:'10px' }}></Options>
                    </Line>

                    <div style={{width:'100%', height:'0.5px', backgroundColor:'rgba(0,0,0,0.5)', margin:'5px auto 5px auto'}}/>

                    {list&&list.map((single,index,array)=>{
                        if(index>=showFrom-1 && index<=showTo-1){
                            if(single.this_candidate_data){
                                return(
                                    <div key={index}>
                                    {index>0 && 
                                        <div style={{width:'100%', height:'1px', backgroundColor:'rgba(178, 178, 178, 0.4)'}}/>
                                    }
                                    <Line key={index} >
                                        <LineInfo style={{justifyContent:'space-between', left:'0px', }}>{single?.this_candidate_data?.nome || 'Indefinido'}</LineInfo>
                                        <LineInfo>{single?.this_tema_data?.nome || 'Indefinido'}</LineInfo>
                                        <LineInfo>{single?.tipo=='D'?'Digitada':single?.tipo=='F' ? "Foto" : 'Indefinido'}</LineInfo>
                                        <LineInfo>{single?.this_status_data?.nome || 'Indefinido'}</LineInfo>
                                        <LineInfo style={{ whiteSpace: 'pre-line' }}>{formatDate(single.updated_at)}</LineInfo>
                                        <LineInfo style={{display:'flex', gap:'5px', width:'10%' }}>
                                            {/* <ButtonDetailsLine><img src={info_dark}   onClick={()=>{setselectedEssay(single) ; setisChanging(false) ; setisRemoving(false);  setisCreating(false) ; setOpenModal(true)  }}   /></ButtonDetailsLine> */}
                                            <ButtonEditLine><img src={edit_dark}   onClick={()=>{setselectedEssay(single) ; setisChanging(true) ; setisRemoving(false);  setisCreating(false) ; setOpenModal(true)  }}      /></ButtonEditLine>
                                            <ButtonEditLine style={{backgroundColor:'rgba(0,0,0,0'}}><img src={print}   onClick={()=>{setselectedEssay(single) ; handleGeneratePdf(single)}}      /></ButtonEditLine>
                                        </LineInfo>
                                    </Line>
                                    </div>
                                )
                            }
                        }
                        return null
                    }).filter(Boolean)}
                </Table>

                <Stack sx={{width:'100%', margin:'20px auto 20px auto', display:'flex', flexDirection:'row',  }}>
                    <Pagination color="primary" count={totalPages} page={page} onChange={handleChangePage} sx={{width:'80%', margin:'auto' }} showLastButton/>
                    <div style={{width:'20%', margin:'auto 0px auto auto', display:'flex', gap:'20px',  alignItems:'center', justifyContent:'center', }}>
                        <div style={{ margin:'auto', display:'flex', gap:'20px',  alignItems:'center', justifyContent:'center', }}>
                            Ítens por página
                        </div>
                        <FormControl sx={{display:'flex', margin:'auto', height:'30px'}}>
                            <SingleElement_info_select
                            value={qtdToShow}
                            onChange={(e)=>{setpage(1);setQtdToShow(e.target.value)}}
                            MenuProps={{
                                sx: {
                                    zIndex: 999999999999, 
                                },
                            }}
                            inputProps={{ 'aria-label': 'Without label', }}
                            >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                            </SingleElement_info_select>
                            <FormHelperText> </FormHelperText>
                        </FormControl>

                    </div>
                </Stack>


                    <div 
                    style={{display:'none'   }}
                    >
                        <div ref={reportTemplateRef}>
                            {selectedEssay && 
                                <ReportTemplate selectedEssay={selectedEssay} />
                            }
                        </div>
                    </div>


        </RootStyle>
    )
}