import React, { useEffect, useState , useRef} from "react";
import { v4 as uuidv4 } from 'uuid';

import useWindowSize from "../../../../API_CONFIGS/useWindowSize";


// import { pdfjs } from 'react-pdf';
import { API_BASE, IMAGE_BASE } from '../../../../API_CONFIGS/constants';

import jsPDF from 'jspdf';
import 'jspdf-autotable';


import Loading from "../../../components/loading/Loading";

import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';


import CustomButtom from '../../../components/customButtom';
import saveicon from '../../../../media/icons/completed.svg'
import { toast } from "react-toastify";
import API from '../../../../API_CONFIGS/redux/API';
import { useAuth } from '../../../../AUTH_CONFIGS/Auth';



import Contract1 from '../contracts_models/Contract1';

 
// const MyPdfViewer = ({ pdfUrl }) => {
//   const [numPages, setNumPages] = useState(null);
//   const [pageNumber, setPageNumber] = useState(1);

//   const onDocumentLoadSuccess = ({ numPages }) => {
//     setNumPages(numPages);
//   };




//   return (
//     <div style={{
//       width:'100%', 
//       // border:'1px solid #CCC'
    
//       }}
    
//     >
//       <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}>
//         <Viewer fileUrl={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}  style={{ backgroundColor: 'tomato' }} />
//       </Worker>
//       {/* <p>
//         Page {pageNumber} of {numPages}
//       </p> */}
//     </div>
//   );
// };


 


export default function ({setLoading , setLoadingText }){


  const {currentUser} = useAuth()

  const ContractRef = useRef(null);

  const { width } = useWindowSize()
  const isMobile = width < 800

  
  const TestRef = useRef(null);


  const [mobileModal, setMobileModal] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [userLocation, setUserLocation] = useState(null);



  const [contract, setContract] = useState("");
  const [course, setCourse] = useState(null);
  const [inscription, setInscription] = useState(null);
  const [user, setUser] = useState(null);
  
  




  function onDocumentLoadSuccess({ numPages ,  }) {
    setNumPages(numPages)
  }

  // const pdfUrl = `${IMAGE_BASE}contract_to_show/sample.pdf`
  const pdfUrl = "http://localhost:8081/uploads/contract_to_show/sample.pdf"

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  useEffect(() => {
    // Check if the Geolocation API is supported by the browser
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ latitude, longitude });
        },
        (error) => {
          console.error('Error getting user location:', error.message);
        }
      );
    } else {
      console.error('Geolocation is not supported by your browser');
    }
  }, []);










const handleGeneratePdf = () => {
    // setLoading(true);

    const pdfWidth = 1050; // in millimeters
    const pdfHeight = 1500; // in millimeters

    const doc = new jsPDF({
        unit: 'px', // Specify the unit for width and height
        format: [pdfWidth, pdfHeight], // Set custom width and height
    });

    // Adding the fonts.
    doc.setFont('Inter-Regular', 'normal');

    const extraSpace = 30; // You can adjust the value to add more or less space

    doc.html(ContractRef.current, {
        margin: [extraSpace, 0, extraSpace, 0],
        async callback(doc) {
            await doc.save('document');
            // setLoading(false);
        },
    });
};





const handleSignDocument = async ()=>{

  
  if(!acceptTerms){return toast.info('Confirme que está ciente e confirma os termos, antes de continuar.')}    

  setLoading(true)
  setLoadingText(`
  Salvando documento e dados... \n

  Isso pode demorar um pouco, mas não se preocupe. \n

  Ainda estamos aqui, ok?
  `) 






  const containerHTML = ContractRef.current.innerHTML;


  const contractName = `contract_user_${currentUser?.id}.pdf`
  

  const data = {
    htmlContent: containerHTML,
    // cssStyles: inlineStyles ,
    userID: currentUser?.id,
    contractName:contractName,
  }

  var isCreated = false

    await API.post(`aluno/generateContract`, data)
           .then((res)=>{
             toast.success('Contrato assinado com sucesso.')
             isCreated = true
             if(res?.data?.returnPath){
              window.open(`${IMAGE_BASE}${res?.data?.returnPath}`, '_blank', )
              }
           })
           .catch((error)=>{
             toast.error('Erro ao assinar contrato. Tente novamente.')
             console.error(error);
           })
          

           if(isCreated){
          const formData = {
            name: contractName ,
            user_id: currentUser?.id || null ,
            browser_info_name: navigator.appName || '' ,
            browser_info_version: navigator.appVersion || '' ,
            browser_info_userAgent: navigator.userAgent || '' ,
            location_latitude: userLocation?.latitude || '' ,
            location_longitude: userLocation?.longitude || '' ,
          }  
      
      
    await API.post(`aluno/signDigitalContract/${currentUser?.id}`, formData)
           .then((res)=>{
             toast.success('Contrato assinado com sucesso.')
             setLoading(false)
             window.location.reload()
           })
           .catch((error)=>{
             toast.error('Erro ao assinar contrato. Tente novamente.')
             console.error(error);
             setLoading(false)
           })
            

           }





}




useEffect(()=>{

  if(currentUser){ 
    setLoading(true)
    API.post('/aluno/getContractInfo',{userID:currentUser?.id})
    .then((res)=>{
      setContract(res?.data?.contract?.body || "Contrato sendo carregado ...")

      setCourse(res?.data?.course || null)
      setInscription(res?.data?.inscription || null)
      setUser(res?.data?.user || null)
      
      
      
      setLoading(false)
      
    })
    .catch((err)=>{
      console.error(err);
      toast.error(err)
      setLoading(false)
    })
  }
},[currentUser])




  return (

    <>

    
    
    {/* {`${IMAGE_BASE}contract_to_show/sample.pdf`} */}



    <div style={{display:'flex', flexDirection:'column', width:'100%', gap:'10px', justifyContent:'center', alignItems:'center', padding:'20px',  }}>
      <h1>Contrato de matrícula</h1>
      {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.js">
        <div style={{ minHeight: '750px' , width:'100%',  }}>
            <Viewer
                fileUrl={pdfUrl}
                plugins={[
                    defaultLayoutPluginInstance,
                ]}
            />
        </div>
    </Worker> */}


{isMobile && 

<>
  <div style={{display:'flex', padding:'20px 40px 20px 40px', backgroundColor:'rgba(162, 0, 207, 0.8)', color:'#fff',fontWeight:'bold', borderRadius:'10px', fontSize:'12pt', cursor:'pointer', }}
    onClick={()=>{setMobileModal(true)}}
  >
    Ver contrato
  </div>

  </>


}

<div
    style={
      isMobile  &&  mobileModal ?
      {      
        position:'fixed',
        width: '90vw',
        height: '90vh', 
        top:'calc(5vh)', 
        left:'calc(5vw)',
        backgroundColor:'rgba(255,255,255)',
        zIndex:'9999999999999999',

        display: "flex",
        // justifyContent:'center',
        // alignItems:'start',
        padding: '100px 100px 100px 100px',
        overflow:'auto',
        border:'1px solid #CCC',
      }
      :
      !isMobile ?
      {
        width: '100%',
        height: '70vh', 
        display: "flex",
        justifyContent:'center',
        alignItems:'start',
        padding: '100px 100px 100px 100px',
        overflow:'auto',
        border:'1px solid #CCC',

      }
      :
      {
        display:'none'
      }
    }
  >

      <Contract1 

        ContractRef={ContractRef}
        user={user}  
        contract={contract}  
        course={course}  
        inscription={inscription}

        // isMobile={isMobile}
      />




{mobileModal &&
    <div style={{
              position:'fixed',
              top:'calc(7vh)',
              right:'calc(7vw)',

      display:'flex',
      padding:'10px 20px 10px 20px', 
      backgroundColor:'rgba(246, 34, 34, 1)',
      color:'#fff',
      fontWeight:'bold', 
      borderRadius:'10px', 
      fontSize:'12pt', 
      cursor:'pointer !important', 
    }}
      onClick={()=>{setMobileModal(false)}}
    >
      Fechar
    </div>
  }


  </div>

    </div>



    {
      contract && 


    <div style={{margin:'40px auto 40px auto', width:'100%', display:'flex', flexDirection:'column', gap:'10px', alignItems:'center', justifyContent:'center',   }}>


    <h3 style={{display:'flex', justifyContent:'center', gap:'10px', }}> <input type='checkbox'  style={{backgroundColor:'green', width:'20px', height:'20px', cursor:'pointer'}} onClick={()=>{setAcceptTerms(!acceptTerms)}} value={acceptTerms}/> Confirmo que li e concordo com todos os termos do contrato acima</h3>
    <CustomButtom color={1} text='Assinar documentos' icon={saveicon} onClick={handleSignDocument}/>

    </div>
    }

    </>



  )
}


