import React, {FC, useEffect, useState} from 'react'

import API from '../../../../../../API_CONFIGS/redux/API'
import { toast } from 'react-toastify'

interface step2Intyerf{
  selectedCourse:string;
  setselectedCourse: (selectedCourse:string)=>void;
  ingressoSelected:string;
  setingressoSelected: (ingressoSelected:string)=>void;
  semesterSelected:string;
  setsemesterSelected: (semesterSelected:string)=>void;

  allStep2DataValid: boolean,
  setAllStep2DataValid: (allStep2DataValid:boolean)=>void;

  promotor_id: number | null,
  setPromotor_id: (promotor_id:number | null)=>void;

} 

interface Promotores{
  id:number|null,
  nome:string,
  set:boolean
}

const Step2: React.FC<step2Intyerf> = ({selectedCourse,setselectedCourse,ingressoSelected,setingressoSelected, semesterSelected, setsemesterSelected, allStep2DataValid, setAllStep2DataValid, promotor_id, setPromotor_id}) => {

  
  const [allCourses,setallCourses] = useState([{nome:'--- Selecione ---', id:-1, tipo_id:-1}])
  const [ingressos,setingressos]=useState([{nome:'--- Selecione ---', id:-1}])
  const [semester,setSemester] =useState([{nome:'--- Selecione ---', id:-1,set:0}])
  const [allCourseTypes,setAllCourseTypes] =useState([{nome:'--- Selecione ---', id:-1,set:0}])
  const [courseTypeID,setCourseTypeID] =useState('-1') 

  const [allPromotores,setAllPromotores] =useState<Promotores[]>([])

useEffect(()=>{
  API.post(`course/signup_chooseacourse`)
  .then((result)=>{
      if(result.data.status === 'success'){
      let courseaux = [...allCourses] 
      setallCourses(result?.data?.allCourses)
      setingressos(result?.data?.ingressos)
      setSemester(result?.data?.campanhas)
      setAllCourseTypes(result?.data?.tipo_curso)
      }
    
  })
  .catch((err)=>{
    toast.error('Erro ao obter dados. Contate suporte.')
    console.log(err);
  })
},[])

useEffect(()=>{
  API.get(`administrativo/promotores`)
  .then((result)=>{
      if(result?.data?.promotores){
      setAllPromotores(result?.data?.promotores)
      }
  })
  .catch((err)=>{
    console.log(err);
  }
  )
},[])



useEffect(()=>{
  if(selectedCourse && ingressoSelected && semesterSelected && selectedCourse !== '-1' && ingressoSelected !== '-1' && semesterSelected !== '-1'){
    setAllStep2DataValid(true)
  }
  else{
    setAllStep2DataValid(false)
  }
},[selectedCourse,ingressoSelected,semesterSelected])



  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>Escolha seu curso e como você quer ingressar na Faculdade Rebouças de Campina Grande</h2>

        
      </div>




      <div className='d-flex align-items-center w-100'>
        <div className='me-3 w-100'>
        <div className='d-flex align-items-center w-100'>
        <div className='me-3 w-100'>
        <div className='m-0 w-100'>
          <label className='form-label fw-bolder text-dark fs-6 w-100 required' >Tipo de Curso:</label>
          <select
            className='form-select form-select-sm form-select-solid w-100'
            onChange={(e) => {
              setselectedCourse('-1') 
              setingressoSelected('-1')
              setsemesterSelected('-1')
              setCourseTypeID(e.target.value)
            }}
          >
            <option value={-1}>--- Selecione ---</option>
            {
              allCourseTypes.map((singleCourseType, index)=>{
                return(<option value={singleCourseType.id}>{singleCourseType.nome}</option>)
              })
            }
          </select>
        </div>
        </div>
        </div>

{ courseTypeID !== '-1' &&

<>

        <div className='d-flex align-items-center w-100'>
        <div className='me-3 w-100'>
        <div className='m-0 w-100'>
          <label className='form-label fw-bolder text-dark fs-6 w-100 required' >Curso:</label>
          <select
            value={selectedCourse !== '-1' ? selectedCourse : '-1' }
            className='form-select form-select-sm form-select-solid w-100'
            onChange={(e) => 
              setselectedCourse(e.target.value)
            }
          >
            <option value={-1}>--- Selecione ---</option>
            {
              allCourses.map((singleCourse, index)=>{

                return(
                <>
                {

                  singleCourse?.tipo_id === Number(courseTypeID) ?

                  <option value={singleCourse.id}>{singleCourse.nome}</option>

                  :

                  null
                }
                
                </>
                )
              }).filter(Boolean)
            }
          </select>
        </div>
        </div>
        </div>


      <div className='d-flex align-items-center w-100'>
        <div className='me-3 w-100'>
        <div className='m-0 w-100'>
          <label className='form-label fw-bolder text-dark fs-6 w-100 required' >Formas de Ingresso:</label>
          <select
            className='form-select form-select-sm form-select-solid w-100'
            onChange={(e) => setingressoSelected(e.target.value)}
            value={ingressoSelected !== '-1' ? ingressoSelected : '-1' }

          >
            <option value={-1}>--- Selecione ---</option>
            {
              ingressos.map((single, index)=>{
                return(
                  <>
                  {
                  courseTypeID !== '1' && !([1,2,3,4]).some(singleCourseTypeId => singleCourseTypeId === single.id)  ?
                  <option value={single.id}>{single.nome}</option>
                  :
                  courseTypeID === '1' && ([1,2,3,4]).some(singleCourseTypeId => singleCourseTypeId === single.id)  ?
                  <option value={single.id}>{single.nome}</option>
                  :
                  null
                  }
                  </>
                )
              }).filter(Boolean)
            }
          </select>
        </div>
        </div>
        </div>


      <div className='d-flex align-items-center w-100'>
        <div className='me-3 w-100'>
        <div className='m-0 w-100'>
          <label className='form-label fw-bolder text-dark fs-6 w-100 required' >Campanha/Semestre:</label>
          <select
            className='form-select form-select-sm form-select-solid w-100'
            onChange={(e) => setsemesterSelected(e.target.value)}
            value={semesterSelected !== '-1' ? semesterSelected : '-1' }

          >
            <option value={-1}>--- Selecione ---</option>
            {
              semester.map((single, index)=>{
                if(single.set){
                  return(<option key={single.id} value={single.id}>{single.nome}</option>)
                }
              })
            }
          </select>
        </div>
        </div>
        </div>



      <div className='d-flex align-items-center w-100'>
        <div className='me-3 w-100'>
        <div className='m-0 w-100'>
          <label className='form-label fw-bolder text-dark fs-6 w-100 ' >Indicado por alguém:</label>
          <select
            className='form-select form-select-sm form-select-solid w-100'
            onChange={(e) => {
              if(e.target.value === '-1'){
                return setPromotor_id(null)
              }
              return setPromotor_id(Number(e.target.value))
            }}

            value={promotor_id? promotor_id : -1}

          >
            <option value={-1}>--- Selecione ---</option>
            {
              allPromotores?.map((single, index)=>{
                if(single?.set){
                  return(<option key={single.id} value={single?.id  || -1}>{single.nome}</option>)
                }
              })
            }
          </select>
        </div>
        </div>
        </div>

        </>
}

      
          </div>
        </div>
    </div>
  )
}

export {Step2}
