import React, { useEffect, useState } from 'react';
import './DragAndDrop.css';

import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'

import API from '../../../API_CONFIGS/redux/API';
import { API_BASE } from '../../../API_CONFIGS/constants';
import { toast } from 'react-toastify';
import { useAuth } from '../../../AUTH_CONFIGS/Auth';

import useWindowSize from '../../../API_CONFIGS/useWindowSize';


export default function DragAndDropExample (props){
  const {currentUser}=useAuth()
  const [numFilesSent, setNumFilesSent] = useState(0);
  const [initialFiles, setinitialFiles] = useState([]);

  const [isSent, setisSent] = useState(false);



  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, remove }, status) => {
    if (status === 'headers_received') {
      // props.setdocSelectedId('-1')
      toast.success(`Arquivo enviado com sucesso! ${meta.name}`)
      remove()


      props?.handleAfterSend()

      props?.setAllowSendFile(true)
      setNumFilesSent((prevNum) => prevNum + 1);

    } else if (status === 'aborted') {
      toast.success(`Erro ao enviar o arquivo ${meta.name}`)
      remove()
    }
    
  }

  
  // receives array of files that are done uploading when submit button is clicked
  const handleSubmit = (files, allFiles) => {
    setNumFilesSent(files.length);
    allFiles.forEach(f => f.remove())
  }

  // specify upload params and url for your files
  const getUploadParams = ({ meta }) => { 
    return { url: props.routeToSend } 
  }

  

  return (
    <div>
      <Dropzone
        getUploadParams={getUploadParams}
        
        onChangeStatus={handleChangeStatus}
        autoUpload={true} // set autoUpload to false
        accept={props.supportedFiles}
        inputContent={props.messageToShow}
        canRemove={false}
        inputWithFilesContent={!props.allowSendFile ? null : 'Adicionar'} // Hide the "Adicionar" button
        submitButtonContent={'Enviar'}
        disabled={props.disabled}

        multiple={props?.multiple || false}


        // onSubmit={handleSubmit}
      />

    </div>
  )
}
