import React, { useEffect, useState } from "react";
import { styled } from '@mui/system';
import CustomButtom from "../customButtom";


import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';

import closeicon from '../../../media/icons/close-dark.svg'
import saveicon from '../../../media/icons/save-dark.svg'
import removeicon from '../../../media/icons/trash-dark.svg'

import { toast } from "react-toastify";
import API from "../../../API_CONFIGS/redux/API";

const RootStyle = styled('div')(({theme})=>({
    position:'fixed',
    width:'100%',
    height:'100%',
    top:'0',
    left:'0',
    backgroundColor:'rgba(0, 0, 0, 0.3)',
    zIndex:'9999',



}))
const Modal = styled('div')(({theme})=>({
    display:'flex',
    flexDirection:'column',
    position:'fixed',
    minWidth:'40%',
    maxWidth:'80%',
    minHeight:'40vh',
    maxHeight:'80vh',
    // overflow:'auto',
    backgroundColor:'rgba(255,255,255)',
    borderRadius:'10px',
    top:'50%',
    left:'50%',
    transform: 'translate(-50%,-50%)',
    zIndex:'99999',
}))

const Header = styled('div')(({theme})=>({
    display:'flex',
    width:'100%',
    display:'flex', 
    top:'0px', 
    left:'0px',
    padding:'10px',
    borderBottom:'1px solid black',
    gap:'10px',
    justifyContent:'center',
    alignItems:'center',
}))
const Body = styled('div')(({theme})=>({
    display:'flex',
    width:'100%',
    display:'flex',
    overflow:'auto',

    padding:'20px',
    gap:'10px',
    justifyContent:'space-between',
    alignItems:'center',


    // backgroundColor:'red',
    margin:'auto auto 55px auto',

    flexWrap: 'wrap',


}))
const SingleElement = styled('div')(({theme})=>({
    width:'calc(33.33% - 10px)',
    display:'flex',
    flexDirection:'column',

    gap:'5px',
    // justifyContent:'space-around',
    // alignItems:'center',


    // backgroundColor:'red'
}))
const SingleElement_title = styled('div')(({theme})=>({
    display:'flex',
    width:'100%',
    fontSize:'10pt',
    fontWeight:'bold',

}))
const SingleElement_info = styled(TextField)(({theme})=>({
    // display:'flex',
    // width:'100%',
    // padding:'10px',
    // border:'1px solid black',
    // borderRadius:'5px',



}))
const SingleElement_info_select = styled(Select)(({theme})=>({
    // display:'flex',
    // width:'100%',
    // padding:'10px',
    // border:'1px solid black',
    // borderRadius:'5px',


}))
const Footer = styled('div')(({theme})=>({
    display:'flex',
    width:'100%',
    display:'flex', 
    position:'inherit',  
    bottom:'0px', 
    left:'0px',
    padding:'10px',
    gap:'10px',
    justifyContent:'space-around',
    alignItems:'center',
    borderTop:'1px solid black',

}))


export default function (props){

    const [selectedEssay1,setselectedEssay1]=useState(
        {id:0, nome:'', tipo_id:'', nota_minima:0, duracao:'', valor:0, set:false},
    )
    const [isEdited,setisedited]=useState(false)
    const [isOK,setisOK]=useState(false)
    const [canRemove,setcanRemove]=useState(false)
    const [essayScore,setEssayScore]=useState(0)
    const [canUpdate,setCanUpdate]=useState(false)
    const [canEditEssay,setcanEditEssay]=useState(false)
    const [capturedValue,setCapturedValue]=useState(props?.activeRow?.capturado || false)
    const [finishedValue,setFinishedValue]=useState(props?.activeRow?.finalizado || false)
    const [editedEssay,setEditedEssay]=useState({})
    const [themeName,setthemeName]=useState('')
    const [themeComment,setthemeComment]=useState('')


    useEffect(()=>{
        setthemeName(props?.activeRow?.nome || '')
        setthemeComment(props?.activeRow?.descricao || '')
    },[])
    useEffect(()=>{
        if( (themeComment !== props?.activeRow?.descricao || themeName !== props?.activeRow?.nome) && (themeName && themeComment)){
            setcanEditEssay(true)
        }
        else{
            setcanEditEssay(false)
        }
    },[themeComment, themeName])


    const EditSingleLead =(e,isRemoving)=>{

        

        const data = {
            singleTheme: props?.activeRow || {} ,
            themeName:themeName,
            themeComment:themeComment,
            isRemoving:isRemoving,

        }

        API.post('administrativo/editsingletheme', data)
        .then((res)=>{
            toast.success('Atualizado com sucesso')
            setTimeout(() => {
                window.location.reload()
            }, 2000);
        })
        .catch((error)=>{

            console.log(error?.response?.data?.message || 'Erro ao atualizar. Tente novamente');

            toast.error(error?.response?.data?.message || 'Erro ao atualizar. Tente novamente')
        })
    }


    const editthemeName = (e)=>{
        setthemeName(e?.target?.value || '')
    }
    const editthemeComment = (e)=>{
        setthemeComment(e?.target?.value || '')
    }


    return(
        <>
            <RootStyle onClick={()=>{props.setOpenModal(false)}}     />
            <Modal>
                <div style={{ display:'flex', position:'inherit',  top:'0px', left:'calc(100% - 40px)', width:'40px', padding:'7px', zIndex:'99'  }}   ><img width='100%'  src={closeicon} style={{cursor:'pointer'}}  onClick={()=>{props.setOpenModal(false)}}/></div>
            
                <Header>
                    <h3 style={{ marginLeft:'50px'}}>Tema:</h3>
                    <h2 style={{fontWeight:'bold', color:'#000c7c', marginRight:'50px'}}>{props?.activeRow?.id || ''}</h2>
                </Header>

                {selectedEssay1 && 
                <Body>
                        <SingleElement style={{width:props?.activeRow?.newTheme?'100%':''}}>
                            <SingleElement_title>Nome:</SingleElement_title>
                            <SingleElement_info value={themeName} onChange={editthemeName}  helperText={' '}             />
                        </SingleElement>
                        {props?.activeRow?.created_at &&
                            <SingleElement>
                                <SingleElement_title>Criado em:</SingleElement_title>
                                <SingleElement_info InputProps={{readOnly: true,}}  value={props?.formatDate(props?.activeRow?.created_at) || '-'}     helperText={' '}     />
                            </SingleElement>
                        }
                        <SingleElement style={{width:'100%', }}>
                            <SingleElement_title>Descrição:</SingleElement_title>
                            <SingleElement_info value={themeComment}  helperText={' '}    onChange={editthemeComment}     multiline style={{width:'100%', maxHeight:'600px', overflow:'auto'}}     />
                        </SingleElement>
                </Body>
                }

                {props && !props?.activeRow?.newTheme &&
                        <div style={{width:'100%', padding:'10px', margin:'-50px auto 50px auto', display:'flex', gap:'10px', alignItems:'center', justifyContent:'center', fontSize:'8pt',  color:'red', fontWeight:'bold', cursor:'pointer'}}  onClick={()=>{setcanRemove(!canRemove)}}>
                                <input type='checkbox' checked={canRemove} style={{width:'20px', height:'20px', cursor:'pointer'}}/>
                                <div style={{display:'flex', flexDirection:'column', gap:'0px', textAlign:'justify' }}>
                                    <div >Confirmo que desejo excluir este dado. Eles não estarão mais disponíveis após a exclusão.</div>
                                    <div >Também salvaremos os seus dados, por questões de controle/identificação de quem realizou a exclusão.</div>
                                </div>
                        </div>
                }


                <Footer>
                    <CustomButtom sx={{backgroundColor:'rgba(200, 200, 200, 0.5)', }}  text='Fechar' onClick={()=>{props.setOpenModal(false)}}     />
                    { canEditEssay && 
                        <CustomButtom color={1} text='Salvar' icon={saveicon} 
                            onClick={EditSingleLead}
                        />
                    }
                    {!props?.activeRow?.newTheme &&
                        <CustomButtom color={3} text='Excluir' icon={saveicon} 
                            onClick={(e)=>{
                               return canRemove? EditSingleLead(e,true) : toast.info('Confirme a exclusão')
                            }}
                        />
                    }
                </Footer>
            </Modal>
        </>
    )
}