import React from "react";
import { toast } from "react-toastify";

import { format } from 'date-fns'; // import the format function from date-fns

import {useState, useEffect} from 'react'
import Dropzone from "../DragAndDrop";
import Loading from "../../../components/loading/Loading";
import API from "../../../../API_CONFIGS/redux/API";
import { useAuth } from "../../../../AUTH_CONFIGS/Auth";
import { cpf } from 'cpf-cnpj-validator'; 

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';

import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';



// import LoadingButton from '@mui/lab/LoadingButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import SaveIcon from '@mui/icons-material/Save';
import SendIcon from '@mui/icons-material/Send';


import TextField from '@mui/material/TextField';


import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateField } from '@mui/x-date-pickers/DateField';

import dayjs, { Dayjs } from 'dayjs';


import './step7.css'
import { validateDate } from "@mui/x-date-pickers/internals";
import { typographyClasses } from "@mui/material";
import { string } from "yup";





interface Step7Props{ 
allPersonalDataOK:boolean;
setAllPersonalDataOK:(allDataOK:boolean)=>void;
}





const Step7Vestibular: React.FC<Step7Props> = ({allPersonalDataOK,setAllPersonalDataOK}) => {

  const {currentUser} = useAuth()


  const currentDate = new Date();
  const initialDate = format(currentDate, 'dd/MM/yyyy'); // format the date in "DD/MM/YYYY" format

  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [cpf1, setCpf] = useState('');
  const [gender, setGender] = React.useState<string | null>(null);
  const [birthdate, setBirthdate] = React.useState<Dayjs | null>(dayjs(''));
  const [changedBirth,setchangedBirth] = useState(false)
  const [changedGender,setChangedGender] = useState(false)
  const [genderValid,setgenderValid] = useState(false)
  const [cpfValid,setCpfValid] = useState(false)
  const [DBupdated,setDBupdated] = useState(false)
  const [cpfFormatted,setcpfFormatted] = useState('')
  const [validName, setValidName]=useState(false)

  const [loading,setLoading]=useState(false)

  
  const [zipCode, setZipCode] = useState('');
  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [complement, setComplement] = useState('');

  const [rg , setrg] = useState('')
  const [raca_cor , setraca_cor] = useState('')
  const [naturalidade , setnaturalidade] = useState('')
  const [nacionalidade , setnacionalidade] = useState('')
  const [estado_civil , setestado_civil] = useState('')
  const [telefone_residencial , settelefone_residencial] = useState('')
  const [nome_mae , setnome_mae] = useState('')
  const [nome_pai , setnome_pai] = useState('')
  
  const [cepOK, setcepOK] = useState(false)
  const [DBinvalid, setDBinvalid] = useState(false)
  const [validBirthday,setvalidBirthday]= useState(false)

  const [hasChanged,sethasChanged] = useState(false)
  const [canContinue,setcanContinue] = useState(false)
  const [validPhone,setValidPhone]=useState(false)
  const [phoneFormatted,setPhoneFormatted]=useState('')
  
  
  const [validEmail,setValidEmail]= useState(false)
  

  const [currentUserData, setCurrentUserData] = useState({
    estado:state     ,
    cidade:city     ,
    bairro:neighborhood     ,
    complemento:complement     ,
    numero:number     ,
    endereco:street     ,
    cep:zipCode     ,
    nascimento:birthdate     ,
    sexo:gender     ,
    cpf:cpf1     ,
    celular:phone     ,
    email:email     ,
    nome:firstName     ,
    rg:rg    ,
    raca_cor:raca_cor    ,
    naturalidade:naturalidade    ,
    nacionalidade:nacionalidade    ,
    estado_civil:estado_civil    ,
    telefone_residencial:telefone_residencial    ,
    nome_mae:nome_mae    ,
    nome_pai:nome_pai    ,
  })
  const [userAfterChanges,setuserAfterChanges] = useState({
    estado:state     ,
    cidade:city     ,
    bairro:neighborhood     ,
    complemento:complement     ,
    numero:number     ,
    endereco:street     ,
    cep:zipCode     ,
    nascimento:birthdate     ,
    sexo:gender     ,
    cpf:cpf1     ,
    celular:phone     ,
    email:email     ,
    nome:firstName     ,
    rg:rg    ,
    raca_cor:raca_cor    ,
    naturalidade:naturalidade    ,
    nacionalidade:nacionalidade    ,
    estado_civil:estado_civil    ,
    telefone_residencial:telefone_residencial    ,
    nome_mae:nome_mae    ,
    nome_pai:nome_pai    ,
  })
    


  
  const formatDateToString = (date:Date)=>{
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }
  

  
  useEffect(() => {


    if(
      
      (userAfterChanges.bairro !== currentUserData.bairro ||
      userAfterChanges.celular !== currentUserData.celular ||
      userAfterChanges.cep !== currentUserData.cep ||
      userAfterChanges.cidade !== currentUserData.cidade ||
      userAfterChanges.complemento !== currentUserData.complemento ||
      userAfterChanges.cpf !== currentUserData.cpf ||
      userAfterChanges.email !== currentUserData.email ||
      userAfterChanges.endereco !== currentUserData.endereco ||
      userAfterChanges.estado !== currentUserData.estado ||
      userAfterChanges.nascimento !== currentUserData.nascimento ||
      userAfterChanges.nome !== currentUserData.nome ||
      userAfterChanges.numero !== currentUserData.numero ||
      userAfterChanges.sexo !== currentUserData.sexo ||

      userAfterChanges.rg !== currentUserData.rg ||
      userAfterChanges.raca_cor !== currentUserData.raca_cor ||
      userAfterChanges.naturalidade !== currentUserData.naturalidade ||
      userAfterChanges.nacionalidade !== currentUserData.nacionalidade ||
      userAfterChanges.estado_civil !== currentUserData.estado_civil ||
      userAfterChanges.telefone_residencial !== currentUserData.telefone_residencial ||
      userAfterChanges.nome_mae !== currentUserData.nome_mae ||
      userAfterChanges.nome_pai !== currentUserData.nome_pai)

      &&

      (validEmail && validName && validPhone && cpfValid && validBirthday && cepOK)

      &&

      (
          userAfterChanges.bairro &&
          userAfterChanges.celular &&
          userAfterChanges.cep &&
          userAfterChanges.cidade &&
          userAfterChanges.cpf &&
          userAfterChanges.email &&
          userAfterChanges.endereco &&
          userAfterChanges.estado &&
          userAfterChanges.nascimento &&
          userAfterChanges.nome &&
          userAfterChanges.numero &&
          userAfterChanges.sexo &&

          userAfterChanges.rg &&
          userAfterChanges.raca_cor &&
          userAfterChanges.naturalidade &&
          userAfterChanges.nacionalidade &&
          userAfterChanges.estado_civil &&
          userAfterChanges.telefone_residencial &&
          userAfterChanges.nome_mae &&
          userAfterChanges.nome_pai
      )

      ){
      return sethasChanged(true)
    }
      return sethasChanged(false)

  }, [userAfterChanges,validEmail,validName,validPhone,cpfValid,validBirthday,cepOK]);

  

const comments = 'Blá ... Blá ... Blá ... Blá ... Blá ... '




const handleCep = (e: string) => {
  setLoading(true)
  // const cepNumber = e.replace(/\D/g, '');
  const urltoSearch =  `https://viacep.com.br/ws/${e}/json/`;
  var auxUserChange = {...userAfterChanges}
  var auxZipCode = ''
  if(e.length<9){
    // setZipCode(e)
    // auxZipCode=e
    auxUserChange.cep=e
    setuserAfterChanges(auxUserChange)

    if (e.length !== 8) {
      setLoading(false)
      setcepOK(false)
      return;
    }

    fetch(urltoSearch)
      .then((res) => {
        if (!res.ok) {
          setcepOK(false)
          throw new Error('Erro durante a busca do CEP.');
        }
        return res.json()
      })
      .then((data) => {
        if(data.erro){
          setLoading(false)
          toast.error('CEP inválido. Digite novamente.');
          setcepOK(false)
          auxUserChange.endereco=''
          auxUserChange.bairro=''
          auxUserChange.cidade=''
          auxUserChange.estado=''
          auxUserChange.numero=''
          auxUserChange.complemento=''
          // setStreet('')
          // setNeighborhood('')
          // setCity('')
          // setState('')
          setuserAfterChanges(auxUserChange)
        }
        else{
          setLoading(false)
          toast.success('Dados obtidos. Complete os demais campos.');
          // alert(JSON.stringify(data))
          auxUserChange.endereco=data.logradouro
          auxUserChange.bairro=data.bairro
          auxUserChange.cidade=data.localidade
          auxUserChange.estado=data.uf

          // setStreet(data.logradouro)
          // setNeighborhood(data.bairro)
          // setCity(data.localidade)
          // setState(data.uf)
          setcepOK(true)
          setuserAfterChanges(auxUserChange)

        }
      })
      .catch((err) => {
        setLoading(false)
        var auxUserChange= {...userAfterChanges}
        auxUserChange.endereco=''
        auxUserChange.bairro=''
        auxUserChange.cidade=''
        auxUserChange.estado=''
        auxUserChange.complemento=''

        setuserAfterChanges(auxUserChange)

        setcepOK(false)
        toast.error(err.message);
      });
    }
    else{
      return
    }
};



useEffect(() => {

      if (currentUser) {
        API.post('/aluno/get_candidato_data_by_id', { userID: currentUser.id })
        .then((res)=>{
          if (res.data.status === 'success') {



            var auxCandidato = {...res.data.candidato}
            var isDBinvalid = false
              if(auxCandidato.nascimento){
                setBirthdate(dayjs(auxCandidato.nascimento))
                if(dayjs(auxCandidato.nascimento).isValid()){
                  auxCandidato.nascimento = dayjs(auxCandidato.nascimento)
                  setvalidBirthday(true)
                }
                else{
                  auxCandidato.nascimento = dayjs('')
                  setvalidBirthday(false)
                }
              }else{
                isDBinvalid=true
              }
              if(auxCandidato.cep){
                // handleCep(auxCandidato.cep)
                setcepOK(true)
              }else{
                isDBinvalid=true
              }
              if(auxCandidato.endereco){
              }else{
                isDBinvalid=true
              }
              if(auxCandidato.numero){
              }else{
                isDBinvalid=true
              }
              if(auxCandidato.complemento){
              }else{
                isDBinvalid=true
              }
              if(auxCandidato.bairro){
              }else{
                isDBinvalid=true
              }
              if(auxCandidato.cidade){
              }else{
                isDBinvalid=true
              }
              if(auxCandidato.estado){
              }else{
                isDBinvalid=true
              }
              if(auxCandidato.sexo){
              }else{
                isDBinvalid=true
              }
              if(auxCandidato.nome){
              }else{
                isDBinvalid=true
              }
              if(auxCandidato.email){
              }else{
                isDBinvalid=true
              }
              if(auxCandidato.celular){
              }else{
                isDBinvalid=true
              }
              if(!auxCandidato.cpf){
                // handleCep(auxCandidato.cep)
                isDBinvalid=true
              }
            setDBinvalid(isDBinvalid)
            setuserAfterChanges(auxCandidato)
            setCurrentUserData(auxCandidato)

          }
        })
        .catch((err)=>{

        })
      }
}, []) 
 

const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  var auxUserChange = {...userAfterChanges}
  auxUserChange.nome = event.target.value

  setuserAfterChanges(auxUserChange)

}


const handleCpfChange = (event: React.ChangeEvent<HTMLInputElement>)=>{
  
  var auxUserChange = {...userAfterChanges}
  if(event.target.value.length<=14){
    const cpfValue = event.target.value.replace(/\D/g, '');
    var formattedCpf;
    if (cpfValue.length <= 3) {
      formattedCpf = cpfValue;
    } else if (cpfValue.length <= 6) {
      formattedCpf = cpfValue.slice(0, 3) + '.' + cpfValue.slice(3);
    } else if (cpfValue.length <= 9) {
      formattedCpf = cpfValue.slice(0, 3) + '.' + cpfValue.slice(3, 6) + '.' + cpfValue.slice(6);
    } else {
      formattedCpf = cpfValue.slice(0, 3) + '.' + cpfValue.slice(3, 6) + '.' + cpfValue.slice(6, 9) + '-' + cpfValue.slice(9, 11);
    }
    auxUserChange.cpf = formattedCpf
    setuserAfterChanges(auxUserChange)
  }
}



const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>)=>{
  const emailValue = event.target.value
  var auxUserChange = {...userAfterChanges}
  auxUserChange.email = emailValue
  setuserAfterChanges(auxUserChange)
}



const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  if(event.target.value.length < 15){
    var auxUserChange = {...userAfterChanges}
    const phoneValue = event.target.value.replace(/\D/g, ''); // removes any non-digit character from the input
    let formattedPhone;
  
    if (phoneValue.length <= 2) {
      formattedPhone = '(' + phoneValue;
    } else if (phoneValue.length <= 7) {
      formattedPhone = '(' + phoneValue.slice(0, 2) + ')' + phoneValue.slice(2);
    } else {
      formattedPhone = '(' + phoneValue.slice(0, 2) + ')' + phoneValue.slice(2, 7) + '-' + phoneValue.slice(7);
    }
    auxUserChange.celular=formattedPhone
    setuserAfterChanges(auxUserChange)
  }
}

const handlePhysicalPhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  if(event.target.value.length < 14){
    var auxUserChange = {...userAfterChanges}
    const phoneValue = event.target.value.replace(/\D/g, ''); // removes any non-digit character from the input
    let formattedPhone;
  
    if (phoneValue.length <= 2) {
      formattedPhone = '(' + phoneValue;
    } else if (phoneValue.length <= 7) {
      formattedPhone = '(' + phoneValue.slice(0, 2) + ')' + phoneValue.slice(2);
    } else {
      formattedPhone = '(' + phoneValue.slice(0, 2) + ')' + phoneValue.slice(2, 6) + '-' + phoneValue.slice(6);
    }
    auxUserChange.telefone_residencial=formattedPhone
    setuserAfterChanges(auxUserChange)
  }
}


const options = { day: '2-digit', month: '2-digit', year: 'numeric' };

const handleChangeDate =(date: Dayjs | null)=>{
  var auxCandidato = {...userAfterChanges}
  auxCandidato.nascimento = date
  setuserAfterChanges(auxCandidato)
  setchangedBirth(true)
  if(!dayjs(date).isValid()){
    return setvalidBirthday(false)
  }
  const currentdatae = new Date().getTime()
  const datestring = date?.toISOString()
  var thisDate = null
  datestring? thisDate = new Date(datestring).getTime() : thisDate = null
  const years = 14*(1000*60*60*24*365)
  const years_max = 120*(1000*60*60*24*365)
  if(thisDate && datestring && ( (currentdatae-years)<=thisDate || currentdatae-thisDate>=years_max) ){
    return setvalidBirthday(false)
  }
  
    return setvalidBirthday(true)
}


useEffect(()=>{
  var auxUserChange = {...userAfterChanges}
  const emailRegex = /^[^\s@]+@[^\s@]+\.(?:co|com|br)$/i

  if(auxUserChange.celular.length === 14){
    setValidPhone(true)
  }
  else{
    setValidPhone(false)
  }

  
  if(emailRegex.test(auxUserChange.email)){
    setValidEmail(true)
  }
  else{
    setValidEmail(false)
  }

    var auxfirstName = auxUserChange.nome.trim();
    let words = auxfirstName.split(' ');
    words = words.filter(word => word !== '');
    if (words.length >= 2) {
      setValidName(true)
    } else {
      setValidName(false)
    }
  

  var minhaString = auxUserChange.cpf
  minhaString = minhaString.replace(/[ .-]/g, "");
  var isValidAux = false
  isValidAux = cpf.isValid(minhaString)
  if (isValidAux) {
    setCpfValid(true);
  } 
  else {
    setCpfValid(false);
  }


  
},[userAfterChanges])

useEffect(()=>{
  if(validEmail && validName && validPhone && cpfValid){
    // setAllStep1DataValid(true)
  }
  else{
    // setAllStep1DataValid(false)
  }


},[validEmail,validName,validPhone,cpfValid,validBirthday])



const handleSendNewData = ()=>{
  if(currentUser){
      
      API.post('/aluno/changepersonaldata',userAfterChanges)
      .then((res)=>{
        if(res.data.status==='success'){
          toast.success(res.data.message)
          window.location.reload()
        }
        else{
          toast.error(res.data.message?res.data.message:'Erro ao alterar dados. Contate o suporte.')
          window.location.reload()
        }
      })
      .catch((err)=>{
        console.log(err);
        toast.error(err)
        // window.location.reload()

      })

  }
    
}



useEffect(()=>{
  if(cepOK && validBirthday && validEmail && validName && validPhone && cpfValid && !DBinvalid){
    return setAllPersonalDataOK(true)
  }
    return setAllPersonalDataOK(false)
},[cepOK,validBirthday,validEmail,validName,validPhone,cpfValid,DBinvalid])


  return (


<>

    {loading && 
        <div style={{position:'fixed', left:'0', top: '0', zIndex:'9999999999999'}}>
          <Loading active={loading} text={'Carregando'} />
        </div>
    }



    <h4>Agora vamos atualizar suas informações:</h4>
    <div className="personal-data">
      <fieldset className="setUpPersonalData">
        <legend>Dados pessoais</legend>
        <div className="formData">

          <div className="column">

          <div className='fv-row mb-3'>
            <label className='form-label fw-bolder text-dark fs-6 required '>Nome Completo</label>
            <Box
              component="form"
              noValidate
              autoComplete="off"
            >
            <TextField
              value={userAfterChanges.nome}
              onChange={handleFirstNameChange}
              type='text'
              autoComplete='off'
              error={!validName && userAfterChanges.nome.length>0 ?true:false}
              className={`form-control bg-transparent  ${!validName && userAfterChanges.nome.length>0 ?'is-invalid':''} `}
            />
              {!validName  &&
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>O nome informado é muito curto ou incompleto</span>
                </div>
              </div>
            }

            </Box>
          </div>


          <div className='fv-row mb-3'>





          <label htmlFor="number" className=" form-label fw-bolder text-dark fs-6 required ">RG</label>
                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                    >
                      <TextField
                        className={'form-control bg-transparent inputNumber '} 
                        id="number" type="number" 
                        value={userAfterChanges.rg}
                        onChange={(e)=>{
                          var auxChange={...userAfterChanges}
                          auxChange.rg=e.target.value
                          setuserAfterChanges(auxChange)
                        }}
                        error={userAfterChanges.rg && userAfterChanges.rg.length<7 ?true:false}
                      />
                    </Box>


        <label className='form-label fw-bolder text-dark fs-6 required '>Email</label>
        <Box
              component="form"
              noValidate
              autoComplete="off"
            >
            <TextField
        value={userAfterChanges.email}
        onChange={handleEmailChange}
          type='email'
          autoComplete='off'
          error={!validEmail && userAfterChanges.email.length>0 ?true:false}
          className={`form-control bg-transparent  ${!validEmail && userAfterChanges.email.length>0 ?'is-invalid':''} `}
        />
        {!validEmail && userAfterChanges.email.length>0 &&
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>O Email informado é inválido</span>
            </div>
          </div>
        }
        {(userAfterChanges.email !== currentUserData.email) &&
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>Alterando seu email, seu login será feito pelo novo endereço</span>
            </div>
          </div>
        }

      </Box>
      </div>



            <label className='form-label fw-bolder text-dark fs-6 required ' htmlFor="phone">Celular:</label>
            <Box
              component="form"
              noValidate
              autoComplete="off"
            >
            <TextField

              id="phone" 
              type="tel" 
              value={userAfterChanges.celular}
              onChange={handlePhoneChange}
              error={userAfterChanges.celular.length<14 ?true:false}
              className={`form-control bg-transparent  ${userAfterChanges.celular.length<14?'is-invalid':''} `}
            />
            {userAfterChanges.celular.length<14 &&
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>Informe o número completo, com DDD</span>
                </div>
              </div>
            }
                </Box>




      <label htmlFor="number" className=" form-label fw-bolder text-dark fs-6 required ">Nacionalidade</label>
                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                    >
                      <TextField
                        className={'form-control bg-transparent inputNumber '} 
                        id="text" type="text" 
                        value={userAfterChanges.nacionalidade}
                        onChange={(e)=>{
                          var auxChange={...userAfterChanges}
                          auxChange.nacionalidade=e.target.value
                          setuserAfterChanges(auxChange)
                        }}
                        error={!userAfterChanges.nacionalidade || userAfterChanges.nacionalidade.length<2 ?true:false}
                      />
                    </Box>



                    <label className='form-label fw-bolder text-dark fs-6 required ' htmlFor="phone">Telefone</label>
                    <Box
                      component="form"
                      noValidate
                      autoComplete="off"
                    >
                    <TextField

                      id="phone" 
                      type="tel" 
                      value={userAfterChanges?.telefone_residencial || ""}
                      onChange={handlePhysicalPhoneChange}
                      error={userAfterChanges.telefone_residencial?.length<13 ?true:false}
                      className={`form-control bg-transparent  ${userAfterChanges?.telefone_residencial?.length<13?'is-invalid':''} `}
                    />
                    {userAfterChanges?.telefone_residencial?.length<13 && 
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>Informe o número completo, com DDD</span>
                        </div>
                      </div>
                    }
                        </Box>



                        <label htmlFor="number" className=" form-label fw-bolder text-dark fs-6 required ">Nome do pai</label>
                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                    >
                      <TextField
                        className={'form-control bg-transparent inputNumber '} 
                        id="text" type="text" 
                        value={userAfterChanges.nome_pai}
                        onChange={(e)=>{
                          var auxChange={...userAfterChanges}
                          auxChange.nome_pai=e.target.value
                          setuserAfterChanges(auxChange)
                        }}
                        error={!userAfterChanges.nome_pai || userAfterChanges.nome_pai.length<4 ?true:false}
                      />
                    </Box>






            </div>

            <div className="column">


                <div className='fv-row mb-3'>
                  <label className='form-label fw-bolder text-dark fs-6 required '>CPF (somente números)</label>
                  <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                  >
                  <TextField
                    value={userAfterChanges.cpf}
                    
                    onChange={handleCpfChange}
                      type='text'
                      autoComplete='off'
                      error={!cpfValid && userAfterChanges.cpf.length === 14?true:false}
                      className={`form-control bg-transparent inputNumber ${!cpfValid && userAfterChanges.cpf.length === 14?'is-invalid':''} `}
                    />
                    {!cpfValid && userAfterChanges.cpf.length === 14&&
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>O CPF informado não é válido</span>
                        </div>
                      </div>
                    }
                  </Box>
                  </div>


                  <div className='fv-row mb-3'>

                  <label className='form-label fw-bolder text-dark fs-6 required '>Sexo</label>

                  <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                  >
                    <FormControl fullWidth>
                      <InputLabel  id="">Sexo</InputLabel>
                      <Select
                        id="demo-simple-select"
                        value={userAfterChanges.sexo?userAfterChanges.sexo:'--- Selecione ---'}                        defaultValue="--- Selecione ---"
                        onChange={(e)=>{
                          var auxChange={...userAfterChanges}
                          if(e.target.value!=='--- Selecione ---'){
                            auxChange.sexo=e.target.value
                            setuserAfterChanges(auxChange)
                            setgenderValid(true)
                          }
                          else{
                            auxChange.sexo=null
                            setuserAfterChanges(auxChange)
                            setgenderValid(false)
                            setChangedGender(true)
                          }
                          
                        }}
                        error={!genderValid && changedGender}
                      >
                        <MenuItem value={'--- Selecione ---'}>--- Selecione ---</MenuItem>
                        <MenuItem value={'Feminino'}>Feminino</MenuItem>
                        <MenuItem value={'Masculino'}>Masculino</MenuItem>
                        <MenuItem value={'Outros'}>Outros</MenuItem>
                        <MenuItem value={'Prefiro não declarar'}>Prefiro não declarar</MenuItem>
                      </Select>
                    </FormControl>
                    {!genderValid && changedGender &&
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>Este campo deve ser preenchido</span>
                        </div>
                      </div>
                    }

                  </Box>
                  </div>


                  <div className='dateDIV' >  
                    <label className='form-label fw-bolder text-dark fs-6 required '>Data de nascimento</label>
                    <LocalizationProvider  dateAdapter={AdapterDayjs} >
                      <DatePicker  
                          className='datePick'
                          format="DD/MM/YYYY"
                          disableFuture
                          
                          value={userAfterChanges.nascimento}
                          onChange={(newValue) => handleChangeDate(newValue)}
                
                          
                      />
                    </LocalizationProvider>



                    {(!validBirthday && changedBirth) &&
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>Este campo deve ser preenchido, e o a data precisa ser válida.</span>
                        </div>
                      </div>
                    }


                  </div>

                  <label htmlFor="number" className=" form-label fw-bolder text-dark fs-6 required ">Raça / Cor</label>
                  <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                  >
                    <FormControl fullWidth>
                      <InputLabel  id="">Raça / Cor</InputLabel>
                      <Select
                        id="demo-simple-select"
                        value={userAfterChanges.raca_cor?userAfterChanges.raca_cor:'--- Selecione ---'}                        defaultValue="--- Selecione ---"
                        onChange={(e)=>{
                          var auxChange={...userAfterChanges}
                          auxChange.raca_cor=e.target.value
                          setuserAfterChanges(auxChange)
                        }}
                        error={!userAfterChanges.raca_cor || userAfterChanges.raca_cor == '--- Selecione ---' }
                      >
                        <MenuItem value={'--- Selecione ---'}>--- Selecione ---</MenuItem>
                        <MenuItem value={'Preto'}>Preto</MenuItem>
                        <MenuItem value={'Branco'}>Branco</MenuItem>
                        <MenuItem value={'Pardo'}>Pardo</MenuItem>
                        <MenuItem value={'Asiático'}>Asiático</MenuItem>
                        <MenuItem value={'Outros'}>Outro</MenuItem>
                        <MenuItem value={'Prefiro não declarar'}>Prefiro não declarar</MenuItem>
                      </Select>
                    </FormControl>
                    {(!userAfterChanges.raca_cor || userAfterChanges.raca_cor == '--- Selecione ---' ) && 
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>Este campo deve ser preenchido</span>
                        </div>
                      </div>
                    }

                  </Box>


                  <label htmlFor="number" className=" form-label fw-bolder text-dark fs-6 required ">Naturalidade</label>
                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                    >
                      <TextField
                        className={'form-control bg-transparent inputNumber '} 
                        id="text" type="text" 
                        value={userAfterChanges.naturalidade}
                        onChange={(e)=>{
                          var auxChange={...userAfterChanges}
                          auxChange.naturalidade=e.target.value
                          setuserAfterChanges(auxChange)
                        }}
                        error={!userAfterChanges.naturalidade || userAfterChanges.naturalidade.length<2 ?true:false}
                      />
                    </Box>





                    <label htmlFor="number" className=" form-label fw-bolder text-dark fs-6 required ">Estado civil</label>
                  <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                  >
                    <FormControl fullWidth>
                      <InputLabel  id="">Estado civil</InputLabel>
                      <Select
                        id="demo-simple-select"
                        value={userAfterChanges.estado_civil?userAfterChanges.estado_civil:'--- Selecione ---'}                        defaultValue="--- Selecione ---"
                        onChange={(e)=>{
                          var auxChange={...userAfterChanges}
                          auxChange.estado_civil=e.target.value
                          setuserAfterChanges(auxChange)
                        }}
                        error={!userAfterChanges.estado_civil || userAfterChanges.estado_civil == '--- Selecione ---' }
                      >
                        <MenuItem value={'--- Selecione ---'}>--- Selecione ---</MenuItem>
                        <MenuItem value={'Solteiro'}>Solteiro</MenuItem>
                        <MenuItem value={'Casado / União estável'}>Casado / União estável</MenuItem>
                        <MenuItem value={'Divorciado'}>Divorciado</MenuItem>
                        <MenuItem value={'Outros'}>Outro</MenuItem>
                      </Select>
                    </FormControl>
                    {(!userAfterChanges.estado_civil || userAfterChanges.estado_civil == '--- Selecione ---' ) && 
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>Este campo deve ser preenchido</span>
                        </div>
                      </div>
                    }

                  </Box>



                        <label htmlFor="number" className=" form-label fw-bolder text-dark fs-6 required ">Nome da mãe</label>
                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                    >
                      <TextField
                        className={'form-control bg-transparent inputNumber '} 
                        id="text" type="text" 
                        value={userAfterChanges.nome_mae}
                        onChange={(e)=>{
                          var auxChange={...userAfterChanges}
                          auxChange.nome_mae=e.target.value
                          setuserAfterChanges(auxChange)
                        }}
                        error={!userAfterChanges.nome_mae || userAfterChanges.nome_mae.length<4 ?true:false}
                      />
                    </Box>





                </div>
                
        </div>
      </fieldset>
    </div>

    <div className="address">
      <fieldset className="setUpPersonalData">
        <legend>Endereço</legend>
        <div className="formData">


        <div className="column">

          <label className="form-label fw-bolder text-dark fs-6 required" >CEP:</label>

          <Box
              component="form"
              noValidate
              autoComplete="off"
            >
            <TextField

              className={'form-control bg-transparent inputNumber '} 
              id="zipCode" 
              type="number" 
              onChange={(e)=>{handleCep(e.target.value)}} 
              error={userAfterChanges.cep && !cepOK?true:false}
              value={userAfterChanges.cep}
            /> 
              {userAfterChanges.cep && (userAfterChanges.cep.length === 8 && !cepOK) && <h6 style={{color:'red'}}>*O cep informado não foi encontrado</h6>}
              {userAfterChanges.cep && (userAfterChanges.cep.length>0 &&userAfterChanges.cep.length !== 8) && <h6 style={{color:'red'}}>CEP possui 08 dígitos</h6>}
          </Box>


          <label className="form-label fw-bolder text-dark fs-6 required">Rua:</label>
          <Box
              component="form"
              noValidate
              autoComplete="off"
            >
            <TextField
              className={'form-control bg-transparent inputNumber '} 
              id="street" 
              type="text" 
              value={userAfterChanges.endereco}
              onChange={(e)=>{
                var auxChange={...userAfterChanges}
                auxChange.endereco=e.target.value
                setuserAfterChanges(auxChange)
              }}
              error={userAfterChanges.cep && (userAfterChanges.cep.length===8 && !userAfterChanges.endereco)?true:false}
              disabled={!userAfterChanges.cep || (userAfterChanges.cep.length<8) || !cepOK}
              />

          </Box>
 
          <label htmlFor="number" className=" form-label fw-bolder text-dark fs-6 required ">Número:</label>
          <Box
              component="form"
              noValidate
              autoComplete="off"
          >
            <TextField
              className={'form-control bg-transparent inputNumber '} 
              id="number" type="number" 
              value={userAfterChanges.numero}
              onChange={(e)=>{
                var auxChange={...userAfterChanges}
                var regex = /[!@#$%^&*(),.?":{}|<>\s]/;

                  if(regex.test(e.target.value)){
                      auxChange.numero=''
                      setuserAfterChanges(auxChange)
                  }
                  else if(Number(e.target.value)>0){
                    auxChange.numero=e.target.value
                    setuserAfterChanges(auxChange)
                  }
                  else{
                  auxChange.numero=''
                  setuserAfterChanges(auxChange)
                  }
                  
              }}
              error={userAfterChanges.cep && (userAfterChanges.cep.length===8 && !userAfterChanges.numero)?true:false}
              disabled={!userAfterChanges.cep || (userAfterChanges.cep.length<8) || !cepOK}

            />
          </Box>


          <label className="form-label fw-bolder text-dark fs-6 required">Bairro:</label>
          <Box
              component="form"
              noValidate
              autoComplete="off"
          >
            <TextField
              className={'form-control bg-transparent inputNumber '} 
              id="neighborhood" 
              type="text" 
              value={userAfterChanges.bairro} 
              onChange={(e)=>{
                var auxChange={...userAfterChanges}
                auxChange.bairro=e.target.value
                setuserAfterChanges(auxChange)
              }}
              error={userAfterChanges.cep && (userAfterChanges.cep.length===8 && !userAfterChanges.bairro)?true:false}
              disabled={!userAfterChanges.cep || (userAfterChanges.cep.length<8) || !cepOK}

            />
          </Box>
          </div>

        <div className="column">

          <label className="form-label fw-bolder text-dark fs-6 required">Cidade:</label>
          <Box
              component="form"
              noValidate
              autoComplete="off"
          >
            <TextField
              className={'form-control bg-transparent inputNumber '} 
              id="city" 
              type="text" 
              value={userAfterChanges.cidade} 
              onChange={(e)=>{
                var auxChange={...userAfterChanges}
                auxChange.cidade=e.target.value
                setuserAfterChanges(auxChange)
              }}
              error={userAfterChanges.cep && (userAfterChanges.cep.length===8 && !userAfterChanges.cidade)?true:false}
              disabled={!userAfterChanges.cep || (userAfterChanges.cep.length<8) || !cepOK}

            />
          </Box>


          <label className="form-label fw-bolder text-dark fs-6 required">Estado:</label>
          <Box
              component="form"
              noValidate
              autoComplete="off"
          >
            <TextField
              className={'form-control bg-transparent inputNumber '} 
              id="state" 
              type="text" 
              value={userAfterChanges.estado}
              onChange={(e)=>{
                var auxChange={...userAfterChanges}
                auxChange.estado=e.target.value
                setuserAfterChanges(auxChange)
              }}
              error={userAfterChanges.cep && (userAfterChanges.cep.length===8 && !userAfterChanges.estado)?true:false}
              disabled={!userAfterChanges.cep || (userAfterChanges.cep.length<8) || !cepOK}


              /> 
          </Box>


          <label className="form-label fw-bolder text-dark fs-6 ">Complemento:</label>
          <Box
              component="form"
              noValidate
              autoComplete="off"
          >
            <TextField
              className={'form-control bg-transparent inputNumber '} 
              id="complement" 
              type="text"
              onChange={(e)=>{
                var auxChange={...userAfterChanges}
                auxChange.complemento=e.target.value
                setuserAfterChanges(auxChange)
              }}
            value={userAfterChanges.complemento}
            disabled={!userAfterChanges.cep || (userAfterChanges.cep.length<8) || !cepOK}

            />
          </Box>
        </div>
        </div>
      </fieldset>



    </div>

    <div className='boxButton'>
      <Button className="saveButton"  variant="contained" endIcon={<SaveIcon/>}
        disabled={!hasChanged}
        onClick={()=>{handleSendNewData()}}
      >
        Salvar
      </Button>

    </div>
    
</>





  )
}


export {Step7Vestibular}