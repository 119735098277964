/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState,useEffect} from 'react'
import {Link} from 'react-router-dom'

import API from '../../../API_CONFIGS/redux/API'
import { useNavigate } from 'react-router-dom'
import {toast} from 'react-toastify' 

import { cpf } from 'cpf-cnpj-validator'; 
import { login_adm } from '../../../AUTH_CONFIGS/auth_adm'
// import { loginAuth } from '../../../../AUTH_CONFIGS/Auth'
import { useAuth } from '../../../AUTH_CONFIGS/Auth'

export default function Login_adm() {
  const navigate = useNavigate()
  const auth = useAuth();

  const [email,setEmail] = useState('')
  const [password,setPassword] = useState('')

  const [validEmail,setValidEmail]= useState(false)
  const [cpfValid,setCpfValid] = useState(false)

  

  const handleEmail = (e) => {
    setEmail(e.target.value)
  }
  const handlePassword = (e) => {
      setPassword(e.target.value);
  };
  const handleLogin = async () => {
    const data = {
        email:email,
        password:password,
    }
    API.post('administrativo/login_adm', data)
    .then((res)=>{
      toast.success(res.data.message?res.data.message:'Logado com sucesso')
        if(res.data.user && res.data.token){
            return login_adm(res.data.user,res.data.token,navigate)
          }
            return toast.info("tente novamente")
    })
    .catch((error)=>{
        if (error.response) {
            console.error('Server error:', error.response.data); // Log the custom error message
            toast.error(error.response.data.message?error.response.data.message:'Erro ao logar')
          } else {
            console.error('Request error:', error.message);
            toast.error(error.message?error.message:'Erro ao logar')
          }
    })
  };
  
  const[loginReady,setloginReady]=useState(false)
 
  useEffect(()=>{
    const emailRegex = /^[^\s@]+@[^\s@]+\.(?:co|com|br)$/i;
    if(emailRegex.test(email)){
      setValidEmail(true)
    }
    else{
      setValidEmail(false)
    }



    let minhaString = password
    minhaString = minhaString.replace(/[ .-]/g, "");
    let isValidAux = false
    isValidAux = cpf.isValid(minhaString)
    if (isValidAux) {
      setCpfValid(true);
    } 
    else {
      setCpfValid(false);
    }



  },[password,email])


  useEffect(()=>{
    if(validEmail && password){
      return setloginReady(true)
    }
      return  setloginReady(false)
  },[validEmail,password])


  return (
    <div
      className='card'>


      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Painel administrativo</h1>
        <div className='text-gray-500 fw-semibold fs-6'>Digite seu e-mail e senha</div>
      </div>
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark required'>Email</label>
        <input
          placeholder='Digite seu Email'
          className={`form-control bg-transparent ${email.length>0 && !validEmail && 'is-invalid'} ${email.length>0 && validEmail && 'is-valid'}`}
          type='text'
          name='email'
          autoComplete='off'
          value={email}
          onChange={(e) => handleEmail(e)}
          />
         {!validEmail && email.length>0&&
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>O Email informado não é válido</span>
              </div>
            </div>
          }

       
      </div>
      <div className='fv-row mb-3'>
  <label htmlFor='password' className='form-label fw-bolder text-dark fs-6 mb-0 required'>SENHA</label>
  <input
    type='password'
    id='password'
    name='password'
    autoComplete='off'
    className={`form-control bg-transparent ${password.length===11 && !cpfValid && 'is-invalid'} ${password.length===11 && cpfValid && 'is-valid'}`}
    placeholder='Senha'
    value={password}
    onChange={(e) => handlePassword(e)}
    />

</div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        {/* begin::Link */}
        <Link to='/auth/forgot-password' className='link-primary'>Esqueci minha senha</Link>
        {/* end::Link */}

      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='button'
          className='btn btn-primary'
          disabled={!loginReady}
          onClick={()=>{handleLogin()}}
        >
          <span className='indicator-label'>Continue</span>
                   
        </button>
      </div>
      {/* end::Action */}

      <div className='text-gray-500 text-center fw-semibold fs-6'>
        Não tem conta?{' '}
        <Link to='/cadastro_administrativo' className='link-primary'>Novo Cadastro</Link>
      </div>
    </div>
  )
}
