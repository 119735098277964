import React, {useEffect, useRef, useState} from 'react'
import { KTSVG } from '../../_metronic/helpers'
import {StepperComponent} from '../../_metronic/assets/ts/components'
import { useNavigate } from 'react-router-dom'
import { Step1Vestibular } from './components/steps/Step1Vestibular'
import { Step2Vestibular } from './components/steps/Step2Vestibular'
import { Step3Vestibular } from './components/steps/Step3Vestibular'
import { Step4Vestibular } from './components/steps/Step4Vestibular' 
import { Step5Vestibular } from './components/steps/Step5Vestibular'
import { Step6Vestibular } from './components/steps/Step6Vestibular'
import { Step7Vestibular } from './components/steps/Step7Vestibular'
import { Step8Vestibular } from './components/steps/Step8Vestibular'
import Step9Vestibular  from './components/steps/Step9Vestibular' 

import useWindowSize from '../../API_CONFIGS/useWindowSize'

import Loading from '../components/loading/Loading'

import API from '../../API_CONFIGS/redux/API'
import { toast } from 'react-toastify'

import { useAuth } from '../../AUTH_CONFIGS/Auth'

import HeaderUser from '../components/headerUser/HeaderUser'

import next from "../../media/icons/next.svg"
import nextComplete from "../../media/icons/next-completed.svg"

import finished from '../../media/icons/celebration.svg'


export default function Vestibular(){
  const {currentUser} = useAuth()
  const navigate = useNavigate()

  const { width } = useWindowSize()
  const isMobile = width < 800

  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  
  const [currentStep, setcurrentStep] = useState(0)
  const [blockTest, setBlockTest] = useState(false)
  
  const [doNotNeedTest, setdoNotNeedTest] = useState(false)
  const [approved,setapproved]=useState(false)
  
  const [allPersonalDataOK,setAllPersonalDataOK]=useState(false)
  const [canstep0,setcanstep0]=useState(true) 
  const [canstep1,setcanstep1]=useState(true) 
  const [canstep2,setcanstep2]=useState(false) 
  const [canstep3,setcanstep3]=useState(false) 
  const [canstep4,setcanstep4]=useState(false) 
  const [canstep5,setcanstep5]=useState(false) 
  const [canstep6,setcanstep6]=useState(false) 
  const [canstep7,setcanstep7]=useState(false) 
  const [canstep8,setcanstep8]=useState(false) 
  const [canstep9,setcanstep9]=useState(false) 
  const [hasalldata,sethasalldata]=useState(false) 

    const [allSteps, setAllSteps] = useState({
    
    cpf: '',
    setCpf: (cpf:string)=> setAllSteps(prevState=>({...prevState, cpf})),
    
    firstName: '',
    setFirstName: (firstName: string) => setAllSteps(prevState => ({ ...prevState, firstName })),

    lastName: '',
    setLastName: (lastName:string)=> setAllSteps(prevState=>({...prevState, lastName})),
  
    email:'',
    setEmail: (email:string)=> setAllSteps(prevState=>({...prevState, email})),
  
    phone: '',
    setPhone: (phone:string)=> setAllSteps(prevState=>({...prevState, phone})),

  })
  
  const [tipo_redacao,set_tipo_redacao]=useState('')
  const [valid_tipo_redacao,setValid_tipo_redacao]=useState(false)
  const [OKchosenTheme,setOKchosenTheme]=useState(false)
  const [chosenTheme,setchosenTheme]=useState(-1)
  const [titleTest,settitleTest]=useState('')
  const [textTest,settextTest]=useState('')
  const [OKTestFiles,setOKTestFiles]=useState(false)
  const [imagemTest,setimagemTest]=useState('')
  const [wordCount,setwordCount]=useState(0)

  const [startCount,setstartCount]=useState(3600)
  const [loading, setLoading] = useState<boolean>(false); // Example boolean state
  const [loadingText, setLoadingText] = useState('');

  const [documentacaoStatus,setDocumentacaoStatus]=useState({aceito:0,id:-1})
  const [selectedCourse,setSelectedCourse]=useState({id:-1,nome:'', duracao: '', valor: ''})
  const [inscricao,setInscricao]=useState({id:-1,status_id:-1, payment_informed:false, payment_complete:false})

  const [isWaitingTestResult,setisWaitingTestResult]=useState(false)
  const [redacao,setRedacao]=useState({
    id:-1,
    vestibular_id:-1,
    tema_id:-1,
    status_id:1,
    tipo:"",
    titulo:null,
    texto:null,
    imagem:null,
    cronometro:"",
    nota:null,
    created_at:"",
    updated_at:"",
    set:false
})



  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if(currentStep > 0){
      setcurrentStep(currentStep-1)
    }

  }

  const EnviarStep = () => {
    // if(currentStep<=1){
    //   setcanstep0(true)
    //   return setcanstep1(true)
    // } 
      return setcurrentStep(currentStep+1)
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])



  const handleEnviarForm = ()=>{
    toast.info('dados obtidos: ' + JSON.stringify(allSteps))

    let data = {...allSteps}
    API.post('/createaccount', data)
    .then((result)=>{

      toast.success('tudo ok com o envio')

      navigate('/')

    })
    .catch((err)=>{ 
      toast.error('ERRO AO ENVIAR')
    })
  }

  useEffect(()=>{
    if(currentStep === 8){
      // handleEnviarForm()
    }
  },[currentStep])





  useEffect(()=>{
    if(OKchosenTheme){
      startTest()
    }
  },[OKchosenTheme])


  const setCronometro = ()=>{
    const hours = Math.floor(startCount / 3600).toString().padStart(2, '0'); // format hours as "HH"
    const minutes = Math.floor((startCount % 3600) / 60).toString().padStart(2, '0'); // format minutes as "mm"
    const seconds = (startCount % 60).toString().padStart(2, '0'); // format seconds as "ss"
    const time = `${hours}:${minutes}:${seconds}`;
      return time
  }
  const timeToSeconds = (timeString:string)=> {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    return (hours * 3600) + (minutes * 60) + seconds;
  }
  

  const startTest = ()=>{
    if(currentUser){
      const data = {
        tipo:tipo_redacao, // 'D' or 'F'
        titulo:null, 
        texto:null,
        tema_id:chosenTheme,
        imagem:null, 
        userID:currentUser.id,
        cronometro:setCronometro(),
      }
      API.post('/course/starttest',data)
      .then((res)=>{
        if(res.data.status === 'success'){
          // toast.success(res.data.message)
        }
        else{
          toast.info(res.data.message)
        }
      })
      .catch((err)=>{
        console.log(err);
        toast.error(err)
      })
    }
  }
  const autoUpdateTest=()=>{
    if(currentUser){
      const data ={
        // tipo:tipo_redacao, // 'D' or 'F'
        titulo:tipo_redacao==='D'?titleTest:null, 
        texto:tipo_redacao==='D'?textTest:null,
        tema_id:chosenTheme,
        // imagem:tipo_redacao==='F'?true:null, 
        userID:currentUser.id,
        cronometro:setCronometro(),
        updated_at:new Date(),
        isAutoUpdate:true,


      }
      API.post('/course/sendtest',data)
      .then((res)=>{
        if(res.data.status === 'success'){
        }
        else{
          toast.info(res.data.message)
        }
      })
      .catch((err)=>{
        console.log(err);
        toast.error(err)
      })
    }

  }

  const sendTest = ()=>{  
    if(currentUser){
      const data ={
        // tipo:tipo_redacao, // 'D' or 'F'
        titulo:tipo_redacao==='D'?titleTest:null, 
        texto:tipo_redacao==='D'?textTest:null,
        tema_id:chosenTheme,
        imagem:tipo_redacao==='F'?true:null, 
        userID:currentUser.id,
        cronometro:setCronometro(),
        updated_at:new Date(),


      }
      API.post('/course/sendtest',data)
      .then((res)=>{
        if(res.data.status === 'success'){
          toast.success(res.data.message)
          window.location.reload()
        }
        else{
          toast.info(res.data.message)
        }
      })
      .catch((err)=>{
        console.log(err);
        toast.error(err)
      })

    }
  }


  useEffect(()=>{
    if(currentUser){
      const data ={
        userID:currentUser.id
      }
      API.post('/aluno/payment_data',data)
      .then((res)=>{
        setSelectedCourse(res?.data?.curso || {})
        setInscricao(res?.data?.inscricao || {})        
      })
      .catch((err)=>{
        console.log(err);
        toast.error(err)
      })
    }
    
  },[])


  useEffect(()=>{
    if(currentUser){
      const data ={
        userID:currentUser.id
      }
      API.post('/course/vestibular_status',data)
      .then((res)=>{
        if(res.data.status==='success'){
          // toast.success(res.data.message?res.data.message:'Sucesso')
          setisWaitingTestResult(res.data.isWaitingTestResult)

          if(res.data.doNotNeedTest){
            setdoNotNeedTest(true)
            setcurrentStep(5)
            setOKchosenTheme(false)
          }
          if(res.data.redacao){
            setRedacao(res.data.redacao)
            setstartCount(timeToSeconds(res.data.redacao.cronometro))
            setcurrentStep(3) 
            setchosenTheme(res.data.redacao.tema_id)
            set_tipo_redacao(res.data.redacao.tipo)
            settextTest(res.data.redacao.texto)
            settitleTest(res.data.redacao.titulo)
            setOKchosenTheme(true)
            

            //  OKchosenTheme={OKchosenTheme}
            //  setOKchosenTheme={setOKchosenTheme}
            //  chosenTheme={chosenTheme} 
            //  titleTest={titleTest}
            //  settitleTest={settitleTest}
            //  textTest={textTest}
            //  settextTest={settextTest}
            //  wordCount={wordCount}
            //  setwordCount={setwordCount}
            //  redacao={redacao}

          }
        }
        else{
          toast.info(res.data.message?res.data.message:'Erro ao obter dados do vestibular. Contate o suporte.')
        }
      })
      .catch((err)=>{
        console.log(err);
        toast.error(err)
      })
    }
    
  },[])

  useEffect(()=>{
    if(currentUser){
      const data ={
        userID:currentUser.id
      }
      API.post('/course/documentacao_candidato',data)
      .then((res)=>{
        if(res.data.status==='success'){
          // toast.success(res.data.message?res.data.message:'Sucesso')
          if(res.data.documentacao){
            setDocumentacaoStatus(res.data.documentacao)
          }
        }
        else{
          toast.info(res.data.message?res.data.message:'Erro ao obter dados do vestibular. Contate o suporte.')
        }
      })
      .catch((err)=>{
        console.log(err);
        toast.error(err)
      })
    }
    
  },[])



  useEffect(()=>{
    if(!currentUser){
        navigate('/login')
    }
    if(currentUser && currentUser.id && currentUser.tipo_usuario_id){
        navigate('/painel_administrativo')
    }
},[currentUser])





  useEffect(()=>{
    // exists any test
    if(redacao.status_id>1){
      // exist any file sent or text written
      if(redacao.texto || redacao.imagem){
        setBlockTest(true)
        setcanstep4(true)
        setcurrentStep(4) 
      }
      else{
        setcanstep4(false) 
      }
    }
    
    if(inscricao?.status_id===6 || inscricao?.status_id===9 || inscricao?.status_id===12){
      setcurrentStep(7)
      setcanstep5(true)
      setcanstep6(true)
      setcanstep7(true)
    }
    if(inscricao?.status_id===9){
      setcanstep5(true)
      setcanstep6(true)
      setcanstep7(true)
      setcanstep8(true)
    }

    if(inscricao?.status_id===4 || inscricao?.status_id===5){
      setcurrentStep(5)
      setcanstep5(true)
    }
    if(inscricao?.status_id===13 ){
      setcurrentStep(6)
      setcanstep5(true)
      setcanstep6(true)
    }
    if(inscricao?.status_id===10){
      setcurrentStep(8)
      setcanstep5(true)
      setcanstep6(true)
      setcanstep7(true)
      setcanstep8(true)
    }



    
  },[isWaitingTestResult, inscricao])
  useEffect(()=>{
    // exists any test
    if(approved){
        return setcanstep5(true) 
      }
      return setcanstep5(false) 
  },[approved])
  useEffect(()=>{
    // exists any test
    if(documentacaoStatus && documentacaoStatus.aceito){
        return setcanstep6(true) 
      }
      return setcanstep6(false) 
  },[documentacaoStatus])


  useEffect(()=>{
    if (valid_tipo_redacao) {
      setcanstep2(true)
    }else{
      setcanstep2(false)
    }

  },[valid_tipo_redacao])




  useEffect(() => {

    if (currentUser) {
      API.post('/aluno/get_candidato_data_by_id', { userID: currentUser.id })
      .then((res)=>{
        if (res.data.status === 'success') {

          var auxCandidato = {...res.data.candidato}
          var isDBinvalid = false
            if(!auxCandidato.nascimento || !auxCandidato.cep || !auxCandidato.endereco || !auxCandidato.numero || !auxCandidato.complemento || !auxCandidato.bairro || !auxCandidato.cidade ||
              !auxCandidato.estado || !auxCandidato.sexo || !auxCandidato.nome || !auxCandidato.email ||
              !auxCandidato.celular || !auxCandidato.cpf
              ){
              isDBinvalid=true
            }


            if(isDBinvalid){
              return sethasalldata(false)
            }
            return sethasalldata(true)

        }
      })
      .catch((err)=>{

      })
    }
}, []) 


useEffect(()=>{
  if(allPersonalDataOK){
    return setcanstep7(true)
  }
  return setcanstep7(false)
},[allPersonalDataOK])


























useEffect(()=>{
  if(inscricao?.status_id===11){
    setcurrentStep(99)
    setcanstep0(false)
    setcanstep1(false)
    setcanstep2(false)
    setcanstep3(false)
    setcanstep4(false)
    setcanstep5(false)
    setcanstep6(false)
    setcanstep7(false)
    setcanstep8(false)
    setcanstep9(true)
  }
},[
  inscricao,
  canstep0,
  canstep1,
  canstep2,
  canstep3,
  canstep4,
  canstep5,
  canstep6,
  canstep7,
  canstep8,
  canstep9,
])






  return (
    <>


      <Loading active={loading} text={loadingText}  />



      <HeaderUser/>




      
    <div className='' style={{
      width:'100%',
      display:'flex',
      justifyContent:'center',
      alignItems:'center',



    }}>
      <div className=''
      
      style={{
        width:isMobile?'100%':'70%',
        border:'1px solid #ccc',
        borderRadius:'10px',
        padding:'10px',

  

      }}>
        <div
          // ref={currentStep}
          className='stepper stepper-links d-flex flex-column '
          id='kt_create_account_stepper'
          style={{
            // backgroundColor:'rgba(100, 100, 100, 0.05)',

          }}
          
        >
          <div 
            className='stepper-nav mb-5' 
            style={{backgroundColor: 'rgba(100, 100, 100, 0)', borderRadius:'5px', width:'100%', borderBottom:'1px solid #ccc', paddingBottom:'10px', marginBottom:'20px', 
            maxHeight:'20vh',
            overflowY:'auto'
          }}
          >
            

            <div 
            className={"stepper-item" + (currentStep === 0 ? " current" : "") + (currentStep > 0 ? " completed" : "")} 
            data-kt-stepper-element='nav' 
            onClick={()=>{
              if(!blockTest && !doNotNeedTest && canstep0){
                setcurrentStep(0)
              }
              else{
                toast.info('Você já fez a redação')
              }
              }
            }
            style={{
              cursor:'pointer',
              // margin:'0px',
              // backgroundColor: 'red',
          }}

            
            
            >
              <h3 className='stepper-title'>1. &nbsp;&nbsp;  Vestibular</h3>
            </div>

            <img src={currentStep > 0 ? nextComplete : next} width='10px' height='10px' />
            

            <div 
            className={"stepper-item" + (currentStep >= 1 && currentStep < 4 ? " current" : "") + (currentStep >= 4 ? " completed" : "")} 
            data-kt-stepper-element='nav'
            onClick={()=>{
              if(canstep1){
                if(!blockTest && !doNotNeedTest){
                  setcurrentStep(1)
                }
                else{
                  toast.info('Você já fez a redação')
                }
              }
            }
            }
            style={{cursor:'pointer'}}
            >
              <h3 className='stepper-title'>2. &nbsp;&nbsp;  Redação</h3>
            </div>
            
            <img src={currentStep >= 4 ? nextComplete : next} width='10px' height='10px' />


            <div 
            className={"stepper-item" + (currentStep === 4 ? " current" : "") + (currentStep > 4 ? " completed" : "")} 
            data-kt-stepper-element='nav'
            onClick={()=>{
              if(canstep4){
                if(!doNotNeedTest){
                  setcurrentStep(4)
                }
              }else{
                toast.info('Etapa aguardando liberação.')
              }
              }
            }
            style={{cursor:'pointer'}}
            >
              <h3 className='stepper-title'>3. &nbsp;&nbsp;  Resultado do vestibular</h3>
            </div>

            <img src={currentStep > 4 ? nextComplete : next} width='10px' height='10px' />

            <div 
            className={"stepper-item" + (currentStep === 5 ? " current" : "") + (currentStep > 5 ? " completed" : "")} 
            data-kt-stepper-element='nav'
            onClick={()=>{
              if(canstep5){ 
                setcurrentStep(5)
              }else{
                  toast.info('Etapa aguardando liberação.')
              }
            }}
            style={{cursor:'pointer'}}
            >
              <h3 className='stepper-title'>4. &nbsp;&nbsp;  Documentação</h3>
            </div>

            <img src={currentStep > 5 ? nextComplete : next} width='10px' height='10px' />

            <div 
            className={"stepper-item" + (currentStep === 6 ? " current" : "") + (currentStep > 6 ? " completed" : "")} 
            data-kt-stepper-element='nav'
            onClick={()=>{
              if(canstep6){
                setcurrentStep(6)
              }else{
                toast.info('Etapa aguardando liberação.')
              }
              }
            }
            style={{cursor:'pointer'}}
            >
              <h3 className='stepper-title'>5. &nbsp;&nbsp;  Dados complementares</h3>
            </div>

            <img src={currentStep > 6 ? nextComplete : next} width='10px' height='10px' />

            <div 
            className={"stepper-item" + (currentStep === 7 ? " current" : "") + (currentStep > 7 ? " completed" : "")} 
            data-kt-stepper-element='nav'
            onClick={()=>{
              if(canstep7){
                setcurrentStep(7)
              }else{
                toast.info('Etapa aguardando liberação.')
              }
              } 
            } 
            style={{cursor:'pointer'}}
            >
              <h3 className='stepper-title'>6. &nbsp;&nbsp;  Pagamentos</h3>
            </div>

            <img src={currentStep > 7 ? nextComplete : next} width='10px' height='10px' />

            <div 
            className={"stepper-item" + (currentStep === 8 ? " current" : "") + (currentStep > 8 ? " completed" : "")} 
            data-kt-stepper-element='nav'
            onClick={()=>{
              if(canstep8){
                setcurrentStep(8)
              }else{
                toast.info('Etapa aguardando liberação.')
              }
              }
            }
            style={{cursor:'pointer'}}
            >
              <h3 className='stepper-title'>7. &nbsp;&nbsp;  Assinar Documentos</h3>
            </div>  

            <img src={currentStep > 9 ? nextComplete : next} width='10px' height='10px' />

            <div 
            className={"stepper-item" + (currentStep > 9 ? " current" : "") + (currentStep > 9 ? " completed" : "")} 
            data-kt-stepper-element='nav'
            onClick={()=>{
              if(canstep9){
                setcurrentStep(99)
              }else{
                toast.info('Etapa aguardando liberação.')
              }
              }
            }
            style={{cursor:'pointer'}}
            >
              <h3 className='stepper-title'>8. &nbsp;&nbsp;  Tudo certo</h3>
            </div>  



          </div>

            <>
          {/* <Formik validationSchema={currentSchema} initialValues={initValues} onEnviar={EnviarStep}> */}
            {/* {() => ( */}
              {/* <Form className='' id='kt_create_account_form'> */}
              {
                currentStep === 0 &&
              
                <div >
                  <Step1Vestibular 

                  />
                </div>

              }

              {
                currentStep === 1 &&
                <div >
                    <Step2Vestibular
                    tipo_redacao={tipo_redacao} 
                    set_tipo_redacao={set_tipo_redacao}
                    valid_tipo_redacao={valid_tipo_redacao}
                    setValid_tipo_redacao={setValid_tipo_redacao}
                    canstep2={canstep2}
                    setcanstep2={setcanstep2}
                    />                
                </div>

                }
              {
                currentStep === 2 &&
                <div >
                    <Step3Vestibular
                    tipo_redacao={tipo_redacao}
                    set_tipo_redacao={set_tipo_redacao}
                    OKchosenTheme={OKchosenTheme}
                    setOKchosenTheme={setOKchosenTheme}
                    chosenTheme={chosenTheme}
                    setchosenTheme={setchosenTheme}
                    />                
                </div>

                }
              {
                currentStep === 3 &&
                <div >
                    <Step4Vestibular
                        tipo_redacao={tipo_redacao}
                        OKchosenTheme={OKchosenTheme}
                        setOKchosenTheme={setOKchosenTheme}
                        chosenTheme={chosenTheme} 
                        titleTest={titleTest}
                        settitleTest={settitleTest}
                        textTest={textTest}
                        settextTest={settextTest}
                        wordCount={wordCount}
                        setwordCount={setwordCount}
                        redacao={redacao}

                        OKTestFiles={OKTestFiles}
                        setOKTestFiles={setOKTestFiles}

                        startCount={startCount}
                        setstartCount={setstartCount}
                        setCronometro={setCronometro}
                        autoUpdateTest={autoUpdateTest}
                    />                
                </div>

                }
              {
                currentStep === 4 &&
                <div >
                    <Step5Vestibular
                    hasalldata={hasalldata}
                    approved={approved}
                    setapproved={setapproved}
                    
                    />                
                </div>

                }
              {
                currentStep === 5 &&
                <div >
                    <Step6Vestibular
                    redacao={redacao}
                    />                
                </div>

                }
              {
                currentStep === 6 &&
                <div >
                    <Step7Vestibular
                    allPersonalDataOK={allPersonalDataOK}
                    setAllPersonalDataOK={setAllPersonalDataOK}
                    />                
                </div>

                }
              {
                currentStep === 7 &&
                <div >
                    <Step8Vestibular 
                    selectedCourse={selectedCourse}
                    inscricao={inscricao}
                    setLoading={setLoading}
                    />                
                </div>

                }
              {
                currentStep === 8 &&
                <div >
                    <Step9Vestibular 
                    setLoading={setLoading}
                    setLoadingText={setLoadingText}

                    />                
                </div>

                }
              {
                currentStep === 99 &&
                <div style={{
                  display:'flex',
                  flexDirection:'column',
                  width:'100%',  
                  padding:'50px',
                  justifyContent:'center',
                  alignItems:'center',
                  textAlign:'center',
                  fontSize:'20pt',
                  gap:'20px',
                  
                }}>
                    <img src={finished} style={{width:'100px', }}  />
                    <div style={{fontWeight:'bold'}}>Uhuu! Tudo certo por aqui!</div>                    
                    <div style={{margin:'30px auto auto auto', fontSize:'16pt', fontWeight:'bold', }}>Sua inscrição já está concluída.</div>
                    <div style={{margin:'30px auto auto auto', fontSize:'12pt', }}>Caso tenha alguma dúvida, basta entrar e contato.</div>


                </div>

                }
              



                <div className='d-flex flex-stack '>
                
                  {
                    currentStep === 0 && 
                    <>
                    <div className='mr-2'>
                      <button onClick={prevStep} type='button' className='btn btn-lg btn-light-primary me-3'  disabled>
                      <span className='indicator-label'>
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr063.svg'
                          className='svg-icon-4 me-1'
                        />
                        Voltar
                        </span>
                      </button>
                    </div>

                  <div>
                    <button  className='btn btn-lg btn-primary me-3' onClick={EnviarStep}>
                      <span className='indicator-label'>
                        Continue
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-3 ms-2 me-0'
                        />
                      </span>
                    </button>
                  </div>
                  </>
                  }
                  {
                    currentStep === 1 && 
                    <>
                    <div className='mr-2'>
                      <button onClick={prevStep} type='button' className='btn btn-lg btn-light-primary me-3'  >
                      <span className='indicator-label'>
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr063.svg'
                          className='svg-icon-4 me-1'
                        />
                        Voltar
                        </span>
                      </button>
                    </div>


                  <div>
                    <button disabled={!valid_tipo_redacao}  className='btn btn-lg btn-primary me-3' onClick={EnviarStep}>
                      <span className='indicator-label'>
                        Continue
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-3 ms-2 me-0'
                        />
                      </span>
                    </button>
                  </div>
                  </>

                  }
                  {
                    currentStep === 2 && 
                    <>
                    <div className='mr-2'>
                      <button onClick={prevStep} type='button' className='btn btn-lg btn-light-primary me-3'  disabled={OKchosenTheme}>
                      <span className='indicator-label'>
                        <KTSVG 
                          path='/media/icons/duotune/arrows/arr063.svg'
                          className='svg-icon-4 me-1'
                        />
                        Voltar
                        </span>
                      </button>
                    </div>

                  <div>
                    <button disabled={!OKchosenTheme}  className='btn btn-lg btn-primary me-3' onClick={EnviarStep}>
                      <span className='indicator-label'>
                        Continue
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-3 ms-2 me-0'
                        />
                      </span>
                    </button>
                  </div>
                  </>
                  }
                  {
                    currentStep === 3 && 
                    <>
                    <div className='mr-2'>
                      <button onClick={prevStep} type='button' className='btn btn-lg btn-light-primary me-3'  disabled>
                      <span className='indicator-label'>
                        <KTSVG 
                          path='/media/icons/duotune/arrows/arr063.svg'
                          className='svg-icon-4 me-1'
                        />
                        Voltar
                        </span>
                      </button>
                    </div>

                  <div>
                    <button disabled={
                      tipo_redacao==='D'?
                        wordCount<150 || !titleTest 
                          :
                        !OKTestFiles
                      }  className='btn btn-lg btn-primary me-3' 
                      onClick={()=>{sendTest()}
                      }
                    >
                      <span className='indicator-label'>
                        Continue
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-3 ms-2 me-0'
                        />
                      </span>
                    </button>
                  </div>
                  </>
                  }
                  {
                    currentStep === 4 && 
                    <>
                    <div className='mr-2'>
                      <button onClick={prevStep} type='button' className='btn btn-lg btn-light-primary me-3'  disabled>
                      <span className='indicator-label'>
                        <KTSVG 
                          path='/media/icons/duotune/arrows/arr063.svg'
                          className='svg-icon-4 me-1'
                        />
                        Voltar
                        </span>
                      </button>
                    </div>

                  <div>
                    <button disabled={!approved}  className='btn btn-lg btn-primary me-3' onClick={EnviarStep}>
                      <span className='indicator-label'>
                        Continue
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-3 ms-2 me-0'
                        />
                      </span>
                    </button>
                  </div>
                  </>
                  }
                  {
                    currentStep === 5 && 
                    <>
                    <div className='mr-2'>
                      <button onClick={prevStep} type='button' className='btn btn-lg btn-light-primary me-3'  >
                      <span className='indicator-label'>
                        <KTSVG 
                          path='/media/icons/duotune/arrows/arr063.svg'
                          className='svg-icon-4 me-1'
                        />
                        Voltar
                        </span>
                      </button>
                    </div>

                  <div>
                    <button disabled={!documentacaoStatus.aceito}  className='btn btn-lg btn-primary me-3' onClick={EnviarStep}>
                      <span className='indicator-label'>
                        Continue
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-3 ms-2 me-0'
                        />
                      </span>
                    </button>
                  </div>
                  </>
                  }
                  {
                    currentStep === 6 && 
                    <>
                    <div className='mr-2'>
                      <button onClick={prevStep} type='button' className='btn btn-lg btn-light-primary me-3'  >
                      <span className='indicator-label'>
                        <KTSVG 
                          path='/media/icons/duotune/arrows/arr063.svg'
                          className='svg-icon-4 me-1'
                        />
                        Voltar
                        </span>
                      </button>
                    </div>

                  <div>
                    <button 
                      // disabled={!allPersonalDataOK}  
                      disabled={!canstep7}  
                      
                      className='btn btn-lg btn-primary me-3' onClick={EnviarStep}>
                      <span className='indicator-label'>
                        Continue
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-3 ms-2 me-0'
                        />
                      </span>
                    </button>
                  </div>
                  </>
                  }
                  {
                    currentStep === 7 &&
                    <>
                    <div className='mr-2'>
                      <button onClick={prevStep} type='button' className='btn btn-lg btn-light-primary me-3'  >
                      <span className='indicator-label'>
                        <KTSVG 
                          path='/media/icons/duotune/arrows/arr063.svg'
                          className='svg-icon-4 me-1'
                        />
                        Voltar
                        </span>
                      </button>
                    </div>

                  <div>
                    <button disabled={!canstep8}  className='btn btn-lg btn-primary me-3' onClick={EnviarStep}>
                      <span className='indicator-label'>
                        Continue
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-3 ms-2 me-0'
                        />
                      </span>
                    </button>
                  </div>
                  </>
                  }
                  {
                    currentStep === 8 &&
                    <>
                    <div className='mr-2'>
                      <button onClick={prevStep} type='button' className='btn btn-lg btn-light-primary me-3'  >
                      <span className='indicator-label'>
                        <KTSVG 
                          path='/media/icons/duotune/arrows/arr063.svg'
                          className='svg-icon-4 me-1'
                        />
                        Voltar
                        </span>
                      </button>
                    </div>

                  <div>
                    <button disabled={true}  className='btn btn-lg btn-primary me-3' onClick={EnviarStep}>
                      <span className='indicator-label'>
                        Continue
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-3 ms-2 me-0'
                        />
                      </span>
                    </button>
                  </div>
                  </>
                  }

                </div>
          </>
        </div>

       </div>
       
    </div>
    </>
  )
}

