import React from "react";
import {useState, useEffect} from 'react'
import './step5.css'

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';



import API from "../../../../API_CONFIGS/redux/API";
import { toast } from "react-toastify";
import { log } from "console";
import { useAuth } from "../../../../AUTH_CONFIGS/Auth";

interface step5interface{
  approved:boolean,
  setapproved:(approved:boolean)=>void,
  hasalldata:boolean,
}


const steps = [
  {
    label: 'Documentação',
    description: `Enviar documentação pessoal e acadêmica, na próxima página.`,
  },
  {
    label: 'Contrato',
    description:
      'Aceitar o contrato de matrícula.',
  },
  {
    label: 'Matrícula',
    description: `Realizar pagamento da matrícula.`,
  },
  {
    label: 'Comece a estudar!',
    description: `Prontinho, você já é aluno da Faculdade Rebouças!`,
  },
];


const Step5Vestibular: React.FC<step5interface> = ({approved,setapproved,hasalldata}) => {
    const {currentUser} = useAuth()

    const [isWaiting,setisWaiting]=useState(true)
    const [testResult,setTestResult]=useState({
      id:-1,
      vestibular_id:-1,
      tema_id:-1,
      status_id:-1,
      tipo:'',
      titulo:null,
      texto:null,
      imagem:null,
      cronometro:'',
      nota:0,
      created_at:'',
      updated_at:'',
      set:true    
    })

    const username = currentUser?currentUser.nome:'Erro ao obter dados do aluno'

    useEffect(()=>{
      if(currentUser){
        const data = {
          userID:currentUser.id
        }

        API.post('/course/test_result',data)
        .then((res)=>{
          if(res.data.status==='success'){
            if(res.data.redacao.nota){
                setisWaiting(false)
              if(res.data.redacao.nota>=500){
                setapproved(true)
              }
            }
            setTestResult(res.data.redacao)
            // toast.success(res.data.message)
          }
          else{
            toast.error(res.data.message)
          }
        })
        .catch((err)=>{
          console.log(err);
          toast.error(err)
        })
      } 
    },[])

    const handleTestRemake = ()=>{
      toast.info('refazendo redação')
    }


    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleReset = () => {
      setActiveStep(0);
    };

    


  return (
<div className="result-container">
      {isWaiting ? 
      
        (
          <div className="waiting-container">
            <h1>Sua redação está sendo corrigida. Você poderá ver o resultado em até 48 horas.</h1>
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>

          </div>
        ) 
      
      : 
      
        (<>
          {
            approved ?
          <div className="approved-container">
            <h1>Uhuuu, {username}, O resultado de sua redação já saiu!</h1>
                <div className="approved-card">
                      <h2>{testResult.nota} | APROVADO!</h2>
                </div>
            <div className="steps-container">
              <h3>Parabéns, {username}!</h3>
              <p>Agora siga os seguintes passos para continuar sua matrícula:</p>
            </div>
            <div style={{width:'100%', textAlign:'center'}}>
                <Box >
                  <Stepper 
                      activeStep={-1} 
                      orientation="vertical">
                    {steps.map((step, index) => {
                      const stepProps: { completed?: boolean } = {};
                      if(hasalldata && step.label==='Documentação'){stepProps.completed = true;}
                      return (
                      <Step key={step.label} {...stepProps}>
                        <StepLabel>
                          {step.label}
                          
                          <Typography>
                            {step.description}
                          </Typography>
                        </StepLabel>
                      </Step>
                    )
                      })}
                  </Stepper>
                </Box>
            </div>



          </div>

          :

          <div>
          <div className="approved-container" >
            <h1>{username}, O resultado de sua redação já saiu!</h1>
                <div className="not-approved-card">
                      <h2>Sua nota foi: {testResult.nota} pontos</h2>
                </div>
          </div>
          </div>
          }
      </>)
      }
    </div>
  )
}


export {Step5Vestibular}