import React, {useEffect, useRef, useState} from 'react'
import {Step1} from './components/steps/Step1'
import {Step2} from './components/steps/Step2'
import {KTSVG} from '../../../../_metronic/helpers'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../../AUTH_CONFIGS/Auth'
import Loading from '../../../components/loading/Loading'
import { useNavigate } from 'react-router-dom'

import API from '../../../../API_CONFIGS/redux/API'


import { toast } from 'react-toastify';




export default function Registration(){
  const navigate = useNavigate()
  const auth = useAuth();

  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  
  const [currentStep, setcurrentStep] = useState(0)

  const [loading,setLoading]=useState(false)
  const [leadCompleted,setLeadCompleted]=useState(false)


  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }


  const prevStep = () => {
    if(currentStep > 0){
      setcurrentStep(currentStep-1)
    }

  }

  const submitStep = () => {
    
    if(currentStep === 0){
      handleLead(false)
      setcurrentStep(currentStep+1)
    }
    else if(currentStep === 1 && allSteps.allStep1DataValid && allSteps.allStep2DataValid){
      handleLead(true)
      // alert(JSON.stringify(allSteps))
      handleSubmitForm()
    }
    else{
      toast.info('Você precisa preencher todos os campos antes de se cadastrar')
    }
    
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])



  const handleSubmitForm = async () => {
    // alert('dados obtidos: ' + JSON.stringify(allSteps))
    setLoading(true)
    let data = {...allSteps}
    API.post('/aluno/createaccount_candidato', data)
    .then((result)=>{
      if(result.data.status === 'success'){
        toast.success(result.data.message)
        const candidato = result.data.candidato
        setLoading(false)
        if(candidato && candidato.email && candidato.cpf){
          return auth.login(candidato.email, candidato.cpf)
        }
        return navigate('/login')
      }
      else{
        setLoading(false)
        toast.error(result.data.message)        
      }
    })
    .catch((err)=>{
      setLoading(false)
      alert('ERRO AO ENVIAR ' + err) 
    })
  }





  const [allSteps, setAllSteps] = useState({
    
    password: '',
    setPassword: (password:string)=> setAllSteps(prevState=>({...prevState, password})),

    cpf1: '',
    setCpf: (cpf1:string)=> setAllSteps(prevState=>({...prevState, cpf1})),
    
    firstName: '',
    setFirstName: (firstName: string) => setAllSteps(prevState => ({ ...prevState, firstName })),

    lastName: '',
    setLastName: (lastName:string)=> setAllSteps(prevState=>({...prevState, lastName})),
  
    email:'',
    setEmail: (email:string)=> setAllSteps(prevState=>({...prevState, email})),
  
    phone: '',
    setPhone: (phone:string)=> setAllSteps(prevState=>({...prevState, phone})),

    allStep1DataValid: false,
    setAllStep1DataValid: (allStep1DataValid:boolean)=>setAllSteps(prevState=>({...prevState, allStep1DataValid})),
    
    selectedCourse:'',
    setselectedCourse: (selectedCourse:string)=>setAllSteps(prevState=>({...prevState, selectedCourse})),

    ingressoSelected:'',
    setingressoSelected: (ingressoSelected:string)=>setAllSteps(prevState=>({...prevState, ingressoSelected})),
    
    semesterSelected:'',
    setsemesterSelected: (semesterSelected:string)=>setAllSteps(prevState=>({...prevState, semesterSelected})),

    allStep2DataValid: false,
    setAllStep2DataValid: (allStep2DataValid:boolean)=>setAllSteps(prevState=>({...prevState, allStep2DataValid})),

    promotor_id: null as number | null,
    setPromotor_id: (promotor_id: number | null): void => setAllSteps(prevState => ({ ...prevState, promotor_id })),

  })



  const handleLead = (state:boolean | null)=>{


    const data = {
      cpf: allSteps?.cpf1 || '' ,
      nome: allSteps?.firstName || '' ,
      email: allSteps?.email || '' ,
      celular: allSteps?.phone || '' ,
      allStep1DataValid: allSteps?.allStep1DataValid || '' ,

      selectedCourse: allSteps?.selectedCourse || '' ,
      ingressoSelected: allSteps?.ingressoSelected || '' ,
      semesterSelected: allSteps?.semesterSelected || '' ,
      allStep2DataValid: allSteps?.allStep2DataValid || '' ,

      promotor_id: allSteps?.promotor_id || null,
  

      }

    API.post('/aluno/updateleads',data)
    .then((res)=>{
      if(allSteps?.allStep2DataValid && allSteps?.allStep1DataValid){ return setLeadCompleted(true) }
      return setLeadCompleted(false)
    })
    .catch((error)=>{
      console.error(error);
      setLeadCompleted(false)
    })
    
  }
  


  return (

    <>

    {loading && 
        <div style={{position:'absolute', left:'0', top: '0', zIndex:'9999999999999'}}>
          <Loading active={loading} text={'Carregando'} />
        </div>
    }
    
    <div className='card'>
 

      <div className='card-body'>
        <div
          // ref={currentStep}
          className='stepper stepper-links d-flex flex-column '
          id='kt_create_account_stepper'
        >
          <div className='stepper-nav mb-5'>
            

            <div 
            className={"stepper-item" + (currentStep === 0 ? " current" : "")} 
            data-kt-stepper-element='nav' 
            onClick={()=>{setcurrentStep(0)}}
            style={{cursor:'pointer'}}
            
            >
              <h3 className='stepper-title'>Dados pessoais</h3>
            </div>
            

            <div 
            className={"stepper-item" + (currentStep === 1 ? " current" : "")} 
            data-kt-stepper-element='nav'
            onClick={()=>{if(allSteps.allStep1DataValid){setcurrentStep(1);handleLead(false)}}}
            style={{cursor:'pointer'}}
            >
              <h3 className='stepper-title'>Escolha seu curso</h3>
            </div>

          </div>

            <>
              {
                currentStep === 0 &&
              
                <div >
                  <Step1 
                  // cpf1={allSteps.cpf1}
                  // setCpf={allSteps.setCpf}

                  password={allSteps.password}
                  setPassword={allSteps.setPassword}

                  firstName={allSteps.firstName}  
                  setFirstName={allSteps.setFirstName}
                  
                  lastName={allSteps.lastName}
                  setLastName={allSteps.setLastName}
                
                  email={allSteps.email}
                  setEmail={allSteps.setEmail}
                
                  phone={allSteps.phone}
                  setPhone={allSteps.setPhone}

                  allStep1DataValid={allSteps.allStep1DataValid}
                  setAllStep1DataValid={allSteps.setAllStep1DataValid}

                  />
                </div>

              }

              {
                currentStep === 1 &&
                <div >
                  <Step2
                  selectedCourse={allSteps.selectedCourse}
                  setselectedCourse={allSteps.setselectedCourse}
                  ingressoSelected={allSteps.ingressoSelected}
                  setingressoSelected={allSteps.setingressoSelected}
                  semesterSelected={allSteps.semesterSelected}
                  setsemesterSelected={allSteps.setsemesterSelected}

                  allStep2DataValid={allSteps.allStep2DataValid}
                  setAllStep2DataValid={allSteps.setAllStep2DataValid}

                  promotor_id={allSteps.promotor_id}
                  setPromotor_id={allSteps.setPromotor_id}

                  />
                </div>

                }
                <div className='d-flex flex-stack '>
                
                  <div className='mr-2'>
                    <button onClick={prevStep} type='button' className='btn btn-lg btn-light-primary me-3' disabled={currentStep === 0} >
                    <span className='indicator-label'>
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr063.svg'
                        className='svg-icon-4 me-1'
                      />
                      Back
                      </span>
                    </button>
                  </div>

                  {
                    currentStep === 0 &&
                  <div>
                    <button type='submit' className='btn btn-lg btn-primary me-3' onClick={submitStep} 
                    disabled={!allSteps.allStep1DataValid}
                    >
                      <span className='indicator-label'>
                        Continue
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg' 
                          className='svg-icon-3 ms-2 me-0'
                        />
                      </span>
                    </button>
                  </div>
                  }
                  {
                    currentStep === 1 &&
                  <div>
                    <button type='submit' className='btn btn-lg btn-primary me-3' onClick={submitStep}>
                      <span className='indicator-label'>
                        Submit
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-3 ms-2 me-0'
                        />
                      </span>
                    </button>
                  </div>
                  }

                </div>
              {/* </Form> */}
            {/* )} */}
          {/* </Formik> */}
                  <div className='text-gray-500 text-center fw-semibold fs-6' style={{paddingTop:'30px'}}>
                    Já é cadastrado?{' '}
                    <Link to='/login' className='link-primary'>Fazer login</Link>
                  </div>
          </>
        </div>
       </div>
    </div>
</>
  )
}

