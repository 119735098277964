import React, {FC, ReactChild, useEffect, useState, ReactElement} from 'react'

interface HTMLRendererProps {
    htmlString: string;
  }
  
  function HTMLRenderer({ htmlString }: HTMLRendererProps): ReactElement {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: htmlString,
        }}
      />
    );
  }
  export default HTMLRenderer