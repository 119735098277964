import {useState, useEffect} from 'react'
import {useAuth} from '../../../core/Auth'

import { cpf } from 'cpf-cnpj-validator'; 


import './step1.css'


interface Step1Props{



  password:string;
  setPassword:(password:string)=>void;
  

  // password:string;
  // setPassword:(password:string)=>void;

  firstName: string;
  setFirstName: (firstName: string)=>void;

  lastName: string;
  setLastName: (lastName:string)=>void;

  email:string;
  setEmail: (email:string)=>void;

  phone: string;
  setPhone: (phone:string)=>void;

  allStep1DataValid: boolean,
  setAllStep1DataValid: (allStep1DataValid:boolean)=>void;
}

const Step1: React.FC<Step1Props> = ({firstName, setFirstName, password , setPassword, lastName,setLastName,email,setEmail,phone,setPhone,allStep1DataValid,setAllStep1DataValid}) => {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()

  
  const [validName, setValidName]=useState(false)
  const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    
    setFirstName(event.target.value)

  }
  
  const [passwordValid,setPasswordValid] = useState(false)
  const [samePassword,setsamePassword] = useState(false)
  const [passwordToConfirm,setpasswordToConfirm] = useState('')
  const [cpfFormatted,setPasswordFormatted] = useState('')


  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>)=>{
    const value = event.target.value
    setPassword(value)
    // const pswRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
      setPasswordValid(value?.length>5)
  }

  const handleconfirmPassword = (event: React.ChangeEvent<HTMLInputElement>)=>{
    const value = event.target.value
    setpasswordToConfirm(value)
    if(value === password){
      return setsamePassword(true)
    }
    return setsamePassword(false)
  }



  // const handleCpfChange = (event: React.ChangeEvent<HTMLInputElement>)=>{
    
  //   if(event.target.value.length<=14){
  //     const cpfValue = event.target.value.replace(/\D/g, '');
  //     let formattedCpf;
  //     if (cpfValue.length <= 3) {
  //       formattedCpf = cpfValue;
  //     } else if (cpfValue.length <= 6) {
  //       formattedCpf = cpfValue.slice(0, 3) + '.' + cpfValue.slice(3);
  //     } else if (cpfValue.length <= 9) {
  //       formattedCpf = cpfValue.slice(0, 3) + '.' + cpfValue.slice(3, 6) + '.' + cpfValue.slice(6);
  //     } else {
  //       formattedCpf = cpfValue.slice(0, 3) + '.' + cpfValue.slice(3, 6) + '.' + cpfValue.slice(6, 9) + '-' + cpfValue.slice(9, 11);
  //     }
  //     setPassword(formattedCpf);
  //   }
  // }


  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>)=>{
    setLastName(event.target.value)
  }

  const [validEmail,setValidEmail]= useState(false)
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>)=>{
    const emailValue = event.target.value
    setEmail(emailValue)
  }


  const [validPhone,setValidPhone]=useState(false)
  const [phoneFormatted,setPhoneFormatted]=useState('')

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    if(event.target.value.length < 15){
      const phoneValue = event.target.value.replace(/\D/g, ''); // removes any non-digit character from the input
      let formattedPhone;
    
      if (phoneValue.length <= 2) {
        formattedPhone = '(' + phoneValue;
      } else if (phoneValue.length <= 7) {
        formattedPhone = '(' + phoneValue.slice(0, 2) + ')' + phoneValue.slice(2);
      } else {
        formattedPhone = '(' + phoneValue.slice(0, 2) + ')' + phoneValue.slice(2, 7) + '-' + phoneValue.slice(7);
      }
      setPhone(formattedPhone)
    }
  }

  useEffect(()=>{
    const emailRegex = /^[^\s@]+@[^\s@]+\.(?:co|com|br)$/i;

    if(phone.length === 14){
      setValidPhone(true)
    }
    else{
      setValidPhone(false)
    }

    
    if(emailRegex.test(email)){
      setValidEmail(true)
    }
    else{
      setValidEmail(false)
    }

    if(firstName.length > 3){
      setValidName(true)
    }
    else{
      setValidName(false)
    }


    let minhaString = password
    minhaString = minhaString.replace(/[ .-]/g, "");
    let isValidAux = false
    isValidAux = cpf.isValid(minhaString)
    if (isValidAux) {
      // setPasswordValid(true);
    } 
    else {
      // setPasswordValid(false);
    }


    
  },[phone,password,email,firstName])
  
  useEffect(()=>{
    if(validEmail && validName && validPhone && passwordValid && samePassword){
      setAllStep1DataValid(true)
    }
    else{
      setAllStep1DataValid(false)
    }
  },[validEmail,validName,validPhone,passwordValid,samePassword])


  return (
    <>
      
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6'>Nome Completo</label>
        <input
          value={firstName}
          onChange={handleFirstNameChange}
          placeholder='Nome'
          type='text'
          autoComplete='off'
          
          className={`form-control bg-transparent  ${!validName && firstName.length>0 ?'is-invalid':''} `}
        />
          {!validName && firstName.length>0 &&
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>O nome informado é muito curto</span>
            </div>
          </div>
        }

      </div>
      {/* <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6'>Sobrenome</label>
        <input
        value={lastName}
        onChange={handleLastNameChange}
          placeholder='Sobrenome'
          type='text'
          autoComplete='off'
          className={'form-control bg-transparent'}
        />
      </div> */}

      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6'>Email</label>
        <input
        value={email}
        onChange={handleEmailChange}
          placeholder='Email'
          type='email'
          autoComplete='off'
          className={`form-control bg-transparent  ${!validEmail && email.length>0 ?'is-invalid':''} `}
        />
        {!validEmail && email.length>0 &&
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>O Email informado é inválido</span>
            </div>
          </div>
        }

      </div>

      <div className='fv-row mb-3'>
      <label className='form-label fw-bolder text-dark fs-6'>Senha</label>
        <input
        value={password}
        onChange={handlePasswordChange}
          placeholder='Senha'
          type='text'
          autoComplete='off'
          className={`form-control bg-transparent inputNumber 
            ${!passwordValid && password.length>0?'is-invalid':''} 
          `}
        />
        {!passwordValid && password.length>0 &&
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>
                A senha precisa ter:
                <li>6 ou mais caracteres</li>
              </span>
            </div>
          </div>
        }
      </div>

      <div className='fv-row mb-3'>
      <label className='form-label fw-bolder text-dark fs-6'>Confirme sua senha</label>
        <input
        value={passwordToConfirm}
        onChange={handleconfirmPassword}
          placeholder='Confirme sua senha'
          type='text'
          autoComplete='off'
          className={`form-control bg-transparent inputNumber 
            ${passwordToConfirm && !samePassword  ?'is-invalid':''} 
          `}
        />
        {passwordToConfirm && !samePassword &&
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>As senhas são diferentes</span>
            </div>
          </div>
        }
      </div>

      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6'>Celular</label>
        <input
        value={phone}
        onChange={handlePhoneChange}
          placeholder='Celular'
          type='text'
          className={`form-control bg-transparent inputNumber ${!validPhone && phone.length>0 ?'is-invalid':''} `}

        />
                  {!validPhone && phone.length>0 &&
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>O telefone informado é inválido</span>
            </div>
          </div>
        }

      </div>
      </>
  )
}


export {Step1}