import {useEffect} from 'react'
import {Navigate, Routes} from 'react-router-dom'
// import {useAuth} from './core/Auth'
import { useAuth } from '../../../AUTH_CONFIGS/Auth'

export function Logout() {
  const auth = useAuth();

  useEffect(() => {
    auth.logout()
    document.location.reload()
  }, [])

  return (
    <Routes>
      <Navigate to='/auth/login' />
    </Routes>
  )
}
