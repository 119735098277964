import React, {useState, useEffect, useRef, } from 'react';

import logo from '../../../media/logos/logo.png'
import { IMAGE_BASE } from '../../../API_CONFIGS/constants';


const ReportTemplate = ({selectedEssay,  }) => {




    // console.log(selectedEssay);



    function formatDate(inputString) {
        if(!inputString){
            return ''
        }
        var inputDate = new Date(inputString)
        // Ensure input is a valid Date object
        if (!(inputDate instanceof Date) || isNaN(inputDate)) {
          throw new Error("Invalid Date");
          return ''
        }
      
        // Extract date components
        const day = String(inputDate.getDate()).padStart(2, '0');
        const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = String(inputDate.getFullYear()).slice(2); // Get last two digits of the year
      
        // Extract time components
        const hours = String(inputDate.getHours()).padStart(2, '0');
        const minutes = String(inputDate.getMinutes()).padStart(2, '0');
      
        // Create the formatted date string
        const formattedDate = `${day}/${month}/${year} - ${hours}:${minutes}`;
      
        return formattedDate;
      }

	return (
<div
  style={{
    width: '1050px',
    // height: '300px', 
    display: "flex",
    padding: 50,
    borderColor: "#000",
    flexWrap: "wrap",
    fontFamily: "Arial, sans-serif",
    fontSize: "12pt"
  }}
>
  <div style={{ textAlign: "center", marginBottom: 20, fontSize: "14pt" }}>
    <img
      src={logo}
      style={{ margin: "0px auto 0px 0px", minWidth: "40%" }}
    />
  </div>
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      gap: 0,
      margin: "0px 0px 0px auto",
      flexWrap: "wrap",
      width: "40%"
    }}
  >
    <p
      style={{ fontWeight: "bold", margin: 0, width: "100%", textAlign: "end" }}
    >
      Av. José Américo de Almeida, 447, Centro, Campina Grande, PB, CEP:
      58.406-040
      <br />
      tel: (83) 3343-6280
      <br />
      www.faculdadereboucas.com.br
    </p>
  </div>
  <div
    style={{
      backgroundColor: "#000",
      color: "#fff",
      fontWeight: "bold",
      fontSize: "16pt",
      padding: 5,
      width: "100%",
      margin: "50px 0px 0px 0px"
    }}
  >
    INFORMAÇÕES DO CANDIDATO
  </div>
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      gap: 0,
      margin: 10,
      flexWrap: "wrap",
      width: "100%"
    }}
  >
    <p style={{ fontWeight: "bold", margin: 0, width: "100%" }}>
      Nome do candidato:
    </p>
    <p style={{ margin: 0, width: "100%" }}>
      {selectedEssay?.this_candidate_data?.nome || '-'}
    </p>
  </div>
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      gap: 0,
      margin: 10,
      flexWrap: "wrap",
      width: "40%"
    }}
  >
    <p style={{ fontWeight: "bold", margin: 0, width: "100%" }}>E-mail:</p>
    <p style={{ margin: 0, width: "100%" }}>      {selectedEssay?.this_candidate_data?.email || '-'}
</p>
  </div>
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      gap: 0,
      margin: 10,
      flexWrap: "wrap",
      width: "40%"
    }}
  >
    <p style={{ fontWeight: "bold", margin: 0, width: "100%" }}>CPF:</p>
    <p style={{ margin: 0, width: "100%" }}>      {selectedEssay?.this_candidate_data?.cpf || '-'}</p>
  </div>
  <div
    style={{
      backgroundColor: "#000",
      color: "#fff",
      fontWeight: "bold",
      fontSize: "16pt",
      padding: 5,
      width: "100%",
      margin: "50px 0px 0px 0px"
    }}
  >
    VESTIBULAR E REDAÇÃO
  </div>
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      gap: 0,
      margin: 10,
      flexWrap: "wrap",
      width: "100%"
    }}
  >
    <p style={{ fontWeight: "bold", margin: 0, width: "100%" }}>Curso:</p>
    <p style={{ margin: 0, width: "100%" }}>Curso do candidato aqui</p>
  </div>
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      gap: 0,
      margin: 10,
      flexWrap: "wrap",
      width: "100%"
    }}
  >
    <p style={{ fontWeight: "bold", margin: 0, width: "100%" }}>
      Tipo de Vestibular:
    </p>
    <p style={{ margin: 0, width: "100%" }}>{selectedEssay?.tipo === 'D'? 'Digitada' : selectedEssay?.tipo === 'F'? 'Foto' : '-' }</p>
  </div>
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      gap: 0,
      margin: 10,
      flexWrap: "wrap",
      width: "100%"
    }}
  >
    <p style={{ fontWeight: "bold", margin: 0, width: "100%" }}>
      Titulo da Redação:
    </p>
    <p style={{ margin: 0, width: "100%" }}>
      {selectedEssay?.titulo || '-'}
    </p>
  </div>
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      gap: 0,
      margin: 10,
      flexWrap: "wrap",
      width: "100%"
    }}
  >
    <p style={{ fontWeight: "bold", margin: 0, width: "100%" }}>Tema:</p>
    <p style={{ margin: 0, width: "100%" }}>
      {selectedEssay?.this_tema_data?.nome || '-'}
    </p>
  </div>
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      gap: 0,
      margin: 10,
      flexWrap: "wrap",
      width: "30%"
    }}
  >
    <p style={{ fontWeight: "bold", margin: 0, width: "100%" }}>Status:</p>
    <p style={{ margin: 0, width: "100%" }}>{selectedEssay?.this_status_data?.nome || '-'}</p>
  </div>
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      gap: 0,
      margin: 10,
      flexWrap: "wrap",
      width: "30%"
    }}
  >
    <p style={{ fontWeight: "bold", margin: 0, width: "100%" }}>Nota:</p>
    <p style={{ margin: 0, width: "100%" }}>{selectedEssay?.nota || '-'}</p>
  </div>

  <div
    style={{
      display: "flex",
      flexDirection: "column",
      gap: 0,
      margin: 10,
      flexWrap: "wrap",
      width: "30%"
    }}
  >
    <p style={{ fontWeight: "bold", margin: 0, width: "100%" }}>Iniciada em:</p>
    <p style={{ margin: 0, width: "100%" }}>{formatDate(selectedEssay?.created_at || null) || '-'}</p>
  </div>
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      gap: 0,
      margin: 10,
      flexWrap: "wrap",
      width: "30%"
    }}
  >
    <p style={{ fontWeight: "bold", margin: 0, width: "100%" }}>Alterada em:</p>
    <p style={{ margin: 0, width: "100%" }}>{formatDate(selectedEssay?.updated_at || null) || '-'}</p>
  </div>
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      gap: 0,
      margin: 10,
      flexWrap: "wrap",
      width: "100%"
    }}
  >
    <p style={{ fontWeight: "bold", margin: 0, width: "100%" }}>
      Texto redigido:
    </p>
    <p style={{ margin: 0, width: "100%" }}>
      {selectedEssay?.texto || '-'}
    </p>
  </div>
  {selectedEssay?.imagem &&
    <div
        style={{
        display: "flex",
        flexDirection: "column",
        gap: 0,
        margin: 10,
        flexWrap: "wrap",
        width: "100%"
        }}
    >
        <p style={{ fontWeight: "bold", margin: 0, width: "100%" }}>
        Imagem anexa:
        </p>
        <p style={{ margin: 0, width: "100%" }}>
        <img src={`${IMAGE_BASE}redacao/${selectedEssay?.id}/${selectedEssay?.imagem}`} width={'100%'}/>
        </p>
    </div>
    }



</div>

	);
}

export default ReportTemplate;