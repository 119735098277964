import React, { useState, useEffect } from 'react';

import { styled } from '@mui/system';

import { useAuth } from "../../../AUTH_CONFIGS/Auth"

import useWindowSize from '../../../API_CONFIGS/useWindowSize';

import logoBranca from '../../../media/logos/logo_branca.png'
import avatar_light from '../../../media/icons/avatar-light.svg'





const Root = styled('div')(({theme})=>({
    width:'100vw',
    minHeight:'100px',
    display:'flex',

    gap:'5px',
    backgroundColor:'#000c7c',
    marginBottom:'15px',
    justifyContent:'center',
    alignItems:'center',



}))
const MenuOption = styled('div')(({theme})=>({
    display:'flex',
    color:'white',
    fontSize:'12pt',
    padding:'10px',
    minWidth:'100px',
    justifyContent:'center',
    alignItems:'center',
    cursor:'pointer',

    '&:hover':{
        backgroundColor:'white',
        color:'#000c7c',
        borderRadius:'5px',
        fontWeight:'bold',
    }


}))
const UserInfo = styled('div')(({theme})=>({
    display:'flex',
    gap:'10px',

    width:'200px',
    // padding:'5px',
    // paddingLeft:'10px',
    // fontSize:'12pt',
    borderRadius:'5px',
    display:'flex',
    alignItems:'center',
    color:'white',
    fontWeight:'600px',
    cursor:'pointer',
    margin:'0px 20px 0px auto',
    




}))

const UserOption = styled('div')(({theme}) => ({
    display:'flex',
    gap:'10px',

    width:'100%',
    padding:'5px',
    fontSize:'12pt',
    borderRadius:'5px',
    display:'flex',
    alignItems:'center',
    color:'#fff',
    fontWeight:'600px',
    cursor:'pointer',
    textAlign:'center',
    justifyContent:'center',
    overflow:'hidden',

    '&:hover': {
        backgroundColor: '#000c7c',
        fontWeight:'bold',
      },
    

}))





export default function (){


    const {currentUser} = useAuth()
    const { width } = useWindowSize()
    const isMobile = width < 800


    const [openUserModal,setOpenUserModal] = useState(false)




    return(
    <Root>

        <img src={logoBranca} width='200px' height='90px' style={{padding:'5px', margin:'0px auto 0px 20px'}}/>
        
        {isMobile
        ?
        <>
        </>

        :

        <>
            <MenuOption onClick={()=>{window.location.href = '/vestibular'}}>Vestibular</MenuOption>
            <MenuOption onClick={()=>{window.open('https://faculdadereboucas.com.br/')}}>FRCG</MenuOption>
            <MenuOption style={{padding:'5px', margin:'0px auto 0px 0px'}} onClick={()=>{window.open('https://faculdadereboucas.com.br/ouvidoria/')}}>Ajuda</MenuOption>

        </>
        }
        
    
        <UserInfo onClick={()=>{setOpenUserModal(!openUserModal)}}>
            <div style={{width:'1px', height:'60px', backgroundColor:'white'}}/>
            <img src={avatar_light} width='35px'style={{margin:'auto'}}/>
            <div style={{fontSize:'12pt', fontWeight:'bold'}}>
                Minha Conta
                    <div style={{fontSize:'8pt', fontWeight:'normal', maxWidth:'100%', overflow:'hidden',}}>
                        {currentUser?.nome || 'Usuário'}
                    </div>
            </div>

        </UserInfo>


        {openUserModal &&
        <>
            <div style={{width:'100vw', height:'100vh', backgroundColor:'rgba(0,0,0,0)',position:'fixed', zIndex:'997', right:'0',top:'0'}}    onClick={()=>{setOpenUserModal(!openUserModal)}}/>
            <div style={{
                    width: "0px",
                    height: "0px",
                    borderStyle: "solid",
                    borderWidth: "0 20px 20px 20px",
                    borderColor: "transparent transparent #000c7c transparent",
                    transform: "rotate(0deg)",
                    position:'relative',
                    top:'65px',
                    right:'170px',
                    zIndex:'998',
                
            }}/> 
            <div style={{width:'250px', minHeight:'50px', borderRadius:'10px', backgroundColor:'#000c7c', zIndex:'999',
                position:'absolute',
                right:'10px',
                top:'120px',
                display:'flex', flexDirection:'column',
                gap:'10px',
            }}>
                <UserOption style={{margin:'5px 0px 0px 0px', display:'flex', flexDirection:'column', gap:'0px', padding:'1px', cursor:'default'}}>{currentUser?.nome || 'Usuário'}<br/><p style={{fontSize:'10px', fontWeight:'normal', padding:'0px', margin:'0px'}}>Candidato</p></UserOption>
                {
                    isMobile &&
                    <>
                        <MenuOption onClick={()=>{window.location.href = '/vestibular'}}>Vestibular</MenuOption>
                        <MenuOption onClick={()=>{window.open('https://faculdadereboucas.com.br/')}}>FRCG</MenuOption>
                        <MenuOption onClick={()=>{window.open('https://faculdadereboucas.com.br/ouvidoria/')}}>Ajuda</MenuOption>
                    </>
                }

                <UserOption style={{color:'red', fontWeight:'bold', margin:'auto 0px 5px 0px'}}
                onClick={()=>{
                        localStorage.removeItem("token");
                        localStorage.removeItem("currentUser");
                        return window.location.href = '/login'
                }}
                >LogOut</UserOption>
            </div>
            </>
        }
    
    </Root>
    
    )


}