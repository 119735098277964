import React, { useEffect, useState } from "react";
import { styled } from '@mui/system';
import CustomButtom from "../customButtom";
import API from "../../../API_CONFIGS/redux/API";
import { toast } from "react-toastify";

import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';

import Modal_singlecourse from "./Modal_singlecourse";

import plus_light from '../../../media/icons/plus-light.svg'
import list_dark from '../../../media/icons/list-dark.svg'
import edit_dark from '../../../media/icons/edit-dark.svg'
import info_dark from '../../../media/icons/magnifying-dark.svg'
import remove_dark from '../../../media/icons/trash-dark.svg'
import arrow_up_dark from '../../../media/icons/arrow-up-dark.svg'
import arrow_down_dark from '../../../media/icons/arrow-down-dark.svg'

const RootStyle = styled('div')(({theme})=>({
    width: '100%',
    padding:'10px',
    display:'flex',
    flexDirection:'column',

}))
const SubHeader = styled('div')(({theme})=>({
    width: '100%',
    padding:'10px',
    display:'flex',

    borderBottom:'0.5px solid black'
    


}))

const Table = styled('div')(({theme})=>({
    width: '100%',
    padding:'10px',
    display:'flex', 
    flexDirection:'column',

    border:'0.5px solid black',
    // gap:'5px',
    overflow:'auto',

    


}))
const Line = styled('div')(({theme})=>({
    width: '100%',
    // padding:'5px',
    display:'flex',
    alignItems:'center',
    justifyContent:'space-between',
    padding:'0px 10px 0px 10px',
    fontSize:'12pt',
    gap:'15px', 

    // backgroundColor:'blue'

    '&:hover':{
        backgroundColor:'rgba(178, 178, 178, 0.2)',
        cursor:'default',
        borderRadius:'10px',
    }

}))
const LineInfo = styled('div')(({ theme }) => ({
    width: '10%',
    minWidth:'250px',
    height: '50px',
    display: 'flex',
    overflow: 'auto',
    position: 'relative', // Add relative positioning to LineInfo
  
  }));


  const Options = styled('div')(({theme})=>({
    width: '10%',
    minWidth:'250px',
    display:'flex',
    // justifyContent:'center',
    fontSize:'12pt',
    fontWeight:'bold',
    // gap:'5px',

    // margin:'0px 10px 0px 10px',

    



}))

const SingleElement_info_select = styled(Select)(({theme})=>({
    display:'flex',
    // width:'70px',
    maxHeight:'30px',
    margin:'auto',

    // padding:'10px',
    // border:'1px solid black',
    // borderRadius:'5px',


}))


const ButtonEditLine = styled('div')(({theme})=>({
    width: '30px',
    height:'30px',
    display:'flex',
    cursor:'pointer',
    backgroundColor:'rgba(255, 222, 119, 0.8)',
    borderRadius:'5px',
    padding:'3px',
}))
const ButtonRemoveLine = styled('div')(({theme})=>({
    width: '30px',
    height:'30px',
    display:'flex',
    cursor:'pointer',
    backgroundColor:'rgba(255, 109, 109, 0.8)',
    borderRadius:'5px',
    padding:'3px',
}))
const ButtonDetailsLine = styled('div')(({theme})=>({
    width: '30px',
    height:'30px',
    display:'flex',
    cursor:'pointer',
    backgroundColor:'rgba(186, 109, 255, 0.8)',
    borderRadius:'5px',
    padding:'5px',
}))




export default function Candidates_adm({setLoading, }){

    const [list,setList]=useState([
        {id:0, nome:'', email:'', celular:'', cpf:'', sexo:'', nascimento:'',cep:'',endereco:'',numero:'',complemento:'',bairro:'',cidade:'',estado:'', set:false},
    ])
    const [tipo_cursos,settipo_cursos]=useState([])
    const [selectedCourse,setselectedCourse]=useState(
        {id:0, nome:'', email:'', celular:'', cpf:'', sexo:'', nascimento:'',cep:'',endereco:'',numero:'',complemento:'',bairro:'',cidade:'',estado:'', set:false},
    )
    const [isChanging,setisChanging]=useState(false)
    const [isRemoving,setisRemoving]=useState(false)
    const [isCreating,setisCreating]=useState(false)

    const [qtdToShow,setQtdToShow]=useState(10)
    const [showFrom,setshowFrom]=useState(1)
    const [showTo,setshowTo]=useState(10)
    const [isFiltering,setisFiltering]=useState(true)
    const [filterType,setfilterType]=useState('')
    const [isFilterUp,setisFilterUp]=useState(true)
    const [totalPages,setTotalPages]=useState(1)
    const [page,setpage]=useState(1)
    
    const [openModal,setOpenModal]=useState(false)
    const [reload,setReload]=useState(false)
    const [options_qtd,setoptions_qtd]=useState(14)



    const [edited_selectedCourse1,setedited_selectedCourse1]=useState(
        {id:0, nome:'', email:'', celular:'', cpf:'', sexo:'', nascimento:'',cep:'',endereco:'',numero:'',complemento:'',bairro:'',cidade:'',estado:'', set:false},
        )

    useEffect(()=>{
        setLoading(true)
        API.post('administrativo/listcandidates')
        .then((res)=>{
            var aux = [...res.data.candidates]
                // aux.map((singleCourse)=>{
                    // const thisCourse_type = aux1.find(singleType=>singleCourse.tipo_id === singleType.id)
                    // singleCourse.tipo_id = thisCourse_type.nome
                    // return singleCourse
                // }).filter(Boolean)
            setList(aux)
            setLoading(false)
            return setTotalPages(Math.ceil(aux.length/qtdToShow))
        })
        .catch((error)=>{
            console.error(error);
            setLoading(false)
            return toast.error(error.response.data.message?error.response.data.message:'Erro ao buscar cursos')
        })
    },[qtdToShow,reload])

    const formatValue = (value)=>{
        value = Number(value)
        value = value.toFixed(2).replace(".",',')
        return `R$ ${value}`
    }
    const handleChangePage =(e,newPage)=>{
        const value = Number(newPage)
        return setpage(value)
    }

    useEffect(()=>{
        const to_number = page*qtdToShow
        const from_number = to_number-(qtdToShow-1)
        setshowFrom(from_number)
        return setshowTo(to_number)
    },[page,qtdToShow])



    const getNumbers = (str) => {
        let result = "";
        for (let i = 0; i < str.length; i++) {
          let char = str[i];
          if (char.match(/[0-9]/)) {
            result += char;
          }
        }
        return result;
      }
      

    const handleFilterList=()=>{
        var aux = [...list]
        if(filterType==='nome'){
            aux = aux.sort((a,b)=>{
                if(isFilterUp){
                    if (a.nome > b.nome) {
                        return -1;
                    }
                    if (b.nome > a.nome) {
                        return 1;
                    }
                    return 0;
                }
                else if(!isFilterUp){
                    if (a.nome < b.nome) {
                        return -1;
                    }
                    if (b.nome < a.nome) {
                        return 1;
                    }
                    return 0;
                }
            })
        }
        if(filterType==='tipo'){
            aux = aux.sort((a,b)=>{
                if(isFilterUp){
                    if (a.tipo_id > b.tipo_id) {
                        return -1;
                    }
                    if (b.tipo_id > a.tipo_id) {
                        return 1;
                    }
                }
                else if(!isFilterUp){
                    if (a.tipo_id < b.tipo_id) {
                        return -1;
                    }
                    if (b.tipo_id < a.tipo_id) {
                        return 1;
                    }
                }
                return 0;
            })
        }
        if(filterType==='nota_minima'){
            aux = aux.sort((a,b)=>{
                if(isFilterUp){
                    if (a.nota_minima > b.nota_minima) {
                        return -1;
                    }
                    if (b.nota_minima > a.nota_minima) {
                        return 1;
                    }
                    return 0;
                }
                else if(!isFilterUp){
                    if (a.nota_minima < b.nota_minima) {
                        return -1;
                    }
                    if (b.nota_minima < a.nota_minima) {
                        return 1;
                    }
                    return 0;
                }
            })
        }
        if(filterType==='valor'){
            aux = aux.sort((a,b)=>{
                if(!isFilterUp){
                    a.valor = Number(a.valor)
                    b.valor = Number(b.valor)
                    return a.valor-b.valor;
                }
                else if(isFilterUp){
                    a.valor = Number(a.valor)
                    b.valor = Number(b.valor)
                    return b.valor-a.valor;
                }
            })
        }
        if(filterType==='set'){
            aux = aux.sort((a,b)=>{
                if(isFilterUp){
                    if (a.set && !b.set) {
                        return -1;
                    }
                    if (!a.set && b.set) {
                        return 1;
                    }
                    return 0;
                }
                else if(!isFilterUp){
                    if (!a.set && b.set) {
                        return -1;
                    }
                    if (a.set && !b.set) {
                        return 1;
                    }
                    return 0;
                }
            })
        }
        if(filterType==='duracao'){
            aux = aux.sort((a,b)=>{
                if(isFilterUp){
                    if(a.duracao_horas && b.duracao_horas){
                        if (a.duracao_horas > b.duracao_horas) {
                            return -1;
                        }
                        if (b.duracao_horas > a.duracao_horas) {
                            return 1;
                        }
                    }
                    return 0;
                }
                else if(!isFilterUp){
                    if(a.duracao_horas && b.duracao_horas){
                        if (a.duracao_horas < b.duracao_horas) {
                            return -1;
                        }
                        if (b.duracao_horas < a.duracao_horas) {
                            return 1;
                        }
                    }
                    return 0;
                }
            })
        }
        setpage(1)
        return setList(aux) 
    }

    const editCourse = (courseData) =>{
        setLoading(true)
        API.post('administrativo/editsinglecourse', courseData)
        .then((res)=>{
            toast.success('sucesso')
            setLoading(false)
        })
        .catch((error)=>{
            console.log(error);
            setLoading(false)
            return toast.error(error.response.data.message?error.response.data.message:'Erro ao buscar cursos')
        })
    }


    useEffect(()=>{
        if (isFiltering && filterType) {
            handleFilterList();
        }
    },[isFiltering,filterType,isFilterUp])

    const handleSingleCourse = ()=>{
        setLoading(true)
        const data = {
            edited_selectedCourse1:edited_selectedCourse1,
            isChanging:isChanging,
            isRemoving:isRemoving,
            isCreating:isCreating,
        }
        API.post(`/administrativo/handlesinglecandidate`,data)
        .then((res)=>{
            setReload(!reload)
            setOpenModal(false)
            setLoading(false)
            return toast.success(res.data.message?res.data.message:'sucesso')
        })
        .catch((error)=>{
            console.log(error);
            setLoading(false)
            toast.error('erro')
        })
    }


    return(
        <RootStyle>

        {openModal && 
            <Modal_singlecourse setOpenModal={setOpenModal}  selectedCourse={selectedCourse} isChanging={isChanging} isRemoving={isRemoving} isCreating={isCreating} handleSingleCourse={handleSingleCourse} edited_selectedCourse1={edited_selectedCourse1}        setedited_selectedCourse1={setedited_selectedCourse1} tipo_cursos={tipo_cursos}  />
        }

            <SubHeader>
                <h1 style={{margin:'auto auto auto 10px', }}>
                    Candidatos {list&&`(${list.length})`} 
                </h1>
            </SubHeader>
 
                <Table>
                    <Line style={{backgroundColor:'rgba(0,0,0,0)', width:`${options_qtd*250}px`  }}>
                        <Options style={{width:'20%',justifyContent:'space-between', left:'0px',}}>
                            <div style={{display:'flex', gap:'10px', cursor:'pointer',  }} onClick={()=>{setisFiltering(true); setfilterType('nome'); setisFilterUp(!isFilterUp);  }} >
                            Nome
                            {isFiltering && filterType==='nome' && isFilterUp &&<img src={arrow_up_dark} width='20px' />}
                            {isFiltering && filterType==='nome' && !isFilterUp &&<img src={arrow_down_dark} width='20px' />}
                            </div>
                        </Options>
                        <Options >
                            <div style={{display:'flex', gap:'10px', cursor:'pointer'}} onClick={()=>{setisFiltering(true); setfilterType('tipo'); setisFilterUp(!isFilterUp);   }} >
                            Email
                            {isFiltering && filterType==='email' && isFilterUp &&<img src={arrow_up_dark} width='20px' />}
                            {isFiltering && filterType==='email' && !isFilterUp &&<img src={arrow_down_dark} width='20px' />}
                            </div>
                        </Options>
                        <Options >
                            <div style={{display:'flex', gap:'10px', cursor:'pointer'}} onClick={()=>{setisFiltering(true); setfilterType('nota_minima'); setisFilterUp(!isFilterUp);   }} >
                            Celular
                            {isFiltering && filterType==='celular' && isFilterUp &&<img src={arrow_up_dark} width='20px' />}
                            {isFiltering && filterType==='celular' && !isFilterUp &&<img src={arrow_down_dark} width='20px' />}
                            </div>
                        </Options>
                        <Options >
                            <div style={{display:'flex', gap:'10px', cursor:'pointer'}} onClick={()=>{setisFiltering(true); setfilterType('duracao'); setisFilterUp(!isFilterUp);   }} >
                            CPF
                            {isFiltering && filterType==='cpf' && isFilterUp &&<img src={arrow_up_dark} width='20px' />}
                            {isFiltering && filterType==='cpf' && !isFilterUp &&<img src={arrow_down_dark} width='20px' />}
                            </div>
                        </Options>
                        <Options >
                            <div style={{display:'flex', gap:'10px', cursor:'pointer'}} onClick={()=>{setisFiltering(true); setfilterType('valor'); setisFilterUp(!isFilterUp);   }} >
                            Sexo
                            {isFiltering && filterType==='sexo' && isFilterUp &&<img src={arrow_up_dark} width='20px' />}
                            {isFiltering && filterType==='sexo' && !isFilterUp &&<img src={arrow_down_dark} width='20px' />}
                            </div>
                        </Options>
                        <Options >
                            <div style={{display:'flex', gap:'10px', cursor:'pointer'}} onClick={()=>{setisFiltering(true); setfilterType('valor'); setisFilterUp(!isFilterUp);   }} >
                            Nascimento
                            {isFiltering && filterType==='nascimento' && isFilterUp &&<img src={arrow_up_dark} width='20px' />}
                            {isFiltering && filterType==='nascimento' && !isFilterUp &&<img src={arrow_down_dark} width='20px' />}
                            </div>
                        </Options>
                        <Options >
                            <div style={{display:'flex', gap:'10px', cursor:'pointer'}} onClick={()=>{setisFiltering(true); setfilterType('valor'); setisFilterUp(!isFilterUp);   }} >
                            CEP
                            {isFiltering && filterType==='cep' && isFilterUp &&<img src={arrow_up_dark} width='20px' />}
                            {isFiltering && filterType==='cep' && !isFilterUp &&<img src={arrow_down_dark} width='20px' />}
                            </div>
                        </Options>
                        <Options >
                            <div style={{display:'flex', gap:'10px', cursor:'pointer'}} onClick={()=>{setisFiltering(true); setfilterType('valor'); setisFilterUp(!isFilterUp);   }} >
                            Endereço
                            {isFiltering && filterType==='endereco' && isFilterUp &&<img src={arrow_up_dark} width='20px' />}
                            {isFiltering && filterType==='endereco' && !isFilterUp &&<img src={arrow_down_dark} width='20px' />}
                            </div>
                        </Options>
                        <Options >
                            <div style={{display:'flex', gap:'10px', cursor:'pointer'}} onClick={()=>{setisFiltering(true); setfilterType('valor'); setisFilterUp(!isFilterUp);   }} >
                            Numero
                            {isFiltering && filterType==='numero' && isFilterUp &&<img src={arrow_up_dark} width='20px' />}
                            {isFiltering && filterType==='numero' && !isFilterUp &&<img src={arrow_down_dark} width='20px' />}
                            </div>
                        </Options>
                        <Options >
                            <div style={{display:'flex', gap:'10px', cursor:'pointer'}} onClick={()=>{setisFiltering(true); setfilterType('valor'); setisFilterUp(!isFilterUp);   }} >
                            Complemento
                            {isFiltering && filterType==='complemento' && isFilterUp &&<img src={arrow_up_dark} width='20px' />}
                            {isFiltering && filterType==='complemento' && !isFilterUp &&<img src={arrow_down_dark} width='20px' />}
                            </div>
                        </Options>
                        <Options >
                            <div style={{display:'flex', gap:'10px', cursor:'pointer'}} onClick={()=>{setisFiltering(true); setfilterType('valor'); setisFilterUp(!isFilterUp);   }} >
                            Bairro
                            {isFiltering && filterType==='bairro' && isFilterUp &&<img src={arrow_up_dark} width='20px' />}
                            {isFiltering && filterType==='bairro' && !isFilterUp &&<img src={arrow_down_dark} width='20px' />}
                            </div>
                        </Options>
                        <Options >
                            <div style={{display:'flex', gap:'10px', cursor:'pointer'}} onClick={()=>{setisFiltering(true); setfilterType('valor'); setisFilterUp(!isFilterUp);   }} >
                            Cidade
                            {isFiltering && filterType==='cidade' && isFilterUp &&<img src={arrow_up_dark} width='20px' />}
                            {isFiltering && filterType==='cidade' && !isFilterUp &&<img src={arrow_down_dark} width='20px' />}
                            </div>
                        </Options>
                        <Options >
                            <div style={{display:'flex', gap:'10px', cursor:'pointer'}} onClick={()=>{setisFiltering(true); setfilterType('valor'); setisFilterUp(!isFilterUp);   }} >
                            Estado
                            {isFiltering && filterType==='estado' && isFilterUp &&<img src={arrow_up_dark} width='20px' />}
                            {isFiltering && filterType==='estado' && !isFilterUp &&<img src={arrow_down_dark} width='20px' />}
                            </div>
                        </Options>
                        <Options style={{width:'50px', minWidth:'50px', maxWidth:'50px',    }}>
                            <div style={{display:'flex', gap:'10px', cursor:'pointer'}} onClick={()=>{setisFiltering(true); setfilterType('set'); setisFilterUp(!isFilterUp);   }} >
                            Ativo
                            {isFiltering && filterType==='set' && isFilterUp &&<img src={arrow_up_dark} width='20px' />}
                            {isFiltering && filterType==='set' && !isFilterUp &&<img src={arrow_down_dark} width='20px' />}
                            </div>
                        </Options>
                        <LineInfo style={{display:'flex', gap:'5px', minWidth:'50px', width:'100px', justifyContent:'center',  position:'absolute', right:'10.5px', top:'65px', backgroundColor:'#f9f9f9' , boxShadow: '-2px 0 0 0 rgba(0, 0, 0, 0.2)',  }}/>
                        {/* <Options style={{width:'10%',height:'10px' }}></Options> */}
                    </Line>

                    <div style={{width:`${options_qtd*250}px`, height:'0.5px', backgroundColor:'rgba(0,0,0,0.5)', margin:'5px auto 5px auto'}}/>

                    {list&&list.map((single,index,array)=>{
                        if(index>=showFrom-1 && index<=showTo-1){
                            return(
                                <div key={index}>
                                {index>0 && 
                                    <div style={{width:`${options_qtd*250}px`,  height:'1px', backgroundColor:'rgba(178, 178, 178, 0.4)'}}/>
                                }
                                <Line key={index} >
                                    <LineInfo style={{width:'20%',justifyContent:'space-between', left:'0px', }}>{single.nome}</LineInfo>
                                    <LineInfo>{single.email}</LineInfo>
                                    <LineInfo>{single.celular}</LineInfo>
                                    <LineInfo>{single.cpf}</LineInfo>
                                    <LineInfo>{single.sexo}</LineInfo>
                                    <LineInfo>{single.nascimento}</LineInfo>
                                    <LineInfo>{single.cep}</LineInfo>
                                    <LineInfo>{single.endereco}</LineInfo>
                                    <LineInfo>{single.numero}</LineInfo>
                                    <LineInfo>{single.complemento}</LineInfo>
                                    <LineInfo>{single.bairro}</LineInfo>
                                    <LineInfo>{single.cidade}</LineInfo>
                                    <LineInfo>{single.estado}</LineInfo>
                                    <LineInfo style={{width:'250px', minWidth:'50px', maxWidth:'250px',    backgroundColor:'red' }}><div style={{padding:'2px 5px 2px 5px', display:'flex', backgroundColor:single.set?'rgba(137, 255, 143, 0.8)':'rgba(255, 137, 137, 0.8)', borderRadius:'15px', maxHeight:'30px',  }}>{single.set?'Sim':'Não'}</div></LineInfo>
                                    <LineInfo style={{display:'flex', gap:'5px', minWidth:'50px', width:'100px',  justifyContent:'center',  position:'absolute', right:'10.5px', backgroundColor:'#f9f9f9' , boxShadow: '-2px 0 0 0 rgba(0, 0, 0, 0.2)',  }}>
                                        <ButtonDetailsLine><img src={info_dark}   onClick={()=>{setselectedCourse(single) ; setisChanging(false) ; setisRemoving(false);  setisCreating(false) ; setOpenModal(true)  }}   /></ButtonDetailsLine>
                                        <ButtonEditLine><img src={edit_dark}   onClick={()=>{setselectedCourse(single) ; setisChanging(true) ; setisRemoving(false);  setisCreating(false) ; setOpenModal(true)  }}      /></ButtonEditLine>
                                        {/* <ButtonRemoveLine><img src={remove_dark}   onClick={()=>{setselectedCourse(single) ; setisChanging(false) ; setisRemoving(true);  setisCreating(false) ; setOpenModal(true)  }}      /></ButtonRemoveLine> */}
                                    </LineInfo>
                                </Line>
                                </div>
                            )
                        }
                        return null
                    })}
                </Table>

                <Stack sx={{width:'100%', margin:'20px auto 20px auto', display:'flex', flexDirection:'row',  }}>
                    <Pagination color="primary" count={totalPages} page={page} onChange={handleChangePage} sx={{width:'80%', margin:'auto' }} showLastButton/>
                    <div style={{width:'20%', margin:'auto 0px auto auto', display:'flex', gap:'20px',  alignItems:'center', justifyContent:'center', }}>
                        <div style={{ margin:'auto', display:'flex', gap:'20px',  alignItems:'center', justifyContent:'center', }}>
                            Ítens por página
                        </div>
                        <FormControl sx={{display:'flex', margin:'auto', height:'30px'}}>
                            <SingleElement_info_select
                            value={qtdToShow}
                            onChange={(e)=>{setpage(1);setQtdToShow(e.target.value)}}
                            MenuProps={{
                                sx: {
                                    zIndex: 999999999999, 
                                },
                            }}
                            inputProps={{ 'aria-label': 'Without label', }}
                            >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                            </SingleElement_info_select>
                            <FormHelperText> </FormHelperText>
                        </FormControl>

                    </div>
                </Stack>






        </RootStyle>
    )
}