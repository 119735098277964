
function login_adm(adm_data,adm_token,navigate){
    localStorage.removeItem('token')
    localStorage.removeItem('currentUser')
    const token = adm_token;
    localStorage.setItem("token", token);
    localStorage.setItem('currentUser',JSON.stringify(adm_data))
    
    
    
    
    
    return window.location.href = '/painel_administrativo'



}






module.exports = {login_adm, }